import {getAdminPermissions} from 'src/utils/AdminApi';

const {SHOW_LOADER, HIDE_LOADER, FETCH_PERMISSIONS} = require('./appActionsTypes');

export const showLoaderAction = {
	type: SHOW_LOADER,
};

export const hideLoaderAction = {
	type: HIDE_LOADER,
};

export const fetchPermissionsAction = () => {
	return async (dispatch) => {
		try {
			const permissions = await getAdminPermissions();
			dispatch({
				type: FETCH_PERMISSIONS,
				payload: {permissions},
			});
		} catch (error) {
			console.log('fail fetching permissions', error);
			// in case of api request failure
		}
	};
};
