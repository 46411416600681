import {
	Timeline,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	TimelineOppositeContent,
	TimelineSeparator,
} from '@mui/lab';
import {Box, Stack, Typography} from '@mui/material';
import QueryString from 'qs';
import {useEffect} from 'react';
import {useQuery} from 'react-query';
import AccordionWrapper from 'src/components/wrappers/AccordionWrapper';
import {getTestFlag} from 'src/getTestFlag';
import {request} from 'src/utils/AdminApi';

export default function ActivityLogs({
	initial,
	resource,
	resourceId,
	renderItem,
	title = 'سجل التغيرات',
}) {
	const filters = resourceId ? {resource, resource_id: resourceId} : {resource};

	const route = `/activities?${QueryString.stringify({
		...filters,
		_sort: 'id:desc',
	})}`;
	const {data, isLoading, refetch, error} = useQuery(route, () => request(route));

	const allLogs = data ?? [];
	/* 
        take: model, resource id (iff), 
        make request to server filtered with model and resource id 

        return list of api results
    */
	return (
		<Box sx={{p: 3}}>
			<Typography sx={{mt: 4, mb: 2, textAlign: 'center'}} variant="h6" component="div">
				{title}
			</Typography>

			{isLoading && (
				<Typography
					sx={{my: 30, textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}
					variant="h6"
					component="div"
				>
					جاري التحميل
				</Typography>
			)}
			<Timeline>
				{[...allLogs, initial].map((log, index) => {
					const operation = log.operation;
					const title = obj[resource]?.[log.event]?.title ?? log.event;
					return (
						<TimelineItem key={log.id}>
							<TimelineOppositeContent color="text.secondary">
								<Box>
									<Typography
										sx={{fontSize: 14}}
									>{`${new Date(log.created_at).toLocaleString()}`}</Typography>
								</Box>
							</TimelineOppositeContent>
							<TimelineSeparator>
								<TimelineDot
									color={operation === 'create' ? 'success' : operation === 'delete' ? 'error' : 'primary'}
								/>
								<TimelineConnector />
							</TimelineSeparator>
							<TimelineContent>
								<Stack sx={{width: 350}} spacing={1}>
									<Typography className="first-letter-capitalize">{title}</Typography>

									{log.user && (
										<Typography className="first-letter-capitalize" sx={{fontSize: 14}}>
											بواسطة: {log.user?.name}
										</Typography>
									)}
									{typeof renderItem === 'function' && renderItem(log)}
									{getTestFlag('test_mode') && (
										<AccordionWrapper parent={'data (dev mode)'}>
											<pre style={{textAlign: 'left', maxWidth: 300, overflow: 'auto'}} dir="ltr">
												{JSON.stringify(log.payload, null, 2)}
											</pre>
										</AccordionWrapper>
									)}
								</Stack>
							</TimelineContent>
						</TimelineItem>
					);
				})}
			</Timeline>
		</Box>
	);
}

const obj = {
	order: {
		create_order: {
			title: 'إنشاء الطلب',
		},
		add_order_item: {
			title: 'إضافة قطع',
		},
		update_order_item: {
			title: 'تعديل قطعة',
		},
		update_order_item_branch: {
			title: 'تغير الفرع',
		},
		delete_order_item: {
			title: 'حذف قطعة',
		},
		update_order_status: {
			title: 'تغير حالة الطلب',
		},
		exchange: {
			title: 'تبديل',
		},
		return: {
			title: 'استرجاع',
		},
	},
};
