import {DateTime} from 'luxon';

export const getTodayDateString = () => {
	const today = new Date();

	const year = today.getFullYear();
	const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
	const day = String(today.getDate()).padStart(2, '0');

	const formattedDate = `${year}-${month}-${day}`;
	return formattedDate;
};
export const getDateTimeString = (dateStr) => {
	if (!dateStr) return '';

	const arr = dateStr.split('T');
	const date = arr[0];
	const time = arr[1].replace('.000Z', '');
	return `التاريخ ${date} الوقت ${time}`;
};

// // 2022-02-23T14:52:53.000Z

export const getDateTime = (dateStr) => {
	if (!dateStr) return '';
	dateStr = DateTime.fromISO(dateStr).toISO();
	const arr = dateStr.split('T');
	const date = arr[0];
	const time = arr[1].replace('.000Z', '');
	return [date, time];
};
// used to convert utc long date that end with .000Z to local date accepted by html input  date string ex '2024-01-31T15:00:00'
export const getTextfieldDate = (dateStr) => {
	if (!dateStr) return '';
	return DateTime.fromISO(dateStr).toLocal().toISO().split('.')[0];
};
export const convertTextfieldDateToUTC = (dateStr) => {
	if (!dateStr) return '';
	return DateTime.fromISO(dateStr).toUTC().toISO().split('.')[0];
};

// used for display date
export const getUtcDateTimeFromISO = (datetime) => {
	return new Date(datetime).toLocaleString('ar-AE');
	// return DateTime.fromISO(datetime).toLocaleString();
};

export const getFormattedDate = (date) => {
	let month = (date.getMonth() + 1).toString();
	month = month.length > 1 ? month : '0' + month;
	let day = date.getDate().toString();
	day = day.length > 1 ? day : '0' + day;
	return {month, day, year: date.getFullYear()};
};

export const getDateBoundaries = (startDate, endDate = startDate) => {
	const start = DateTime.fromISO(startDate).startOf('day').toUTC().toISO();
	const end = DateTime.fromISO(endDate).endOf('day').toUTC().toISO();
	return {start, end};
};

export const getNewExpirationDate = (currentExpirationDate, addNumberOfDays) => {
	if (!currentExpirationDate) currentExpirationDate = DateTime.now().toISO();
	// TODO if currentExpirationDate < now then use current date instead of currentExpirationDate
	return DateTime.fromISO(currentExpirationDate).plus({days: addNumberOfDays}).toISODate();
};

export function getDynamicMonthListWithDates() {
	const months = [
		'يناير',
		'فبراير',
		'مارس',
		'إبريل',
		'مايو',
		'يونيو',
		'يوليو',
		'أغسطس',
		'سبتمبر',
		'أكتوبر',
		'نوفمبر',
		'ديسمبر',
	];

	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();
	const currentMonthIndex = currentDate.getMonth();

	// Calculate the number of months to display based on the current month
	const numberOfMonths = currentMonthIndex + 1;

	// Generate the list of months with start and end dates
	const monthList = [];
	for (let i = 0; i < numberOfMonths; i++) {
		const monthIndex = (currentMonthIndex - i + 12) % 12;
		const monthName = months[monthIndex];

		// Calculate start and end dates of the month
		const startDate = new Date(currentYear, monthIndex, 1, 0).toISOString();
		const lastDay = new Date(currentYear, monthIndex + 1);
		const endDate = lastDay.toISOString();

		monthList.push({
			index: i,
			month: monthName,
			startDate,
			endDate,
		});
	}

	return monthList;
}

/* 
	this when working when html date time and we want to pass datetime filter with respect to user timezone
	we will get date like this 2024-03-01T00:00:00.000Z or 2024-03-01
	and the output should be (+3 istanbul time)
	'2024-02-29T21:00:00.000Z'
*/
export const fromUTCdateToISO = (datestr) => {
	// when we send just the date it will consider it in local time and when convert it to utc it will decrement 3 hours (ist time)
	return DateTime.fromISO(datestr.split('T')[0]).toUTC().toISO();
};

/* 
	this format important for textfield datetime input
	other format causing issue
*/
export function getStartAndEndOfToday() {
	const start = DateTime.now().startOf('day').toFormat(`yyyy-MM-dd'T'HH:mm`);
	const end = DateTime.now().endOf('day').toFormat(`yyyy-MM-dd'T'HH:mm`);
	return {start, end};
}

export const htmlDateFormat = "yyyy-MM-dd'T'HH:mm";
