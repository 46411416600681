// material
import {Button, Container, Fab, Stack, Typography} from '@mui/material';
// components
import Page from '../../../components/Page';
import {OrdersList} from './Orders.list';
//
import {getOrdersAsString, getOrdersStatistics} from '../../../utils/AdminApi';
// redux
import AddIcon from '@mui/icons-material/Add';
import {useQuery} from 'react-query';
import {useNavigate} from 'react-router-dom';
import AccessControlWrapper from 'src/components/AccessControlWrapper';
import CopyButton from 'src/components/buttons/CopyButton';
import useUrlFilters from 'src/hooks/useUrlFilters';
import {
	createOrderPermission,
	findOneOrderPermission,
	findOrderPermission,
} from 'src/permissionsList';
import {canAccess} from 'src/utils/permissions';
import {getSiteCurrency} from 'src/utils/selectors';
import OrdersFilter from './OrdersFilter';
import useOrdersQuery from './useOrdersQuery';
// ----------------------------------------------------------------------

export default function Orders() {
	const {filtersQueryString, filters} = useUrlFilters();
	const {refetch, orders = [], fetchNextPage, hasNextPage} = useOrdersQuery();

	const navigate = useNavigate();

	const goToCreatePage = () => {
		navigate('/orders/create');
	};

	const _getOrdersAsString = async () => {
		const str = await getOrdersAsString(filtersQueryString);
		return str;
	};

	return (
		<AccessControlWrapper controllerAction={findOrderPermission}>
			<Page title="الطلبات">
				<Container>
					<Stack>
						<Stack sx={{mb: 3}} direction="row" alignItems="center" justifyContent="space-between">
							<Typography variant="h4">الطلبات</Typography>
							<Stack spacing={2} direction="row" justifyContent="space-between">
								{canAccess(findOneOrderPermission) && (
									<CopyButton label="نسخ الطلبات" generateValue={_getOrdersAsString} />
								)}
								{canAccess(createOrderPermission) && (
									<Fab size="small" onClick={goToCreatePage} color="primary" aria-label="add">
										<AddIcon />
									</Fab>
								)}
							</Stack>
						</Stack>
						<OrdersFilter />

						<OrdersList refetch={refetch} orders={orders} showStats={orders.length > 0} />
						{hasNextPage && (
							<Button sx={{mt: 2}} onClick={() => fetchNextPage()}>
								عرض المزيد
							</Button>
						)}
					</Stack>
				</Container>
			</Page>
		</AccessControlWrapper>
	);
}
