import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Box, Button, Typography} from '@mui/material';
import Tab from '@mui/material/Tab';
import React from 'react';
import {useQuery, useQueryClient} from 'react-query';
import {getAllFeatures} from 'src/utils/AdminApi';
import Page from '../../Page';
import Styles from './features.module.css';
import {featureStatus, getFeatures} from './features.utils';
import FeaturesList from './FeaturesList';
import CreateOrUpdateFeature from './CreateOrUpdateFeature';

function Features() {
	const {data: features = []} = useQuery('features', () => getAllFeatures(), {
		refetchOnMount: false,
		refetchOnWindowFocus: false,
	});
	const queryclient = useQueryClient();
	const setFeatures = () => {
		queryclient.refetchQueries('features');
	};

	const [showAddFeature, setShowAddFeature] = React.useState(false);

	const [selectedTab, setSelectedTab] = React.useState('suggested');

	const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	return (
		<Page title="الاقتراحات">
			<Box paddingX={{lg: 8, xl: 14}}>
				{/* <Box className={Styles.featuresMainContainer}> */}
				<Box className={Styles.featuresHeader}>
					<Typography variant="h2">الاقتراحات</Typography>
					<Button
						sx={{height: 45}}
						variant="contained"
						onClick={() => {
							setShowAddFeature(true);
						}}
					>
						+ إقتراح
					</Button>
				</Box>
				<TabContext value={selectedTab}>
					<Box sx={{borderBottom: 1, borderColor: 'divider'}}>
						<TabList variant="scrollable" onChange={handleTabChange}>
							{/* <Tab label="الجميع" value="all" /> */}
							{Object.keys(featureStatus).map((key) => (
								<Tab
									key={key}
									label={featureStatus[key].label + '     ' + featureStatus[key].emojy}
									value={key}
									sx={{py: 0.25}}
								/>
							))}
						</TabList>
					</Box>

					{Object.keys(featureStatus).map((key) => (
						<TabPanel key={key} value={key} className={Styles.TabPanelCont}>
							<FeaturesList setFeatures={setFeatures} features={features.filter((f) => f.status == key)} />
						</TabPanel>
					))}
				</TabContext>

				<CreateOrUpdateFeature
					setFeatures={setFeatures}
					showForm={showAddFeature}
					setShowForm={setShowAddFeature}
					featuresCount={0}
				/>
				{/* </Box> */}
			</Box>
		</Page>
	);
}

export default Features;
