import { AUTH_LOADING, SET_USER, AUTH_FAILURE } from './adminAuthActions';
// import UserMessages from "../../messages/user";

const initialState = {
  user: null,
  isLoading: false,
  error: null,
};

export const adminAuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SET_USER:
      return {
        user: action.user,
        isLoading: false,
      };

    case AUTH_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    default:
      return state;
  }
};
