import {useEffect, useState} from 'react';

export default function useFind(request) {
	const [data, setData] = useState([]);
	const [{isLoading, error, errorMessage}, setFetchingStatus] = useState({
		loading: true,
		error: false,
		errorMessage: '',
	});

	const fetch = async () => {
		setFetchingStatus({loading: true, error: false, errorMessage: ''});
		try {
			const response = await request();
			setData(response);
			setFetchingStatus({loading: false, error: false, errorMessage: ''});
		} catch (err) {
			// handle error
			setFetchingStatus({
				loading: false,
				error: true,
				errorMessage: err?.message ?? 'حدث خطا غير متوقع',
			});
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	return [data, setData, {isLoading, error, errorMessage}];
}
