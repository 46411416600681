import React from 'react';
import {createListFromRange} from 'src/utils/array';
import Picker from 'src/components/inputs/Picker';

export default function DiscountPicker({
	disabled = false,
	sx = {},
	label = '%',
	value,
	name,
	onChange,
	placeholder,
}) {
	return (
		<Picker
			placeholder={placeholder}
			disabled={disabled}
			sx={sx}
			label={label}
			fullWidth
			labelKey="label"
			valueKey="value"
			options={[
				{
					label: '-',
					value: '',
				},

				...createListFromRange(0, 100, 5, (el) => ({
					label: `%${el}`,
					value: el,
				})),
			]}
			name={name}
			value={value}
			onChange={(e) => {
				onChange(e.target.value);
			}}
		/>
	);
}
