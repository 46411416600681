import {getLoggedInUser} from './selectors';
import {ADMINS_ACTIONS} from 'src/types/enums/ADMINS_ACTIONS';
// format: controller.action
export const canAccess = (controllerAction?: ADMINS_ACTIONS | keyof typeof ADMINS_ACTIONS) => {
	let user = getLoggedInUser();

	if (!user) return false;

	if (user.isOwner === true) return true;
	else
		return !!user.allowed_actions.find(
			(action) => controllerAction?.toLowerCase() === `${action.identifier}`.toLowerCase(),
		);
};

export const isOrderAssignee = (order) => {
	const user = getLoggedInUser();
	if (!user) return false;

	return order?.assignee?.some((assignee) => assignee.id == user.id);
};

export const canAccessOrder = (order) => {
	const user = getLoggedInUser();
	if (!user) return false;

	return user.isOwner || isOrderAssignee(order);
};
