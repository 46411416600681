import React, {useState} from 'react';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import {Box} from '@mui/material';

// value key if we have object instead of string
const CommaSeparatedTextField = ({
	containerSx = {},
	label,
	valueFormatter,
	labelFormatter,
	value = [],
	setValue = () => {},
}) => {
	const [inputValue, setInputValue] = useState('');

	const handleInputChange = (event) => {
		setInputValue(event.target.value);
	};

	const handleInputKeyDown = (event) => {
		if (event.key === 'Enter' && inputValue.trim() !== '') {
			setValue([...value, valueFormatter?.(inputValue.trim()) ?? inputValue.trim()]);
			setInputValue('');
		}
	};

	const handleDeleteChip = (index) => {
		const newValues = [...value];
		newValues.splice(index, 1);
		setValue(newValues);
	};

	return (
		<Box sx={containerSx} flex={1}>
			<TextField
				label={label}
				value={inputValue}
				onChange={handleInputChange}
				onKeyDown={handleInputKeyDown}
				fullWidth
			/>
			<div style={{marginTop: '10px'}}>
				{value.map((_value, index) => (
					<Chip
						key={index}
						label={labelFormatter?.(_value) ?? _value}
						onDelete={() => handleDeleteChip(index)}
						style={{margin: '4px'}}
					/>
				))}
			</div>
		</Box>
	);
};

export default CommaSeparatedTextField;
