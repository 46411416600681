import {Container, Typography} from '@mui/material';
import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import DynamicForm from 'src/components/DynamicForm/DynamicForm';
import Page from 'src/components/Page';
import {nameValidation, stringValidatoin} from 'src/schema/commanValidationSchema';
import {createDeliveryMethod} from 'src/utils/AdminApi';

export default function CreateDeliveryMethod() {
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const onSubmit = async ({name, type}) => {
		try {
			const res = await createDeliveryMethod(name, type);
			queryClient.refetchQueries('delivery_methods');
			navigate('/delivery-methods/' + res.id);
		} catch (error) {
			// handle error
		}
	};
	// type, name
	return (
		<Page title="اضافة طريقة شحن">
			<Container>
				<Typography>اضافة طريقة توصيل/استلام </Typography>
				<DynamicForm config={formConfig} onSubmit={onSubmit} />
			</Container>
		</Page>
	);
}

const formConfig = {
	inputs: [
		{
			label: 'الاسم',
			placeholder: 'الاسم',
			name: 'name',
			type: 'text',
			validation: nameValidation.required('يرجي ادخال اسم طريقة التوصيل'),
			value: '',
		},
		{
			label: 'النوع',
			placeholder: 'النوع',
			name: 'type',
			type: 'picker',
			options: [
				{id: 'flatRate', label: 'توصيل'},
				{id: 'pickup', label: 'استلام شخصي'},
			],
			labelKey: 'label',
			valueKey: 'id',
			validation: stringValidatoin.required('يرجي اختيار النوع'),
			value: '',
		},
	],
};
