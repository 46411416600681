import {useState} from 'react';
export default function useDynamicInputsCRUD(defaultState = []) {
	const [items, setItems] = useState(defaultState ?? []);

	const handleItemChange = (index, changes) => {
		let updatedItems = [];
		items.forEach((v, i) => {
			if (i === index) {
				updatedItems.push({...v, ...changes});
			} else {
				updatedItems.push(v);
			}
		});
		setItems(updatedItems);
	};

	const onRemoveItem = (index) => {
		let updatedItems = [];
		items.forEach((v, i) => {
			if (i != index) {
				updatedItems.push(v);
			}
		});
		setItems(updatedItems);
	};

	const addItem = (item) => {
		setItems([...items, item]);
	};

	return {
		state: items,
		onRemoveItem,
		addItem,
		handleItemChange,
		setItems,
	};
}
