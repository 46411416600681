import {Card, Grid, Stack} from '@mui/material';
import React from 'react';
import InfoTip from 'src/components/ui/InfoTip';

export default function StatsItem({label, value, subValue, info}) {
	return (
		<Grid item xs={12} sm={4} md={4}>
			<Card sx={{p: 2, minHeight: 140}}>
				<Stack alignItems="center">
					<h5>{label}</h5>
					<h1 style={{fontSize: 42, color: '#36B858'}}>{value}</h1>
					{subValue && <h3 style={{color: '#36B858'}}>{subValue}</h3>}
				</Stack>
				{info && <InfoTip sx={{position: 'absolute', top: 8, right: 8}} title={info} />}
			</Card>
		</Grid>
	);
}
