import {rawAllCountries} from 'src/data/countries';
import {getFormattedPhoneNumber, normalizeArabicText} from 'src/utils/stringUtils';
import {
	dhlPackageCodes,
	dhlProductTable,
	dhlShippingTerms,
	dhlTypeCode,
	dhlUnitDescriptions,
} from './dhl-constants';
import {request, updateOrder} from 'src/utils/AdminApi';
import {toast} from 'react-toastify';
import getDhlRequestBody from './getDhlRequestBody';
import {useFormik} from 'formik';
import {useQuery} from 'react-query';
import _ from 'lodash';
import {dhlValidationSchema} from './dhlSchema';
import {useEffect, useState} from 'react';
import {getOrderItemsWeight} from 'src/utils/orderUtils';
import {DateTime} from 'luxon';
import {htmlDateFormat} from 'src/utils/date';
import {getNumber} from 'src/utils/numberUtils';
import {useDebounce} from 'src/hooks/useDebounce';
import {useNavigate} from 'react-router';

export default function useDhlForm({order, dhlConfig}) {
	const navigate = useNavigate();
	const [shipper_cities_options, set_shipper_cities_options] = useState([]);
	const [receiver_cities_options, set_receiver_cities_options] = useState([]);

	const send_inputs = [
		'العنوان',
		[
			{
				label: 'countryCode',
				name: 'customerDetails.shipperDetails.postalAddress.countryCode',
				defaultValue: 'LY',
				...getCountryCodeProps(),
				onChange: (_, newCountryCode) => {
					formik.setFieldValue(
						'customerDetails.shipperDetails.postalAddress.countryCode',
						newCountryCode?.code,
					);
					formik.setFieldValue(`customerDetails.shipperDetails.postalAddress.cityName`, '');

					set_shipper_cities_options([]);
				},
			},
			{
				label: 'cityName',
				name: 'customerDetails.shipperDetails.postalAddress.cityName',
				defaultValue: 'Tripoli',
				...getCityProps({
					cities: shipper_cities_options,
					setFieldValue: (key, value) => {
						formik.setFieldValue(key, value);
					},
					isSender: true,
				}),
			},
			{
				size: 'medium',
				label: 'postalCode',
				name: 'customerDetails.shipperDetails.postalAddress.postalCode',
				helperText: 'leave it empty if not available',
				defaultValue: '',
			},
		],
		{
			label: 'addressLine1',
			name: 'customerDetails.shipperDetails.postalAddress.addressLine1',
			defaultValue: dhlConfig.sender_address ?? '',
		},
		'معلومات الاتصال',
		[
			{
				label: 'fullName',
				name: 'customerDetails.shipperDetails.contactInformation.fullName',
				defaultValue: dhlConfig.sender_fullname ?? '',
			},
			{
				label: 'companyName',
				name: 'customerDetails.shipperDetails.contactInformation.companyName',
				defaultValue: dhlConfig.sender_company_name ?? '',
			},
		],
		{
			label: 'email',
			name: 'customerDetails.shipperDetails.contactInformation.email',
			/* TODO i think this should be the sender customer */
			defaultValue: dhlConfig.sender_email ?? '',
		},
		[
			{
				label: 'phone',
				name: 'customerDetails.shipperDetails.contactInformation.phone',
				/* TODO */
				defaultValue: dhlConfig.sender_phone ?? '',
			},
			{
				label: 'mobilePhone',
				name: 'customerDetails.shipperDetails.contactInformation.mobilePhone',
				defaultValue: dhlConfig.sender_phone ?? '',
			},
		],
		{
			name: 'customerDetails.shipperDetails.typeCode',
			label: 'typeCode',
			type: 'picker',
			options: dhlTypeCode,
			defaultValue: 'business',
		},
	];
	const receiver_inputs = [
		'العنوان',

		[
			{
				label: 'countryCode',
				name: 'customerDetails.receiverDetails.postalAddress.countryCode',
				defaultValue: order.delivery_info.country,
				...getCountryCodeProps(),
				onChange: (_, newCountryCode) => {
					formik.setFieldValue(
						'customerDetails.receiverDetails.postalAddress.countryCode',
						newCountryCode?.code,
					);
					formik.setFieldValue(`customerDetails.receiverDetails.postalAddress.cityName`, '');

					set_receiver_cities_options([]);
				},
			},
			{
				label: 'cityName',
				name: 'customerDetails.receiverDetails.postalAddress.cityName',
				defaultValue: order.delivery_info.city,
				...getCityProps({
					cities: receiver_cities_options,
					setFieldValue: (key, value) => {
						formik.setFieldValue(key, value);
					},
					isSender: false,
				}),
			},
			{
				label: 'postalCode',
				name: 'customerDetails.receiverDetails.postalAddress.postalCode',
				helperText: 'leave it empty if not available',
				defaultValue: order.delivery_info.postal,
			},
		],
		{
			label: 'addressLine1',
			name: 'customerDetails.receiverDetails.postalAddress.addressLine1',
			defaultValue: order.delivery_info.address,
		},
		'معلومات الاتصال',

		[
			{
				label: 'companyName',
				name: 'customerDetails.receiverDetails.contactInformation.companyName',
				defaultValue: order.customer?.name ?? order.name,
			},
			{
				label: 'fullName',
				name: 'customerDetails.receiverDetails.contactInformation.fullName',
				defaultValue: order.customer?.name ?? order.name,
			},
		],
		{
			label: 'email',
			name: 'customerDetails.receiverDetails.contactInformation.email',
			defaultValue: order.customer?.email ?? order.email ?? '',
		},
		[
			{
				label: 'phone',
				name: 'customerDetails.receiverDetails.contactInformation.phone',
				defaultValue: getFormattedPhoneNumber(
					order.customer?.phone ?? order.phone,
					true,
					order.phone_country_code,
				).replace('+', ''),
			},
			{
				label: 'mobilePhone',
				name: 'customerDetails.receiverDetails.contactInformation.mobilePhone',
				defaultValue: getFormattedPhoneNumber(
					order.customer?.phone ?? order.phone,
					true,
					order.phone_country_code,
				).replace('+', ''),
			},
		],
		{
			name: 'customerDetails.receiverDetails.typeCode',
			label: 'typeCode',
			type: 'picker',
			options: dhlTypeCode,
			defaultValue: 'direct_consumer',
		},
	];

	const info_inputs = [
		{
			label: 'plannedShippingDateAndTime',
			name: 'info.plannedShippingDateAndTime',
			defaultValue: DateTime.now().toFormat(htmlDateFormat),
			// plus({day: 3})
			htmlType: 'datetime-local',
			// helperText: 'shipment description in (english)',
		},

		{
			label: 'unitOfMeasurement',
			name: 'info.unitOfMeasurement',
			defaultValue: 'metric',
			options: ['metric', 'imperial'],
			type: 'picker',
		},
		{
			label: 'incoterm',
			name: 'info.incoterm',
			defaultValue: 'DAP',
			options: dhlShippingTerms.map((v) => v.code),
			type: 'picker',
			renderMenuItem: (code) => {
				const option = dhlShippingTerms.find((v) => v.code === code);
				return `${option.code} - ${option.description}`;
			},
			info:
				'The Incoterms rules are a globally-recognized set of standards, used worldwide in international and domestic contracts for the delivery of goods, illustrating responsibilities between buyer and seller for costs and risk, as well as cargo insurance.',
		},
		{
			label: 'isCustomsDeclarable',
			name: 'info.isCustomsDeclarable',
			defaultValue: dhlConfig?.isCustomsDeclarable ?? false,

			/* TODO */
			// helperText: 'shipment description in (english)',
			info:
				'For customs purposes please advise if your shipment is dutiable (true) or non dutiable (false).Note:If the shipment is dutiable, exportDeclaration element must be provided.',
			type: 'checkbox',
		},
		[
			{
				label: 'declaredValue',
				name: 'info.declaredValue',
				defaultValue: 8, // (order.total * 1000 - order.delivery_price * 1000) / 1000,
				htmlType: 'number',
				// helperText: 'shipment description in (english)',
			},
			{
				label: 'declaredValueCurrency',
				name: 'info.declaredValueCurrency',
				/* TODO take it from site currencty?? */
				defaultValue: 'EUR',
				options: ['EUR', 'USD', 'LYD'],
				type: 'picker',
				// helperText: 'shipment description in (english)',
			},
		],
		[
			{
				freeSolo: true,
				label: 'productCode',
				name: 'info.productCode',
				type: 'autocomplete',
				helperText: ' DHL Express Global Product code',
				options: dhlProductTable,
				getOptionLabel: (option) => {
					// console.log('option label', option);
					return `${option.ProductCode} - ${option.Name}`;
				},
				onChange: (e, option) => {
					// console.log('onChange option', option);
					formik.setFieldValue('info.productCode', option?.ProductCode ?? '');
				},
				onInputChange: (e, option) => {
					if (!e?.target?.value) return; /* prevent set value when mount */
					formik.setFieldValue('info.productCode', option);
				},
				valueKey: 'ProductCode',
				defaultValue: dhlConfig.isCustomsDeclarable ? 'P' : 'D',
				/* description and guide */
				// helperText: 'shipment description in (english)',
			},
			{
				freeSolo: true,
				label: 'localProductCode',
				name: 'info.localProductCode',
				type: 'autocomplete',
				helperText: ' DHL Express Local Product code. Important when shipping domestic products.',
				options: dhlProductTable,
				getOptionLabel: (option) => {
					// console.log('option label', option);
					return `${option.ProductCode} - ${option.Name}`;
				},
				onChange: (e, option) => {
					// console.log('onChange option', option);
					formik.setFieldValue('info.localProductCode', option?.ProductCode ?? '');
				},
				onInputChange: (e, option) => {
					// console.log('onInputChange option', option);
					if (!e?.target?.value) return; /* prevent set value when mount */
					formik.setFieldValue('info.localProductCode', option);
				},
				valueKey: 'ProductCode',
				defaultValue: dhlConfig.isCustomsDeclarable ? 'P' : 'D',
			},
		],
		// 'الاشعارات',
		// [
		// 	{
		// 		label: 'notification email',
		// 		name: 'info.receiverId',
		// 		defaultValue: 'info@alassalsweets.com',
		// 		info:
		// 			'This is to support sending email notification once the shipment is created. The email will contain the basic information on the shipper, recipient, waybill number, and shipment information.',
		// 	},

		// 	{
		// 		label: 'bespokeMessage',
		// 		name: 'info.bespokeMessage',
		// 		defaultValue: '',
		// 		info: 'message to be included in the notification',
		// 	},
		// ],

		{
			label: 'description',
			name: 'info.description',
			defaultValue: dhlConfig.default_description ?? '',
			variant: 'filled',
			helperText: 'shipment description in (english)',
			multiline: true,
			rows: 3,
			dir: 'ltr',
		},
	];

	const items_inputs = order.items.map((item, index) => {
		return [
			{
				label: 'description',
				name: `content.exportDeclaration.lineItems[${index}].description`,
				defaultValue: item.product.custom_fields?.english_name ?? item.product.name,
			},
			[
				{
					label: 'price',
					name: `content.exportDeclaration.lineItems[${index}].price`,
					defaultValue: item.price,
					htmlType: 'number',
				},
				{
					label: 'quantity',
					name: `content.exportDeclaration.lineItems[${index}].quantity.value`,
					defaultValue: item.quantity,
					htmlType: 'number',
				},
				{
					label: 'unitOfMeasurement',
					name: `content.exportDeclaration.lineItems[${index}].quantity.unitOfMeasurement`,
					type: 'picker',
					options: dhlUnitDescriptions.map((v) => v.code),
					renderMenuItem: (code) => {
						const option = dhlUnitDescriptions.find((v) => v.code === code);
						return `${code} - ${option?.description}`;
					},
					defaultValue: 'KG',
				},
			],
			[
				{
					label: 'weight ( grossValue )',
					name: `content.exportDeclaration.lineItems[${index}].weight.grossValue`,
					defaultValue: getNumber(item.weight, 0.1) * 1 /* to cast */,
					htmlType: 'number',
				},
				{
					label: 'weight ( netValue )',
					name: `content.exportDeclaration.lineItems[${index}].weight.netValue`,
					defaultValue: (getNumber(item.weight, 0.1) * 100 * item.quantity) / 100,
					htmlType: 'number',
				},
			],
		];
	});
	const packages = [{}];
	const package_inputs = packages.map((item, index) => {
		return [
			[
				{
					label: 'weight',
					name: `content.packages[${index}].weight`,
					defaultValue: getOrderItemsWeight(order.items),
					htmlType: 'number',
				},
				{
					label: 'typeCode',
					name: `content.packages[${index}].typeCode`,
					defaultValue: '2BP',
					type: 'picker',
					options: dhlPackageCodes.map((v) => v.code),
					renderMenuItem: (code) => {
						const option = dhlPackageCodes.find((v) => v.code === code);
						return `${code} - ${option?.description}`;
					},
					info:
						'Please contact your DHL Express representative if you wish to use a DHL specific package otherwise ignore this element.',
				},
			],
			[
				{
					label: 'length',
					name: `content.packages[${index}].dimensions.length`,
					defaultValue: dhlConfig?.length ?? 10,
					htmlType: 'number',
				},
				{
					label: 'width',
					name: `content.packages[${index}].dimensions.width`,
					defaultValue: dhlConfig?.width ?? 10,
					htmlType: 'number',
				},
				{
					label: 'height',
					name: `content.packages[${index}].dimensions.height`,
					defaultValue: dhlConfig?.height ?? 10,
					htmlType: 'number',
				},
			],
		];
	});

	const formik = useFormik({
		initialValues: getInitialValues(),
		validationSchema: dhlValidationSchema,
		onSubmit: async (v) => {
			console.log('submit values', v);
			const body = getDhlRequestBody(v, order, dhlConfig);
			console.log('🚀 ~ onSubmit: ~ body:', body);

			try {
				const res = await request('/createDhlShipment', 'POST', body);
				await updateOrder(order.uuid, {
					delivery_info: {
						...order.delivery_info,
						shippingProvider: 'dhl',
						shippingId: res.shipmentTrackingNumber,
					},
				});
				// console.log(res.shipmentTrackingNumber);
				toast.success('تم إنشاء الشحنة بنجاح');

				// Your Base64 encoded PDF string
				var base64PDF = res.documents[0].content;

				// Create a new window
				var pdfWindow = window.open('');

				// Create a data URI
				var dataURI = 'data:application/pdf;base64,' + base64PDF;

				// Write the iframe HTML to the new window
				pdfWindow.document.write('<iframe width="100%" height="100%" src="' + dataURI + '"></iframe>');

				navigate(`/orders/${order.uuid}`, {
					replace: true,
				});
			} catch (err) {
				console.error('submit failure', err);
				toast.error('حدث خطأ ما');
				if (err.response) {
					console.log(err.response.data);
					let msg = err.response.data.detail;
					const additionalDetails = err.response.data.additionalDetails;
					if (additionalDetails) {
						if (Array.isArray(additionalDetails)) {
							msg = msg + '\n\n' + additionalDetails.map((detail) => detail).join('\n');
						} else {
							msg = msg + '\n\n' + additionalDetails;
						}
					}
					alert(msg);
				} else {
					console.log('we didnot get response');
				}
			}
		},
	});

	const shipper_country_code = _.get(
		formik.values,
		'customerDetails.shipperDetails.postalAddress.countryCode',
	);

	useQuery(
		['cities', shipper_country_code],
		() => request(`/getDhlCities?countryCode=${shipper_country_code}`),
		{
			enabled: !!shipper_country_code,
			onSuccess: (data) => set_shipper_cities_options(data),
		},
	);

	const receiver_country_code = _.get(
		formik.values,
		'customerDetails.receiverDetails.postalAddress.countryCode',
	);
	const debounced_receiver_city_name = useDebounce(
		_.get(formik.values, 'customerDetails.receiverDetails.postalAddress.cityName'),
		300,
	);

	const {data: _receiver_cities_options} = useQuery(
		['cities', receiver_country_code, debounced_receiver_city_name],
		() => {
			let url = `/getDhlCities?countryCode=${receiver_country_code}`;
			if (debounced_receiver_city_name?.length > 0) {
				url += `&city=${debounced_receiver_city_name}`;
			}
			return request(url);
		},
		{
			enabled: !!receiver_country_code,

			staleTime: 1000 * 60 * 60 * 24,
			cacheTime: 1000 * 60 * 60 * 24,
		},
	);

	useEffect(() => {
		set_receiver_cities_options(_receiver_cities_options ?? []);
	}, [_receiver_cities_options]);

	return {
		formik,
		send_inputs,
		receiver_inputs,
		info_inputs,
		items_inputs,
		package_inputs,
	};
	function getInitialValues() {
		let initialValues = {};

		setInitialValuesRecursively(send_inputs, initialValues);
		setInitialValuesRecursively(receiver_inputs, initialValues);
		setInitialValuesRecursively(info_inputs, initialValues);
		setInitialValuesRecursively(items_inputs, initialValues);
		setInitialValuesRecursively(package_inputs, initialValues);

		return initialValues;
	}
}

function setInitialValuesRecursively(items, initialValues) {
	items.forEach((item) => {
		if (typeof item === 'string') return;
		if (Array.isArray(item)) {
			// If the item is an array, call this function recursively
			setInitialValuesRecursively(item, initialValues);
		} else {
			// If the item is not an array, set the value in initialValues
			_.set(initialValues, item.name, item.defaultValue);
		}
	});
}

function getCountryCodeProps() {
	return {
		type: 'autocomplete',
		size: 'medium',
		valueKey: 'code',
		getOptionLabel: (c) => `${c.code} | ${c.flag} ${c.arabicName}`,
		options: rawAllCountries,

		filterOptions: (options, {inputValue}) => {
			const filtered = options.filter(
				(option) =>
					option.code.toLowerCase().includes(inputValue.toLowerCase()) ||
					option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
					normalizeArabicText(option.arabicName).includes(inputValue.toLowerCase()) ||
					option.dial_code.includes(inputValue),
			);

			const searchTerm = inputValue.toUpperCase();

			function getMatchScore(item, searchTerm) {
				const code = item.code.toUpperCase();
				if (code.startsWith(searchTerm)) {
					return 3; // Best match
				} else if (code.includes(searchTerm)) {
					return 2; // Medium match
				} else {
					return 1; // Least match
				}
			}

			const sortedResults = filtered
				.map((item) => ({...item, score: getMatchScore(item, searchTerm)}))
				.sort((a, b) => b.score - a.score);

			return sortedResults;
		},
	};
}

function getCityProps({cities, setFieldValue, isSender}) {
	const key = isSender ? 'shipperDetails' : 'receiverDetails';
	return {
		size: 'medium',
		freeSolo: true,
		type: 'autocomplete',
		options: cities.map((c) => c.city),
		getOptionLabel: (c) => {
			console.log('option label', c);
			const city = cities.find((x) => x.city === c);
			console.log('🚀 ~ getCityProps ~ city:', cities?.[0], typeof city, city?.postalCode);
			if (!city?.postalCode) return c;
			return `${city.city} | ${city.postalCode}`;
		},
		onChange: (_, newCityName) => {
			const selectedCity = cities.find((c) => c.city === newCityName);
			setFieldValue(`customerDetails.${key}.postalAddress.cityName`, newCityName);
			if (selectedCity?.postalCode) {
				setFieldValue(`customerDetails.${key}.postalAddress.postalCode`, selectedCity.postalCode ?? '');
			}
		},
		onInputChange: (e, newCityName) => {
			if (!e?.target?.value) return; /* prevent set value when mount */
			setFieldValue(`customerDetails.${key}.postalAddress.cityName`, newCityName ?? '');
		},
	};
}
