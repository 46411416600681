// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// css
import './App.css';
// components
import {Provider} from 'react-redux';
// Create rtl cache
import {QueryClient, QueryClientProvider} from 'react-query';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import store from 'src/redux-store';
import ServiceWorkerWrapper from './ServiceWorkerWrapper';
import RTL from './theme/RTL';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useEffect} from 'react';
import {storeAdminToken} from './utils/storage';
import {ModalProvider} from 'src/hooks/useModal';
import {accountIsDemo} from 'src/utils/customSite';

////////////////////////////////////////

const reactQueryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});
export default function App() {
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		const token = searchParams.get('token');
		if (token != null) {
			storeAdminToken(token);
			window.location.href = '/';
		}
	}, []);

	const navigate = useNavigate();

	useEffect(() => {
		if (!accountIsDemo()) return;
		const handleKeyDown = (event) => {
			if (event.ctrlKey) {
				switch (event.key) {
					case '1':
						navigate('/');
						break;
					case '2':
						navigate('/orders');
						break;
					case '3':
						navigate('/orders/pos');
						break;
					case '4':
						navigate('/products');
						break;
					default:
						break;
				}
			}
		};

		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [navigate]);

	return (
		<QueryClientProvider client={reactQueryClient}>
			<Provider store={store}>
				{/* <StylesProvider jss = {jss} > */}
				<ThemeConfig>
					<RTL>
						<ToastContainer
							position="top-center"
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>
						<ModalProvider>
							<Router />
						</ModalProvider>
						<ServiceWorkerWrapper />
					</RTL>
				</ThemeConfig>
				{/* </StylesProvider> */}
			</Provider>
		</QueryClientProvider>
	);
}
