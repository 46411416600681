import {Alert, Card, Container, Stack, Typography} from '@mui/material';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import DynamicForm from 'src/components/DynamicForm/DynamicForm';
import {setUpShippingAccountAction, updateTenantStoreConfigAction} from 'src/reducers';
import {stringValidatoin} from 'src/schema/commanValidationSchema';
import {request} from 'src/utils/AdminApi';
import {formatStrapiError} from 'src/utils/helperMethods';
import {handleAccessError} from 'src/utils/shipping-api/handleShippingErrors';
import * as yup from 'yup';

export default function FoxlineSettingsPage() {
	const provider = 'foxline';
	const dispatch = useDispatch();
	const site = useSelector((s) => s.admin.user.OwnedWebsite);
	const providerConfig = site?.config?.shippingProviders?.[provider] ?? {};
	const providerStoreConfig = site?.config?.[provider] ?? {};
	const isEnabled = providerConfig?.enabled === true;
	const providerIsReady = providerConfig?.credentials != null;
	const [errorMsg, setErrorMsg] = useState();

	const onSubmit = async (values) => {
		setErrorMsg(null);
		const {username, password, rate, enabled} = values;
		const credentials = {
			username,
			password,
		};
		const formValues = {
			credentials,
			enabled: true,
			rate,
		};

		try {
			await dispatch(
				setUpShippingAccountAction({
					...providerConfig,
					...formValues,
					provider,
				}),
			);
			toast.success('تم الربط بنجاح');
		} catch (error) {
			// 400 invalid username/password
			// 406 not enabled or not configured correctly
			// 5xx errors from there server
			if (error?.response?.data) {
				return setErrorMsg(error?.response?.data.message);
			}
			let [status, errorMessage] = handleAccessError(provider, error);
			return setErrorMsg(errorMessage);
		}
	};
	const formConfig = {
		inputs: [
			{
				label: 'اسم المستخدم',
				name: 'username',
				type: 'text',
				validation: yup.string().required('يرجي ادخال اسم المستخدم'),
				value: providerConfig?.credentials?.username ?? '',
			},
			{
				label: 'كلمة المرور',
				placeholder: 'كلمة المرور الخاصة بدرب السبيل',
				name: 'password',
				type: 'password',
				validation: yup.string().required('يرجي ادخال كلمة المرور'),
				value: '',
			},
			{
				label: 'سعر التحويل من اليورو',
				name: 'rate',
				type: 'number',
				// validation: nameValidation,
				value: providerStoreConfig.rate ?? 1,
			},
			{
				label: 'مفعلة',
				name: 'enabled',
				type: 'checkbox',
				// validation: nameValidation,
				value: providerConfig.enabled ?? false,
			},
		],
	};

	return (
		<Container maxWidth="sm">
			<Card sx={{p: 4}}>
				<Stack py={1} alignItems="center">
					<img src={`/third-party-icons/${provider}-icon-dark.png`} height={80} />
					<Typography variant="h4">اعدادات الربط </Typography>
				</Stack>
				<Typography sx={{textAlign: 'center', color: 'gray', pb: 4}} variant="subtitle2">
					يرجي ادخال بيانات حسابك
				</Typography>
				<Stack>
					<DynamicForm submitButtonText="تفعيل" config={formConfig} onSubmit={onSubmit} />
				</Stack>

				{errorMsg && (
					<Alert sx={{fontSize: 18, mt: 2}} severity="error">
						{errorMsg}
					</Alert>
				)}
			</Card>

			<Card sx={{p: 4, mt: 5}}>
				<FoxlineCalcPriceForm />
			</Card>
		</Container>
	);
}

function FoxlineCalcPriceForm() {
	const onSubmit = async (values) => {
		// dummy city
		try {
			const res = await request(
				`/shipping/foxline/price?city=test&country=${values.country}&total_weight=${values.weight}`,
			);

			alert(res.price);
		} catch (err) {
			toast.error(formatStrapiError(err) ?? 'something went wrong');
		}
	};
	return (
		<div>
			<Typography sx={{textAlign: 'center', color: 'gray', pb: 4}} variant="subtitle2">
				حساب تكلفة الشحن
			</Typography>
			<DynamicForm
				submitButtonText="حساب"
				config={{
					inputs: [
						{
							label: 'وزن الشحنة',
							placeholder: 'اجمالي وزن الشحنة بالكيلو',
							name: 'weight',
							type: 'text',
							value: '0.1',
						},
						{
							label: 'الدولة',
							placeholder: 'الدولة',
							name: 'country',
							type: 'picker',
							options: [
								{iso_code: 'AT', name: 'النمسا'},
								{iso_code: 'BE', name: 'بلجيكا'},
								{iso_code: 'BG', name: 'بلغاريا'},
								{iso_code: 'CY', name: 'قبرص'},
								{iso_code: 'CZ', name: 'جمهورية التشيك'},
								{iso_code: 'DK', name: 'الدنمارك'},
								{iso_code: 'EE', name: 'استونيا'},
								{iso_code: 'FI', name: 'فنلندا'},
								{iso_code: 'FR', name: 'فرنسا'},
								{iso_code: 'DE', name: 'المانيا'},
								{iso_code: 'GR', name: 'اليونان'},
								{iso_code: 'HU', name: 'المجر'},
								{iso_code: 'IE', name: 'ايرلندا'},
								{iso_code: 'IT', name: 'ايطاليا'},
								{iso_code: 'LV', name: 'لاتفيا'},
								{iso_code: 'LT', name: 'ليتوانيا'},
								{iso_code: 'LU', name: 'لوكسمبورغ'},
								{iso_code: 'MT', name: 'مالطا'},
								{iso_code: 'NL', name: 'هولندا'},
								{iso_code: 'PL', name: 'بولندا'},
								{iso_code: 'PT', name: 'البرتغال'},
								{iso_code: 'RO', name: 'رومانيا'},
								{iso_code: 'SK', name: 'سلوفاكيا'},
								{iso_code: 'SI', name: 'سلوفينيا'},
								{iso_code: 'ES', name: 'اسبانيا'},
								{iso_code: 'SE', name: 'السويد'},
							],
							labelKey: 'name',
							valueKey: 'iso_code',
							value: 'DE',
						},
					],
				}}
				onSubmit={onSubmit}
			/>
		</div>
	);
}
