import {Box, Button, Card, Stack, Typography} from '@mui/material';
import Row from 'src/components/ui/Row';
import AccordionWrapper from 'src/components/wrappers/AccordionWrapper';
import {getSiteCurrency} from 'src/utils/selectors';
import {getUtcDateTimeFromISO} from 'src/utils/date';
import PaidIcon from '@mui/icons-material/Paid';
import CancelIcon from '@mui/icons-material/Cancel';
import PendingIcon from '@mui/icons-material/AccessTime';
import {canAccess} from 'src/utils/permissions';
import StyledLink from 'src/components/ui/StyledLink';
import {Attributes} from 'src/types/contentTypes';
import {strapiUpdate} from 'src/utils/strapiApi';
import {accountIs} from 'src/utils/siteUtils';
import {updateOrder} from 'src/utils/AdminApi';
import useUser from 'src/hooks/useUser';
import {useState} from 'react';
export default function InvoiceItem({
	invoice,
	onDelete,
	onUpdate,
	allowUpdate = true,
}: {
	invoice: Attributes['invoices'];
	onDelete: Function;
	onUpdate: Function;
	allowUpdate?: boolean;
}) {
	const currency = getSiteCurrency();
	const {creator, order, number, type, amount, paid_at, created_at} = invoice;
	const orderId = order?.id ?? order;
	const isPositive = amount >= 0;
	const isPaid = paid_at != null;
	const isCancelled = invoice.cancelled_at != null;
	const {user} = useUser();

	// loading state
	const [isUpdating, setIsUpdating] = useState(false);

	const isOrder = invoice.type === 'order';
	return (
		<Card sx={{p: 2}}>
			<Stack alignItems="center" justifyContent="space-between" direction="row">
				<StyledLink color="inherit" addHoverEffect underline="none" to={`/orders/${orderId}`}>
					<Stack alignItems="center" direction="row">
						<Box width={30} height={30}>
							{getStatusIcon(invoice)}
						</Box>
						<Stack px={3}>
							<Typography textAlign="start" fontWeight="bold" style={{fontSize: 16}}>
								{getInvoiceTitle(invoice)}
							</Typography>
							<Typography textAlign="start" style={{fontSize: 14}}>
								{'تم الانشاء ' + new Date(created_at).toLocaleString('ar-AE')}
							</Typography>

							{/* {new Date(created_at).toLocaleDateString('ar-AE')} */}
							{isPaid && (
								<Typography textAlign="start" style={{fontSize: 14}}>
									{'تم الاستلام: ' + new Date(paid_at).toLocaleString('ar-AE')}
								</Typography>
							)}
							<Typography style={{fontSize: 15, color: 'gray'}}>
								{canAccess() && creator && getInvoiceUserActionText(invoice)}
							</Typography>
						</Stack>
					</Stack>
				</StyledLink>
				<Stack spacing={1} alignItems="center" direction={{xs: 'column', md: 'row'}}>
					{!isCancelled && allowUpdate && (canAccess() || !isPaid) && (
						<Button
							disabled={isUpdating}
							onClick={async () => {
								const newIsPaid = !isPaid ? new Date().toISOString() : null;
								setIsUpdating(true);
								try {
									await strapiUpdate('invoices', invoice.id, {
										paid_at: newIsPaid,
										// @ts-ignore
										received_by: user?.id,
									});

									onUpdate(newIsPaid);
								} catch (err) {
								} finally {
									setIsUpdating(false);
								}
							}}
							variant="outlined"
							sx={{color: !isPaid ? '#6FB677' : '#BE4655'}}
						>
							{!isPaid ? 'تأكيد' : 'الغاء'}
						</Button>
					)}
					<Typography style={{fontSize: 20, color: !isPositive && '#BE4655'}}>
						{isPositive ? '+' : '-'} {Math.abs(amount)}
						{currency}
					</Typography>
				</Stack>
			</Stack>
		</Card>
	);
}

const getStatusIcon = (invoice: Attributes['invoices']) => {
	if (invoice.cancelled_at) return <CancelIcon color="error" />;
	if (invoice.paid_at) return <PaidIcon sx={{color: '#6FB677'}} />;

	return <PendingIcon sx={{color: '#A9A592'}} />;
};
const getInvoiceTitle = (invoice: Attributes['invoices']) => {
	const base = `${invoice.number}#`;
	if (invoice.type === 'down_payment') return `${base}-عربون`;
	if (invoice.type === 'order') return `${base}-طلب`;
	if (invoice.type === 'refund') return `${base}-استرجاع`;
	if (invoice.type === 'exchange') return `${base}-تبديل`;

	return invoice.number;
};

const getInvoiceUserActionText = (invoice: Attributes['invoices']) => {
	if (invoice.paid_at) {
		if (invoice.creator?.id == invoice.received_by?.id) {
			return 'تم الانشاء والاستلام بواسطة ' + invoice.received_by?.name;
		} else {
			return (
				'تم الانشاء بواسطة ' + invoice.creator?.name + ' والاستلام بواسطة ' + invoice.received_by?.name
			);
		}
	}

	return 'تم الانشاء بواسطة ' + invoice.creator?.name;
};
