import _ from 'lodash';

const buildGetInputProps = (formik) => (name) => {
	const touched = _.get(formik.touched, name);
	const error = _.get(formik.errors, name);
	const touchedError = touched && error;
	const helperProps = {
		onWheel: (e) => e.target.blur(),
		error: Boolean(touchedError),
		helperText: touchedError,
	};

	return {...helperProps, ...formik.getFieldProps(name)};
};

export default buildGetInputProps;
