import {Stack, TextField} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {getDateBoundaries} from 'src/utils/date';

export default function DateRangeInput({from, to, direction = 'row', spacing = 1}) {
	// const [state, setState] = useState()
	// useEffect(()=>{},[])
	return (
		<Stack spacing={spacing} direction={direction}>
			<TextField
				fullWidth
				InputLabelProps={{shrink: true}}
				pattern="\d{4}-\d{2}-\d{2}"
				label={from.label ?? 'من'}
				placeholder={from.label ?? 'من'}
				onChange={(e) => from.onChange(e.target.value)}
				value={from.value}
				type="date"
			/>
			<TextField
				fullWidth
				InputLabelProps={{shrink: true}}
				pattern="\d{4}-\d{2}-\d{2}"
				label={to.label ?? 'الى'}
				placeholder={to.label ?? 'الى'}
				onChange={(e) => {
					to.onChange(getDateBoundaries(e.target.value).end ?? '');
				}}
				value={to.value ? to.value.split('T')[0] : ''}
				type="date"
			/>
		</Stack>
	);
}
