import {Stack, TextField} from '@mui/material';
import Button from '@mui/material/Button';
import * as React from 'react';
import BasicModal from './BasicModal';
import {LoadingButton} from '@mui/lab';

export default function DeleteWithReasonModal({
	title,
	subtitle,
	isVisible = false,
	setVisibility,
	handleSubmit,
}) {
	const [isSubmitting, setisSubmitting] = React.useState(false);
	return (
		<>
			<BasicModal
				title={title}
				subtitle={subtitle}
				isVisible={isVisible}
				setVisibility={setVisibility}
			>
				<form
					onSubmit={async (...args) => {
						setisSubmitting(true);
						try {
							await handleSubmit(...args);
						} catch (err) {
						} finally {
							setisSubmitting(false);
						}
					}}
				>
					<Stack spacing={3}>
						<TextField
							multiline
							inputProps={{minRows: 3}}
							placeholder="السبب"
							type="text"
							name="reason"
							required
						/>
						{/* <Input type="submit" value="Confirm" /> */}
						<LoadingButton loading={isSubmitting} variant="contained" type="submit">
							تاكيد
						</LoadingButton>
					</Stack>
				</form>
			</BasicModal>
		</>
	);
}
