import {useSelector} from 'react-redux';
/**
  * 
  * @returns {{
  *     user:import("../types/contentTypes").ContentTypeAttributes<'user'>,
  * }} user
  
  */
export default function useUser() {
	return useSelector((s) => s.admin);
}
