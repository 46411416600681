import * as React from 'react';
import TextField from '@mui/material/TextField';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {TimePicker as MUITimePicker} from '@mui/x-date-pickers/TimePicker';
import {DateTime} from 'luxon';
/**
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function(DateTime): string} props.onChange
 * @returns
 */
export default function TimePicker({fullWidth = false, value, label, onChange}) {
	return (
		<LocalizationProvider dateAdapter={AdapterLuxon}>
			<MUITimePicker
				label={label}
				// value is date object
				value={value}
				onChange={onChange}
				renderInput={(params) => <TextField fullWidth={fullWidth} {...params} />}
			/>
		</LocalizationProvider>
	);
}
