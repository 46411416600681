export const isProduction = process.env.NODE_ENV === 'production';
export const TestingComponent = ({production, dev}) => {
	if (isProduction) return production;
	else return dev;
};

// helper to log function calls ( usefull with react hook form setValue function to check if the form state is changed by some side effects)
/* 
  const setValue = watchCalls(_setValue, "setValue")
*/
export const watchCalls = (fn, fnName) => {
	if (process.env.NODE_ENV !== 'development') return fn;

	return (...args) => {
		console.debug(`👓 ${fnName} ~ called with the following args`, args);
		return fn(...args);
	};
};

export const devLog = (...args) => {
	if (process.env.NODE_ENV !== 'development') return;
	console.log(...args);
};
