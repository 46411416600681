import {HIDE_LOADER, SHOW_LOADER, FETCH_PERMISSIONS} from './appActionsTypes';

const initalState = {
	isLoading: false,
	permissions: [],
};

export default function (state = initalState, action) {
	const {payload, type} = action;

	switch (type) {
		case SHOW_LOADER:
			return {...state, isLoading: true};
		case HIDE_LOADER:
			return {...state, isLoading: false};
		case FETCH_PERMISSIONS:
			return {...state, permissions: payload.permissions};
		default:
			return state;
	}
}
