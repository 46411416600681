import * as React from 'react';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {accountIsDemo} from 'src/utils/customSite';

export default function InfoTip({title, mb = 1, devTitle, devOnly = false, sx = {}}) {
	if (devOnly === true && !accountIsDemo()) return;
	return (
		// enterTouchDelay is workaround to show tip in mobile
		<Tooltip
			sx={{mb, ...sx}}
			enterTouchDelay={0}
			title={accountIsDemo() && devTitle ? devTitle : title}
		>
			<IconButton style={{fontSize: 16}} size="small">
				<InfoIcon fontSize="inherit" />
			</IconButton>
		</Tooltip>
	);
}
