import {Button, Card, Stack} from '@mui/material';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {addProduct, updateProduct} from 'src/utils/AdminApi';
import ProductSelection from 'src/pages/search/ProductSelection';
import ResponsiveDialog from '../../../components/modals/ResponsiveDiaog';
import Row from '../../../components/ui/Row';

// when we found product using barcode then we need to display some action buttons
export default function ProductBarcodeNotFoundActions({barcode}) {
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();

	const handleCreateProduct = async () => {
		try {
			const product = await addProduct({
				name: barcode,
				price: 0,
				quantity: 0,
				sku: barcode,
			});
			navigate(`/products/${product.id}`);
		} catch (err) {
			toast.error('حدث خطا');
		}
	};

	return (
		<>
			{/* product info */}
			<Card sx={{p: 2}}>
				<Row label="الباركود" value={barcode} />

				<Stack mt={2} spacing={1} direction="row" justifyContent="space-between">
					{/* action buttons */}
					<Button variant="outlined" onClick={handleCreateProduct}>
						انشاء منتج باستخدام الكود
					</Button>
					<Button variant="contained" onClick={() => setOpen(true)}>
						ربط الكود مع منتج موجود
					</Button>
				</Stack>
			</Card>
			<ResponsiveDialog open={open} setOpen={setOpen}>
				<ProductSelection
					onConfirmSelection={async (product) => {
						setOpen(false);
						try {
							await updateProduct(product.id, {sku: barcode});
							toast.success('تم الربط بنجاح');
							navigate('/barcode/result', {
								state: {
									product,
									barcode,
								},
							});
						} catch (error) {
							toast.error('حدث خطا غير متوقع');
						}
					}}
				/>
			</ResponsiveDialog>
		</>
	);
}
