import {useEffect, useState} from 'react';

export default function useDelay(delay) {
	const [finish, setFinish] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setFinish(true);
		}, delay);

		return () => clearTimeout(timer);
	}, [delay]);

	return finish;
}
