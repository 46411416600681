import {Stack} from '@mui/material';
import React from 'react';
import {canAccess} from 'src/utils/permissions';

export default function AccessControlWrapper({controllerAction, or = false, children}) {
	if (canAccess(controllerAction) || or) return children;
	else {
		return (
			<Stack
				style={{
					minHeight: '70vh',
				}}
				justifyContent="center"
				alignItems="center"
			>
				<p>ليس لديك صلاحية للوصول لهذه الصفحة</p>
			</Stack>
		);
	}
}
