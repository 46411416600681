const {useState, useEffect} = require('react');
const {useDebounce} = require('./useDebounce');

export default function useDataSearch(callback, defaultValue) {
	const [filter, setFilter] = useState(defaultValue);
	const debouncedFilter = useDebounce(filter, 500, callback);

	useEffect(() => {
		setFilter(defaultValue);
	}, [defaultValue]);

	const onSearch = (e) => {
		const searchTerm = e.target.value?.toLowerCase();
		if (searchTerm != filter) setFilter(searchTerm);
	};

	return {
		filter,
		setFilter,
		debouncedFilter,
		onSearch,
	};
}
