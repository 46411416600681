// material
import {Card, Container, Stack, Typography} from '@mui/material';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import Page from 'src/components/Page';
import {getActiveorders} from 'src/utils/AdminApi';
import {isNullOrEmpty} from 'src/utils/helperMethods.js';
import VisitSite from '../../components/VisitSite';
import {OrdersList} from '../Orders/list/Orders.list.js';
import DateRangeStats from './DateRangeStats.js';
import StoreProgress from './StoreProgress';
// components
import './homepage.css';

export default function HomePage() {
	const admin = useSelector((state) => state.admin.user);
	const site_name = admin.OwnedWebsite?.site_name;
	// const stats = initalStats;
	// const {data: stats = initalStats} = useQuery('overallStats', () => getGeneralStatistics(), {
	// 	refetchOnWindowFocus: false,
	// 	refetchOnMount: false,
	// });
	const {data: activeOrders = []} = useQuery('activeorders', () => getActiveorders(), {
		refetchOnWindowFocus: false,
		// refetchOnMount: false,
	});

	return (
		<Page title="الرئيسية">
			<Container>
				<Stack direction="row" alignItems="center" spacing={2} sx={{p: 2}}>
					<Typography variant="h3">متجر {site_name}</Typography>
					<VisitSite />
				</Stack>
				{admin.isOwner && (
					<>
						<StoreProgress />

						<Card>
							<Stack spacing={2} sx={{p: 2}}>
								<DateRangeStats />
								{/* {!isNullOrEmpty(stats.top5Products) && (
								<Card sx={{p: 2}}>
									<h4 className="dashboard-title">المنتجات الأكثر طلبًا:</h4>
									<Stack spacing={2}>
										{stats.top5Products.map((item, index) => (
											<div key={item.id}>
												<Stack direction="row" spacing={2}>
													<img src={item.main_image?.url} className="product-avatar" />
													<Stack>
														<p>اسم المنتج: {item.name}</p>
														<p>{item.soldQuantity} قطعة </p>
													</Stack>
												</Stack>
												{index < stats.top5Products.length - 1 && <Divider />}
											</div>
										))}
									</Stack>
								</Card>
							)} */}
							</Stack>
						</Card>
					</>
				)}
				{!isNullOrEmpty(activeOrders) && (
					<Card sx={{p: 2, mt: 2}}>
						<Typography variant="h4" my={2}>
							الطلبات الفعالة
						</Typography>
						<OrdersList bulkActionEnabled={false} showStats={false} orders={activeOrders} />
					</Card>
				)}
			</Container>
		</Page>
	);
}
