import {Typography, Stack} from '@mui/material';
import React from 'react';
import {useLocation} from 'react-router-dom';
import ProductBarcodeFoundActions from 'src/pages/User/barcode/ProductBarcodeFoundActions';
import ProductBarcodeNotFoundActions from 'src/pages/User/barcode/ProductBarcodeNotFoundActions';

export default function BarcodeResultPage() {
	const location = useLocation();
	const {products, barcode} = location.state ?? {};

	if (products === null)
		return (
			<Stack spacing={3} px={4}>
				<Typography color="primary" variant="h5">
					لم يتم العثور علي المنتج
				</Typography>
				<ProductBarcodeNotFoundActions barcode={barcode} />
			</Stack>
		);
	else return <ProductBarcodeFoundActions barcode={barcode} products={products} />;
}
