import {useEffect, useState} from 'react';
import {getProducts} from '../utils/AdminApi';

// what if we don't need pagination ?
// what if we need to add extra filters
// concat vs replace ?
// inputs : page size ,
// states :
export default function useProducts({onFetch, pageSize = -1, qs = ''}) {
	const [products, setProducts] = useState([]);
	const [currentPageOffset, setCurrentPageOffset] = useState(0);
	const [canLoadMore, setCanLoadMore] = useState(true);
	const [{loading, error}, setStatus] = useState({loading: true, error: null});

	const fetchProducts = async () => {
		if (currentPageOffset < 0) return;
		try {
			const productsRes = await getProducts(
				`?_sort=featured:desc,id:desc&_limit=${pageSize}&_start=${currentPageOffset}&` + qs,
			);

			if (productsRes.length < pageSize) {
				setCanLoadMore(false);
			}
			setProducts(products.concat(productsRes));
			setCurrentPageOffset(currentPageOffset + pageSize);
			if (onFetch) onFetch(productsRes);
			setStatus({loading: false, error: null});
		} catch (error) {
			// handle error
			setStatus({loading: false, error});
		}
	};

	useEffect(() => {
		fetchProducts();
	}, []);

	return {
		products,
		fetchProducts,
		setProducts,
		canLoadMore,
		currentPageOffset,
		isEmpty: !canLoadMore && products.length === 0,
		loading,
		error,
	};
}
