import {Typography} from '@mui/material';
import React from 'react';
import StyledLink from 'src/components/ui/StyledLink';

export default function ProductLink({product, textProps = {}}) {
	return (
		<StyledLink to={`/products/${product.id}`}>
			<Typography {...textProps}>{product.name}</Typography>
		</StyledLink>
	);
}
