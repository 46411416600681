import {SHOW_MODAL, HIDE_MODAL} from './modalActionsTypes';

const initalState = {
	isVisible: false,
	title: '',
	body: '',
	actionButtonText: '',
	redirectTo: null,
};
// linked to GlobalModal
export default function (state = initalState, action) {
	const {payload, type} = action;

	switch (type) {
		case SHOW_MODAL:
			return {...state, ...payload, isVisible: true};
		case HIDE_MODAL:
			return initalState;
		default:
			return state;
	}
}
