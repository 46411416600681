import {Stack} from '@mui/material';
import {useState} from 'react';
/* eslint-disable no-unreachable */
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import {Box, Button} from '@mui/material';
import {toast} from 'react-toastify';

const Counter = ({
	disabled = false,
	max,
	enableZero,
	step = 1,
	defaultValue = 1,
	onChange,
	handleZero,
}) => {
	const [state, setState] = useState(defaultValue ?? 1);
	const updateValue = (amount) => {
		// if (state == 1 && amount == -1 && enableZero == undefined) return handleZero?.call();

		// if (max && amount > 0 && state + amount > max) {
		// 	return;
		// }
		setState(amount);
		if (typeof onChange === 'function') onChange(amount);
	};

	const color = '#B6B6B6';

	const btnStyles = {...buttonStyles, color: disabled ? color : 'black'};

	return (
		<Stack sx={{p: 0, minWidth: '0px', borderRadius: '4px 0 0 4px'}} direction={'row'}>
			<Button
				variant="contained"
				size="small"
				sx={{px: 1, minWidth: '0px', borderRadius: '4px 0 0 4px'}}
				onClick={() => {
					updateValue(step + state);
				}}
			>
				<AddRoundedIcon fontSize="16px" />
			</Button>
			<Box
				display={'flex'}
				alignItems={'center'}
				px={0.5}
				py={0.5}
				borderTop={'1px solid #076458'}
				borderBottom={'1px solid #076458'}
			>
				<input
					// variant="body2"
					style={{border: 'none', width: 30, textAlign: 'center', outline: 'none'}}
					value={state ?? 1}
					onChange={(e) => updateValue(+e.target.value)}
				/>
			</Box>
			<Button
				variant="contained"
				size="small"
				sx={{px: 1, minWidth: '0px', borderRadius: '0 4px 4px 0'}}
				onClick={() => {
					updateValue(state - step);
				}}
			>
				<RemoveRoundedIcon fontSize="16px" />
			</Button>
		</Stack>
	);
};

const buttonStyles = {
	width: '35px',
	height: '35px',
	fontSize: 20,
	padding: 4,
	fontWeight: 'bold',
	margin: 0,
	background: '#EEEEEE',
	borderRadius: 10,
	color: 'black', // we added this because in ios buttons are blue by default
	borderWidth: 0,
};

export default Counter;
