// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
import {getMessaging, onMessage, getToken} from 'firebase/messaging';
import {setFcmToken} from './utils/AdminApi';

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: 'AIzaSyDVGBnJR5aODjXDRR7IAlHQh4jxOqPj20Q',
	authDomain: 'vetrina-2c261.firebaseapp.com',
	databaseURL: 'https://vetrina-2c261-default-rtdb.europe-west1.firebasedatabase.app',
	projectId: 'vetrina-2c261',
	storageBucket: 'vetrina-2c261.appspot.com',
	messagingSenderId: '726738679498',
	appId: '1:726738679498:web:18af0b9e34d49584fee633',
};

let app, messaging;
if (typeof navigator !== 'undefined') {
	// Initialize Firebase
	app = initializeApp(firebaseConfig);
	messaging = getMessaging(app);

	// Handle incoming messages. Called when:
	// - a message is received while the app has focus
	// - the user clicks on an app notification created by a service worker
	//   `messaging.onBackgroundMessage` handler.
	onMessage(messaging, (payload) => {
		console.log('Message received. ', payload);
		// Update the UI to include the received message.
	});
}

export function requestPermission() {
	console.log('Requesting permission...');

	if (typeof navigator === 'undefined') return;
	if (typeof Notification === 'undefined') return;
	Notification.requestPermission().then((permission) => {
		if (permission === 'granted') {
			console.log('Notification permission granted.');
			// TODO(developer): Retrieve a registration token for use with FCM.
			// In many cases once an app has been granted notification permission,
			// it should update its UI reflecting this.
			// Get registration token. Initially this makes a network call, once retrieved
			// subsequent calls to getToken will return from cache.
			tryToGetToken();
		} else {
			console.log('Unable to get permission to notify.');
		}
	});
}

export function tryToGetToken() {
	if (typeof navigator === 'undefined') return;
	if (typeof Notification === 'undefined') return;
	if (Notification.permission !== 'granted') return;
	return getToken(messaging, {
		vapidKey:
			'BMGrYu68YUuRNm8j8gfGRksr1eY9HCifcEnwxSxUbtzkzOx-b6T9oLDvUg3soZhzBNMuivMUrN3KeyJiRbEA7-0',
	})
		.then(async (currentToken) => {
			console.log('get token hanlder', currentToken);
			const oldToken = localStorage.getItem('fcmToken');
			if (currentToken) {
				if (oldToken !== currentToken) {
					localStorage.setItem('fcmToken', currentToken);

					try {
						await setFcmToken(window.navigator?.userAgent ?? 'deviceId', currentToken);
					} catch (err) {
						console.log('fail to save token in server');
					}
				} else {
					// token didn't change
				}
				//   sendTokenToServer(currentToken);
				// updateUIForPushEnabled(currentToken);
			} else {
				// Show permission request.
				console.log('No registration token available. Request permission to generate one.');
				// Show permission UI.
				//   updateUIForPushPermissionRequired();
				//   setTokenSentToServer(false);
			}
		})
		.catch((err) => {
			console.log('An error occurred while retrieving token. ', err);
		});
}
