import {Card, CircularProgress, Divider, Stack, Typography} from '@mui/material';
import React from 'react';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import Row from 'src/components/ui/Row';
import TableWrapper from 'src/components/wrappers/TableWrapper';
import useShippingProviders from 'src/pages/DeliveryMethods/useShippingProviders';

const bgcolor = '#EDEEF4';
export default function OrderShippingDetails({shipping, provider, logs, shippingDataIsLoading}) {
	const {data: shippingProviders = []} = useShippingProviders();
	const aShippingProvider = shippingProviders.find((item) => item.id === provider);
	const shippingLogs = (isNullOrEmpty(logs) ? shipping?.logs : logs) ?? [];

	return (
		<Card id="shipping-details" sx={{padding: 4, my: 2}}>
			<Typography sx={{mb: 2}} variant="h5">
				معلومات الشحنة
			</Typography>
			{shippingDataIsLoading ? (
				<Stack my={2} alignItems="center">
					<CircularProgress size={22} color="primary" />
					<Typography>يرجي الانتظار</Typography>
				</Stack>
			) : !isNullOrEmpty(shipping) ? (
				<Stack spacing={1} sx={{mb: 2}}>
					<Row label="شركة الشحن" value={aShippingProvider?.name} />
					<Row bgcolor={bgcolor} label="رقم الشحنة" value={shipping.shippingId} />
					<Row label="حالة الشحنة" value={shipping.status} />
					{shipping.shippingPayment && (
						<Row
							bgcolor={bgcolor}
							label="تكلفة الشحن"
							value={shipping.shippingPayment + (shipping.currency || ' د.ل')}
						/>
					)}
					{/* <Row label="مسار الشحنة" value={`من ${shipping.cityFrom} الي ${shipping.cityTo}`} /> */}
					<Row label="تاريخ تسجيل الشحنة" value={shipping.createdOn} />
				</Stack>
			) : (
				<p>لا يوجد بيانات</p>
			)}

			{!isNullOrEmpty(shippingLogs) && (
				<>
					<Divider />
					<Typography sx={{my: 2}} variant="h5">
						تتبع الشحنة
					</Typography>
					<TableWrapper
						align="start"
						headerColumns={['التفاصيل', 'التاريخ والوقت']}
						dataRows={shippingLogs.map((item) => [item.description, item.date_time])}
					/>
				</>
			)}
		</Card>
	);
}
