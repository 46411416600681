import {Card, Container} from '@mui/material';
import React from 'react';
import {Helmet} from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';

export default function Layout({
	title,
	titleVariant,
	htmlTitle,
	children,
	maxWidth = 'md',
	minHeight = '75vh',
}) {
	return (
		<Container maxWidth={maxWidth}>
			{htmlTitle && (
				<Helmet>
					<title>{title}</title>
					{/* <title>{process.env.NODE_ENV === 'development' ? 'cms-dev' : title}</title> */}
				</Helmet>
			)}
			<Card sx={{p: 2, minHeight}}>
				<PageTitle variant={titleVariant} title={title} sx={{my: 1}} />
				{children}
			</Card>
		</Container>
	);
}
