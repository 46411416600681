import {Stack, Typography} from '@mui/material';
import React from 'react';

export default function Row({
	justifyContent = 'space-between',
	sx = {},
	spacing = undefined,
	bgcolor = undefined,
	label,
	value,
	labelVariant = 'body1',
	valueVariant = 'subtitle2',
	valueColor = undefined,
}) {
	return (
		<Stack
			spacing={spacing}
			sx={{bgcolor, borderRadius: 5, p: 0.35, px: 1, ...sx}}
			direction="row"
			justifyContent={justifyContent}
			alignItems={'center'}
		>
			<Typography variant={labelVariant}>{label}</Typography>
			<Typography sx={{color: valueColor}} variant={valueVariant}>
				{value}
			</Typography>
		</Stack>
	);
}
