import {
	Button,
	Card,
	Checkbox,
	Container,
	Divider,
	FormControlLabel,
	Stack,
	Switch,
	TextField,
	Typography,
} from '@mui/material';
import {cloneDeep, isEqual} from 'lodash';
import {memo, useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import FabSaveButton from 'src/components/buttons/FabSaveButton';
import MuiLink from 'src/components/ui/StyledLink';
import useBulkActions from 'src/hooks/useBulkActions';
import {getCities, getDeliveryMethodById, request, updateDeliveryMethods} from 'src/utils/AdminApi';
import {accountIsDemo} from 'src/utils/customSite';
import {isNullOrEmpty, updateWhere} from 'src/utils/helperMethods';
import {getNumber} from 'src/utils/numberUtils';

export default function UpdateDeliveryMethod() {
	// form states
	const [name, setName] = useState('');
	const [citiesSettingsState, setCitiesSettingsState] = useState([]);
	// get data from api
	const {id} = useParams();
	const {data: cities = []} = useQuery('cities', getCities, {
		refetchOnMount: false,
		refetchOnWindowFocus: false,
	});
	const {data: deliveryMethodInfo, refetch} = useQuery(
		['delivery_method', id],
		() => getDeliveryMethodById(id),
		{
			enabled: cities.length > 0,
			refetchOnWindowFocus: false,
			onSuccess: (data) => {
				setName(data.name);
				setCitiesSettingsState(
					cities.map((c) => {
						const details = data.details?.cities.find((dc) => dc.name == c.name);
						if (details) return details;

						return {
							name: c.name,
							enabled: false,
						};
					}),
				);
			},
		},
	);

	const onSubmitCitiesForm = async (e) => {
		e.preventDefault();

		await updateDeliveryMethods(id, name, {
			cities: citiesSettingsState,
		});
		refetch();
	};

	if (!deliveryMethodInfo) return;

	// name and length c	heck is added to avoid show save change button before loading the state correctly
	const dataChanged =
		(name !== '' && name !== deliveryMethodInfo?.name) ||
		(citiesSettingsState.length > 0 &&
			!isEqual(deliveryMethodInfo?.details?.cities, citiesSettingsState));

	return (
		<Container>
			<Typography variant="h4">تعديل طريقة التوصيل/الاستلام</Typography>

			<>
				<Divider sx={{my: 2}} />

				<form onSubmit={onSubmitCitiesForm}>
					<Card sx={{p: 2}}>
						<TextField
							required
							fullWidth
							label="اسم طريقة التوصيل"
							name="name"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</Card>
					{deliveryMethodInfo?.type === 'flatRate' && (
						<>
							<Divider sx={{mt: 2}} />

							{/* 
							<Stack mt={1} direction="row" justifyContent="space-between">
								<Typography variant="body2">متاح - اسم المدينة</Typography>
								<Typography variant="body2">السعر</Typography>
							</Stack> */}

							<Stack mt={1} spacing={2}>
								<MemoizedCitiesInputs
									citiesSettingsState={citiesSettingsState}
									cities={cities}
									setCitiesSettingsState={setCitiesSettingsState}
								/>
							</Stack>
						</>
					)}
					{dataChanged && <FabSaveButton type="submit" />}
				</form>
			</>
		</Container>
	);
}

function CitiesInputs({citiesSettingsState, cities, setCitiesSettingsState}) {
	const {
		selections,
		setSelections,
		bulkInputs,
		setBulkInputs,
		reset,
		getHandleCheck,
		getMainCheckboxProps,
	} = useBulkActions({
		data: cities,
		key: 'name',
		initialFormValues: {
			enabled: true,
		},
	});

	return (
		<>
			<Stack spacing={1} direction="row" alignItems="center">
				<Checkbox size="small" {...getMainCheckboxProps()} />
				<Typography mt={2} variant="h5">
					المدن
				</Typography>
			</Stack>
			{cities.map((city) => {
				// console.log('it renders');
				const citySettings = citiesSettingsState.find((c) => c.name === city.name) ?? {
					...city,
					enabled: true,
				};
				return (
					<Card sx={{p: 1}}>
						<Stack alignItems="center" key={city.name} direction="row" justifyContent="space-between">
							<Stack alignItems="center" spacing={1} direction="row" justifyContent="space-between">
								<Checkbox
									size="small"
									type={'checkbox'}
									onChange={getHandleCheck(city)}
									checked={Boolean(selections[city.name])}
								/>

								<Typography>{city.name}</Typography>
								<Switch
									size="small"
									key={`${city.name}.enabled`}
									onChange={(e) => {
										setCitiesSettingsState(
											updateWhere(
												citiesSettingsState,
												{enabled: e.target.checked},
												(c) => c.name === citySettings.name,
											),
										);
									}}
									checked={citySettings.enabled !== false}
								/>
							</Stack>

							<Stack alignItems="center" spacing={2} direction="row">
								{!isNullOrEmpty(city.districts) && (
									<MuiLink to={`./${city.name}/districts`}>المناطق</MuiLink>
								)}
								{accountIsDemo() && (
									<Button
										onClick={async () => {
											const res = await request('/getCityDeliveryOptions', 'post', {
												city: city.name,
											});
											if (isNullOrEmpty(res)) return alert('no delivery options');

											alert(res.map((r) => `#${r.name || r.id} - ${r.price}`).join('\n'));
										}}
									>
										test
									</Button>
								)}
								<TextField
									label="السعر"
									placeholder="السعر"
									type="number"
									sx={{width: 75}}
									value={citySettings.price ?? city.price ?? ''}
									onChange={(e) =>
										setCitiesSettingsState(
											updateWhere(
												citiesSettingsState,
												{price: e.target.value},
												(c) => c.name === citySettings.name,
											),
										)
									}
								/>
							</Stack>
						</Stack>
					</Card>
				);
			})}

			{/* bulk action form */}
			{!isNullOrEmpty(selections) && (
				<Stack
					sx={{
						zIndex: 10,
						position: 'fixed',
						bottom: 50,
						width: {xs: '90vw', md: 'inherit'},
						left: '50%',
						transform: 'translate(-50%, -50%)',
						background: 'white',
						p: 2,
						px: 4,
						borderRadius: 3,
						boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
					}}
					spacing={1}
					direction="row"
					flex={1}
				>
					<Stack flex={1} spacing={2} direction="row" alignItems="center">
						<FormControlLabel
							label="تفعيل"
							control={
								<Switch
									size="small"
									onChange={(e) => setBulkInputs({...bulkInputs, enabled: e.target.checked})}
									checked={bulkInputs.enabled}
								/>
							}
						/>

						<TextField
							type="number"
							label="السعر"
							name="price"
							value={bulkInputs.price}
							onChange={(e) => setBulkInputs({...bulkInputs, price: e.target.value})}
							InputLabelProps={{shrink: true}}
						/>

						<Button
							variant="outlined"
							onClick={async () => {
								// [citiesSettingsState] vs selections
								let _citiesSettingsState = cloneDeep(citiesSettingsState);
								_citiesSettingsState.forEach((c) => {
									if (c.name in selections) {
										c.enabled = bulkInputs.enabled;
										c.price = getNumber(bulkInputs.price, c.price);
									}
								});
								setCitiesSettingsState(_citiesSettingsState);
								reset();
							}}
						>
							حفظ
						</Button>
					</Stack>
				</Stack>
			)}
		</>
	);
}
// I used memo to fix lag issue after every keystore on name input
// everytime input change it will rerender the component and this cause lag because we have to iterate over cities array and rerender again
const MemoizedCitiesInputs = memo(CitiesInputs);
