import {Button} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';

export default function NavButton({sx = {}, variant = 'outlined', text = '', to = '', ...props}) {
	const navigate = useNavigate();

	const onClick = () => {
		navigate(to);
	};

	return (
		<Button {...props} sx={sx} variant={variant} onClick={onClick}>
			{text}
		</Button>
	);
}
