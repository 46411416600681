import * as Sentry from '@sentry/react';
import {getSite} from '../selectors';

const handleSkyexErrors = (errorResponse) => {
	// we will use it to log that we get unexpected errors
	let unexpected = errorResponse.errors.length !== 1;
	let errors = [];

	if (errorResponse.errors.length > 0) {
		const validation = errorResponse.errors[0].extensions?.validation;
		if (validation) {
			// we are expecting 1 item in each array
			if (validation['input.recipientPhone'] || validation['input.recipientMobile']) {
				errors.push('يجب ادخال رقم هاتف صحيح');
			}

			// check if validation contains other than the expected keys
			unexpected = Object.keys(errorResponse.errors[0].extensions?.validation).some((key) => {
				return key !== 'input.recipientPhone' && key !== 'input.recipientMobile';
			});
		}
	}
	if (unexpected) {
		Sentry.captureMessage('SKYEX Unexpected Error response', {
			level: 'warning',
			extra: {
				response: JSON.stringify(errorResponse),
				tenant_id: getSite()?.id,
				tenant_name: getSite()?.site_name,
			},
			context: {
				url: window.location.href,
			},
		});
	}

	return errors;
};

export default handleSkyexErrors;
