import React, {Fragment, useState} from 'react';
import {
	Box,
	Card,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import {toast} from 'react-toastify';
import FormikWrapper from './wrappers/FormikWrapper.js';
import SubmitButton from './buttons/SubmitButton.js';
import {useDispatch, useSelector} from 'react-redux';
import ImageCropPicker from './inputs/ImageCropPicker.js/ImageCropPicker.js';
import {addBannerAction, deleteBannerAction, updateBannerAction} from '../reducers/index.js';
import * as Yup from 'yup';
function BannerDialog({banner, open, onClose}) {
	const isEditDialog = banner != null;
	const dispatch = useDispatch();

	const handleClose = () => {
		onClose();
	};

	const onUpdate = async (values) => {
		const {image, linkTo} = values;
		const {public_id, url, ...otherFields} = banner;
		const data = {
			public_id,
			fields: {
				...otherFields,
				url,
				link: linkTo,
			},
		};

		try {
			await dispatch(updateBannerAction(data, image));
			toast.success('تم التعديل بنجاح');
			onClose();
		} catch (error) {
			toast.error('حدث خطا غير متوقع');
		}
	};

	const onSubmit = async (values) => {
		const {image, linkTo} = values;

		try {
			await dispatch(addBannerAction(linkTo, image));
			toast.success('تمت الاضافة بنجاح');
			onClose();
		} catch (error) {
			// if it's known error show it to user else = server error
			toast.error('حدث خطا غير متوقع');
		}
	};

	const onDelete = async () => {
		const ip = banner?.public_id;
		try {
			await dispatch(deleteBannerAction(ip));
			onClose();
			toast.success('تم الحذف بنجاح');
		} catch (error) {
			// if it's known error show it to user else = server error
			toast.error('حدث خطا غير متوقع');
		}
	};

	return (
		<Dialog onClose={handleClose} open={open} maxWidth="sm">
			<DialogTitle>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'baseline',
					}}
				>
					<Typography variant="h4" gutterBottom>
						{isEditDialog ? 'تعديل البانر' : 'اضافة بانر'}
					</Typography>
					{isEditDialog && <DeleteIcon color="error" onClick={onDelete} sx={{cursor: 'pointer'}} />}
				</Box>
				<Typography>الصورة</Typography>
			</DialogTitle>
			<DialogContent>
				<FormikWrapper
					schema={isEditDialog ? bannerUpdateFormSchema : bannerFormSchema}
					hideSubmitButton
					values={{image: null, linkTo: banner?.link ?? ''}}
					handleSubmit={isEditDialog ? onUpdate : onSubmit}
				>
					{({isSubmitting, getInputProps, setFieldValue}) => {
						return (
							<Stack sx={{width: '100%'}}>
								<ImageCropPicker
									maxSize={1}
									ratio={10 / 4}
									setFile={(file) => {
										setFieldValue('image', file);
									}}
									placeholder="ابعاد الصورة المناسبة: الطول 1000 و العرض 400"
									defaultImage={banner?.url}
									{...getInputProps('image')}
								/>

								<TextField
									{...getInputProps('linkTo')}
									label="رابط الصفحة"
									fullWidth
									placeholder="رابط الصورة التي سيتم انتقال اليها عتد الضغظ على الصورة"
									sx={{mt: 2}}
								/>
								<Typography sx={{mt: 1}} variant="caption">
									رابط الصفحة التي سيتم الانتقال اليها عندالضغط علي الصورة
								</Typography>
								<Typography sx={{mt: 1}} variant="caption">
									مثال : <a href="demo.vetrinas.ly/products/330">demo.vetrinas.ly/products/330</a>
								</Typography>
								<SubmitButton isSubmitting={isSubmitting}>{isEditDialog ? 'تعديل' : 'اضافة'}</SubmitButton>
							</Stack>
						);
					}}
				</FormikWrapper>
			</DialogContent>
		</Dialog>
	);
}

BannerDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	edit: PropTypes.bool,
};

export default function StoreDesign() {
	// reading list of banners from redux
	const storeDynamicInfo = useSelector((s) => s.admin.user.OwnedWebsite.dynamic_info);
	const banners = storeDynamicInfo?.banners ?? [];
	// state to control create/update dialog
	const [dialogState, setDialogState] = useState({
		isVisible: false,
		banner: null, // if set then this dialog is for update
	});

	const showBannerDialog = (banner = null) => {
		setDialogState({
			isVisible: true,
			banner,
		});
	};
	const hideBannerDialog = () => {
		setDialogState({
			...dialogState,
			isVisible: false,
		});
		// hide dialog first then delete banner
		// if we remove this the submit button on dialog will change to create before dialog is closing
		setTimeout(() => {
			setDialogState({
				isVisible: false,
				banner: null,
			});
		}, 200);
	};

	return (
		<Box sx={{width: '100%', minHeight: '100%'}}>
			<Stack sx={{width: '100%'}}>
				<Typography variant="h4" gutterBottom>
					تصميم الصفحة الرئيسية
				</Typography>
				<Box
					sx={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
				>
					<Typography gutterBottom>البانرات</Typography>
					<IconButton
						color="primary"
						component="span"
						onClick={() => {
							showBannerDialog();
						}}
					>
						<AddCircleIcon fontSize="large" />
					</IconButton>
					{/*<BannerDialog addOpen={newOpen} onClose={hideAddDialog} edit={false} />*/}
				</Box>
				<Grid container sx={{width: '100%'}} justifyContent="flex-start" spacing={3}>
					{banners.map((banner, index) => (
						<Fragment key={index}>
							<Grid
								item
								key={banner.public_id}
								xs={12}
								md={6}
								lg={4}
								sx={{cursor: 'pointer'}}
								onClick={() => {
									showBannerDialog(banner);
								}}
							>
								<Card sx={{borderRadius: 0}}>
									<img src={banner.url} style={{width: '100%'}} />
								</Card>
							</Grid>
						</Fragment>
					))}
				</Grid>
			</Stack>

			<BannerDialog
				onClose={hideBannerDialog}
				open={dialogState.isVisible}
				banner={dialogState.banner}
			/>
		</Box>
	);
}

const linkValidation = Yup.string().matches(
	/((https?):\/\/)?(www.)?[a-zA-Z0-9]+(\.[a-zA-Z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
	'يرجي ادخال رابط صحيح',
);

const bannerFormSchema = Yup.object().shape({
	image: Yup.mixed().required('يجب اضافة صورة'),
	linkTo: linkValidation,
});

const bannerUpdateFormSchema = Yup.object().shape({
	linkTo: linkValidation,
});
