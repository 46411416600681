import {Button} from '@mui/material';
import React from 'react';
import {useQuery} from 'react-query';
import {useModal} from 'src/hooks/useModal';
import StockTransfer from 'src/pages/products/common/StockTransfer';
import {ADMINS_ACTIONS} from 'src/types/enums/ADMINS_ACTIONS';
import {request} from 'src/utils/AdminApi';
import {canAccess} from 'src/utils/permissions';

export default function ProductBranchesAvailability({product, variationId, refetch}) {
	const {data: branches} = useQuery('branches', () => request('branches'), {
		refetchOnMount: false,
		refetchOnWindowFocus: false,
	});
	const {open, close} = useModal();

	if (!branches || branches.length <= 1) return;

	// for each branch check if product exist

	return (
		<div>
			{branches.map((branch) => {
				const branchQuantity =
					product.stocks.find(
						(s) => s.branch == branch.id && s.variation == variationId /* can be null */,
					)?.quantity ?? 0;
				return (
					<div key={branch.id}>
						{branch.name} - {branchQuantity}
					</div>
				);
			})}

			{canAccess(ADMINS_ACTIONS.StockTransfersCreate) && (
				<Button
					onClick={() => {
						open({
							title: 'نقل الكمية',
							children: (
								<StockTransfer
									product={product}
									variationId={variationId}
									refetch={refetch}
									onSuccess={() => {
										close();
									}}
								/>
							),
						});
					}}
				>
					نقل الكمية
				</Button>
			)}
		</div>
	);
}
