import {ADMINS_ACTIONS} from 'src/types/enums/ADMINS_ACTIONS';

// products
export const findProductPermission = ADMINS_ACTIONS.ProductsFind;
export const findOneProductPermission = ADMINS_ACTIONS.ProductsFindone;
export const addProductPermission = ADMINS_ACTIONS.ProductsCreate;
export const deleteProductPermission = ADMINS_ACTIONS.ProductsDelete;
export const updateProductPermission = ADMINS_ACTIONS.ProductsUpdate;
// orders
export const findOneOrderPermission = ADMINS_ACTIONS.OrdersFindone;
export const findOrderPermission = ADMINS_ACTIONS.OrdersFind;
export const createOrderPermission = ADMINS_ACTIONS.OrdersCreate;
export const deleteOrderPermission = ADMINS_ACTIONS.OrdersDelete;
export const updateOrderPermission = ADMINS_ACTIONS.OrdersUpdate;
export const updateOrderStatusPermission = ADMINS_ACTIONS.OrdersUpdateStatus;
export const addDiscountPermission = ADMINS_ACTIONS.OrdersAddDiscount;
