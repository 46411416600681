import {Box} from '@mui/material';
import React from 'react';
import AccordionWrapper from 'src/components/wrappers/AccordionWrapper';
import {getTestFlag} from 'src/getTestFlag';

export default function Dev({title, object, sx = {}}) {
	if (!getTestFlag('test_mode')) return null;

	return (
		<Box sx={sx}>
			<AccordionWrapper card parent={title}>
				<pre
					style={{
						textAlign: 'left',
						overflow: 'auto',
						background: '#292d3d',
						color: 'white',
						borderRadius: 12,
						padding: 8,
					}}
					dir="ltr"
				>
					{JSON.stringify(object, null, 8)}
				</pre>
			</AccordionWrapper>
		</Box>
	);
}
