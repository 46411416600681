import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import * as React from 'react';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	borderRadius: 4,
	p: 4,
};

export default function BasicModal({
	dismissable = true,
	title,
	subtitle,
	isVisible = false,
	setVisibility,
	onClose,
	children,
}) {
	const handleClose = () => {
		if (dismissable) {
			if (typeof onClose === 'function') onClose();
			if (typeof setVisibility === 'function') setVisibility(false);
		}
	};

	return (
		<Modal
			open={isVisible ?? false}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				{title && (
					<Typography id="modal-modal-title" variant="h6" component="h2">
						{title}
					</Typography>
				)}
				<Typography id="modal-modal-description" sx={{mt: 2}}>
					{subtitle}
				</Typography>

				{children}
			</Box>
		</Modal>
	);
}
