import {
	Button,
	Checkbox,
	Divider,
	FormControlLabel,
	IconButton,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import {Container as DContainer, Draggable} from 'react-smooth-dnd';
import {arrayMoveImmutable} from 'src/utils/dndUtils';
import {updateArray, uuidv4} from 'src/utils/helperMethods';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import DeleteIcon from '@mui/icons-material/Delete';
import DynamicFormInput from '../DynamicForm/DynamicFormInput';
import Picker from './Picker';
import CommaSeparatedTextField from '../CommaSeparatedTextField';

const defaultFields = [
	{
		label: 'الوصف',
		key: 'name',
	},
	{
		label: 'القيمة',
		key: 'value',
	},
];
export default function CustomFields({
	title,
	value = [],
	onChange,
	fields = defaultFields,
	errors = [],
	touches = [],
	inputKey = 'id',
	maxInputs,
	vertical = false,
	setFieldTouched,
	flexWrap,
	renderOptions,
	bgcolor,
	allowChangeType = false,
}) {
	const add = (e) => {
		e.preventDefault();
		onChange([...value, {[inputKey]: uuidv4()}]);
	};

	const remove = (_key, e) => {
		e.preventDefault();
		onChange(value.filter((s) => s[inputKey] !== _key));
	};

	const onInputChange = (changes) => {
		onChange(updateArray(value, changes, inputKey));
	};

	const onDrop = async ({removedIndex, addedIndex, ...other}) => {
		onChange(arrayMoveImmutable(value, removedIndex, addedIndex));
	};

	return (
		<Stack spacing={0.5}>
			<Stack direction="row" alignItems="center" justifyContent="space-between">
				<Typography variant="body1">{title}</Typography>
				{(!maxInputs || value.length < maxInputs) && (
					<Button variant="text" type="button" onClick={add}>
						إضافة
					</Button>
				)}
			</Stack>
			<Divider />

			<DContainer
				orientation="vertical"
				lockAxis="y"
				dragHandleSelector=".drag-handle"
				onDrop={onDrop}
			>
				{/* list of input fields */}
				{value.map((input, i) => (
					<Draggable key={i}>
						<Stack
							bgcolor={bgcolor}
							spacing={1}
							sx={{my: 1, p: 1}}
							direction="row"
							justifyContent="space-between"
						>
							<Stack spacing={2}>
								<Stack alignItems="center" spacing={4} direction="row">
									<DragHandleIcon className={'drag-handle hover-effect'} />

									{allowChangeType && (
										<Picker
											sx={{minWidth: 80}}
											label="نوع الحقل"
											placeholder="نوع الحقل"
											defaultValue="text"
											options={[
												{key: 'text', label: 'نص'},
												{key: 'picker', label: 'اختيار'},
											]}
											labelKey="label"
											value={input.type ?? 'text'}
											onChange={(e) => onInputChange({...input, type: e.target.value})}
											valueKey="key"
										/>
									)}
									{/* input field config */}
									{fields.map((fieldConfig) => {
										const fieldKey = fieldConfig.key;
										const v = input[fieldKey];

										return (
											<DynamicFormInput
												input={fieldConfig}
												value={v}
												fullWidth={fieldConfig.fullWidth}
												setFieldValue={(fieldName, value) => onInputChange({...input, [fieldKey]: value})}
											/>
										);
									})}
									<IconButton
										color="error"
										sx={{maxWidth: 30}}
										size="small"
										variant="outlined"
										onClick={(e) => remove(input[inputKey], e)}
									>
										<DeleteIcon />
									</IconButton>
								</Stack>
								{allowChangeType && input.type === 'picker' && (
									<CommaSeparatedTextField
										containerSx={{padding: 2}}
										label="الاختيارات"
										value={input.options ?? []}
										valueFormatter={(str) => ({value: str})}
										labelFormatter={(obj) => obj.value}
										setValue={(values) => onInputChange({...input, options: values})}
									/>
								)}
								{allowChangeType &&
									input.type === 'picker' &&
									renderOptions &&
									renderOptions({
										options: input.options ?? [],
										handleInputChange: (value) =>
											onInputChange({...input, options: updateArray(input.options, value, 'value')}),
									})}
							</Stack>
						</Stack>
						<Divider />
					</Draggable>
				))}
			</DContainer>
		</Stack>
	);
}
