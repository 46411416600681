import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function ResponsiveDialog({
	maxWidth = 'sm',
	open,
	setOpen,
	actions,
	children,
	title,
	fullScreen,
	bgcolor = 'white',
}) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const _fullScreen = fullScreen || isSmallScreen;

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Dialog
			maxWidth={maxWidth}
			fullWidth={true}
			fullScreen={_fullScreen}
			open={open}
			onClose={handleClose}
			aria-labelledby="responsive-dialog-title"
		>
			{title && <DialogTitle>{title}</DialogTitle>}
			<DialogContent sx={{bgcolor, p: 2}}>{children}</DialogContent>
			{actions && <DialogActions sx={{px: 4}}>{actions}</DialogActions>}
		</Dialog>
	);
}
