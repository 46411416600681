import {Card, Container, Stack, TextField, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import * as yup from 'yup';
import FormikWrapper from '../../components/wrappers/FormikWrapper.js';
import Picker from 'src/components/inputs/Picker';
import SubmitButton from '../../components/buttons/SubmitButton.js';
import useFind from '../../hooks/useFind.js';
import PhoneInput from '../../components/inputs/PhoneInput.js';
import {getCities, getOrder, updateOrder} from '../../utils/AdminApi.js';
import {isNullOrEmpty} from '../../utils/helperMethods.js';
import React from 'react';
import {getCity} from '../../utils/cityUtils.js';
import {LoadingView} from 'src/routes.js';
import {getNumber} from 'src/utils/numberUtils.js';
import {order_selected_date_feature_flag} from 'src/feature_flag_list.js';
import {isFeatureAllowed} from 'src/utils/featureUtils';
import {convertTextfieldDateToUTC, getDateTime, getTextfieldDate} from 'src/utils/date.js';
import {canAccess} from 'src/utils/permissions';
import {addDiscountPermission} from 'src/permissionsList.js';
import {CustomAutoComplete} from 'vetrina-ui';

const UpdateOrderDetails = () => {
	const [order, setOrder] = useState();
	const navigate = useNavigate();
	const {id} = useParams();
	const [cities] = useFind(getCities);

	// removes the city from the full address and return base address
	const getAddressWithoutCity = (fullAddress) => {
		return fullAddress
			.split(' ')
			.filter((word) => word !== order?.delivery_info?.city)
			.join(' ');
	};
	const getPureAddress = (fullAddress, delvInfo) => {
		let address =
			delvInfo?.type === 'delivery'
				? fullAddress !== undefined
					? getAddressWithoutCity(fullAddress)
					: ''
				: '';
		return address;
	};

	const orderAddress = getPureAddress(order?.address, order?.delivery_info);

	// fetching the order to be updated
	useEffect(() => {
		getOrder(id)
			.then(async (res) => {
				setOrder(res);
			})
			.catch((err) => alert(err));
	}, []);

	// navigate to order page
	const navigateToOrderPage = () => {
		navigate(`/orders/${id}`, {replace: true});
	};

	// determines whether info were changed or not by comparing formik values with fetched order values while checking for undefined fetched values

	const handleSubmit = async (values) => {
		const {
			name,
			discount,
			phone2,
			selected_date,
			down_payment,
			note,
			phone,
			address,
			city,
			district,
			delivery_price,
		} = values;

		const nweOrder = {
			name,
			phone,
			selected_date: selected_date ? convertTextfieldDateToUTC(selected_date) : null,
			address,
			delivery_info: {
				...order?.delivery_info,
				city,
				address,
				// prevent sending emptyh string district
				district: isNullOrEmpty(district) ? undefined : district,
			},
			discount: getNumber(discount),
			delivery_price: getNumber(delivery_price),
			note,
			custom_fields: {
				phone2,
			},
			// down_payment: getNumber(down_payment),
		};
		try {
			await updateOrder(order.uuid, nweOrder);
			toast.success('تم تعديل البيانات بنجاح');
			navigateToOrderPage();
		} catch (error) {
			toast.error('حدث خطأ غير متوقع');
		}
	};

	if (!order) return <LoadingView />;

	return (
		<Container>
			<FormikWrapper
				values={{
					name: order?.customer?.name ?? '',
					phone: order?.customer?.phone ?? '',
					address: orderAddress,
					country: order?.delivery_info?.country ?? '',
					city: order?.delivery_info?.city ?? '',
					district: order?.delivery_info?.district ?? '',
					note: order?.note ?? '',
					discount: order?.discount ?? '',
					down_payment: order?.down_payment ?? '',
					delivery_method: order?.delivery_info?.type ?? 'delivery',
					phone2: order?.custom_fields?.phone2 ?? '',
					delivery_price: getNumber(order.delivery_price),
					selected_date: order?.selected_date ? getTextfieldDate(order.selected_date) : '',
				}}
				schema={schema}
				handleSubmit={handleSubmit}
			>
				{({values, isSubmitting, getInputProps, setFieldValue}) => {
					console.log('selected_date', values.selected_date);
					const selectedCity = getCity(cities, values.city);
					const cityHasDistricts = !isNullOrEmpty(selectedCity?.districts);
					return (
						<Card sx={{p: 2}}>
							<Stack spacing={3}>
								<Typography variant="h3">{' تعديل الطلب ' + '#' + order?.id}</Typography>

								<TextField fullWidth type="text" label="اسم الزبون" {...getInputProps('name')} />

								<PhoneInput
									label="رقم الهاتف"
									placeholder="رقم الهاتف"
									{...getInputProps('phone')}
									fullWidth
									type="tel"
								/>
								<PhoneInput
									label="رقم هاتف - احتياطي"
									placeholder="رقم هاتف - احتياطي"
									{...getInputProps('phone2')}
									fullWidth
									type="tel"
								/>

								{order?.delivery_info?.type === 'delivery' && (
									<>
										<CustomAutoComplete
											options={values.country == 'LYD' ? cities.map((c) => c.name) : []}
											label="المدينة"
											{...getInputProps('city')}
											onChange={(e, opt) => {
												setFieldValue('city', opt ?? '');
												setFieldValue('district', '');
											}}
											onInputChange={(event, newInputValue) => {
												setFieldValue('city', newInputValue ?? '');
											}}
											freeSolo={values.country != 'LYD'}
											value={values.city}
										/>

										{cityHasDistricts && (
											<Picker
												options={selectedCity?.districts ?? []}
												label="المنطقة"
												labelKey="name"
												valueKey="name"
												{...getInputProps('district')}
											/>
										)}

										<TextField
											label="العنوان"
											placeholder="العنوان"
											{...getInputProps('address')}
											fullWidth
											type="text"
										/>

										{canAccess() && (
											<TextField
												fullWidth
												type="text"
												inputMode="numeric"
												pattern="\d*"
												InputProps={{min: 0}}
												label="سعر التوصيل"
												{...getInputProps('delivery_price')}
											/>
										)}
									</>
								)}

								{isFeatureAllowed(order_selected_date_feature_flag) && (
									<TextField
										{...getInputProps('selected_date')}
										sx={{
											mt: 2,
										}}
										label={'موعد التسليم'}
										InputLabelProps={{
											shrink: true,
										}}
										fullWidth
										type="datetime-local"
									/>
								)}

								<TextField
									label="ملاحظات"
									placeholder="ملاحظات اضافية عن الطلب"
									{...getInputProps('note')}
									fullWidth
									minRows={3}
									multiline
									type="text"
								/>

								{canAccess(addDiscountPermission) && (
									<TextField
										fullWidth
										type="text"
										inputMode="numeric"
										pattern="\d*"
										InputProps={{min: 0}}
										label="تخفيض"
										{...getInputProps('discount')}
									/>
								)}

								{/* <TextField
									fullWidth
									type="text"
									inputMode="numeric"
									pattern="\d*"
									InputProps={{min: 0}}
									label="العربون"
									{...getInputProps('down_payment')}
								/>  */}

								<SubmitButton isSubmitting={isSubmitting}>تعديل</SubmitButton>
							</Stack>
						</Card>
					);
				}}
			</FormikWrapper>
		</Container>
	);
};
export default UpdateOrderDetails;

const schema = yup.object({
	name: yup.string(),
	phone: yup.string('').min(9, 'رقم الهاتف يجب ان يتكون من ٩ ارقام'),
	address: yup.string(),
	city: yup.string().when('delivery_method', (delivery_method, field) => {
		if (delivery_method === 'delivery') {
			return field.required('يرجي اختيار المدينة');
		} else {
			// no need for validation
			return field;
		}
	}),
	district: yup.string().when('city', (city, field) => {
		const cityHasDistricts = city === 'طرابلس' || city === 'بنغازي';
		if (cityHasDistricts) {
			// district is required when city has districts
			return field.required('يرجي اختيار المنطقة');
		} else {
			// no need for validation
			return field;
		}
	}),
});
