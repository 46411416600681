//mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
//icons
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
//react-router-dom
import {Link, useLocation, useNavigate} from 'react-router-dom';
//hooks & utils
import {toast} from 'react-toastify';
import {createOrder, getOrder} from 'src/utils/AdminApi';
import {
	buildCartItemFromOrderItem,
	buildOrderItem,
	getItemTotalPrice,
	isCartItemValid,
} from 'src/utils/orderItemUtils';
import {getNumber, sumBy} from '../../../utils/numberUtils';
//components
import {useEffect, useRef, useState} from 'react';
import {printJsonReceipt} from 'src/features/printer';
import ProductsGridSelection from 'src/pages/products/common/ProductsGridSelection/ProductsGridSelection';
import {addDiscountPermission, findOrderPermission} from 'src/permissionsList';
import {handleRequestError, isNullOrEmpty} from 'src/utils/helperMethods';
import {canAccess} from 'src/utils/permissions';
import {accountIs, accountOneOf} from 'src/utils/siteUtils';
import SelectedOrderItems from './SelectedOrderItems';
import useOrderItemsSelection from './useOrderItemsSelection';
import Page from 'src/components/Page';
import {getCartItemsTotalPrice} from 'src/utils/cartItemUtils';
import {InputAdornment} from '@mui/material';
import CartItem from '../CartItem.item';
import {askForOption, askYesOrNo} from 'src/components/modals/ChoicesModal';
import {isFeatureAllowed} from 'src/utils/featureUtils';
import {invoices_feature_flag, order_selected_date_feature_flag} from 'src/feature_flag_list';
import {showModel} from 'src/components/modals/showModel';
import {Howl, Howler} from 'howler';
import equalId from 'src/utils/equalId';
import {LoadingButton} from '@mui/lab';

export default function CashierPage() {
	const [downPayment, setDownPayment] = useState(0);
	const [discount, setDiscount] = useState(0);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const navigate = useNavigate();
	const productsGridSelectionRef = useRef();
	// these variables for inputs coming from dublicate button in order details
	const location = useLocation();
	// we will get order from duplicate button
	const {order} = location.state ?? {};

	const cashSFX = new Howl({
		src: ['/sfx/cash-register.mp3'],
	});
	const clickSFX = new Howl({
		src: ['/sfx/click.mp3'],
	});
	const removeSFX = new Howl({
		src: ['/sfx/remove.mp3'],
	});

	const withDiscount = getNumber(discount) > 0;
	const {
		handleItemQuantityChange,
		checkAddedItemExists,
		handleAddItem,
		handleRemoveItem,
		addedProducts,
		handleItemChange,
		resetSelections,
		setAddedProducts,
	} = useOrderItemsSelection(order?.items?.map(buildCartItemFromOrderItem) ?? []);

	const handleBuyButton = async (gateway = 'cash') => {
		let phone;
		if (gateway == 't_lync') {
			phone = prompt('ادخل رقم الهاتف', '0924474423');
			if (!phone) return;
		}

		if (!isCartItemValid(addedProducts)) return toast.error('يرجى تعبئة جميع الحقول');
		// let selected_date;
		// if (isFeatureAllowed(order_selected_date_feature_flag)) {
		// 	selected_date = await askForDate();
		// }

		setIsSubmitting(true);
		// transform added products state to array accepted by the api
		const cart = addedProducts.map(buildOrderItem);

		try {
			const orderDetails = await createOrder({
				phone,
				cart,
				status: 'delivered',
				delivery_info: {
					type: 'pickup',
				},
				discount: getNumber(discount),
				down_payment: getNumber(downPayment),
				payment_received: getNumber(downPayment) === 0,
				is_cashier: isFeatureAllowed(invoices_feature_flag),
				payment: {
					// payment info: default is cash
					gateway,
				},
				// selected_date,
			});
			cashSFX.play();
			toast.success('تم انشاء الطلب بنجاح');
			// PRINT RECEIPT
			// we need to fetch order details so that we can get the order in format accepted by print function
			// const orderDetails = await getOrder(res.uuid);
			// RESET
			resetSelections();
			setDiscount('');
			setDownPayment('');
			// reset products results (to update quantity)
			productsGridSelectionRef.current.refetchProducts();

			askForOption('', [
				{
					id: 1,
					variant: 'outlined',
					text: 'عرض الطلب',
					action: () => navigate(`/orders/${orderDetails.uuid}`),
				},
				{id: 2, variant: 'outlined', text: 'طباعة', action: () => printJsonReceipt(orderDetails)},
				{id: 3, default: true, variant: 'outlined', text: 'اضافة طلب جديد'},
			]);
		} catch (error) {
			let isCartIssue = false;
			let updatedItems = [];
			const msg = await handleRequestError(error, async (status, responseBody) => {
				const errorBody = responseBody?.data;

				if (status === 406) {
					const errorId = errorBody?.id;
					if (errorId === 'CART_EMPTY') {
						return 'يجب اختيار منتجات لإدخال الطلب';
					} else if (errorId === 'CART_NOT_VALID') {
						isCartIssue = true;
						updatedItems = errorBody.updatedItems;

						// return 'يوجد مشكلة في المنتجات المختارة, يرجى التأكد من السعر';
					}

					return responseBody.message;
				}
			});

			toast.error(msg);

			// if (isCartIssue) {
			// 	const bool = await askYesOrNo('تحديث المنتجات');
			// 	if (bool) {
			// 		const updatedCart = updatedItems
			// 			.map((newItem) => {
			// 				const oldItem = addedProducts.find(
			// 					(a) => equalId(a.product, newItem.product) && equalId(a.variation, newItem.variation),
			// 				);
			// 				if (!oldItem) return null; // unexpected to happen

			// 				return {
			// 					...oldItem,
			// 					price: newItem.price,
			// 					discount: newItem.discount,
			// 				};
			// 			})
			// 			.filter((a) => a); // filter null
			// 		setAddedProducts(updatedCart);
			// 	}
			// }
		} finally {
			setIsSubmitting(false);
		}
	};

	// to automatically focus on search when typing on keyboard
	useEffect(() => {
		const handlePress = (event) => {
			if (event.target.tagName.toLowerCase() !== 'input') {
				const element = document.getElementById('searchInput');
				element.focus();
			}
		};
		document.addEventListener('keypress', handlePress);
		return () => {
			document.removeEventListener('keypress', handlePress);
			cashSFX.unload();
			clickSFX.unload();
			removeSFX.unload();
		};
	}, []);

	const canSeeOrders = canAccess(findOrderPermission);

	return (
		<Page title="الكاشير">
			<Stack height={'100vh'}>
				<Stack direction={'row'} spacing={3} p={3} alignItems={'center'}>
					<Link to={canSeeOrders ? '/orders' : '/'} style={{textDecoration: 'none'}}>
						<Button startIcon={<ArrowForwardIosRoundedIcon />}>
							{canSeeOrders ? 'الطلبات' : 'الرئيسية'}
						</Button>
					</Link>
					<Typography variant="h3">إضافة طلب</Typography>
				</Stack>

				<Grid container flexGrow={1} sx={{borderTop: '1px solid rgba(145, 158, 171, 0.5)'}}>
					<Grid p={2} item xs={8}>
						<ProductsGridSelection
							ref={productsGridSelectionRef}
							flexBasis={1}
							handleItemQuantityChange={handleItemQuantityChange}
							checkAddedItemExists={checkAddedItemExists}
							handleAddItem={(item) => {
								clickSFX.play();
								handleAddItem(item);
							}}
							handleRemoveItem={handleRemoveItem}
							addedProducts={addedProducts}
							resetSelections={resetSelections}
						/>
					</Grid>
					{/* Left section (Selected Products) */}
					<Grid
						item
						xs={4}
						sx={{
							borderLeft: '1px solid rgba(145, 158, 171, 0.5)',
						}}
					>
						<Stack height={'100%'}>
							<Stack spacing={2} p={3} pt={2} flexGrow={1}>
								<Typography variant="h5">تفاصيل الطلب</Typography>
								<Divider />
								<Typography fontWeight={'bold'}>المنتجات المضافة</Typography>
								<Box flexGrow={1} flexBasis={'10px'} overflow={'auto'}>
									<Stack spacing={1}>
										{/* <SelectedOrderItems
										 	product={product}
										 	handleItemQuantityChange={handleItemQuantityChange}
										 	handleRemoveItem={handleRemoveItem}
										 	key={product.id}
										 /> */}
										{addedProducts.map((cartItem) => (
											<CartItem
												item={cartItem}
												handleItemChange={handleItemChange}
												handleItemQuantityChange={(id, quantity) => {
													clickSFX.play();
													handleItemQuantityChange(id, quantity);
												}}
												handleRemoveItem={(...args) => {
													removeSFX.play();

													handleRemoveItem(...args);
												}}
												key={cartItem.id}
											/>
										))}
									</Stack>
								</Box>
							</Stack>
							<Stack
								height={'150px'}
								justifyContent={'space-between'}
								sx={{backgroundColor: '#076458', color: 'white'}}
								p={3}
							>
								<Stack alignItems="center" spacing={1} direction="row" justifyContent="space-between">
									<Stack direction="row" justifyContent="space-between">
										<Stack>
											<Stack spacing={1} direction="row" justifyContent="space-between">
												<Typography variant="h5">المجموع:</Typography>
												<Typography sx={{textDecoration: withDiscount && 'line-through'}} variant="h5">
													{getCartItemsTotalPrice(addedProducts)}
												</Typography>
											</Stack>
											{withDiscount && (
												<Typography color="secondary" my={-1} mx={1} variant="h5">
													{getCartItemsTotalPrice(addedProducts) - getNumber(discount)}
												</Typography>
											)}
										</Stack>
									</Stack>
									<Stack spacing={1} direction="row" justifyContent="space-between">
										{canAccess(addDiscountPermission) && (
											<TextField
												variant="outlined"
												size="small"
												value={discount}
												InputProps={{
													sx: {background: 'white'},

													startAdornment: <InputAdornment position="end">تخفيض</InputAdornment>,
												}}
												disabled={isNullOrEmpty(addedProducts)}
												sx={{width: 150}}
												onChange={(e) => {
													let v = getNumber(e.target.value, 0);
													if (v > getCartItemsTotalPrice(addedProducts)) return;
													setDiscount(Math.abs(v));
												}}
											/>
										)}

										<TextField
											variant="outlined"
											size="small"
											value={downPayment}
											InputProps={{
												sx: {background: 'white'},

												startAdornment: <InputAdornment position="end">العربون</InputAdornment>,
											}}
											disabled={isNullOrEmpty(addedProducts)}
											sx={{width: 150}}
											onChange={(e) => {
												let v = getNumber(e.target.value, 0);
												if (v > getCartItemsTotalPrice(addedProducts)) return;
												setDownPayment(Math.abs(v));
											}}
										/>
									</Stack>
								</Stack>

								<Stack spacing={1} direction="row" justifyContent="space-between">
									<LoadingButton
										loading={isSubmitting}
										onClick={() => handleBuyButton('cash')}
										variant="contained"
										color="secondary"
										fullWidth
										sx={{color: 'black'}}
										disabled={addedProducts.length == 0}
									>
										كاش
									</LoadingButton>
									<Button
										onClick={() => handleBuyButton('pos')}
										variant="outlined"
										color="secondary"
										fullWidth
										sx={{color: 'white'}}
										disabled={addedProducts.length == 0 || isSubmitting}
									>
										بطاقة
									</Button>
									{accountIs('demo') && (
										<Button
											onClick={() => handleBuyButton('t_lync')}
											variant="outlined"
											color="secondary"
											fullWidth
											sx={{color: 'white'}}
											disabled={addedProducts.length == 0 || isSubmitting}
										>
											اونلاين
										</Button>
									)}
								</Stack>
							</Stack>
						</Stack>
					</Grid>
				</Grid>
			</Stack>
		</Page>
	);
}

export function askForDate() {
	return showModel({
		title: 'موعد التسليم',
		component: function ({proceed}) {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const [date, setDate] = useState();
			return (
				<form
					onSubmit={async (e) => {
						e.preventDefault();
						proceed(date);
					}}
				>
					<Stack spacing={6}>
						<Stack spacing={1}>
							<TextField
								sx={{
									mt: 2,
								}}
								label={'موعد التسليم'}
								InputLabelProps={{
									shrink: true,
								}}
								fullWidth
								value={date ?? ''}
								onChange={(e) => setDate(e.target.value)}
								type="datetime-local"
							/>
						</Stack>
						<Button type="submit" variant="contained">
							تأكيد
						</Button>
					</Stack>
				</form>
			);
		},
	});
}
