import {Stack, Switch, Typography} from '@mui/material';
import InfoTip from 'src/components/ui/InfoTip';
import BasicRadioGroup from 'src/components/wrappers/BasicRadioGroup';
export default function AvailabilityRadioButtons({sx = {}, value = '', onChange}) {
	return (
		<Stack sx={sx} alignItems="center" direction="row" spacing={1}>
			<BasicRadioGroup
				label="نوع المنتج"
				value={value}
				options={[
					{label: 'استلام فوري', value: false},
					{label: 'متوفر بالحجز', value: true},
				]}
				onChange={(e) => onChange(e.target.value === 'true' ? true : false)}
			/>
			{/* <InfoTip title="" /> */}
		</Stack>
	);
}
