import React, {useRef, useState} from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import BasicModal from 'src/components/modals/BasicModal';
import DragDropInput from './DragDropInput';
import {Button, CircularProgress, IconButton} from '@mui/material';
import CropIcon from '@mui/icons-material/Crop';
import ClearIcon from '@mui/icons-material/Clear';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import {validateFileSize, validateFileType} from './filesUtils';
import {toast} from 'react-toastify';
import {uploadFileRequest} from 'src/utils/AdminApi';

export const FileUploadField = ({
	value,
	onChange,
	label,
	defaultImage = null,
	onClear = () => {},
	ratio = 4 / 5,
	helperText,
	maxSize = 2,
	error,
	placeholder,
}) => {
	// this will be used to trigger upload files when button clicked
	const fileInputRef = useRef(null);
	// image source ( data url ) for preview, example preview image in update page
	const [selectedImage, setSelectedImage] = useState();
	const [image, setImage] = useState(defaultImage);
	//####################################
	// cropper
	const cropperRef = useRef(null);
	const [shouldShowCropModal, setShouldShowCropModal] = useState(false);
	// used to show loader until upload image to server complete
	const [isUploading, setIsUploading] = useState(false);
	//####################################

	const handleFileUpload = (e) => {
		e.preventDefault();
		let files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}
		// validation
		if (!files || files.length === 0) return;

		if (!validateFileType(files[0])) {
			return toast.error('ملف غير مدعوم');
		}
		if (!validateFileSize(files[0], maxSize)) {
			return toast.error(`حجم الصور يجب ان لا يتجاوز ${maxSize} ميجا`);
		}

		setSelectedImage(URL.createObjectURL(files[0]));
		setShouldShowCropModal(true);
	};

	const confirmFileCrop = () => {
		const croppedCanves = cropperRef.current.cropper.getCroppedCanvas();
		// disabled because of performance issue due ui thread blocking, readImageFromFile used instead
		// setImage(croppedCanves.toDataURL('image/webp', 0.9));
		setIsUploading(true);
		croppedCanves.toBlob(
			async (blob) => {
				try {
					const res = await uploadFileRequest(blob);
					setImage(res[0].url);
					setShouldShowCropModal(false);
					onChange(res[0].id);
				} catch (err) {
					toast.error('تعذر تحميل الصورة');
				} finally {
					setIsUploading(false);
				}
			},
			'image/webp',
			0.9,
		);
	};

	// eslint-disable-next-line no-unused-vars
	const clearImage = () => {
		setImage(null);
		onChange(null);
		onClear();
	};

	return (
		<div>
			{label && <label className="label">{label}</label>}
			{/* this logic to hide input when image is selected & */}
			{/* (!image || (!didSelectCroppedImage && !shouldShowCropModal)) */}
			{!value ? (
				<DragDropInput placeholder={placeholder} onChange={handleFileUpload} />
			) : (
				<div style={{width: '100%'}}>
					<div style={{position: 'relative'}}>
						<img src={image} style={{objectFit: 'contain'}} height={200} alt="الصورة المراد تحميلها" />
						<input hidden ref={fileInputRef} onChange={handleFileUpload} type="file" />
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								position: 'absolute',
								background: 'rgb(0, 0, 0,0.1)',
								right: '5px',
								top: '5px',
							}}
						>
							<IconButton
								size="small"
								sx={{color: 'white'}}
								type="button"
								onClick={() => setShouldShowCropModal(true)}
							>
								<CropIcon />
							</IconButton>
							<IconButton
								size="small"
								sx={{color: 'white'}}
								type="button"
								onClick={() => fileInputRef.current.click()}
							>
								<ChangeCircleIcon />
							</IconButton>
							<IconButton size="small" sx={{color: 'white'}} type="button" onClick={clearImage}>
								<ClearIcon />
							</IconButton>
						</div>
					</div>
				</div>
			)}

			{/* preview continer */}

			{error && <p style={{color: 'red'}}>{helperText}</p>}

			{/* crop modal */}
			<BasicModal
				isVisible={shouldShowCropModal}
				// prevent dismiss
				setVisibility={setShouldShowCropModal}
			>
				<Cropper
					movable={false}
					draggable={false}
					modal={false}
					style={{
						height: 200,
						width: '100%',
					}}
					initialAspectRatio={ratio}
					aspectRatio={ratio}
					src={selectedImage ?? image}
					zoomable={false}
					viewMode={1}
					minCropBoxHeight={10}
					minCropBoxWidth={10}
					background={false}
					// responsive={true}
					autoCropArea={1}
					checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
					ref={cropperRef}
					guides
				/>

				<p style={{textAlign: 'center'}}>يرجي تحديد ابعاد الصورة لكي تظهر بشكل مناسب في الموقع</p>

				{isUploading ? (
					<CircularProgress />
				) : (
					<Button
						fullWidth
						size="large"
						sx={{mt: 2}}
						variant="contained"
						type="button"
						onClick={confirmFileCrop}
					>
						اختيار
					</Button>
				)}
			</BasicModal>
		</div>
	);
};

export default FileUploadField;
