import {
	deletePropertyValue,
	updateProperties,
	updatePropertyValues,
} from '../../../../utils/AdminApi.js';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete.js';
import Button from '@mui/material/Button';
import {toast} from 'react-toastify';

const UpdatePropForm = ({prop, setProperty}) => {
	console.log(prop);

	// Props of  the Box container of the all the property's values
	const valuesContainerStyles = {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	};

	// Props of the submit button of the property edit form
	const submitBtnProps = {
		variant: 'contained',
		size: 'large',
		sx: {width: {xs: '100%', sm: 150}},
		onClick: () => handleSubmit(),
	};

	// Props of the property's name's field's TextField
	const nameTextFieldProps = {
		variant: 'filled',
		id: 'prop-name',
		value: prop?.name ?? '',
		sx: {width: '97%', mb: 3},
		onChange: (e) => handleNameChange(e),
	};

	// Props of the property's values' fields' TextFields
	const valueTextFieldProps = {sx: {width: '97%'}, variant: 'filled'};

	// Name change handler function
	const handleNameChange = (e) => {
		e.preventDefault();
		setProperty((prevProp) => ({...prevProp, name: e.target.value}));
	};

	// Values change handler function
	const handleValueChange = (e, id) => {
		e.preventDefault();
		const valueIndex = prop?.values.findIndex((value) => value.id === id);
		setProperty((prevProp) => {
			let {values} = prevProp;
			let newValues = [...values];
			newValues[valueIndex] = {...newValues[valueIndex], value: e.target.value};
			return {...prevProp, values: [...newValues]};
		});
	};

	const handleValueBlur = async (e, value_id) => {
		e.preventDefault();
		let propValue = e.target.value;
		try {
			await updatePropertyValues(value_id, {value: propValue});
		} catch (err) {
			toast.error('حدث خطأ غير متوقع!');
		}
	};

	// Form submit handler function
	const handleSubmit = async () => {
		// const finalProp = {...prop, name, values}
		try {
			// TODO check updateProperties API response
			/*
        API response returns original property before change, also it doesn't change it
        when changes are made then submit is clicked, going to properties page shows the
        original property without the made changes
       */
			const nameResponse = await updateProperties(prop.id, {name: prop?.name, values: prop?.values});
			toast.success('تم تعديل الخاصية بنجاح');
		} catch (e) {
			toast.error('حدث خطأ غير متوقع!');
		}
	};

	// Delete single property's Value handler function
	const deletePropValue = async (id) => {
		setProperty((prevProp) => {
			let {values} = prevProp;
			const filteredValues = values.filter((value) => value.id !== id);
			return {...prevProp, values: filteredValues};
		});
		try {
			await deletePropertyValue(id);
			toast.success('تمت العملية بنجاح');
		} catch (e) {
			toast.error('حدث خطأ غير متوقع!');
		}
	};

	return (
		<Stack sx={{mt: 1}}>
			<label htmlFor="prop-name" style={{marginInlineStart: 10}}>
				اسم الخيار
			</label>
			<TextField {...nameTextFieldProps} />
			<label htmlFor="prop-vals" style={{marginInlineStart: 10}}>
				قيمة الخيار
			</label>
			<Box id="prop-vals" sx={{display: 'flex', flexDirection: 'column', rowGap: 1}}>
				{typeof prop?.values !== 'undefined' &&
					prop?.values.length !== 0 &&
					prop?.values.map((value, index) => (
						<Box key={value?.id ?? index} sx={valuesContainerStyles}>
							<TextField
								{...valueTextFieldProps}
								value={value.value}
								onChange={(e) => handleValueChange(e, value.id)}
								onBlur={(e) => handleValueBlur(e, value.id)}
							/>
							<IconButton color="error" onClick={() => deletePropValue(value?.id)}>
								<DeleteIcon />
							</IconButton>
						</Box>
					))}
			</Box>
			<Box sx={{mt: 2}}>
				<Button {...submitBtnProps}>حفظ التعديل</Button>
			</Box>
		</Stack>
	);
};
export default UpdatePropForm;
