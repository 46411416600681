import {Checkbox, FormControlLabel} from '@mui/material';
import React from 'react';

export default function CheckboxWrapper({label, value, defaultValue, ...props}) {
	return (
		<FormControlLabel
			control={<Checkbox {...props} />}
			label={label}
			checked={value}
			defaultChecked={defaultValue}
		/>
	);
}
