import {Box, CircularProgress, Stack, Typography} from '@mui/material';
// material
import {experimentalStyled as styled} from '@mui/material/styles';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Outlet, useLocation} from 'react-router-dom';
import GlobalModal from 'src/components/modals/GlobalModal.js';
import SubscriptionAlert from 'src/components/SubscriptionAlert.js';
import useCategories from 'src/hooks/useCategories.js';
import ExpiredSite from 'src/pages/ExpiredSite';
import BottomTab from '../../components/BottomTab/BottomTab.js';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import {askYesOrNo} from 'src/components/modals/ChoicesModal.js';
import {requestPermission} from 'src/firebase.js';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
	display: 'flex',
	minHeight: '100%',
	overflow: 'hidden',
});

const MainStyle = styled('div')(({theme}) => ({
	flexGrow: 1,
	overflow: 'auto',
	// minHeight: '100%',
	minHeight: '100vh',
	paddingTop: APP_BAR_MOBILE + 24,
	// paddingBottom: theme.spacing(6),
	[theme.breakpoints.up('lg')]: {
		paddingTop: APP_BAR_DESKTOP + 24,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
}));

// ----------------------------------------------------------------------

export default function AdminDashboardLayout() {
	const location = useLocation();
	const [locationPathName, setPathName] = useState(location.pathname);
	let {isLoading: shouldShowLoader} = useSelector((state) => state.app);
	let admin = useSelector((state) => state.admin.user);
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);

	const {fetchCategories} = useCategories();

	useEffect(() => {
		fetchCategories();
	}, []);

	useEffect(() => {
		if (
			typeof Notification === 'undefined' ||
			Notification.permission !== 'default' ||
			localStorage.getItem('notification_rejected')
		)
			return;

		setTimeout(() => {
			askYesOrNo('هل تريد استقبال اشعارات؟', {dismissable: false}).then((isYes) => {
				if (isYes) requestPermission();
				else {
					localStorage.setItem('notification_rejected', true);
				}
			});
		}, 1000);
	}, []);

	if (admin.OwnedWebsite.isExpired) return <ExpiredSite />;

	/* 	useEffect(() => {
		// Get registration token. Initially this makes a network call, once retrieved
		// subsequent calls to getToken will return from cache.
		getToken(messaging, {
			vapidKey:
				'BMGrYu68YUuRNm8j8gfGRksr1eY9HCifcEnwxSxUbtzkzOx-b6T9oLDvUg3soZhzBNMuivMUrN3KeyJiRbEA7-0',
		})
			.then((newToken) => {
				if (newToken) {
					// Send the token to your server and update the UI if necessary
					// ...
					console.log(newToken);
					try {
						// send request to update token if user is logged in and token changed
						if (admin && admin.fcmToken != newToken) setFcmToken(newToken);
						else {
							// console.log('token didn:t change');
						}
					} catch (error) {
						// handle error
					}
					// console.log('user token', currentToken);
				} else {
					// Show permission request UI
					console.log('No registration token available. Request permission to generate one.');
					// ...
				}
			})
			.catch((err) => {
				console.log('An error occurred while retrieving token. ', err);
				// ...
			});
	}, [admin]); */
	const handleSidebarClose = () => {
		setOpen(false);
		setPathName(location.pathname);
	};
	const pathNameStateObject = {
		locationPathName,
		changePathName: (newPathName) => setPathName(newPathName),
	};

	return (
		<RootStyle className="gradient-custom">
			<DashboardNavbar onOpenSidebar={() => setOpen(true)} pthNameObj={pathNameStateObject} />
			<DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => handleSidebarClose()} />
			<MainStyle>
				<Box sx={{mb: 2}}>
					<SubscriptionAlert />
				</Box>
				<Box sx={{mb: '80px'}}>
					<Outlet />
				</Box>
				{shouldShowLoader && (
					<div className="window">
						<Stack spacing={1} justifyContent="center" alignItems="center" style={{height: '100vh'}}>
							<CircularProgress color="secondary" />
							<Typography variant="h6" style={{color: 'white'}}>
								يرجي الانتظار
							</Typography>
						</Stack>
					</div>
				)}
				<GlobalModal />
				<BottomTab sideBarSetState={setOpen} />
			</MainStyle>
		</RootStyle>
	);
}
