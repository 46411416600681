export const baseURL = process.env.REACT_APP_API_URL ?? 'https://api.vetrinas.ly';

// Firebase work
/* import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';
import {getAnalytics, setAnalyticsCollectionEnabled} from 'firebase/analytics'; */
// import {getMessaging, onMessage} from 'firebase/messaging';

/* export const firebaseConfig = {
	apiKey: 'AIzaSyDVGBnJR5aODjXDRR7IAlHQh4jxOqPj20Q',
	authDomain: 'vetrina-2c261.firebaseapp.com',
	databaseURL: 'https://vetrina-2c261-default-rtdb.europe-west1.firebasedatabase.app',
	projectId: 'vetrina-2c261',
	storageBucket: 'vetrina-2c261.appspot.com',
	messagingSenderId: '726738679498',
	appId: '1:726738679498:web:18af0b9e34d49584fee633',
	measurementId: 'G-PSRV8Q00WY',
};
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const analyticsInstance = getAnalytics(app);
setAnalyticsCollectionEnabled(analyticsInstance, process.env.NODE_ENV === 'production');
// export const messaging = getMessaging(app);
export const authentication = getAuth(app); */

/* onMessage(messaging, (payload) => {
	console.log('Message received. ', payload);
	// ...
});
 */
