import {FormControlLabel, Stack, Switch, Typography} from '@mui/material';
import InfoTip from 'src/components/ui/InfoTip';
export default function PublishSwitch({sx = {}, value, onChange}) {
	return (
		<Stack sx={sx} alignItems="center" direction="row">
			<FormControlLabel
				control={
					<Switch
						checked={value}
						onChange={(e) => {
							if (e.target.checked) {
								onChange(new Date());
							} else {
								onChange(null);
							}
						}}
					/>
				}
				label={
					<Typography variant="body2">
						معروض
						<InfoTip sx={{mb: 0}} title="سيتم عرض المنتج في المتجر إذا تم تفعيل هذا الخيار" />
					</Typography>
				}
			/>
		</Stack>
	);
}
