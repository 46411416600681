import {LoadingButton} from '@mui/lab';
import {Button, CircularProgress} from '@mui/material';
import React from 'react';

export default function SubmitButton({
	fullWidth = true,
	isSubmitting,
	disabled,
	sx = {},
	size = 'large',
	...props
}) {
	return (
		<LoadingButton
			sx={{mt: 4, ...sx}}
			fullWidth={fullWidth}
			size={size}
			type="submit"
			variant="contained"
			color="secondary"
			loading={isSubmitting}
			// disabled={disabled}
		>
			{props.children}
		</LoadingButton>
	);
}
