import {toast} from 'react-toastify';
import {request} from 'src/utils/AdminApi';

export function extractError(xmlString) {
	const errorPattern = /<error>(.*?)<\/error>/s;
	const match = xmlString.match(errorPattern);

	if (match && match[1]) {
		return match[1];
	} else {
		return 'حدث خطا غير متوقع';
	}
}

export const sendOrderToPrinterco = async (order_id) => {
	try {
		const res = await request('/sendOrderToPrinterco', 'POST', {
			order_id,
		});

		let response_body = res;
		console.log(typeof response_body, response_body);
		// it may return 200 but with error code -_-

		if (typeof response_body === 'string') {
			if (response_body.includes('<status>OK</status>')) return toast.success('تم ارسال الطلب بنجاح');
			else if (response_body.includes('<status>FAILED</status>'))
				return toast.error(extractError(response_body));
		}
	} catch (err) {
		// <response><status>FAILED</status><details><error>Missing: license key</error></details></response>

		// in case it return error and we can extract the reason
		if (err.response) {
			const {status, data: body} = err.response;
			if (typeof body === 'string' && body.includes('<status>FAILED</status>')) {
				return toast.error(extractError(body));
			}
		}

		// otherwise
		toast.error('حدث خطا غير متوقع، يرجي التاكد من معلومات الربط');
	}
};
