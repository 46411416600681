import {useNavigate, useParams} from 'react-router-dom';
// material
import {Button, Card, Container, Divider, IconButton, Stack, Typography} from '@mui/material';

// components
import {OrderItemsList} from './OrderItems.list';

import DeleteAlertDialog from 'src/components/modals/DeleteAlertDialog';
import DeleteWithReasonModal from 'src/components/modals/DeleteWithReason';
import {isNullOrEmpty} from 'src/utils/helperMethods';

import ReceiptIcon from '@mui/icons-material/Receipt';
import AccessControlWrapper from 'src/components/AccessControlWrapper';
import NavButton from 'src/components/NavButton';
import OrderCustomerDetails from 'src/pages/Orders/show/OrderCustomerDetails';
import OrderDetailsSummury from 'src/pages/Orders/show/OrderDetailsSummury';
import OrderShippingDetails from 'src/pages/Orders/show/OrderShippingDetails';
import OrderStatusPicker from 'src/pages/Orders/show/OrderStatusPicker';
import Page from 'src/components/Page';
import OrderAdditionalDetails from 'src/pages/Orders/show/OrderAdditionalDetails';
import OrderAssignPicker, {useOrderAssign} from 'src/pages/Orders/show/OrderAssignPicker';
import ShippingProvidersActions from 'src/pages/Orders/show/ShippingProvidersActions';
import InfoTip from 'src/components/ui/InfoTip';
import {printJsonReceipt} from 'src/features/printer';
import {
	createOrderPermission,
	deleteOrderPermission,
	findOneOrderPermission,
	updateOrderPermission,
	updateOrderStatusPermission,
} from 'src/permissionsList';
import {orderStatus} from 'src/utils/orderUtils';
import {canAccess, isOrderAssignee} from 'src/utils/permissions';
import {accountIs} from 'src/utils/siteUtils';
import useOrderDetails from '../../../hooks/useOrderDetails';
import {sendOrderToPrinterco} from 'src/plugins/printerco/printercoApi';
import thirdPartyApps from 'src/pages/apps/thirdPartyApps';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import InvoiceItem from 'src/pages/Invoices/InvoiceItem';
import ActionMenu from 'src/components/ui/ActionMenu';
import {deleteOrder, updateOrder} from 'src/utils/AdminApi';
import {confirmAction} from 'src/utils/confirm';
import Row from 'src/components/ui/Row';
import useSite from 'src/hooks/useSite';
import {accountIsAssal} from 'src/utils/customSite';
import Dev from 'src/components/Dev';
import {getTestFlag} from 'src/getTestFlag';
import ActivityLogs from 'src/components/ActivityLogs';

const bgcolor = '#EDEEF4';
export default function OrderDetails() {
	// hooks
	let {id} = useParams(); // this will take order id from url path
	let {
		order,
		setOrder,
		refetch,
		shippingDataIsLoading,
		shipping,
		shippingTimelines,
		itemToDelete,
		setItemToDelete,
		shouldCancelOrder,
		setShouldCancelOrder,
		onSendShippmentClicked,
		onDeleteShippingClicked,
		handleCancelOrder,
		onStatusChange,
		handleDeleteItemSubmit,
		onDeleteOrder,
		dublicate,
		receivePayment,
		handleReturnOrder,
		manualShippmentLink,
	} = useOrderDetails(id);
	const tenant = useSite();
	const navigate = useNavigate();
	const {allAdmins, onAssigneeChange} = useOrderAssign({
		orderUUID: order?.uuid,
	});
	// variables
	const deliveryType = order?.delivery_info?.type ?? 'delivery';

	if (!order) return null;
	const order_closed =
		order.is_cashier && (order.status === 'cancelled' || order.status === 'returned');
	const order_is_delivered = order.status === 'delivered';

	const canUpdateOrder = canAccess(updateOrderPermission);

	// admin can update status if he's the owner or ( order is not delivered and he has permission to update order or order status)
	const canUpdateOrderStatus =
		canAccess() || canUpdateOrder || isOrderAssignee(order) || canAccess(updateOrderStatusPermission);

	return (
		<Page title="معلومات الطلب">
			<AccessControlWrapper
				controllerAction={findOneOrderPermission}
				// we will allow user to access this page if he is assigned to it
				or={isOrderAssignee(order)}
			>
				<Container>
					<Stack sx={{mb: 2}} direction="row" alignItems="center" justifyContent="space-between">
						<Stack>
							<Typography variant="h4">{' الطلب ' + '#' + order.tracking_number}</Typography>
							{/* show it only to owner */}
							{order.creator && canAccess() && (
								<Typography variant="body2">بواسطة : {order.creator.name}</Typography>
							)}
						</Stack>

						{/* delete icon button when clicked show model to confirm the action */}
						<Stack spacing={2} direction="row" justifyContent="space-between">
							{/* <IconButton size="small" onClick={() => printOrderReceipt(order.id, order.uuid)}> */}
							<ActionMenu
								title="اجراء"
								items={[
									{
										id: 'print',
										text: 'طباعة',
										action: () => printJsonReceipt(order),
									},
									{
										id: 'edit',
										text: 'تعديل الطلب',
										action: () => navigate(`/orders/update/${order.uuid}`),
										hidden: !canUpdateOrder || order_closed,
									},
									{
										id: 'exchange',
										text: 'تبديل',
										action: () => navigate(`/orders/${order.uuid}/exchange`),
										hidden: !(canUpdateOrder && order.is_cashier && order.status != 'returned'),
									},
									{
										id: 'return',
										text: 'ارجاع',
										action: handleReturnOrder,
										hidden: !(canUpdateOrder && order.is_cashier && order.status != 'returned'),
									},
									{
										id: 'dublicate',
										text: 'تكرار الطلب',
										action: dublicate,
										hidden: !canAccess(createOrderPermission),
									},

									{
										id: 'delete',
										text: 'حذف',
										action: () =>
											confirmAction(async () => {
												await deleteOrder(order.uuid);
												navigate(`/orders`);
											}),
										hidden:
											!canAccess(deleteOrderPermission) ||
											tenant.config?.features?.allow_order_delete !== true,
									},
								]}
							/>
							{/**/}

							{/* <IconButton size="small" onClick={() => printJsonReceipt(order)}>
								<Typography>طباعة فاتورة</Typography>
								<ReceiptIcon sx={{fontSize: 16}} />
							</IconButton> */}
						</Stack>
					</Stack>
					<Card sx={{padding: 4}}>
						<Stack mb={1} direction="row" justifyContent="space-between" alignItems="center">
							{(!order.is_cashier || accountIs('alassalsweets', true)) &&
								(canUpdateOrderStatus ? (
									<OrderStatusPicker forUpdate status={order.status} onStatusChange={onStatusChange} />
								) : (
									<Typography>حالة الطلب: {orderStatus[order.status].label}</Typography>
								))}
							{order.is_cashier && order_closed && (
								<Typography py={0.5} px={3} bgcolor={'#E1DADB'} fontSize={18}>
									تم ارجاع الطلب
								</Typography>
							)}
						</Stack>

						{order.status === orderStatus.cancelled.key && order.reason && (
							<Typography variant="body" color="error">
								سبب الالغاء : {order.reason}
							</Typography>
						)}
						<Divider sx={{mb: 2}} />

						<Stack sx={{mt: 3}} direction={{md: 'row', xs: 'column'}} spacing={2}>
							<Stack flex={1} sx={{minWidth: {md: 450}}} spacing={1.5}>
								{/* 		<Link to={'/orders/update/' + order.uuid} color="inherit" underline="none">
								<Button variant="contained" size="medium">
									تعديل الطلب
								</Button>
							</Link> */}

								<Typography variant="h6">معلومات الزبون</Typography>
								<OrderCustomerDetails order={order} />
								<Typography variant="h6">معلومات اضافية</Typography>
								<OrderAdditionalDetails order={order} />
								{deliveryType !== 'pickup' && (
									<ShippingProvidersActions
										manualShippmentLink={manualShippmentLink}
										onSendShippmentClicked={onSendShippmentClicked}
										onDeleteShippingClicked={onDeleteShippingClicked}
										order={order}
										setOrder={setOrder}
									/>
								)}
								{accountIs('crocotools', true) && (
									<Button
										variant="outlined"
										startIcon={<img width={60} src={thirdPartyApps.printerco.darkIconPath} />}
										onClick={() => sendOrderToPrinterco(order.id)}
									>
										{'send order to printer'}
									</Button>
								)}
								{canAccess('orders.updateAssignee') && (
									<>
										<Typography fontWeight="bold">تعيين مسوؤل عن الطلب</Typography>
										<OrderAssignPicker
											label="المسوؤل"
											defaultValue={order?.assignee?.map((admin) => admin.id) ?? []}
											admins={allAdmins}
											onChange={onAssigneeChange}
										/>
									</>
								)}
								{!canAccess() && !isNullOrEmpty(order?.assignee) && (
									<Row label="المسؤول عن الطلب" value={order?.assignee[0].name} />
								)}
							</Stack>

							<OrderDetailsSummury receivePayment={receivePayment} order={order} />
						</Stack>
					</Card>

					{order.is_cashier && order.invoices.length && (
						<Card sx={{p: 2, mt: 2}}>
							<Stack my={1} spacing={1} direction="row" justifyContent="space-between">
								<Typography variant="h5">الفواتير</Typography>
							</Stack>
							<Stack spacing={1}>
								{order.invoices
									?.map((invoice) => {
										return (
											<InvoiceItem
												key={invoice.id}
												invoice={invoice}
												onUpdate={async (isPaid) => {
													const payment_received = order.invoices
														.filter((inv) => inv.cancelled_at == null)
														// apply changes to the changed invoices so that we can check if all invoices are paid after the update
														.map((inv) => {
															if (inv.id == invoice.id) {
																inv.paid_at = isPaid;
															}
															return inv;
														})
														.every((inv) => inv.paid_at != null);

													await updateOrder(order.uuid, {
														payment_received,
													});

													refetch();
												}}
												allowUpdate={!order_closed}
											/>
										);
									})
									.reverse()}
							</Stack>
						</Card>
					)}
					{/* products */}
					<Card sx={{padding: 4, my: 2}}>
						<Stack alignItems="center" spacing={1} direction="row" justifyContent="space-between">
							<Typography sx={{mb: 2}} variant="h5">
								المنتجات
							</Typography>

							{(!order.is_cashier || accountIsAssal(true)) && canAccess(updateOrderPermission) && (
								<Stack direction="row">
									{!isNullOrEmpty(order.shippingId) && (
										<InfoTip title="لا يمكن تعديل المنتجات بعد تسجيل الشحنة" />
									)}
									<NavButton
										disabled={!isNullOrEmpty(order.shippingId)}
										text="تعديل"
										to={`/orders/${id}/items/update`}
									/>
								</Stack>
							)}
						</Stack>

						<OrderItemsList
							orderStatus={order.status}
							isCashier={order.is_cashier}
							setItemToDelete={setItemToDelete}
							items={order?.items}
							refetch={refetch}
						/>
					</Card>

					{!isNullOrEmpty(order?.delivery_info?.shippingId) && (
						<OrderShippingDetails
							provider={order?.delivery_info?.shippingProvider}
							shippingDataIsLoading={shippingDataIsLoading}
							shipping={shipping}
							logs={shippingTimelines}
						/>
					)}
					<Card sx={{padding: 4, mt: 2}}>
						<Typography variant="h5">ملاحظات</Typography>

						<Stack sx={{mt: 2}}>
							{order.note?.length > 0 ? (
								<pre>{order.note}</pre>
							) : (
								<Typography variant="body1">لا يوجد ملاحظات</Typography>
							)}
						</Stack>
					</Card>
					{/* <ProductCartWidget /> */}

					{order && getTestFlag('test_mode') && (
						<Dev
							title={'order (JSON)'}
							object={{
								order,
							}}
						/>
					)}

					{canAccess() && accountIs('arzu', true) && (
						<ActivityLogs
							resource="order"
							resourceId={order.id}
							initial={{
								id: 0,
								created_at: order.created_at,
								user: order.creator,
								event: 'create_order',
								payload: {},
							}}
						/>
					)}
				</Container>

				{/* this for deleting item from order items */}
				<DeleteWithReasonModal
					title="هل انت متاكد ؟"
					subtitle="ادخل سبب حدف المنتج"
					isVisible={itemToDelete}
					setVisibility={setItemToDelete}
					handleSubmit={handleDeleteItemSubmit}
				/>
				{/* this for cancelling the order  */}
				<DeleteWithReasonModal
					title="هل انت متاكد ؟"
					subtitle="ادخل سبب الغاء الطلب"
					isVisible={shouldCancelOrder}
					setVisibility={setShouldCancelOrder}
					handleSubmit={handleCancelOrder}
				/>
			</AccessControlWrapper>
		</Page>
	);
}
