import CopyIcon from '@mui/icons-material/ContentCopy';
import {IconButton, Typography} from '@mui/material';
import {toast} from 'react-toastify';
export default function CopyButton({value, generateValue, label}) {
	return (
		<IconButton
			size="small"
			onClick={async () => {
				let _value = value;
				if (typeof generateValue === 'function') {
					try {
						_value = await generateValue();
					} catch (err) {
						return toast.error('حدث خطأ غير متوقع');
					}
				}

				navigator.clipboard.writeText(_value);
				toast.success('تم نسخ المحتوي بنجاح');
			}}
		>
			{label && <Typography sx={{mx: 1}}>{label}</Typography>}
			<CopyIcon sx={{fontSize: 16}} />
		</IconButton>
	);
}
