import {DateTime} from 'luxon';
const getDuration = (obj) => {
	return parseInt(DateTime.now().plus(obj).diff(DateTime.now(), ['days']).days);
};

const subscriptionBundles = {
	1: {
		name: 'الاشتراك الشهري',

		price: 100,
		id: 1,
		expirationDate: DateTime.now().plus({month: 1}).toISODate(),
		duration: getDuration({month: 1}),
		value: 'شهري',
	},
	2: {
		name: 'الاشتراك النصف سنوي',
		price: 510,
		id: 2,
		expirationDate: DateTime.now().plus({month: 6}).toISODate(),
		duration: getDuration({month: 6}),
		value: 'نصف سنوي',
	},
	3: {
		name: 'الاشتراك سنوي',
		price: 960,
		id: 3,
		expirationDate: DateTime.now().plus({year: 1}).toISODate(),
		duration: getDuration({month: 12}),
		value: 'سنوي',
	},
};
export default subscriptionBundles;
