import {Navigate, useRoutes} from 'react-router-dom';

// layouts
import AdminDashboardLayout from './layouts/AdminDashboard';

import LogoOnlyLayout from './layouts/LogoOnlyLayout';

//
import AddProduct from './pages/products/create/CreateProduct.page';

import Orders from './pages/Orders/list/ListOrders.page';
import NotFound from './pages/Page404';

//redux
import {CircularProgress, Stack} from '@mui/material';

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import Features from './components/_dashboard/features/features';
import BunqSettingsPage from './components/_dashboard/UpdateSite/PymentMethodPage/BunqSettingsPage';
import PaypalSettingsPage from './components/_dashboard/UpdateSite/PymentMethodPage/PaypalSettingsPage';
import PymentMethodsPage from './components/_dashboard/UpdateSite/PymentMethodPage/PymentMethodsPage';
import {ErrorView} from './ErrorView';
import AdminAccount from './pages/User/AdminAccount';
import AdminLogin from './pages/User/AdminLogin';
import BarcodeResultPage from './pages/search/BarcodeResultPage';
import Categories from './pages/categories/ListCategories.page';
import ChangePassword from './pages/User/ChangePassword';
import CreateOrder from './pages/Orders/create/CreateOrder.page';
import CreateDeliveryMethod from './pages/DeliveryMethods/CreateDeliveryMethod';
import DeliveryMethods from './pages/DeliveryMethods/DeliveryMethods';
import UpdateDeliveryMethod from './pages/DeliveryMethods/UpdateDeliveryMethod';
import UpdateDeliveryMethodDistricts from './pages/DeliveryMethods/UpdateDeliveryMethodDistricts';
import UpdateDeliveryProvider from './pages/DeliveryMethods/UpdateDeliveryProvider';
import HomePage from './pages/HomePage/home.page';
import InfiniteScrollProducts from './pages/products/list/ListProducts.page.js';
import ManageAdminsPage from './pages/ManageAdminsPage';
import NotificationPage from './pages/notifications/NotificationPage.js';
import NotificationsPage from './pages/notifications/NotificationsPage';
import OrderDetails from './pages/Orders/show/ShowOrder';
import UpdateOrderItemsPage from './pages/Orders/UpdateOrderItemsPage';
import OutOfStockPage from './pages/products/OutOfStock.page';
import ProductsProperties from './pages/products/ProductsProperties';
import PurchasesPage from './pages/Purchases/PurchasesPage';
import Register from './pages/User/Register';
import SearchByBarcodePage from './pages/search/SearchByBarcodePage';
import SetupPage from './pages/SetupPage';
import UpdateOrderDetails from './pages/Orders/UpdateOrder.js';
import UpdateProduct from './pages/products/UpdateProduct.page';
import UpdateProperty from './pages/UpdateProperty';
import UpdateSite from './pages/UpdateSite';
import FoxlineSettingsPage from './plugins/foxline/FoxlineSettingsPage';
import {GetUserInfo} from './reducers';
import store from './redux-store';
import {handleRequestError} from './utils/helperMethods';
import {getAdminToken} from './utils/storage';
import CashierPage from './pages/Orders/NewAddOrderPage/Cashier.page';
import PurchasesUpdatePage from './pages/Purchases/PurchasesUpdatePage';
import FinancePage from './pages/finance/Finance.page';
import ListApps from './pages/apps/ListApps.page';
import ShowApps from './pages/apps/ShowApps.page';
import ExchangeOrderItems from './pages/Orders/ExchangeOrderItems.page';
import InvoicesPage from './pages/Invoices/InvoicesPage';
import CategoryStats from './pages/categories/CategoryStats.page';
import CustomerDetails from './pages/CustomerDetails';
import CreateDhlPage from './pages/CreateDhlPage/CreateDhlPage';
import QuantityUpdatePage from './pages/products/QuantityUpdatePage';
import ProductInventoryChanges from './pages/ProductInventoryChanges';
import BranchesPage from './pages/BranchesPage';
import DevPages from 'src/components/DevPages';
import OrderProductsReport from 'src/pages/reports/OrderProductsReport';
import ReportsPage from 'src/pages/reports/ReportsPage';

// ----------------------------------------------------------------------

export default function Router() {
	let {user: admin, error} = useSelector((state) => state.admin);

	const [isLoading, setLoading] = useState(true);

	const getLoggedUserInfo = async () => {
		const token = getAdminToken();

		if (token.length > 0) {
			await store.dispatch(GetUserInfo(token));
		}

		setLoading(false);
	};

	useEffect(() => {
		getLoggedUserInfo();
	}, []);

	const getLoggedInLayout = () => {
		if (admin) {
			if (admin.OwnedWebsite) return <AdminDashboardLayout />;
			else return <Navigate to="/setup" />;
		} else {
			return <Navigate to="/login" />;
		}
	};

	const getNotCompletedOwnerAccount = () => {
		if (admin) {
			if (admin.OwnedWebsite) return <Navigate to="/" />;
			else return <SetupPage />;
		} else {
			return <Navigate to="/login" />;
		}
	};

	const routes = useRoutes([
		{
			path: '/',
			element: getLoggedInLayout(),

			// element: admin ? <AdminDashboardLayout /> : <Navigate to='/login' />,
			children: [
				// payment methods
				// Orders pages
				{
					path: '/',
					element: <HomePage />,
				},
				{
					path: 'site',
					element: <UpdateSite />,
				},
				{
					path: 'payment-methods',
					element: <PymentMethodsPage />,
				},
				{
					path: 'orders',
					element: <Orders />,
				},
				{
					path: 'orders/create',
					element: <CreateOrder />,
				},
				{
					path: 'orders/:id',
					element: <OrderDetails />,
				}, // =================================================================
				{
					path: 'orders/update/:id',
					element: <UpdateOrderDetails />,
				}, // Delivery methods pages
				{
					path: 'orders/:id/items/update',
					element: <UpdateOrderItemsPage />,
				},
				{
					path: 'orders/:id/exchange',
					element: <ExchangeOrderItems />,
				},
				{
					path: 'delivery-methods',
					element: <DeliveryMethods />,
				},
				{
					path: 'delivery-methods/:id',
					element: <UpdateDeliveryMethod />,
				}, // =================================================================
				{
					path: 'delivery-methods/:id/:city/districts',
					element: <UpdateDeliveryMethodDistricts />,
				}, // Products pages
				{
					path: 'delivery-methods/create',
					element: <CreateDeliveryMethod />,
				}, // {path: 'discounts', element: <DiscountPage />},
				{
					path: 'shipping-methods/:provider',
					element: <UpdateDeliveryProvider />,
				},
				{
					path: 'products',
					element: <InfiniteScrollProducts />,
				},
				{
					path: 'draft-products',

					element: (
						<InfiniteScrollProducts
							queryName="draftProducts"
							title="منتجات غير معروضة"
							qs="publish_at_null=true"
						/>
					),
				}, // {path: 'products/:productId/variations/:variationId', element: <VariationPage />},
				{
					path: 'products-out-of-stock',
					element: <OutOfStockPage />,
				}, // =================================================================
				{
					path: 'products/create',
					element: <AddProduct />,
				}, // Not used yet
				{
					path: 'products/:id',
					element: <UpdateProduct />,
				}, // {path: 'customers', element: <Customers />},
				// {path: 'reviews', element: <Reviews />},
				// {path: 'Billing', element: <Billing />},
				// =================================================================
				// {path: 'subscription', element: <PricesPage />},
				{
					path: 'admins',
					element: <ManageAdminsPage />,
				},
				{
					path: 'categories',
					element: <Categories />,
				},
				{
					path: 'categories-stats',
					element: <CategoryStats />,
				},
				{
					path: 'invoices',
					element: <InvoicesPage />,
				},
				{
					path: 'purchases',
					element: <PurchasesPage />,
				},
				{
					path: 'purchases/:id',
					element: <PurchasesUpdatePage />,
				},
				{
					path: 'product-quantity-updates/:id',
					element: <QuantityUpdatePage />,
				},
				{
					path: 'suggestions',
					element: <Features />,
				}, // manage user account
				{
					path: 'apps',
					element: <ListApps />,
				},
				{
					path: 'apps/:id',
					element: <ShowApps />,
				},
				{
					path: 'finance',
					element: <FinancePage />,
				},
				{
					path: 'me',
					element: <AdminAccount />,
				}, // =================================================================
				{
					path: 'change-password',
					element: <ChangePassword />,
				},
				{
					path: 'properties',
					element: <ProductsProperties />,
				},
				{
					path: 'properties/:id',
					element: <UpdateProperty />,
				},
				{
					path: 'barcode',
					element: <SearchByBarcodePage />,
				}, // =================================================================
				{
					path: 'barcode/result',
					element: <BarcodeResultPage />,
				}, // plugins
				{
					path: 'notifications',
					element: <NotificationsPage />,
				},
				{
					path: 'notification/:id',
					element: <NotificationPage />,
				},
				{
					path: 'plugins/paypal/settings',
					element: <PaypalSettingsPage />,
				},
				{
					path: '/plugins/bunq/settings',
					element: <BunqSettingsPage />,
				},
				{
					path: 'plugins/foxline',
					element: <FoxlineSettingsPage />,
				},
				{
					path: '/customers/:id',
					element: <CustomerDetails />,
				},
				{
					path: '/orders/:id/shipping/dhl/create',
					element: <CreateDhlPage />,
				},
				{
					path: '/dev/:path',
					element: process.env.NODE_ENV === 'development' ? <DevPages /> : <Navigate to="/404" />,
				},
				{
					path: '/products/:id/inventory-changes',
					element: <ProductInventoryChanges />,
				},
				{
					path: '/branches',
					element: <BranchesPage />,
				},
				{
					path: '/reports',
					element: <ReportsPage />,
					children: [
						{
							path: 'orders-products',
							element: <OrderProductsReport />,
						},
					],
				},
			],
		}, // {path: 'pricing', element: <PricesPage />},
		{
			path: '/setup',
			element: getNotCompletedOwnerAccount(),
		},
		{
			path: 'orders/pos',
			element: <CashierPage />,
		},
		{
			path: '/',
			element: !admin ? <LogoOnlyLayout /> : <Navigate to="/" />,

			children: [
				{
					path: 'register',
					element: <Register />,
				},
				{
					path: 'login',
					element: <AdminLogin />,
				},
				{
					path: '404',
					element: <NotFound />,
				},
				{
					path: '/',
					element: <Navigate to="/" />,
				},
				{
					path: '*',
					element: <Navigate to="/404" />,
				},
			],
		},
		{
			path: '*',
			element: <Navigate to="/404" replace />,
		},
	]);

	if (isLoading) {
		return <LoadingView />;
	}

	// if server down or no connection show error
	if (error && (!error?.request || !error?.response || error?.response.status === 500)) {
		const msg = handleRequestError(error);
		return <ErrorView onRefresh={getLoggedUserInfo} error={msg} />;
	}

	return routes;
}

export const LoadingView = ({minHeight = '100vh'}) => (
	<Stack spacing={4} minHeight={minHeight} justifyContent="center" alignItems="center">
		<CircularProgress />
		جاري التحميل
	</Stack>
);
