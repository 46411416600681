import {
	Card,
	Checkbox,
	Divider,
	FormControlLabel,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import {useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import useSite from 'src/hooks/useSite';
import {updateTenantConfigAction} from 'src/reducers/index.js';
import SubmitButton from 'src/components/buttons/SubmitButton.js';
import FormikWrapper from 'src/components/wrappers/FormikWrapper.js';
import CustomFields from 'src/components/inputs/CustomFields';
export default function UpdateOrderSettings() {
	const dispatch = useDispatch();
	const site = useSite();
	const order_settings = site.config?.order_settings ?? {};

	const onUpdate = async (values) => {
		const new_config = {...site.config};
		new_config.order_settings = values;

		try {
			await dispatch(updateTenantConfigAction(site.id, new_config));
			toast.success('تم التعديل بنجاح');
		} catch (error) {
			toast.error('حدث خطا غير متوقع');
		}
	};

	return (
		<Card sx={{maxWidth: 600, p: 4}}>
			<FormikWrapper
				// schema={isEditDialog ? bannerUpdateFormSchema : bannerFormSchema}
				hideSubmitButton
				values={{
					sm_input_text: order_settings.sm_input_text ?? '',
					show_sm_input: order_settings.show_sm_input ?? true,
					show_email_input: order_settings.show_email_input ?? true,
					receipt_show_logo: order_settings.receipt_show_logo ?? false,
					receipt_show_sitename: order_settings.receipt_show_sitename ?? true,
					show_phone2: order_settings.show_phone2 ?? false,
					receipt_footnote: order_settings.receipt_footnote || '',
					force_login: order_settings.force_login || false,
					disable_website_orders: order_settings.disable_website_orders || false,
					// hide_cart: order_settings.hide_cart || false,
					customer_custom_fields: order_settings.customer_custom_fields ?? [],
				}}
				handleSubmit={onUpdate}
			>
				{({isSubmitting, setFieldTouched, getInputProps, values, setFieldValue}) => {
					return (
						<Stack sx={{width: '100%'}}>
							<Typography variant="h6">اعدادات صفحة الشراء</Typography>
							<FormControlLabel
								control={<Checkbox {...getInputProps('force_login')} defaultChecked={values.force_login} />}
								label={'تسجيل دخول اجباري'}
							/>
							<FormControlLabel
								control={
									<Checkbox
										{...getInputProps('disable_website_orders')}
										defaultChecked={values.disable_website_orders}
									/>
								}
								label={'ايقاف استلام الطلبات من الموقع'}
							/>

							{/* 		<FormControlLabel
								sx={{mb: 2}}
								control={
									<Checkbox
										{...getInputProps('show_email_input')}
										defaultChecked={values.show_email_input}
									/>
								}
								label={'طلب ادخال البريد الالكتروني'}
							/>
							<FormControlLabel
								sx={{mb: 2}}
								control={<Checkbox {...getInputProps('show_phone2')} defaultChecked={values.show_phone2} />}
								label={'طلب ادخال رقم احتياطي'}
							/>
							<CustomFields
								value={values.customer_custom_fields}
								setFieldTouched={setFieldTouched}
								onChange={(v) => setFieldValue('customer_custom_fields', v)}
								title={'حقول اضافية'}
								fields={[
									{
										label: 'اسم الحقل',
										key: 'label',
									},
									{
										label: 'اجباري',
										type: 'checkbox',
										key: 'required',
									},
								]}
							/>
							<Typography sx={{color: 'gray'}} fontSize={15} my={1}>
								سيتم اضافة هذه الحقول في صفحة الشراء لطلب بيانات اضافية عن الزبون
							</Typography>
							<Divider /> */}
							<Typography mt={2} variant="h6">
								اعدادات الفاتورة
							</Typography>
							<FormControlLabel
								control={
									<Checkbox
										{...getInputProps('receipt_show_sitename')}
										defaultChecked={values.receipt_show_sitename ?? true}
									/>
								}
								label={'عرض اسم الموقع علي الفاتورة'}
							/>
							<FormControlLabel
								control={
									<Checkbox
										{...getInputProps('receipt_show_logo')}
										defaultChecked={values.receipt_show_logo}
									/>
								}
								label={'عرض الشعار علي الفاتورة'}
							/>
							<TextField
								{...getInputProps('receipt_footnote')}
								label="ملاحظة علي الفاتورة"
								fullWidth
								sx={{mt: 2}}
							/>

							<SubmitButton isSubmitting={isSubmitting}>تعديل</SubmitButton>
						</Stack>
					);
				}}
			</FormikWrapper>
		</Card>
	);
}
