import {Card, Container, Divider, Stack, TextField, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import Picker from 'src/components/inputs/Picker';
import TableWrapper from 'src/components/wrappers/TableWrapper';
import useCategories from 'src/hooks/useCategories';
import {useDebounce} from 'src/hooks/useDebounce';
import {getCategoriesStats, getCategoryStats, getCategoryStock} from 'src/utils/AdminApi';
import {useLocation} from 'react-router-dom';
import {isNumber} from 'src/utils/numberUtils';
import {DateTime} from 'luxon';
import {getCountString} from 'src/utils/stringUtils';
import {formatAmountString} from 'src/utils/moneyUtils';

export default function CategoryStats() {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	// Access specific query parameters
	const categoryId = queryParams.get('categoryId');

	const {categories} = useCategories();

	const [selectedCategories, setSelectedCategories] = useState([]);
	const [startDateFilter, setStartDateFilter] = useState(
		DateTime.local().startOf('month').toISODate(),
	);
	const [endDateFilter, setEndDateFilter] = useState(DateTime.local().toISODate());

	const debouncedselectedCategories = useDebounce(selectedCategories, 500);

	const {data = [], isSuccess} = useQuery(
		['category-stats', debouncedselectedCategories, startDateFilter, endDateFilter],
		() =>
			getCategoriesStats(
				selectedCategories,
				DateTime.fromISO(startDateFilter).startOf('day').toISO(),
				DateTime.fromISO(endDateFilter).endOf('day').toISO(),
			),
		{
			enabled: debouncedselectedCategories.length > 0,
		},
	);
	// const {data:stock=[]} = useQuery(['category-stock',categoryId],()=>getCategoryStock(categoryId))

	useEffect(() => {
		if (categories.length > 0 && isNumber(categoryId)) setSelectedCategories([+categoryId]);
	}, [categories, categoryId]);
	return (
		<Container maxWidth="md">
			<Card sx={{p: 2}}>
				{categories.length > 0 && (
					<Picker
						multiple
						fullWidth
						// sx={{minWidth: 100}}
						label="اختر التصنيفات"
						// we add -1 to allow user to clear filter
						options={categories}
						labelKey="name"
						valueKey="id"
						value={selectedCategories}
						onChange={(e) => {
							setSelectedCategories(e.target.value);
						}}
					/>
				)}

				<Stack mt={1} spacing={1} direction="row">
					<TextField
						InputLabelProps={{shrink: true}}
						pattern="\d{4}-\d{2}-\d{2}"
						fullWidth
						label="من"
						placeholder="من"
						onChange={(e) => setStartDateFilter(e.target.value ?? '')}
						value={startDateFilter}
						type="date"
						required
					/>
					<TextField
						InputLabelProps={{shrink: true}}
						pattern="\d{4}-\d{2}-\d{2}"
						fullWidth
						label="الي"
						placeholder="الي"
						onChange={(e) => setEndDateFilter(e.target.value ?? '')}
						value={endDateFilter}
						type="date"
						required
					/>
				</Stack>

				<Typography my={2}>سيتم عرض المشتريات والطلبات حسب الفترة التي تم تحديدها</Typography>

				{/* <Typography variant="h4">التصنيفات</Typography> */}

				{data.length > 0 && (
					<TableWrapper
						headerColumns={[
							'التنصيف',
							'عدد المشتريات',
							'قيمة المشتريات',
							'عدد الطلبات',
							'قيمة الطلبات',
							'المخزون المتوفر',
							'قيمة المخزون',
						]}
						dataRows={data.map((stat) => [
							[stat.c_name],
							[stat.purchases_count ? getCountString(stat.purchases_count, 'قطعة', 'قطعتان', 'قطع') : 0],
							[stat.total_costs ? formatAmountString(stat.total_costs) : 0],
							[stat.orders_count ? getCountString(stat.orders_count, 'طلب', '2 طلب', 'طلبات', '1') : 0],
							[stat.total_revenue ? formatAmountString(stat.total_revenue) : 0],
							[stat.stock ? getCountString(stat.stock, 'قطعة', 'قطعتان', 'قطع') : 0],
							[stat.expected_revenue ? formatAmountString(stat.expected_revenue) : 0],
						])}
					/>
				)}

				{isSuccess && data.length === 0 && (
					<Typography textAlign="center" my={4}>
						لم يتم عثور علي احصائيات مرتبطة بهذا التصنيف
					</Typography>
				)}
			</Card>
			{/*     <Card sx={{mt:2,p:2}}>

        <Typography variant='h4'>
            التصنيف: {category?.name}
        </Typography>

        <Typography color="primary" textAlign="center" variant='h6'>
           المشتريات
        </Typography>

        <TableWrapper headerColumns={['المنتج', 'التكلفة','الكمية',  'الاجمالي','سعر البيع','الاجمالي']} 
        dataRows={stock.filter(d=>d.type==='purchase').map((stat) => [
            [stat.p_name],
			[stat.price|| 0],
			[stat.quantity || 0],
			[stat.total || 0],
			[stat.sell_price],
			[stat.sell_price*stat.quantity],
		])} />

        <Divider/>

        <Typography color="primary" mt={2} textAlign="center" variant='h6'>
           الطلبات
        </Typography>

        <TableWrapper headerColumns={['المنتج', 'السعر','الكمية',  'الاجمالي']} 
        dataRows={stock.filter(d=>d.type==='order').map((stat) => [
            [stat.p_name],
			[stat.price|| 0],
			[stat.quantity || 0],
			[stat.total || 0],
		])} />

        </Card> */}
		</Container>
	);
}
