// material
import {
	Checkbox,
	FormControlLabel,
	InputAdornment,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import {useState} from 'react';
// redux
import {useSelector} from 'react-redux';
import useCurrencies from 'src/hooks/useCurrencies';
import {createTenantSchhema} from 'src/schema/authSchema';
import {createTenant} from 'src/utils/AdminApi';
import {handleRequestError} from 'src/utils/helperMethods';
import FormikWrapper from './wrappers/FormikWrapper';
import Picker from 'src/components/inputs/Picker';
import SubmitButton from './buttons/SubmitButton';

// ----------------------------------------------------------------------

export default function CreateTenantForm() {
	let admin = useSelector((state) => state.admin.user);
	const [error, setError] = useState();
	const [currencies] = useCurrencies([]);
	const [isSubmitting, setSubmitting] = useState(false);

	const onSubmit = async (values) => {
		setError(null);
		setSubmitting(true);

		try {
			const {site_name, host, description, currency, type} = values;
			await createTenant(
				`${host?.toLowerCase()}.vetrinas.ly`,
				site_name,
				admin.id,
				type,
				{
					colors: {
						primary: '#161C24',
						background: '#FDFCF9',
						text: '#161C24',
					},
				},
				{
					meta: {description},
					currency,
				},
			);

			window.location = '/products/create';
		} catch (err) {
			setError(handleRequestError(err));
			setSubmitting(false);
		}
	};

	return (
		<FormikWrapper
			values={{
				site_name: '',
				host: '',
				currency: 'د.ل',
				description: '',
				type: '',
				agreement: false,
			}}
			schema={createTenantSchhema}
			handleSubmit={onSubmit}
		>
			{({errors, touched, values, setFieldValue, getInputProps}) => (
				<Stack>
					<Stack spacing={2}>
						<TextField label="اسم الموقع" placeholder="الاسم" {...getInputProps('site_name')} />
						<TextField label="وصف الموقع" placeholder="وصف الموقع" {...getInputProps('description')} />

						<TextField
							InputProps={{
								inputMode: 'numeric',
								startAdornment: <InputAdornment> vetrinas.ly.</InputAdornment>,
							}}
							label="اسم النطاق"
							placeholder="mysite"
							{...getInputProps('host')}
						/>

						<Picker
							{...getInputProps('currency')}
							label="العملة"
							options={currencies}
							labelKey="name"
							valueKey="short_name"
							onChange={(e) => setFieldValue('currency', e.target.value)}
						/>

						<Picker
							{...getInputProps('type')}
							label="نوع المحل"
							options={['عطور', 'ملابس', 'الكترونيات', 'اخري']}
							onChange={(e) => setFieldValue('type', e.target.value)}
						/>

						<FormControlLabel
							control={<Checkbox {...getInputProps('agreement')} checked={values.agreement} />}
							label={
								<p>
									اوافق علي{' '}
									<a target="_blank" href="https://vetrinas.ly/terms.html" rel="noreferrer">
										شروط و سياسة الاستخدام
									</a>
								</p>
							}
						/>

						{errors.agreement && touched.agreement && (
							<Typography color="error">{errors.agreement}</Typography>
						)}
						{error && <Typography color="error">{error}</Typography>}

						<SubmitButton isSubmitting={isSubmitting}>انشاء</SubmitButton>
					</Stack>
				</Stack>
			)}
		</FormikWrapper>
	);
}
