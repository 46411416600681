import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import useVisible from 'src/hooks/useVisible';
import {permissionsSelector} from 'src/reducers/app/appSelectors';
import PermissionsCheckboxList from './PermissionsCheckboxList';
import {Button, Dialog, Divider, Stack, Typography} from '@mui/material';
import {UpdateAdminAccount} from 'src/reducers';
/* 
    this component is created for testing purposes 
    it will allow you change your super admin account to admin with custom permissions
    in order to make testing experience easier without the need to login from different browser
    with different account 

    this is done by updating user state in redux and modify user allowed actions
*/
export default function TestPermissionSelector() {
	let {user} = useSelector((state) => state.admin);
	const allPermissions = useSelector(permissionsSelector);
	const [selectedActions, setSelectedActions] = useState(user.allowed_actions.map((act) => act.id));
	const dispatch = useDispatch();
	const {isVisible, toggle, hide} = useVisible();

	const confirm = () => {
		dispatch(
			UpdateAdminAccount(
				{
					...user,
					allowed_actions: selectedActions.map((actId) =>
						allPermissions.find((perm) => perm.id == actId),
					),
					isOwner: false,
				},
				false,
			),
		);
		hide();
	};

	return (
		<div>
			<Dialog onClose={hide} open={isVisible}>
				<Stack p={2} spacing={2}>
					<Typography variant="subtitle1">اختر الصلاحيات التي تود تجربتها</Typography>
					<PermissionsCheckboxList
						fieldName="allowed_actions"
						allowed_actions={selectedActions}
						allPermissions={allPermissions}
						setFieldValue={(_, newSelections) => setSelectedActions(newSelections)}
					/>
					<Divider />
					<Button onClick={confirm} variant="contained">
						confirm
					</Button>
				</Stack>
			</Dialog>
			<button onClick={toggle}>test permissions</button>
		</div>
	);
}
