import {Divider, Stack, Typography} from '@mui/material';
import React from 'react';
import {isNullOrEmpty} from 'src/utils/helperMethods';

export default function DataList({data = [], noDataMessage = 'لا يوجد بيانات', renderItem}) {
	if (isNullOrEmpty(data) || !Array.isArray(data))
		return (
			<Stack alignItems="center" justifyContent="center" p={2}>
				<Typography>{noDataMessage}</Typography>
			</Stack>
		);

	const getKey = (item, index) => {
		if (typeof item === 'object' && item.id != null) return item.id;

		if (typeof item === 'string' || typeof item === 'number') return item;

		return index;
	};
	return data.map((item, index) => {
		return (
			<Stack key={getKey(item, index)}>
				<Stack spacing={1}>
					{renderItem(item, index)}
					{index != data.length - 1 && <Divider />}
				</Stack>
			</Stack>
		);
	});
}
