import {IconButton, Typography} from '@mui/material';
import React from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Logout as LogoutAction} from 'src/reducers/adminAuth/adminAuthActions';

export default function LogoutButton({containerStyles}) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	return (
		<IconButton
			style={containerStyles}
			onClick={async () => {
				try {
					await dispatch(LogoutAction());
					navigate('/login', {replace: true});
				} catch (err) {
					alert(err);
				}
			}}
		>
			<img src="/static/icons/logout.svg" width="26" height="22" />
			<Typography sx={{fontWeight: 'bold'}} variant="body2">
				تسجيل الخروج
			</Typography>
		</IconButton>
	);
}
