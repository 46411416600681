import {LoadingButton} from '@mui/lab';
import {Box, Button, Stack, TextField} from '@mui/material';
import React, {useState} from 'react';
import {useQuery} from 'react-query';
import {toast} from 'react-toastify';
import BranchPicker from 'src/components/BranchPicker';
import useUser from 'src/hooks/useUser';
import {request} from 'src/utils/AdminApi';
import equalId from 'src/utils/equalId';
import {fixPositiveNumbers, handleRequestError} from 'src/utils/helperMethods';
import {getNumber} from 'src/utils/numberUtils';

export default function StockTransfer({product, variationId, refetch, onSuccess}) {
	const {user} = useUser();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const {data, isLoading} = useQuery('branches', () => request('branches'), {
		refetchOnMount: false,
		refetchOnWindowFocus: false,
	});

	const [qty, setQty] = useState();
	const [from, setFrom] = useState();
	const [to, setTo] = useState();

	if (!data) return;

	const onSubmit = async () => {
		if (!from || !to || getNumber(qty) <= 0) return toast.error('يرجى ادخال البيانات بشكل صحيح');

		if (from === to) {
			return toast.error('يرجى نقل الكمية الي فرع مختلف');
		}

		setIsSubmitting(true);
		try {
			await request(`/stock-transfers`, 'POST', {
				from_branch: from,
				to_branch: to,
				quantity: qty,
				product: product?.id ?? product,
				variation: variationId,
			});
			setQty('');
			setFrom();
			setTo();
			if (typeof refetch === 'function') refetch();
			if (typeof onSuccess === 'function') onSuccess();
		} catch (err) {
			toast.error(handleRequestError(err));
			refetch();
		} finally {
			setIsSubmitting(false);
		}
	};
	// no equal branches - from branch most have enough quantity ( use product.stocks )
	return (
		<Stack spacing={2}>
			<TextField
				id="addedQuantity"
				name="addedQuantity"
				label="الكمية"
				autoComplete="off"
				sx={{flex: '1 1 auto'}}
				type="number"
				onWheel={(e) => e.target.blur()}
				value={qty ?? ''}
				onChange={(e) => setQty(fixPositiveNumbers(e.target.value))}
			/>
			<Stack direction="row" spacing={2}>
				<BranchPicker
					fullWidth
					label={'من الفرع'}
					allowNone={false}
					filterOptions={(branches) => {
						return branches.filter((b) => {
							const s = product.stocks.find((s) => s.branch == b.id && equalId(s.variation, variationId));
							return !!s && s.quantity > 0 && s.quantity >= getNumber(qty) && !equalId(b, to);
						});
					}}
					value={from ?? ''}
					onChange={(e) => setFrom(e.target.value)}
				/>
				<BranchPicker
					fullWidth
					label={'الي الفرع'}
					allowNone={false}
					value={to ?? ''}
					filterOptions={(branches) => {
						return branches.filter((b) => {
							return !equalId(b, from);
						});
					}}
					onChange={(e) => setTo(e.target.value)}
				/>
			</Stack>

			<Box pt={4}>
				<LoadingButton
					loading={isSubmitting}
					fullWidth
					disabled={!from || !to || getNumber(qty) <= 0}
					variant="contained"
					onClick={onSubmit}
				>
					نقل
				</LoadingButton>
			</Box>
		</Stack>
	);
}
