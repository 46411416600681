import * as yup from 'yup';

export const nameValidation = yup.string();
export const stringValidatoin = yup.string();

export const emailValidation = yup.string().email('بريد الكتروني غير صحيح');

export const phoneValidation = yup
	.string()
	.matches(/^[0-9]*$/, 'ادخل رقم هاتف صحيح')
	.min(9, 'ادخل رقم هاتف صحيح')
	.max(10, 'ادخل رقم هاتف صحيح');
