import {
	getSavedUser,
	removeAdminToken,
	removeSavedUser,
	saveUser,
	storeAdminToken,
} from 'src/utils/storage';
import {
	addBanner,
	adminLogin,
	adminRegister,
	deleteBanner,
	getAdminUserInfo,
	setAllNotificationSeen,
	setUpShippingAccount,
	updateAdminUserInfo,
	updateBanner,
	updateTenant,
	updateTenantConfig,
	updateTenantStoreConfig,
} from '../../utils/AdminApi';
import {fetchPermissionsAction} from '../app/appActions';

export const AUTH_LOADING = 'AUTH_LOADING';
export const SET_USER = 'SET_USER';
export const AUTH_FAILURE = 'AUTH_FAILURE';

// action creator
export const setUserActionCreator = (user) => ({type: SET_USER, user});

//Login
export const Login = (email, password) => {
	return async (dispatch) => {
		dispatch({
			type: AUTH_LOADING,
		});

		const response = await adminLogin(email, password);
		const {user, jwt} = response;
		storeAdminToken(jwt);
		dispatch(setUserActionCreator(user));
		dispatch(fetchPermissionsAction());
	};
};
//RegisterAction
export const RegisterAction = (name, phone, email, password) => {
	return async (dispatch) => {
		dispatch({
			type: AUTH_LOADING,
		});

		const response = await adminRegister(name, phone, email, password);
		const {user, jwt} = response;
		storeAdminToken(jwt);
		dispatch(setUserActionCreator(user));
		dispatch(fetchPermissionsAction());
	};
};

export const UpdateAdminAccount = (user, shouldPersist = true) => {
	return async (dispatch, getState) => {
		dispatch({
			type: AUTH_LOADING,
		});
		if (shouldPersist) await updateAdminUserInfo(user);
		dispatch(setUserActionCreator({...getState().admin.user, ...user}));
	};
};
export const updateTenantAction = (id, site_name, style, config, logo) => {
	return async (dispatch, getState) => {
		dispatch({
			type: AUTH_LOADING,
		});
		const res = await updateTenant(id, site_name, style, config, logo);
		const user = getState().admin.user;
		dispatch(setUserActionCreator({...user, OwnedWebsite: {...user.OwnedWebsite, ...res}}));
	};
};
export const setUpShippingAccountAction = (config) => {
	return async (dispatch, getState) => {
		const res = await setUpShippingAccount(config);
		const user = getState().admin.user;
		dispatch(setUserActionCreator({...user, OwnedWebsite: res}));
	};
};
export const updateTenantConfigAction = (id, config) => {
	return async (dispatch, getState) => {
		const res = await updateTenantConfig(id, config);
		const user = getState().admin.user;
		dispatch(setUserActionCreator({...user, OwnedWebsite: res}));
	};
};
export const updateTenantStoreConfigAction = (id, config) => {
	return async (dispatch, getState) => {
		const res = await updateTenantStoreConfig(id, config);
		const user = getState().admin.user;
		dispatch(setUserActionCreator({...user, OwnedWebsite: res}));
	};
};
export const updateBannerAction = (data, image) => {
	return async (dispatch, getState) => {
		dispatch({
			type: AUTH_LOADING,
		});
		const res = await updateBanner(data, image && {banner: image});
		const updatedUser = {...getState().admin.user};
		updatedUser.OwnedWebsite.dynamic_info.banners = res;
		dispatch(setUserActionCreator(updatedUser));
	};
};
export const addBannerAction = (link, image) => {
	return async (dispatch, getState) => {
		dispatch({
			type: AUTH_LOADING,
		});
		const res = await addBanner(link, image);
		const updatedUser = {...getState().admin.user};
		updatedUser.OwnedWebsite.dynamic_info = {banners: res};

		dispatch(setUserActionCreator(updatedUser));
	};
};
export const deleteBannerAction = (id) => {
	return async (dispatch, getState) => {
		dispatch({
			type: AUTH_LOADING,
		});
		const res = await deleteBanner(id);
		dispatch(
			setUserActionCreator({
				...getState().admin.user,
				OwnedWebsite: {
					...getState().admin.user.OwnedWebsite,
					dynamic_info: {
						...getState().admin.user.OwnedWebsite.dynamic_info,
						banners: res,
					},
				},
			}),
		);
	};
};

export const GetUserInfo = (token) => {
	return async (dispatch) => {
		dispatch({
			type: AUTH_LOADING,
		});
		try {
			const response = await getAdminUserInfo(token);
			saveUser(response);
			dispatch(setUserActionCreator(response));
			dispatch(fetchPermissionsAction());
		} catch (err) {
			// if user user open the app offline then we use saved object
			if (!err.response) {
				const savedUser = getSavedUser();
				if (savedUser) return dispatch(setUserActionCreator(savedUser));
			}

			dispatch({
				type: AUTH_FAILURE,
				error: err,
			});
		}
	};
};

//Logout
export const Logout = () => {
	removeAdminToken();
	removeSavedUser();
	return setUserActionCreator(null);
};
