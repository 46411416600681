import {
	Button,
	Divider,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import React from 'react';
import AccordionWrapper from 'src/components/wrappers/AccordionWrapper';
import Row from 'src/components/ui/Row';
import {deletePurchases} from 'src/utils/AdminApi';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import {getSiteCurrency} from 'src/utils/selectors';
import {getPurchaseTotalAmount} from './purchasesUtils';
import {Purchase, PurchaseItem} from './types';
import MuiLink from 'src/components/ui/StyledLink';
import DeleteAlertDialog from 'src/components/modals/DeleteAlertDialog';
import NavButton from 'src/components/NavButton';
import {canAccess} from 'src/utils/permissions';

export default function PurchaseListItemView({
	purchase,
	onDelete,
}: {
	purchase: Purchase;
	onDelete: Function;
}) {
	const currency = getSiteCurrency();
	const {description, creator, date} = purchase;
	const handleDelete = (id: number) => {
		deletePurchases(id).then((res) => onDelete());
	};

	return (
		<AccordionWrapper
			card
			parent={
				<Stack>
					<Typography style={{fontSize: 16, paddingInline: 10}}>{description}</Typography>
					<Typography style={{fontSize: 14, paddingInline: 10}}>
						المجموع:{' '}
						<strong>
							{getPurchaseTotalAmount(purchase.items)}
							{currency}
						</strong>
					</Typography>
				</Stack>
			}
		>
			<Stack>
				{canAccess() && (
					<Row
						valueVariant="body2"
						spacing={2}
						justifyContent=""
						label="بواسطة: "
						value={creator?.name}
					/>
				)}
				<Row valueVariant="body2" spacing={2} justifyContent="" label="بتاريخ: " value={date} />
				<Divider />
				<Table style={{paddingTop: 18, paddingBottom: 18}}>
					<TableHead>
						<TableRow>
							<TableCell>القطعة</TableCell>
							<TableCell>الكمية</TableCell>
							<TableCell>سعر القطعة</TableCell>
							<TableCell>المجموع</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{purchase.items.map((item) => {
							return <PurchaseItemView key={item.id} item={item} />;
						})}
					</TableBody>
					<br />
					<TableFooter>
						<TableRow>
							<TableCell style={{fontWeight: 'bold'}}>مجموع المشتريات</TableCell>
							<TableCell style={{fontWeight: 'bold'}}>
								{getPurchaseTotalAmount(purchase.items)}
								{currency}
							</TableCell>
						</TableRow>
					</TableFooter>
				</Table>
				<Divider />
				<Stack py={2} px={3} spacing={4} alignItems="center" justifyContent="center" direction="row">
					<NavButton text="تعديل" to={`/purchases/${purchase.id}`} />
					<DeleteAlertDialog onDelete={() => handleDelete(purchase.id)} sx={{}} />
				</Stack>
			</Stack>
		</AccordionWrapper>
	);
}

export function PurchaseItemView({item}: {item: PurchaseItem}) {
	const {total_quantity, cost_per_unit} = item;

	const isProduct = item.product != null;
	const hasVariations = isProduct && item.variation != null;
	const name = isProduct ? getItemNameWithVariation(item) : item.name;

	const handleDelete = (id: number) => {
		deletePurchases(id);
	};

	return (
		<TableRow>
			<TableCell>
				{isProduct ? <MuiLink to={`/products/${item.product.id}`}>{name} </MuiLink> : name}
			</TableCell>
			<TableCell>{total_quantity}</TableCell>
			<TableCell>{cost_per_unit}</TableCell>
			<TableCell>{cost_per_unit * total_quantity}</TableCell>
			{/* <button onClick={() => handleDelete(item.id)}>delete</button> */}
		</TableRow>
	);
}

const getItemNameWithVariation = (item: PurchaseItem) => {
	return !isNullOrEmpty(item.variation?.name)
		? `${item.product.name} - ${item.variation.name}`
		: item.product.name;
};
