import * as Sentry from '@sentry/react';
import {getSite} from '../selectors';

const handleDarbErrors = (errorResponse) => {
	let errors = [];
	let unexpected = true;

	/* 
			{"status":false,
			"messages":[{"name":"Error","param":"contact","location":"body/order/receivers/0","message":"Invalid Receiver's Contact Number length!","value":"0905072272057"}],
			"data":{},"code":1}
		*/
	if (errorResponse.messages[0].param == 'contact') {
		errors.push('رقم الهاتف غير صحيح');
		unexpected = false;
	} else if (errorResponse.messages[0].message == "You don't have permission!") {
		errors.push(
			'يوجد مشكلة في اسم المستخدم، تأكد انه مطابق لحسابك في درب السبيل، وتأكد من استخدام حروف كبيرة اذا كانت موجودة ',
		);
		unexpected = false;
	}

	if (errorResponse.messages.length > 1) {
		unexpected = true;
	}
	if (unexpected) {
		Sentry.captureMessage('DARB Unexpected Error response', {
			level: 'warning',
			extra: {
				response: JSON.stringify(errorResponse),
				tenant_id: getSite()?.id,
				tenant_name: getSite()?.site_name,
			},
			context: {
				url: window.location.href,
			},
		});
	}

	return errors;
};

export default handleDarbErrors;
