import {Grid} from '@mui/material';
import React from 'react';
import FeatureCard from 'src/components/_dashboard/features/featureCard';

export default function FeaturesList({features = [], setFeatures}) {
	return (
		<Grid container spacing={2}>
			{features.length > 0 &&
				features.map((feature, index) => (
					<Grid item key={index} xs={12} sm={6} xl={4}>
						<FeatureCard
							key={index}
							didVote={feature.didVote}
							title={feature.title}
							status={feature.status}
							proposer={feature?.creator?.name}
							votes={feature.votes}
							discreption={feature.description}
							date={feature.created_at}
							id={feature.id}
							setFeatures={setFeatures}
						/>
						{/* <Button
						sx={{opacity: 0.4, color: 'gray'}}
						onClick={(e) => {
							handleDelete(feature.id, feature.status);
						}}>
						delete for test
					</Button> */}
					</Grid>
				))}
		</Grid>
	);
}
