import {Button, Chip, Stack} from '@mui/material';
import React from 'react';
import {updateArray} from 'src/utils/helperMethods';

const isSelectedOption = (selectedProperty, propertyValueId) => {
	return selectedProperty.values.find((s) => s.id === propertyValueId);
};

export default function PropertiesSelectionList({
	selectedProperties = [],
	setSelectedProperties,
	allSiteProperties = [],
	setPvModalVisibility,
	setPropertyToAddTo,
}) {
	return selectedProperties.map((selectedProperty, selectedPropertyIndex) => {
		return (
			<div key={selectedPropertyIndex}>
				<Stack spacing={1} direction="row" justifyContent="space-between">
					<p>{selectedProperty.name}</p>
					<Button
						type="button"
						variant="outlined"
						color="grey"
						onClick={() =>
							setSelectedProperties(selectedProperties.filter((s) => s.id !== selectedProperty.id))
						}
					>
						حذف
					</Button>
				</Stack>
				{/* we need to check if property value exist in selected properties.values */}
				{/* list of property values */}
				<Stack mt={2} flexWrap="wrap" gap={1} direction="row">
					{allSiteProperties
						.find((p) => p.id === selectedProperty.id)
						.values.map((propertyValue) => (
							<Chip
								key={propertyValue.id}
								color="primary"
								onClick={() => {
									if (isSelectedOption(selectedProperty, propertyValue.id)) {
										// we need to delete value from values array
										const _selectedProperties = [...selectedProperties];
										_selectedProperties[selectedPropertyIndex].values = _selectedProperties[
											selectedPropertyIndex
										].values.filter((s) => s.id !== propertyValue.id);
										setSelectedProperties(_selectedProperties);
									} else {
										setSelectedProperties(
											updateArray(
												selectedProperties,
												{
													...selectedProperty,
													values: [...selectedProperty.values, propertyValue],
												},
												'id',
											),
										);
									}
								}}
								variant={isSelectedOption(selectedProperty, propertyValue.id) ? 'filled' : 'outlined'}
								value={propertyValue.id}
								label={propertyValue.value}
							/>
						))}
					<Chip
						onClick={() => {
							setPvModalVisibility(true);
							setPropertyToAddTo(selectedProperty.id);
						}}
						variant="outlined"
						label="+ اضافة خيار جديد"
					/>
				</Stack>
			</div>
		);
	});
}
