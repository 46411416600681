import {confirmable, createConfirmation} from 'react-confirm';
import ThemeConfig from 'src/theme';
import BasicModal from './BasicModal';
import {cloneElement} from 'react';
import RTL from 'src/theme/RTL';

export function showModel(props) {
	return createConfirmation(
		confirmable((props = {}) => {
			const {
				dismissable = true,
				okLabel = 'OK',
				cancelLabel = 'Cancel',
				title,
				confirmation,
				show,
				proceed,
				dismiss,
				cancel,
				component: Component,
			} = props;

			const comp =
				typeof Component === 'function'
					? cloneElement(<Component />, {
							dismiss,
							proceed,
							cancel,
					  })
					: cloneElement(Component, {
							dismiss,
							proceed,
							cancel,
					  });

			return (
				<ThemeConfig>
					<RTL>
						<BasicModal
							dismissable={dismissable}
							title={title}
							onClose={() => dismiss()}
							isVisible={show}
						>
							{comp}
						</BasicModal>
					</RTL>
				</ThemeConfig>
			);
		}),
	)(props);
}
