import {BETA_FEATURES} from 'src/types/enums/BETA_FEATURES';
import {getSite} from './selectors';
import {accountIs} from './siteUtils';

export const isFeatureAllowed = (featureFlag: BETA_FEATURES | keyof typeof BETA_FEATURES) => {
	const config = getSite()?.config;
	return (
		config?.features?.[featureFlag] === true ||
		(accountIs('demo') && config?.features?.[featureFlag] !== false)
	);
};
