import {getNumber} from './numberUtils';

export const calcPriceAfterDiscount = (price, discount) => {
	const factor = 100; // I added variable in case we need to change it to 1 when we decide to use float instead of decimals
	return (getNumber(price) * (factor - getNumber(discount))) / factor;
};
// current price 100 and current discount is 50 then original price is 200
export const getPriceWithoutDiscount = (currentPrice, oldDiscount) => {
	if (oldDiscount < 1) return currentPrice;
	const factor = 100; // I added variable in case we need to change it to 1 when we decide to use float instead of decimals
	return (currentPrice / (factor - oldDiscount)) * factor;
};
