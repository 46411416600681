import React from 'react';
import {nameValidation} from 'src/schema/authSchema';
import {addPropertyValue} from 'src/utils/AdminApi';
import BasicModal from 'src/components/modals/BasicModal';
import DynamicForm from 'src/components/DynamicForm/DynamicForm';

export default function AddPropertyValueModal({
	isVisible = false,
	setVisibility,
	onCreate,
	propertyId,
}) {
	const onSubmit = async ({name}) => {
		try {
			const res = await addPropertyValue(propertyId, name);
			onCreate(res);
		} catch (error) {
			// handle error
		}
	};
	// label , csv, button to create,
	return (
		<BasicModal isVisible={isVisible} setVisibility={setVisibility}>
			<DynamicForm config={formConfig} onSubmit={onSubmit} />
		</BasicModal>
	);
}

const formConfig = {
	inputs: [
		{
			label: 'اسم الاختيار',
			placeholder: 'اسم الاختيار',
			name: 'name',
			type: 'text',
			validation: nameValidation,
			value: '',
		},
	],
};
