import {Button, Card, Stack, Typography} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';

export default function IntegrationItem({app}) {
	const {id, name, bgcolor, iconPath, darkIconPath, customSettingsPageLink, soon} = app;
	const imageSrc = darkIconPath ?? iconPath;

	const navigate = useNavigate();
	return (
		<div>
			<Card
				sx={{
					p: 2,
					minHeight: 240,
				}}
			>
				<Stack spacing={1} alignItems="center">
					<img src={imageSrc} height={70} />
					<Typography variant="h5">{name}</Typography>
					{soon ? (
						<Typography color="grey" variant="h5">
							قريباً
						</Typography>
					) : (
						<Button
							onClick={() => {
								navigate(customSettingsPageLink ? customSettingsPageLink : '/apps/' + id); // he want to stop this provider
							}}
							variant="outlined"
							color="primary"
						>
							اعدادات
						</Button>
					)}
				</Stack>
			</Card>
		</div>
	);
}
