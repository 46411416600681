import {useState} from 'react';
// material
import {
	Container,
	Stack,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from '@mui/material';
// components
import GridViewIcon from '@mui/icons-material/GridView';
import ListIcon from '@mui/icons-material/List';

import Page from '../../../components/Page';
import ProductGridView from './ProductGridView';
//
// redux
import InfiniteScroll from 'react-infinite-scroll-component';
import {useInfiniteQuery, useQuery} from 'react-query';
import {useNavigate} from 'react-router-dom';
import Picker from 'src/components/inputs/Picker';
import PlusButton from 'src/components/buttons/PlusButton';
import {getApiSearchQueryString} from 'src/features/productSearch';
import useCategories from 'src/hooks/useCategories';
import {useDebounce} from 'src/hooks/useDebounce';
import {addProductPermission, findProductPermission} from 'src/permissionsList';
import {getProducts, getProperties} from 'src/utils/AdminApi';
import {getAllDescendingCategoryIds} from 'src/utils/categoryUtil';
import {canAccess} from 'src/utils/permissions';
import AccessControlWrapper from 'src/components/AccessControlWrapper';
import useUrlFilters from 'src/hooks/useUrlFilters';
import {stringify} from 'qs';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import {accountIs} from 'src/utils/siteUtils';
import ProductsListView from './ProductsListView';
// ----------------------------------------------------------------------

export default function InfiniteScrollProducts({
	queryName = 'products',
	qs = '',
	title = 'منتجاتي',
}) {
	const navigate = useNavigate();
	// get query string values from url
	const {filters, setQueryStringValue} = useUrlFilters();
	const {view: viewType = 'grid', categoryId = -1, property_value} = filters;
	const limit = 8;
	const [filter, setFilter] = useState('');
	const debouncedFilter = useDebounce(filter, 500);

	const {categories} = useCategories();

	const fetchProducts = async ({pageParam = 0}) => {
		let url = `?_sort=featured:desc,id:desc&_start=${pageParam * limit}&_limit=${limit}`;

		if (qs) url += `&${qs}`;

		if (debouncedFilter) {
			url += `&${getApiSearchQueryString(debouncedFilter)}`;
		}

		if (categoryId != -1) {
			if (categoryId === 'uncategorized') {
				url += `&categories_null=true`;
			} else {
				const selectedCategory = categories.find((cat) => cat.id == categoryId);
				console.log(selectedCategory, categoryId);
				if (selectedCategory) {
					// to filter based by nested levels of sub categories
					// we need to pass the id of all descending categories as query string in order to get products from all levels
					// ex: categories[0]=1&categories[1]=2
					url +=
						'&' +
						getAllDescendingCategoryIds(categories, selectedCategory)
							.map((categoryId, index) => `categories[${index}]=${categoryId}`)
							.join('&');
				}
			}
		}

		if (property_value) url += `&variations.values_in=${property_value}`;
		// url += `&categories_in=${selectedCategoryIdFilter}`;

		let productsBatch = await getProducts(url);

		return productsBatch;
	};

	const {data, fetchNextPage, hasNextPage, isLoading, refetch} = useInfiniteQuery(
		[queryName, debouncedFilter, categoryId, property_value],
		fetchProducts,
		{
			refetchOnWindowFocus: false,
			getNextPageParam: (lastPage, allPages) =>
				lastPage.length < limit ? undefined : allPages.length,
		},
	);
	let products = Array.isArray(data?.pages) ? data.pages.flat() : [];

	const {data: properties = []} = useQuery('properties', getProperties, {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		enabled: !isLoading,
	});
	const pv = properties.flatMap((p) => p.values);

	const onSearch = (e) => {
		const searchTerm = e.target.value?.toLowerCase();
		setFilter(searchTerm);
	};
	return (
		<Page title="منتجاتي">
			<AccessControlWrapper controllerAction={findProductPermission}>
				<Container>
					<Stack sx={{mb: 2}} justifyContent="space-between" direction="row">
						<Typography variant="h4">{title}</Typography>
						{canAccess(addProductPermission) && (
							<PlusButton onClick={() => navigate('/products/create')} />
						)}{' '}
					</Stack>
					<Stack alignItems="center" pb={2} spacing={1} direction="row">
						<Picker
							sx={{minWidth: 100}}
							label="التصنيف"
							// we add -1 to allow user to clear filter
							options={[{name: 'الكل', id: -1}, {name: 'غير مصنف', id: 'uncategorized'}, ...categories]}
							labelKey="name"
							valueKey="id"
							value={categoryId}
							onChange={(e) => setQueryStringValue('categoryId', e.target.value)}
						/>
						{(accountIs('demo') || accountIs('arzu')) && (
							<Picker
								sx={{minWidth: 100}}
								label="اختيارات"
								// we add -1 to allow user to clear filter
								options={pv}
								labelKey="value"
								valueKey="id"
								value={property_value}
								onChange={(e) => setQueryStringValue('property_value', e.target.value)}
							/>
						)}
						<TextField
							sx={{maxWidth: {md: 350}}}
							fullWidth
							placeholder="البحث عن منتج"
							type="text"
							onChange={onSearch}
						/>
						<ToggleButtonGroup
							value={viewType}
							exclusive
							onChange={(_, ns) => ns && setQueryStringValue('view', ns)}
							aria-label="text alignment"
						>
							<ToggleButton value="grid" aria-label="left aligned">
								<GridViewIcon />
							</ToggleButton>
							<ToggleButton value="list" aria-label="left aligned">
								<ListIcon />
							</ToggleButton>
						</ToggleButtonGroup>
					</Stack>

					<InfiniteScroll
						dataLength={products.length} //This is important field to render the next data
						next={() => fetchNextPage()}
						hasMore={hasNextPage}
						loader={<p>جاري تحميل المزيد</p>}
					>
						{viewType === 'grid' ? (
							<ProductGridView products={products} />
						) : (
							<ProductsListView refetch={refetch} products={products} />
						)}
					</InfiniteScroll>
				</Container>
			</AccessControlWrapper>
		</Page>
	);
}
