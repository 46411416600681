import min from 'lodash/min';
import sumBy from 'lodash/sumBy';
import max from 'lodash/max';
import {isNullOrEmpty} from './helperMethods';
import {getNumber} from './numberUtils';
import {getSiteCurrency} from './selectors';
import {getImageFormat} from './imageUtils';
import {calcPriceAfterDiscount} from './discountUtils';

export const defaultImageSrc = '/static/default-product-image.png';
export const getImageSrc = (product) => getImageFormat(product.main_image);

const getVariationsPrice = (variations = [], showCurrency = true, withDiscount = true, product) => {
	// all are equal then return price , else return 'سعر متعدد'
	const prices = variations.map((v) =>
		calcPriceAfterDiscount(
			v.price ?? product?.price ?? 0,
			withDiscount ? (v.discount ?? product?.discount) : 0,
		),
	);
	const minPrice = min(prices);
	const maxPrice = max(prices);
	return minPrice === maxPrice
		? `${minPrice}${showCurrency ? getSiteCurrency() : ''}`
		: `${minPrice} - ${maxPrice} ${showCurrency ? getSiteCurrency() : ''}`;
};

export const getProductPrice = (product, showCurrency = true, withDiscount = true) => {
	return !isNullOrEmpty(product.variations)
		? getVariationsPrice(product.variations, showCurrency, withDiscount, product)
		: `${calcPriceAfterDiscount(product.price, withDiscount ? product.discount : 0)}${
				showCurrency ? getSiteCurrency() : ''
			}`;
};

export const getProductQuantity = (product) => {
	if (product.infinite === true) return '∞';
	if (product.derived) {
		return product.derived.branch_quantity;
	}
	return !isNullOrEmpty(product.variations)
		? sumBy(product.variations, (v) => getNumber(v.quantity))
		: product.quantity;
};
