export const rawAllCountries = [
	{name: 'Afghanistan', arabicName: 'أفغانستان', flag: '🇦🇫', code: 'AF', dial_code: '+93'},
	{name: 'Åland Islands', arabicName: 'جزر أولاند', flag: '🇦🇽', code: 'AX', dial_code: '+358'},
	{name: 'Albania', arabicName: 'ألبانيا', flag: '🇦🇱', code: 'AL', dial_code: '+355'},
	{name: 'Algeria', arabicName: 'الجزائر', flag: '🇩🇿', code: 'DZ', dial_code: '+213'},
	{
		name: 'American Samoa',
		arabicName: 'ساموا الأمريكية',
		flag: '🇦🇸',
		code: 'AS',
		dial_code: '+1684',
	},
	{name: 'Andorra', arabicName: 'أندورا', flag: '🇦🇩', code: 'AD', dial_code: '+376'},
	{name: 'Angola', arabicName: 'أنغولا', flag: '🇦🇴', code: 'AO', dial_code: '+244'},
	{name: 'Anguilla', arabicName: 'أنغويلا', flag: '🇦🇮', code: 'AI', dial_code: '+1264'},
	{name: 'Antarctica', arabicName: 'أنتاركتيكا', flag: '🇦🇶', code: 'AQ', dial_code: '+672'},
	{
		name: 'Antigua and Barbuda',
		arabicName: 'أنتيغوا وباربودا',
		flag: '🇦🇬',
		code: 'AG',
		dial_code: '+1268',
	},
	{name: 'Argentina', arabicName: 'الأرجنتين', flag: '🇦🇷', code: 'AR', dial_code: '+54'},
	{name: 'Armenia', arabicName: 'أرمينيا', flag: '🇦🇲', code: 'AM', dial_code: '+374'},
	{name: 'Aruba', arabicName: 'أروبا', flag: '🇦🇼', code: 'AW', dial_code: '+297'},
	{name: 'Australia', arabicName: 'أستراليا', flag: '🇦🇺', code: 'AU', dial_code: '+61'},
	{name: 'Austria', arabicName: 'النمسا', flag: '🇦🇹', code: 'AT', dial_code: '+43'},
	{name: 'Azerbaijan', arabicName: 'أذربيجان', flag: '🇦🇿', code: 'AZ', dial_code: '+994'},
	{name: 'Bahamas', arabicName: 'الباهاما', flag: '🇧🇸', code: 'BS', dial_code: '+1242'},
	{name: 'Bahrain', arabicName: 'البحرين', flag: '🇧🇭', code: 'BH', dial_code: '+973'},
	{name: 'Bangladesh', arabicName: 'بنغلاديش', flag: '🇧🇩', code: 'BD', dial_code: '+880'},
	{name: 'Barbados', arabicName: 'باربادوس', flag: '🇧🇧', code: 'BB', dial_code: '+1246'},
	{name: 'Belarus', arabicName: 'بيلاروسيا', flag: '🇧🇾', code: 'BY', dial_code: '+375'},
	{name: 'Belgium', arabicName: 'بلجيكا', flag: '🇧🇪', code: 'BE', dial_code: '+32'},
	{name: 'Belize', arabicName: 'بليز', flag: '🇧🇿', code: 'BZ', dial_code: '+501'},
	{name: 'Benin', arabicName: 'بنين', flag: '🇧🇯', code: 'BJ', dial_code: '+229'},
	{name: 'Bermuda', arabicName: 'برمودا', flag: '🇧🇲', code: 'BM', dial_code: '+1441'},
	{name: 'Bhutan', arabicName: 'بوتان', flag: '🇧🇹', code: 'BT', dial_code: '+975'},
	{
		name: 'Bolivia, Plurinational State of Bolivia',
		arabicName: 'بوليفيا',
		flag: '🇧🇴',
		code: 'BO',
		dial_code: '+591',
	},
	{
		name: 'Bosnia and Herzegovina',
		arabicName: 'البوسنة والهرسك',
		flag: '🇧🇦',
		code: 'BA',
		dial_code: '+387',
	},
	{name: 'Botswana', arabicName: 'بوتسوانا', flag: '🇧🇼', code: 'BW', dial_code: '+267'},
	{name: 'Bouvet Island', arabicName: 'جزيرة بوفيه', flag: '🇧🇻', code: 'BV', dial_code: '+47'},
	{name: 'Brazil', arabicName: 'البرازيل', flag: '🇧🇷', code: 'BR', dial_code: '+55'},
	{
		name: 'British Indian Ocean Territory',
		arabicName: 'إقليم المحيط الهندي البريطاني',
		flag: '🇮🇴',
		code: 'IO',
		dial_code: '+246',
	},
	{name: 'Brunei Darussalam', arabicName: 'بروناي', flag: '🇧🇳', code: 'BN', dial_code: '+673'},
	{name: 'Bulgaria', arabicName: 'بلغاريا', flag: '🇧🇬', code: 'BG', dial_code: '+359'},
	{name: 'Burkina Faso', arabicName: 'بوركينا فاسو', flag: '🇧🇫', code: 'BF', dial_code: '+226'},
	{name: 'Burundi', arabicName: 'بوروندي', flag: '🇧🇮', code: 'BI', dial_code: '+257'},
	{name: 'Cambodia', arabicName: 'كمبوديا', flag: '🇰🇭', code: 'KH', dial_code: '+855'},
	{name: 'Cameroon', arabicName: 'الكاميرون', flag: '🇨🇲', code: 'CM', dial_code: '+237'},
	{name: 'Canada', arabicName: 'كندا', flag: '🇨🇦', code: 'CA', dial_code: '+1'},
	{name: 'Cape Verde', arabicName: 'الرأس الأخضر', flag: '🇨🇻', code: 'CV', dial_code: '+238'},
	{name: 'Cayman Islands', arabicName: 'جزر كايمان', flag: '🇰🇾', code: 'KY', dial_code: '+345'},
	{
		name: 'Central African Republic',
		arabicName: 'جمهورية أفريقيا الوسطى',
		flag: '🇨🇫',
		code: 'CF',
		dial_code: '+236',
	},
	{name: 'Chad', arabicName: 'تشاد', flag: '🇹🇩', code: 'TD', dial_code: '+235'},
	{name: 'Chile', arabicName: 'تشيلي', flag: '🇨🇱', code: 'CL', dial_code: '+56'},
	{name: 'China', arabicName: 'الصين', flag: '🇨🇳', code: 'CN', dial_code: '+86'},
	{
		name: 'Christmas Island',
		arabicName: 'جزيرة الكريسماس',
		flag: '🇨🇽',
		code: 'CX',
		dial_code: '+61',
	},
	{
		name: 'Cocos (Keeling) Islands',
		arabicName: 'جزر كوكوس',
		flag: '🇨🇨',
		code: 'CC',
		dial_code: '+61',
	},
	{name: 'Colombia', arabicName: 'كولومبيا', flag: '🇨🇴', code: 'CO', dial_code: '+57'},
	{name: 'Comoros', arabicName: 'جزر القمر', flag: '🇰🇲', code: 'KM', dial_code: '+269'},
	{name: 'Congo', arabicName: 'الكونغو', flag: '🇨🇬', code: 'CG', dial_code: '+242'},
	{
		name: 'Congo, The Democratic Republic of the Congo',
		arabicName: 'جمهورية الكونغو الديمقراطية',
		flag: '🇨🇩',
		code: 'CD',
		dial_code: '+243',
	},
	{name: 'Cook Islands', arabicName: 'جزر كوك', flag: '🇨🇰', code: 'CK', dial_code: '+682'},
	{name: 'Costa Rica', arabicName: 'كوستاريكا', flag: '🇨🇷', code: 'CR', dial_code: '+506'},
	{name: "Cote d'Ivoire", arabicName: 'ساحل العاج', flag: '🇨🇮', code: 'CI', dial_code: '+225'},
	{name: 'Croatia', arabicName: 'كرواتيا', flag: '🇭🇷', code: 'HR', dial_code: '+385'},
	{name: 'Cuba', arabicName: 'كوبا', flag: '🇨🇺', code: 'CU', dial_code: '+53'},
	{name: 'Cyprus', arabicName: 'قبرص', flag: '🇨🇾', code: 'CY', dial_code: '+357'},
	{name: 'Czech Republic', arabicName: 'جمهورية التشيك', flag: '🇨🇿', code: 'CZ', dial_code: '+420'},
	{name: 'Denmark', arabicName: 'الدنمارك', flag: '🇩🇰', code: 'DK', dial_code: '+45'},
	{name: 'Djibouti', arabicName: 'جيبوتي', flag: '🇩🇯', code: 'DJ', dial_code: '+253'},
	{name: 'Dominica', arabicName: 'دومينيكا', flag: '🇩🇲', code: 'DM', dial_code: '+1767'},
	{
		name: 'Dominican Republic',
		arabicName: 'جمهورية الدومينيكان',
		flag: '🇩🇴',
		code: 'DO',
		dial_code: '+1849',
	},
	{name: 'Ecuador', arabicName: 'الإكوادور', flag: '🇪🇨', code: 'EC', dial_code: '+593'},
	{name: 'Egypt', arabicName: 'مصر', flag: '🇪🇬', code: 'EG', dial_code: '+20'},
	{name: 'El Salvador', arabicName: 'السلفادور', flag: '🇸🇻', code: 'SV', dial_code: '+503'},
	{
		name: 'Equatorial Guinea',
		arabicName: 'غينيا الاستوائية',
		flag: '🇬🇶',
		code: 'GQ',
		dial_code: '+240',
	},
	{name: 'Eritrea', arabicName: 'إريتريا', flag: '🇪🇷', code: 'ER', dial_code: '+291'},
	{name: 'Estonia', arabicName: 'إستونيا', flag: '🇪🇪', code: 'EE', dial_code: '+372'},
	{name: 'Ethiopia', arabicName: 'إثيوبيا', flag: '🇪🇹', code: 'ET', dial_code: '+251'},
	{
		name: 'Falkland Islands (Malvinas)',
		arabicName: 'جزر فوكلاند',
		flag: '🇫🇰',
		code: 'FK',
		dial_code: '+500',
	},
	{name: 'Faroe Islands', arabicName: 'جزر فارو', flag: '🇫🇴', code: 'FO', dial_code: '+298'},
	{name: 'Fiji', arabicName: 'فيجي', flag: '🇫🇯', code: 'FJ', dial_code: '+679'},
	{name: 'Finland', arabicName: 'فنلندا', flag: '🇫🇮', code: 'FI', dial_code: '+358'},
	{name: 'France', arabicName: 'فرنسا', flag: '🇫🇷', code: 'FR', dial_code: '+33'},
	{name: 'French Guiana', arabicName: 'غيانا الفرنسية', flag: '🇬🇫', code: 'GF', dial_code: '+594'},
	{
		name: 'French Polynesia',
		arabicName: 'بولينيزيا الفرنسية',
		flag: '🇵🇫',
		code: 'PF',
		dial_code: '+689',
	},
	{
		name: 'French Southern Territories',
		arabicName: 'الأقاليم الجنوبية الفرنسية',
		flag: '🇹🇫',
		code: 'TF',
		dial_code: '+262',
	},
	{name: 'Gabon', arabicName: 'الغابون', flag: '🇬🇦', code: 'GA', dial_code: '+241'},
	{name: 'Gambia', arabicName: 'غامبيا', flag: '🇬🇲', code: 'GM', dial_code: '+220'},
	{name: 'Georgia', arabicName: 'جورجيا', flag: '🇬🇪', code: 'GE', dial_code: '+995'},
	{name: 'Germany', arabicName: 'ألمانيا', flag: '🇩🇪', code: 'DE', dial_code: '+49'},
	{name: 'Ghana', arabicName: 'غانا', flag: '🇬🇭', code: 'GH', dial_code: '+233'},
	{name: 'Gibraltar', arabicName: 'جبل طارق', flag: '🇬🇮', code: 'GI', dial_code: '+350'},
	{name: 'Greece', arabicName: 'اليونان', flag: '🇬🇷', code: 'GR', dial_code: '+30'},
	{name: 'Greenland', arabicName: 'جرينلاند', flag: '🇬🇱', code: 'GL', dial_code: '+299'},
	{name: 'Grenada', arabicName: 'غرينادا', flag: '🇬🇩', code: 'GD', dial_code: '+1473'},
	{name: 'Guadeloupe', arabicName: 'جزر جوادلوب', flag: '🇬🇵', code: 'GP', dial_code: '+590'},
	{name: 'Guam', arabicName: 'غوام', flag: '🇬🇺', code: 'GU', dial_code: '+1671'},
	{name: 'Guatemala', arabicName: 'غواتيمالا', flag: '🇬🇹', code: 'GT', dial_code: '+502'},
	{name: 'Guernsey', arabicName: 'غيرنزي', flag: '🇬🇬', code: 'GG', dial_code: '+44'},
	{name: 'Guinea', arabicName: 'غينيا', flag: '🇬🇳', code: 'GN', dial_code: '+224'},
	{name: 'Guinea-Bissau', arabicName: 'غينيا بيساو', flag: '🇬🇼', code: 'GW', dial_code: '+245'},
	{name: 'Guyana', arabicName: 'غيانا', flag: '🇬🇾', code: 'GY', dial_code: '+592'},
	{name: 'Haiti', arabicName: 'هايتي', flag: '🇭🇹', code: 'HT', dial_code: '+509'},
	{
		name: 'Heard Island and Mcdonald Islands',
		arabicName: 'جزيرة هيرد وجزر ماكدونالد',
		flag: '🇭🇲',
		code: 'HM',
		dial_code: '+672',
	},
	{
		name: 'Holy See (Vatican City State)',
		arabicName: 'الكرسي الرسولي (دولة الفاتيكان)',
		flag: '🇻🇦',
		code: 'VA',
		dial_code: '+379',
	},
	{name: 'Honduras', arabicName: 'هندوراس', flag: '🇭🇳', code: 'HN', dial_code: '+504'},
	{name: 'Hong Kong', arabicName: 'هونغ كونغ', flag: '🇭🇰', code: 'HK', dial_code: '+852'},
	{name: 'Hungary', arabicName: 'المجر', flag: '🇭🇺', code: 'HU', dial_code: '+36'},
	{name: 'Iceland', arabicName: 'آيسلندا', flag: '🇮🇸', code: 'IS', dial_code: '+354'},
	{name: 'India', arabicName: 'الهند', flag: '🇮🇳', code: 'IN', dial_code: '+91'},
	{name: 'Indonesia', arabicName: 'إندونيسيا', flag: '🇮🇩', code: 'ID', dial_code: '+62'},
	{
		name: 'Iran, Islamic Republic of Persian Gulf',
		arabicName: 'إيران',
		flag: '🇮🇷',
		code: 'IR',
		dial_code: '+98',
	},
	{name: 'Iraq', arabicName: 'العراق', flag: '🇮🇶', code: 'IQ', dial_code: '+964'},
	{name: 'Ireland', arabicName: 'أيرلندا', flag: '🇮🇪', code: 'IE', dial_code: '+353'},
	{name: 'Isle of Man', arabicName: 'جزيرة مان', flag: '🇮🇲', code: 'IM', dial_code: '+44'},
	{name: 'Israel', arabicName: 'إسرائيل', flag: '🇮🇱', code: 'IL', dial_code: '+972'},
	{name: 'Italy', arabicName: 'إيطاليا', flag: '🇮🇹', code: 'IT', dial_code: '+39'},
	{name: 'Jamaica', arabicName: 'جامايكا', flag: '🇯🇲', code: 'JM', dial_code: '+1876'},
	{name: 'Japan', arabicName: 'اليابان', flag: '🇯🇵', code: 'JP', dial_code: '+81'},
	{name: 'Jersey', arabicName: 'جيرسي', flag: '🇯🇪', code: 'JE', dial_code: '+44'},
	{name: 'Jordan', arabicName: 'الأردن', flag: '🇯🇴', code: 'JO', dial_code: '+962'},
	{name: 'Kazakhstan', arabicName: 'كازاخستان', flag: '🇰🇿', code: 'KZ', dial_code: '+7'},
	{name: 'Kenya', arabicName: 'كينيا', flag: '🇰🇪', code: 'KE', dial_code: '+254'},
	{name: 'Kiribati', arabicName: 'كيريباس', flag: '🇰🇮', code: 'KI', dial_code: '+686'},
	{
		name: "Korea, Democratic People's Republic of Korea",
		arabicName: 'كوريا الشمالية',
		flag: '🇰🇵',
		code: 'KP',
		dial_code: '+850',
	},
	{
		name: 'Korea, Republic of South Korea',
		arabicName: 'كوريا الجنوبية',
		flag: '🇰🇷',
		code: 'KR',
		dial_code: '+82',
	},
	{name: 'Kosovo', arabicName: 'كوسوفو', flag: '🇽🇰', code: 'XK', dial_code: '+383'},
	{name: 'Kuwait', arabicName: 'الكويت', flag: '🇰🇼', code: 'KW', dial_code: '+965'},
	{name: 'Kyrgyzstan', arabicName: 'قيرغيزستان', flag: '🇰🇬', code: 'KG', dial_code: '+996'},
	{name: 'Laos', arabicName: 'لاوس', flag: '🇱🇦', code: 'LA', dial_code: '+856'},
	{name: 'Latvia', arabicName: 'لاتفيا', flag: '🇱🇻', code: 'LV', dial_code: '+371'},
	{name: 'Lebanon', arabicName: 'لبنان', flag: '🇱🇧', code: 'LB', dial_code: '+961'},
	{name: 'Lesotho', arabicName: 'ليسوتو', flag: '🇱🇸', code: 'LS', dial_code: '+266'},
	{name: 'Liberia', arabicName: 'ليبيريا', flag: '🇱🇷', code: 'LR', dial_code: '+231'},
	{name: 'Libya', arabicName: 'ليبيا', flag: '🇱🇾', code: 'LY', dial_code: '+218'},
	{name: 'Liechtenstein', arabicName: 'ليختنشتاين', flag: '🇱🇮', code: 'LI', dial_code: '+423'},
	{name: 'Lithuania', arabicName: 'ليتوانيا', flag: '🇱🇹', code: 'LT', dial_code: '+370'},
	{name: 'Luxembourg', arabicName: 'لوكسمبورغ', flag: '🇱🇺', code: 'LU', dial_code: '+352'},
	{name: 'Macao', arabicName: 'ماكاو', flag: '🇲🇴', code: 'MO', dial_code: '+853'},
	{name: 'Macedonia', arabicName: 'مقدونيا', flag: '🇲🇰', code: 'MK', dial_code: '+389'},
	{name: 'Madagascar', arabicName: 'مدغشقر', flag: '🇲🇬', code: 'MG', dial_code: '+261'},
	{name: 'Malawi', arabicName: 'مالاوي', flag: '🇲🇼', code: 'MW', dial_code: '+265'},
	{name: 'Malaysia', arabicName: 'ماليزيا', flag: '🇲🇾', code: 'MY', dial_code: '+60'},
	{name: 'Maldives', arabicName: 'جزر المالديف', flag: '🇲🇻', code: 'MV', dial_code: '+960'},
	{name: 'Mali', arabicName: 'مالي', flag: '🇲🇱', code: 'ML', dial_code: '+223'},
	{name: 'Malta', arabicName: 'مالطا', flag: '🇲🇹', code: 'MT', dial_code: '+356'},
	{name: 'Marshall Islands', arabicName: 'جزر مارشال', flag: '🇲🇭', code: 'MH', dial_code: '+692'},
	{name: 'Martinique', arabicName: 'مارتينيك', flag: '🇲🇶', code: 'MQ', dial_code: '+596'},
	{name: 'Mauritania', arabicName: 'موريتانيا', flag: '🇲🇷', code: 'MR', dial_code: '+222'},
	{name: 'Mauritius', arabicName: 'موريشيوس', flag: '🇲🇺', code: 'MU', dial_code: '+230'},
	{name: 'Mayotte', arabicName: 'مايوت', flag: '🇾🇹', code: 'YT', dial_code: '+262'},
	{name: 'Mexico', arabicName: 'المكسيك', flag: '🇲🇽', code: 'MX', dial_code: '+52'},
	{
		name: 'Micronesia, Federated States of Micronesia',
		arabicName: 'ميكرونيزيا',
		flag: '🇫🇲',
		code: 'FM',
		dial_code: '+691',
	},
	{name: 'Moldova', arabicName: 'مولدوفا', flag: '🇲🇩', code: 'MD', dial_code: '+373'},
	{name: 'Monaco', arabicName: 'موناكو', flag: '🇲🇨', code: 'MC', dial_code: '+377'},
	{name: 'Mongolia', arabicName: 'منغوليا', flag: '🇲🇳', code: 'MN', dial_code: '+976'},
	{name: 'Montenegro', arabicName: 'الجبل الأسود', flag: '🇲🇪', code: 'ME', dial_code: '+382'},
	{name: 'Montserrat', arabicName: 'مونتسرات', flag: '🇲🇸', code: 'MS', dial_code: '+1664'},
	{name: 'Morocco', arabicName: 'المغرب', flag: '🇲🇦', code: 'MA', dial_code: '+212'},
	{name: 'Mozambique', arabicName: 'موزمبيق', flag: '🇲🇿', code: 'MZ', dial_code: '+258'},
	{name: 'Myanmar', arabicName: 'ميانمار', flag: '🇲🇲', code: 'MM', dial_code: '+95'},
	{name: 'Namibia', arabicName: 'ناميبيا', flag: '🇳🇦', code: 'NA', dial_code: '+264'},
	{name: 'Nauru', arabicName: 'ناورو', flag: '🇳🇷', code: 'NR', dial_code: '+674'},
	{name: 'Nepal', arabicName: 'نيبال', flag: '🇳🇵', code: 'NP', dial_code: '+977'},
	{name: 'Netherlands', arabicName: 'هولندا', flag: '🇳🇱', code: 'NL', dial_code: '+31'},
	{
		name: 'Netherlands Antilles',
		arabicName: 'جزر الأنتيل الهولندية',
		flag: '',
		code: 'AN',
		dial_code: '+599',
	},
	{
		name: 'New Caledonia',
		arabicName: 'كاليدونيا الجديدة',
		flag: '🇳🇨',
		code: 'NC',
		dial_code: '+687',
	},
	{name: 'New Zealand', arabicName: 'نيوزيلندا', flag: '🇳🇿', code: 'NZ', dial_code: '+64'},
	{name: 'Nicaragua', arabicName: 'نيكاراغوا', flag: '🇳🇮', code: 'NI', dial_code: '+505'},
	{name: 'Niger', arabicName: 'النيجر', flag: '🇳🇪', code: 'NE', dial_code: '+227'},
	{name: 'Nigeria', arabicName: 'نيجيريا', flag: '🇳🇬', code: 'NG', dial_code: '+234'},
	{name: 'Niue', arabicName: 'نيوي', flag: '🇳🇺', code: 'NU', dial_code: '+683'},
	{name: 'Norfolk Island', arabicName: 'جزيرة نورفولك', flag: '🇳🇫', code: 'NF', dial_code: '+672'},
	{
		name: 'Northern Mariana Islands',
		arabicName: 'جزر ماريانا الشمالية',
		flag: '🇲🇵',
		code: 'MP',
		dial_code: '+1670',
	},
	{name: 'Norway', arabicName: 'النرويج', flag: '🇳🇴', code: 'NO', dial_code: '+47'},
	{name: 'Oman', arabicName: 'عمان', flag: '🇴🇲', code: 'OM', dial_code: '+968'},
	{name: 'Pakistan', arabicName: 'باكستان', flag: '🇵🇰', code: 'PK', dial_code: '+92'},
	{name: 'Palau', arabicName: 'بالاو', flag: '🇵🇼', code: 'PW', dial_code: '+680'},
	{
		name: 'Palestinian Territory, Occupied',
		arabicName: 'فلسطين',
		flag: '🇵🇸',
		code: 'PS',
		dial_code: '+970',
	},
	{name: 'Panama', arabicName: 'بنما', flag: '🇵🇦', code: 'PA', dial_code: '+507'},
	{
		name: 'Papua New Guinea',
		arabicName: 'بابوا غينيا الجديدة',
		flag: '🇵🇬',
		code: 'PG',
		dial_code: '+675',
	},
	{name: 'Paraguay', arabicName: 'باراغواي', flag: '🇵🇾', code: 'PY', dial_code: '+595'},
	{name: 'Peru', arabicName: 'بيرو', flag: '🇵🇪', code: 'PE', dial_code: '+51'},
	{name: 'Philippines', arabicName: 'الفلبين', flag: '🇵🇭', code: 'PH', dial_code: '+63'},
	{name: 'Pitcairn', arabicName: 'بيتكيرن', flag: '🇵🇳', code: 'PN', dial_code: '+64'},
	{name: 'Poland', arabicName: 'بولندا', flag: '🇵🇱', code: 'PL', dial_code: '+48'},
	{name: 'Portugal', arabicName: 'البرتغال', flag: '🇵🇹', code: 'PT', dial_code: '+351'},
	{name: 'Puerto Rico', arabicName: 'بورتوريكو', flag: '🇵🇷', code: 'PR', dial_code: '+1939'},
	{name: 'Qatar', arabicName: 'قطر', flag: '🇶🇦', code: 'QA', dial_code: '+974'},
	{name: 'Romania', arabicName: 'رومانيا', flag: '🇷🇴', code: 'RO', dial_code: '+40'},
	{name: 'Russia', arabicName: 'روسيا', flag: '🇷🇺', code: 'RU', dial_code: '+7'},
	{name: 'Rwanda', arabicName: 'رواندا', flag: '🇷🇼', code: 'RW', dial_code: '+250'},
	{name: 'Reunion', arabicName: 'ريونيون', flag: '🇷🇪', code: 'RE', dial_code: '+262'},
	{
		name: 'Saint Barthelemy',
		arabicName: 'سانت بارتيليمي',
		flag: '🇧🇱',
		code: 'BL',
		dial_code: '+590',
	},
	{
		name: 'Saint Helena, Ascension and Tristan Da Cunha',
		arabicName: 'سانت هيلينا وأسينسيون وتريستان دا كونا',
		flag: '🇸🇭',
		code: 'SH',
		dial_code: '+290',
	},
	{
		name: 'Saint Kitts and Nevis',
		arabicName: 'سانت كيتس ونيفيس',
		flag: '🇰🇳',
		code: 'KN',
		dial_code: '+1869',
	},
	{name: 'Saint Lucia', arabicName: 'سانت لوسيا', flag: '🇱🇨', code: 'LC', dial_code: '+1758'},
	{name: 'Saint Martin', arabicName: 'سانت مارتين', flag: '🇲🇫', code: 'MF', dial_code: '+590'},
	{
		name: 'Saint Pierre and Miquelon',
		arabicName: 'سانت بيير وميكلون',
		flag: '🇵🇲',
		code: 'PM',
		dial_code: '+508',
	},
	{
		name: 'Saint Vincent and the Grenadines',
		arabicName: 'سانت فنسنت وجزر غرينادين',
		flag: '🇻🇨',
		code: 'VC',
		dial_code: '+1784',
	},
	{name: 'Samoa', arabicName: 'ساموا', flag: '🇼🇸', code: 'WS', dial_code: '+685'},
	{name: 'San Marino', arabicName: 'سان مارينو', flag: '🇸🇲', code: 'SM', dial_code: '+378'},
	{
		name: 'Sao Tome and Principe',
		arabicName: 'ساو تومي وبرينسيبي',
		flag: '🇸🇹',
		code: 'ST',
		dial_code: '+239',
	},
	{
		name: 'Saudi Arabia',
		arabicName: 'المملكة العربية السعودية',
		flag: '🇸🇦',
		code: 'SA',
		dial_code: '+966',
	},
	{name: 'Senegal', arabicName: 'السنغال', flag: '🇸🇳', code: 'SN', dial_code: '+221'},
	{name: 'Serbia', arabicName: 'صربيا', flag: '🇷🇸', code: 'RS', dial_code: '+381'},
	{name: 'Seychelles', arabicName: 'سيشيل', flag: '🇸🇨', code: 'SC', dial_code: '+248'},
	{name: 'Sierra Leone', arabicName: 'سيراليون', flag: '🇸🇱', code: 'SL', dial_code: '+232'},
	{name: 'Singapore', arabicName: 'سنغافورة', flag: '🇸🇬', code: 'SG', dial_code: '+65'},
	{name: 'Slovakia', arabicName: 'سلوفاكيا', flag: '🇸🇰', code: 'SK', dial_code: '+421'},
	{name: 'Slovenia', arabicName: 'سلوفينيا', flag: '🇸🇮', code: 'SI', dial_code: '+386'},
	{name: 'Solomon Islands', arabicName: 'جزر سليمان', flag: '🇸🇧', code: 'SB', dial_code: '+677'},
	{name: 'Somalia', arabicName: 'الصومال', flag: '🇸🇴', code: 'SO', dial_code: '+252'},
	{name: 'South Africa', arabicName: 'جنوب أفريقيا', flag: '🇿🇦', code: 'ZA', dial_code: '+27'},
	{name: 'South Sudan', arabicName: 'جنوب السودان', flag: '🇸🇸', code: 'SS', dial_code: '+211'},
	{
		name: 'South Georgia and the South Sandwich Islands',
		arabicName: 'جورجيا الجنوبية وجزر ساندويتش الجنوبية',
		flag: '🇬🇸',
		code: 'GS',
		dial_code: '+500',
	},
	{name: 'Spain', arabicName: 'إسبانيا', flag: '🇪🇸', code: 'ES', dial_code: '+34'},
	{name: 'Sri Lanka', arabicName: 'سريلانكا', flag: '🇱🇰', code: 'LK', dial_code: '+94'},
	{name: 'Sudan', arabicName: 'السودان', flag: '🇸🇩', code: 'SD', dial_code: '+249'},
	{name: 'Suriname', arabicName: 'سورينام', flag: '🇸🇷', code: 'SR', dial_code: '+597'},
	{
		name: 'Svalbard and Jan Mayen',
		arabicName: 'سفالبارد ويان ماين',
		flag: '🇸🇯',
		code: 'SJ',
		dial_code: '+47',
	},
	{name: 'Eswatini', arabicName: 'إسواتيني', flag: '🇸🇿', code: 'SZ', dial_code: '+268'},
	{name: 'Sweden', arabicName: 'السويد', flag: '🇸🇪', code: 'SE', dial_code: '+46'},
	{name: 'Switzerland', arabicName: 'سويسرا', flag: '🇨🇭', code: 'CH', dial_code: '+41'},
	{name: 'Syrian Arab Republic', arabicName: 'سوريا', flag: '🇸🇾', code: 'SY', dial_code: '+963'},
	{name: 'Taiwan', arabicName: 'تايوان', flag: '🇹🇼', code: 'TW', dial_code: '+886'},
	{name: 'Tajikistan', arabicName: 'طاجيكستان', flag: '🇹🇯', code: 'TJ', dial_code: '+992'},
	{
		name: 'Tanzania, United Republic of Tanzania',
		arabicName: 'تنزانيا',
		flag: '🇹🇿',
		code: 'TZ',
		dial_code: '+255',
	},
	{name: 'Thailand', arabicName: 'تايلاند', flag: '🇹🇭', code: 'TH', dial_code: '+66'},
	{name: 'Timor-Leste', arabicName: 'تيمور الشرقية', flag: '🇹🇱', code: 'TL', dial_code: '+670'},
	{name: 'Togo', arabicName: 'توغو', flag: '🇹🇬', code: 'TG', dial_code: '+228'},
	{name: 'Tokelau', arabicName: 'توكيلو', flag: '🇹🇰', code: 'TK', dial_code: '+690'},
	{name: 'Tonga', arabicName: 'تونغا', flag: '🇹🇴', code: 'TO', dial_code: '+676'},
	{
		name: 'Trinidad and Tobago',
		arabicName: 'ترينيداد وتوباغو',
		flag: '🇹🇹',
		code: 'TT',
		dial_code: '+1868',
	},
	{name: 'Tunisia', arabicName: 'تونس', flag: '🇹🇳', code: 'TN', dial_code: '+216'},
	{name: 'Turkey', arabicName: 'تركيا', flag: '🇹🇷', code: 'TR', dial_code: '+90'},
	{name: 'Turkmenistan', arabicName: 'تركمانستان', flag: '🇹🇲', code: 'TM', dial_code: '+993'},
	{
		name: 'Turks and Caicos Islands',
		arabicName: 'جزر تركس وكايكوس',
		flag: '🇹🇨',
		code: 'TC',
		dial_code: '+1649',
	},
	{name: 'Tuvalu', arabicName: 'توفالو', flag: '🇹🇻', code: 'TV', dial_code: '+688'},
	{name: 'Uganda', arabicName: 'أوغندا', flag: '🇺🇬', code: 'UG', dial_code: '+256'},
	{name: 'Ukraine', arabicName: 'أوكرانيا', flag: '🇺🇦', code: 'UA', dial_code: '+380'},
	{
		name: 'United Arab Emirates',
		arabicName: 'الإمارات العربية المتحدة',
		flag: '🇦🇪',
		code: 'AE',
		dial_code: '+971',
	},
	{name: 'United Kingdom', arabicName: 'المملكة المتحدة', flag: '🇬🇧', code: 'GB', dial_code: '+44'},
	{
		name: 'United States',
		arabicName: 'الولايات المتحدة الأمريكية',
		flag: '🇺🇸',
		code: 'US',
		dial_code: '+1',
	},
	{name: 'Uruguay', arabicName: 'أوروغواي', flag: '🇺🇾', code: 'UY', dial_code: '+598'},
	{name: 'Uzbekistan', arabicName: 'أوزبكستان', flag: '🇺🇿', code: 'UZ', dial_code: '+998'},
	{name: 'Vanuatu', arabicName: 'فانواتو', flag: '🇻🇺', code: 'VU', dial_code: '+678'},
	{
		name: 'Venezuela, Bolivarian Republic of Venezuela',
		arabicName: 'فنزويلا',
		flag: '🇻🇪',
		code: 'VE',
		dial_code: '+58',
	},
	{name: 'Vietnam', arabicName: 'فيتنام', flag: '🇻🇳', code: 'VN', dial_code: '+84'},
	{
		name: 'Virgin Islands, British',
		arabicName: 'جزر العذراء البريطانية',
		flag: '🇻🇬',
		code: 'VG',
		dial_code: '+1284',
	},
	{
		name: 'Virgin Islands, U.S.',
		arabicName: 'جزر العذراء الأمريكية',
		flag: '🇻🇮',
		code: 'VI',
		dial_code: '+1340',
	},
	{
		name: 'Wallis and Futuna',
		arabicName: 'واليس وفوتونا',
		flag: '🇼🇫',
		code: 'WF',
		dial_code: '+681',
	},
	{name: 'Yemen', arabicName: 'اليمن', flag: '🇾🇪', code: 'YE', dial_code: '+967'},
	{name: 'Zambia', arabicName: 'زامبيا', flag: '🇿🇲', code: 'ZM', dial_code: '+260'},
	{name: 'Zimbabwe', arabicName: 'زيمبابوي', flag: '🇿🇼', code: 'ZW', dial_code: '+263'},
].sort((a, b) => a.dial_code.substring(1) - b.dial_code.substring(1));
