import {Stack, Switch, Typography} from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import {Link} from 'react-router-dom';
import {paymentMethods} from 'src/components/payments/paymentMethods';
import useSite from 'src/hooks/useSite';
import SettingsIcon from '@mui/icons-material/Settings';
export default function PaymentMethodSelectionCard({enabled, method, toggleState}) {
	const tenant = useSite();

	return (
		<Card sx={{minHeight: '220px'}}>
			<CardHeader
				title={
					<Stack spacing={1} justifyContent="center" direction="row">
						<Typography variant="subtitle1">{method.label}</Typography>
						{method.link && (
							<Link style={{color: 'gray'}} to={method.link}>
								<SettingsIcon fontSize="14" />
							</Link>
						)}
					</Stack>
				}
				titleTypographyProps={{align: 'center'}}
			/>
			<CardContent>
				<CardMedia
					sx={{objectFit: 'contain'}}
					component="img"
					height={50}
					image={method.icon}
					alt={method.value}
				/>
			</CardContent>
			<CardActions disableSpacing sx={{justifyContent: 'center'}}>
				<Switch
					disabled={method.value === paymentMethods.t_lync.value && tenant.config?.t_lync == null}
					checked={enabled}
					onChange={() => toggleState(method.value, !enabled)}
				/>
			</CardActions>
		</Card>
	);
}
