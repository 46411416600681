import DoneAllIcon from '@mui/icons-material/DoneAll.js';
import {Box, Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import {styled} from '@mui/material/styles';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Page from 'src/components/Page.js';

// Styled Grid
const ClickableGrid = styled(Grid)({
	cursor: 'pointer',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'flex-start',
	alignItems: 'stretch',
	paddingInline: '0.5rem',
	border: '1px solid lightgrey',
	borderInline: '0px',
});

function NotificationsPage(props) {
	const navigate = useNavigate();
	const [notifications, setNotifications] = useState([]);
	const userNotifications = useSelector((state) => state.admin.user.notifications);

	const fetchNotifications = () => {
		setNotifications(userNotifications);
	};

	useEffect(() => {
		fetchNotifications();
	}, []);

	// const Notifications = useSelector(state => state);
	const notificationViewHandler = (id, type) => {
		if (type === 'suggestion') {
			return navigate('/suggestions', {replace: true});
		}
		navigate('/notification/' + id, {replace: true});
	};
	return (
		<Page title="notification">
			<Grid container gap={1}>
				{notifications?.length > 0 &&
					notifications.map((notification) => (
						<ClickableGrid
							key={notification.id}
							item
							xs={12}
							onClick={() => notificationViewHandler(notification.id, notification.type)}
						>
							<h3>{notification.title}</h3>
							<Typography noWrap>{notification.body}</Typography>
							<Box
								sx={{
									alignSelf: 'flex-end',
									justifySelf: 'start',
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center',
									columnGap: 0,
									width: 'max-content',
								}}
							>
								<Typography variant="caption" sx={{width: 'fit-content'}}>
									{!notification.seen && 'not '}seen
								</Typography>
								<DoneAllIcon color={notification.seen ? 'info' : 'action'} />
							</Box>
						</ClickableGrid>
					))}
			</Grid>
		</Page>
	);
}

export default NotificationsPage;
