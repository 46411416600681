export const paymentMethods = {
	cash: {
		label: 'كاش',
		value: 'cash',
		icon: '/static/icons/payment-methods/cash-on-delivery.png',
		enabledByDefault: true,
	},
	t_lync: {
		label: 'دفع الكتروني',
		value: 't_lync',
		icon: '/static/icons/payment-methods/t_lync.png',
	},
	paypal: {
		label: 'paypal',
		value: 'paypal',
		icon: '/static/icons/payment-methods/paypal.png',
		link: '/plugins/paypal/settings',
	},
	// These not used any more but we keep them for mapping old payments made with them
	sadad: {
		label: 'سداد',
		value: 'sadad',
	},
	adfali: {
		label: 'ادفعلي',
		value: 'adfali',
	},
	localbankcards: {
		label: 'البطاقة المصرفية',
		value: 'localbankcards',
	},
	bank: {
		label: 'البطاقة المصرفية',
		value: 'localbankcards',
	},
	pos: {
		label: 'البطاقة المصرفية',
		value: 'pos',
	},
	bunq: {
		label: 'دفع الكتروني - bunq',
		value: 'bunq',
		icon:
			'https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/Bunq_%28bank%29_company_logo_2017.svg/2048px-Bunq_%28bank%29_company_logo_2017.svg.png',
		link: '/plugins/bunq/settings',
	},
};
export const availablePaymentMethods = [
	paymentMethods.cash,
	paymentMethods.t_lync,
	paymentMethods.paypal,
	paymentMethods.bunq,
];

export const paymentStatus = {
	confirmed: {
		ar: 'تم الدفع',
	},
	pending: {
		ar: 'غير مكتمل',
	},
	failed: {
		ar: 'فشلت العملية',
	},
};

// export const paymentIconsLinks = {
// 	[paymentMethods.sadad.value]: '/static/icons/payment-methods/sadad.svg',
// 	[paymentMethods.adfali.value]: '/static/icons/payment-methods/edfali.svg',
// 	[paymentMethods.cash.value]: '/static/icons/payment-methods/cash-on-delivery.png',
// 	[paymentMethods.localbankcards.value]: '/static/icons/payment-methods/moamalat.png',
// 	[paymentMethods.bank.value]: '/static/icons/payment-methods/moamalat.png',
// 	[paymentMethods.t_lync.value]: '/static/icons/payment-methods/t_lync.png',
// 	[paymentMethods.paypal.value]: '/static/icons/payment-methods/paypal.png',
// };
