/* eslint-disable no-unreachable */
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import {Box, Button, Divider, IconButton, Stack, Typography} from '@mui/material';
import {useRef} from 'react';
import {toast} from 'react-toastify';
import DynamicFormInput from 'src/components/DynamicForm/DynamicFormInput';
import ItemBranchPicker from 'src/components/ItemBranchPicker';
import {ItemPrice} from 'src/components/Price';
import StyledLink from 'src/components/ui/StyledLink';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import {getNumber} from 'src/utils/numberUtils';
import {getImageSrc} from 'src/utils/productUtils';

/* 
	item will contain all attributes from product  (IFF variatoni: common attributes overriden variation attributes like price and qty, name )
	check addItem method in ProductsGridSelectionItem.jsx
*/
export default function CartItem({
	item,
	handleItemChange = (id, changes) => {},
	handleItemQuantityChange,
	handleRemoveItem,
}) {
	const {price: defaultPrice, discount: defaultDiscount} = useRef(item).current;
	return (
		<Stack spacing={2}>
			<Stack direction={{xs: 'column', sm: 'row'}} justifyContent="space-between">
				<Stack alignItems="center" spacing={2} direction="row">
					<img loading="lazy" alt="product" src={getImageSrc(item)} width={45} height={45} />
					<Stack>
						<StyledLink to={`/products/${item.product?.id ?? item.product}`}>
							<Typography variant="subtitle2">
								{item.name} {item.variation?.name && `- ${item.variation?.name}`}
							</Typography>
						</StyledLink>
						<Stack spacing={1} direction="row" justifyContent="space-between">
							<Typography sx={{fontSize: 14}}>
								الكمية: {item.infinite ? '∞' : item.derived.branch_quantity}
							</Typography>
							<ItemPrice item={item} />
						</Stack>
					</Stack>
				</Stack>
				{/* 		<Stack alignItems="center" spacing={1} direction="row" justifyContent="space-between">
					<TextField
						type="number"
						label="الكمية"
						placeholder="الكمية"
						size="small"
						name="selectedQuantity"
						defaultValue={1}
						sx={{maxWidth: 65, fontSize: 5}}
						value={item.selectedQuantity}
						onChange={(e) => handleItemQuantityChange(item.id, e.target.value)}
					/>

					<IconButton
						sx={{opacity: 0.8}}
						color="error"
						onClick={() => {
							handleRemoveItem(item.id);
						}}
					>
						<DeleteIcon fontSize="small" />
					</IconButton>
				</Stack>
 */}

				<Stack spacing={1} direction="row" alignItems={'center'}>
					<ItemBranchPicker
						stocks={item.stocks}
						variationId={item.variation}
						value={item.branch ?? ''}
						onChange={(e, option) => {
							handleItemChange(item.id, {
								...item,
								branch: e.target.value,
							});
						}}
					/>
					<Stack sx={{p: 0, minWidth: '0px', borderRadius: '4px 0 0 4px'}} direction={'row'}>
						<Button
							variant="contained"
							size="small"
							sx={{px: 1, minWidth: '0px', borderRadius: '4px 0 0 4px'}}
							onClick={() => {
								if (item.infinite || item.selectedQuantity < item.derived.branch_quantity)
									handleItemQuantityChange(item.id, item.selectedQuantity + 1);
								else toast.warning('وصلت لحد الكمية المتاحة');
							}}
						>
							<AddRoundedIcon fontSize="16px" />
						</Button>
						<Box
							display={'flex'}
							alignItems={'center'}
							px={0.5}
							py={0.5}
							borderTop={'1px solid #076458'}
							borderBottom={'1px solid #076458'}
						>
							<input
								// variant="body2"
								style={{border: 'none', width: 30, textAlign: 'center', outline: 'none'}}
								value={item.selectedQuantity}
								onChange={(e) => handleItemQuantityChange(item.id, e.target.value)}
							/>
						</Box>
						<Button
							variant="contained"
							size="small"
							sx={{px: 1, minWidth: '0px', borderRadius: '0 4px 4px 0'}}
							onClick={() => {
								if (item.selectedQuantity > 1) handleItemQuantityChange(item.id, item.selectedQuantity - 1);
								else {
									handleRemoveItem(item.id);
								}
							}}
						>
							<RemoveRoundedIcon fontSize="16px" />
						</Button>
					</Stack>
					<IconButton
						sx={{opacity: 0.8}}
						color="error"
						onClick={() => {
							handleRemoveItem(item.id);
						}}
					>
						<DeleteIcon fontSize="small" />
					</IconButton>
				</Stack>
			</Stack>
			{/* render dynamic inputs */}
			{!isNullOrEmpty(item.inputs) && (
				<DynamicFormInput
					value={item.selectedInputs?.[0]?.value ?? ''}
					setFieldValue={(inputName, value, selectedOption) => {
						// selectedOption will be set only if the input is picker
						const {name, label} = item.inputs[0];
						const price = getNumber(selectedOption?.price, defaultPrice);
						handleItemChange(item.id, {
							...item,
							price,
							discount: selectedOption?.price ? 0 : defaultDiscount,
							selectedInputs: [{name, label, value, price}],
						});
					}}
					input={{...item.inputs[0], valueKey: 'value', labelKey: 'value'}}
				/>
			)}

			<Divider />
		</Stack>
	);
}
