// material
import {Card, Container, Typography} from '@mui/material';
// components
import AdminRegisterForm from 'src/components/authentication/AdminRegisterForm';
// ----------------------------------------------------------------------

export default function Register() {
	return (
		<Container maxWidth="sm">
			<Card sx={{p: 4}}>
				<Typography variant="h4" gutterBottom>
					انشاء حساب في فترينا
				</Typography>

				<AdminRegisterForm />
			</Card>
		</Container>
	);
}
