function isNumericString(str) {
	if (typeof str !== 'string' || !str) return false;

	return (
		!isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
		!isNaN(parseFloat(str))
	); // ...and ensure strings of whitespace fail
}

function isNumber(num) {
	if (typeof num === 'string') return isNumericString(num);
	else return typeof num === 'number';
}
// this function takes any param and check if it's a number then it will return the number it self, else will return default value
// ex if we pass '' then it will return fullback
// '4' => 4
// 4 => 4
// 'sdfjodsf' => 0
function getNumber(number, fullback = 0) {
	return isNumber(number) ? Number(number) : fullback;
}

function getPriceNumber(price) {
	const number = getNumber(price);
	const numberString = parseFloat(number).toFixed(2);
	// parse it to number again
	return +numberString;
}
// console.log(getNumber(4), 4);
// console.log(getNumber('4'), 4);
// console.log(getNumber(''), 0);
// console.log(getNumber('sdfds'), 0);

/**
 *
 * @param {array} collection
 * @param {function} fn
 * @returns
 */
const sumBy = (collection, fn) => {
	let sum = 0;
	collection.forEach((item) => {
		sum += getNumber(fn(item), 0) || 0;
	});
	return sum;
};

const isBetween = (n, from, to) => {
	return n >= from && n <= to;
};

function isFloat(n) {
	return Number(n) === n && n % 1 !== 0;
}

const weightSum = (collection) => {
	return sumBy(collection, (a) => getNumber(a.weight, 0.1) * 100) / 100;
};

module.exports = {
	isFloat,
	isBetween,
	sumBy,
	isNumericString,
	isNumber,
	getNumber,
	getPriceNumber,
	weightSum,
};
