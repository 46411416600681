// components
import {Stack} from '@mui/material';
import {Outlet} from 'react-router-dom';

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
	return (
		<Stack sx={{minHeight: '100vh', bgcolor: '#02393E', paddingBottom: 8}}>
			<img
				height="150px"
				width="150px"
				style={{objectFit: 'contain', alignSelf: 'center'}}
				src="/logo-white.png"
			/>
			<Outlet />
		</Stack>
	);
}
