import {object, string} from 'yup';

const postalAddressSchema = object().shape({
	countryCode: string().length(2, '2 letter ISO CODE').required('هذا الحقل مطلوب'),
	cityName: string().required('هذا الحقل مطلوب'),
	postalCode: string(),
	addressLine1: string().required('هذا الحقل مطلوب'),
});

const contactInformationSchema = object().shape({
	fullName: string().required('هذا الحقل مطلوب'),
	companyName: string().required('هذا الحقل مطلوب'),
	email: string().email('must be valid email').required('هذا الحقل مطلوب'),
	phone: string().required('هذا الحقل مطلوب'),
	mobilePhone: string().required('هذا الحقل مطلوب'),
});

export const dhlValidationSchema = object().shape({
	info: object().shape({
		description: string().required('هذا الحقل مطلوب'),
		declaredValueCurrency: string().length(3, '3 letter ISO CODE').required('هذا الحقل مطلوب'),
	}),
	customerDetails: object().shape({
		shipperDetails: object().shape({
			postalAddress: postalAddressSchema,
			contactInformation: contactInformationSchema,
		}),
		receiverDetails: object().shape({
			postalAddress: postalAddressSchema,
			contactInformation: contactInformationSchema,
		}),
	}),
});
