export const daysToExpire = (dateString) => {
	if (!dateString || typeof dateString !== 'string' || dateString.length === 0) return 0;
	// convert date coming from databse to date object
	let date;
	// safety check for bad date strings format
	try {
		date = new Date(dateString);
	} catch (error) {
		return 0;
	}
	// get time in milliseconds
	const timestamp = date.getTime();
	// compare difference between current time and expireation date
	const diff = timestamp - Date.now();
	// calculate milliseconds in 1 day
	const oneDay = 1000 * 60 * 60 * 24;

	// divide time difference to get number of days
	const daysRemains = Math.ceil(diff / oneDay);
	return daysRemains;
};
