import FilterIcon from '@mui/icons-material/Tune';
import {Chip, IconButton, Stack, TextField, useMediaQuery, useTheme} from '@mui/material';
import {useState} from 'react';

import useDataSearch from 'src/hooks/useDataSearch';
import useUrlFilters from 'src/hooks/useUrlFilters';
export default function PurchasePageFilter() {
	const [isOpen, setisOpen] = useState(false);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const {filters, setQueryStringValue, removeFilter} = useUrlFilters({ignoreNullOrEmpty: true});

	const {filter: search_value, onSearch} = useDataSearch((value) => {
		setQueryStringValue('search_term', value);
	}, filters.search_term);

	return (
		<Stack>
			<Stack my={1} spacing={2} direction={{sm: 'row', xs: 'column'}}>
				<Stack flex={1} spacing={1} direction="row" justifyContent="space-between">
					<TextField
						label="بحث"
						sx={{minWidth: '50%'}}
						size="medium"
						fullWidth
						placeholder="البحث حسب الوصف او المنتج"
						type="text"
						value={search_value ?? ''}
						onChange={onSearch}
					/>
					{isSmallScreen && (
						<IconButton onClick={() => setisOpen(!isOpen)}>
							<FilterIcon />
						</IconButton>
					)}
				</Stack>
				{(!isSmallScreen || isOpen) && (
					<>
						<Stack spacing={1} direction="row">
							<TextField
								InputLabelProps={{shrink: true}}
								pattern="\d{4}-\d{2}-\d{2}"
								fullWidth
								label="من"
								placeholder="من"
								onChange={(e) => setQueryStringValue('date_gte', e.target.value ?? '')}
								value={filters.date_gte ?? ''}
								type="date"
								required
							/>
							<TextField
								InputLabelProps={{shrink: true}}
								pattern="\d{4}-\d{2}-\d{2}"
								fullWidth
								label="الي"
								placeholder="الي"
								// '2023-02-09T23:59:59.000Z'
								onChange={(e) => setQueryStringValue('date_lte', e.target.value ?? '')}
								value={filters.date_lte ?? ''}
								type="date"
								required
							/>
						</Stack>
					</>
				)}
			</Stack>
			{/* list of filters */}
			<Stack direction="row" spacing={1}>
				{Object.keys(filters).map((filter) => (
					<Chip label={filters[filter]} onDelete={() => setQueryStringValue(filter, '')} />
				))}
			</Stack>

			{/* {!isNullOrEmpty(filters) && `${Object.keys(filters).length} filters applied`} */}
		</Stack>
	);
}
