import {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
// material
import {Button, Card, Container, Stack, Typography} from '@mui/material';
import Page from 'src/components/Page';
import DynamicList from 'src/components/DynamicList';
import {useModal} from 'src/hooks/useModal';
import {DynamicForm} from 'vetrina-ui';
import {nameValidation} from 'src/schema/commanValidationSchema';
import {request} from 'src/utils/AdminApi';
import {toast} from 'react-toastify';
import {useQueryClient} from 'react-query';

export default function BranchesPage({}) {
	const {open, close} = useModal();
	const navigate = useNavigate();
	const [state, setState] = useState();
	const api_route = '/branches';
	useEffect(() => {}, []);

	return (
		<Page title="الفروع">
			<Container maxWidth="md">
				<Card sx={{p: 3}}>
					<Stack spacing={2}>
						<Stack direction="row" justifyContent="space-between">
							<Typography variant="h4">الفروع </Typography>
							<Button
								onClick={() => {
									open({
										title: 'اضافة فرع',
										children: <BranchForm onSuccess={() => close()} />,
									});
								}}
							>
								اضافة
							</Button>
						</Stack>

						<DynamicList
							api_route={api_route}
							RowComponent={({item}) => (
								<Stack justifyContent="space-between" direction="row">
									<Typography>{item.name}</Typography>
									<Button
										onClick={() => {
											open({
												title: 'تعديل فرع',
												children: <BranchForm initialData={item} onSuccess={() => close()} />,
											});
										}}
									>
										تعديل
									</Button>
								</Stack>
							)}
						/>
					</Stack>
				</Card>
			</Container>
		</Page>
	);
}

const BranchForm = ({initialData, onSuccess}) => {
	const isEdit = initialData?.id;
	const queryClient = useQueryClient();

	return (
		<DynamicForm
			submitButtonText={isEdit ? 'تعديل' : 'اضافة'}
			initialValues={initialData}
			config={{
				inputs: [
					{
						name: 'name',
						type: 'text',
						label: 'اسم الفرع',
						validation: nameValidation.required('يرجي ادخال اسم الفرع'),
					},
				],
			}}
			onSubmit={async (formData) => {
				try {
					if (isEdit) await request(`/branches/${initialData.id}`, 'PUT', formData);
					else await request('/branches', 'POST', formData);

					toast.success(isEdit ? 'تم التعديل بنجاح' : 'تم الاضافة بنجاح');
					queryClient.invalidateQueries(['/branches']);

					if (onSuccess) onSuccess();
				} catch (err) {
					toast.error('حدث خطاء غير متوقع');
				}
			}}
		/>
	);
};
