import {createNewFeature, getAllFeatures, toggleVoteForFeature} from '../../../utils/AdminApi.js';

export const featureStatus = {
	suggested: {
		label: 'مقترح',
		backgroundColor: '#DEDEDE',
		textColor: 'black',
		emojy: '💡',
	},
	under_consideration: {
		label: 'قيد الدراسة',
		backgroundColor: '#DDD9E7',
		textColor: 'black',
		emojy: '🤔',
	},
	inProgress: {
		label: 'قيد التنفيد',
		backgroundColor: '#E9C888',
		textColor: 'black',
		emojy: '🚧',
	},
	testing: {
		label: 'قيد الاختبار',
		backgroundColor: '#E59367',
		textColor: 'white',
		emojy: '📐',
	},
	done: {
		label: 'تم الاطلاق',
		backgroundColor: '#4DBD79',
		textColor: 'black',
		emojy: '✅',
	},
};

export const getFeatures = async () => {
	try {
		return await getAllFeatures();
	} catch (err) {
		console.log(err);
	}
};

export const toggleFeatureVote = async (featureId) => {
	try {
		const response = await toggleVoteForFeature(featureId);
		return response;
	} catch (e) {
		console.log(e);
	}
};

export const addNewFeature = async (feature) => {
	return createNewFeature(feature);
};
