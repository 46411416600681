export enum ADMINS_ACTIONS {
  OrdersCreate = 'orders.create',
  OrdersUpdate = 'orders.update',
  OrdersFind = 'orders.find',
  OrdersDelete = 'orders.delete',
  OrdersUpdateStatus = 'orders.updateStatus',
  OrdersFindone = 'orders.findone',
  ProductsCreate = 'products.create',
  ProductsDelete = 'products.delete',
  ProductsUpdate = 'products.update',
  ProductsFind = 'products.find',
  ProductsFindone = 'products.findone',
  OrdersAddDisount = 'orders.addDisount',
  OrdersAddDiscount = 'orders.addDiscount',
  OrdersUpdateAssignee = 'orders.updateAssignee',
  OrdersUpdatePaymentStatus = 'orders.updatePaymentStatus',
  StockTransfersCreate = 'stock-transfers.create',
}