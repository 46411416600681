import * as Yup from 'yup';

const commonValidation = {
	images: Yup.array(),
	addedQuantity: Yup.number().typeError('يجب إدخال رقم').min(1, 'لا يمكن إدخال رقم أقل من 1'),
	cost_per_unit: Yup.number().typeError('يجب إدخال رقم').min(0, 'لا يمكن إدخال رقم أقل من 0'),

	//// old method for adding quantity
	// quantity: Yup.number().when('infinite', (isInfinite, field) => {
	// 	// ignore validation if product has infinite quantity
	// 	if (isInfinite) return field;
	// 	else return field.required('يجب ادخال الكمية').min(0, 'ادخل كمية صحيحة');
	// }),

	// variations: Yup.array().of(
	// 	Yup.object().shape({
	// 		price: Yup.number().required('يجب ادخال السعر').min(0, 'ادخل سعر صحيح'),
	// 		quantity: Yup.number()
	// 			.required('يجب ادخال الكمية')
	// 			.min(0, 'ادخل كمية صحيحة'),
	// 	}),
	// ),

	name: Yup.string().required('يجب ادخال اسم المنتج '),
	price: Yup.number().typeError('ادخل رقم صحيح').min(0, 'يجب ادخال السعر'),
	// weight: Yup.number().typeError('يجب ادخال وزن صحيح بالكيلو').min(0, 'ادخل وزن صحيح'),

	attrs: Yup.array().of(
		Yup.object().shape({
			name: Yup.string().required(),
			value: Yup.string().required(),
		}),
	),
};
export const updateProductSchema = Yup.object().shape({
	...commonValidation,
});

export const addProductSchema = Yup.object().shape({
	...commonValidation,
	images: Yup.array(),
	// weight: Yup.number().typeError('يجب ادخال وزن صحيح بالكيلو').min(0, 'ادخل وزن صحيح'),
	// mainImage: Yup.mixed().required('يجب اضافة صورة'),
	// mainImage: Yup.array().min(1, 'يجب اضافة صورة').required(),
	// variations: Yup.array().of(
	// 	Yup.object().shape({
	// 		price: Yup.number().required('يجب ادخال السعر').min(0, 'ادخل سعر صحيح'),
	// 		quantity: Yup.number()
	// 			.required('يجب ادخال الكمية')
	// 			.min(0, 'ادخل كمية صحيحة'),
	// 	}),
	// ),
});
