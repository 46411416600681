export const getAllDescendingCategoryIds = (categories, category) => {
	const childrens = categories.filter((cat) => cat.parent?.id === category.id);
	// if not children found then return it's id
	// if there's children call the function with each
	if (childrens.length === 0) return [category.id];
	else
		return [
			category.id,
			...childrens.flatMap((child) => getAllDescendingCategoryIds(categories, child)),
		];
};
