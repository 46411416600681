import axios from 'axios';
import {baseURL} from 'src/config';
import {getAdminToken} from '../storage';

const getDefaultAuthConfig = () => {
	return {
		headers: {
			Authorization: `Bearer ${getAdminToken()}`,
		},
	};
};

export const createShippment = (provider, order, extraValues) => {
	return axios.post(`${baseURL}/shipping/${provider}`, {order, extraValues}, getDefaultAuthConfig());
};

export const getShippmentById = (provider, orderId) => {
	return axios.get(`${baseURL}/shipping/${provider}/${orderId}`, getDefaultAuthConfig());
};

export const deleteShippment = (provider, orderId) => {
	return axios.delete(`${baseURL}/shipping/${provider}/${orderId}`, getDefaultAuthConfig());
};

export const getTimelines = (provider, orderId) => {
	return axios.get(`${baseURL}/shipping/${provider}/${orderId}/logs`, getDefaultAuthConfig());
};
