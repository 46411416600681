import * as yup from 'yup';

export const nameValidation = yup.string().required('يرجي ادخال الاسم');

export const requiredEmailValidation = yup
	.string()
	.email('بريد الكتروني غير صحيح')
	.required(' يرجى ادخال البريد الالكتروني ');

export const phoneValidation = yup
	.string()
	.matches(/^[0-9]*$/, 'ادخل رقم هاتف صحيح')
	.min(9, 'ادخل رقم هاتف صحيح')
	.required('يرجي ادخال رقم الهاتف');

export const requiredPasswordValidation = yup.string().required(' يرجى ادخال  كلمة السر  ');

const confirmPasswordValidation = yup
	.string()
	.required('يرجي تاكيد كلمة المرور')
	.test('confirm-password-test', 'كلمة المرور غير متطابقة', function (value) {
		return value === this.parent.password;
	});

export const registerSchema = yup.object({
	name: nameValidation,
	email: requiredEmailValidation,
	phone: phoneValidation,
	password: requiredPasswordValidation,
	confirmPassword: confirmPasswordValidation,
});

export const updateUserSchema = yup.object({
	name: nameValidation,
	email: requiredEmailValidation,
	phone: phoneValidation,
});

export const changePasswordSchema = yup.object({
	oldPassword: yup
		.string()
		// .min(8, 'كلمة السر القديمة غير صحيحة')
		.required(' يرجى ادخال  كلمة السر القديمة '),
	password: yup
		.string()
		// .min(8, 'ادخل كلمة السر اكثر من 8 حروف')
		.required(' يرجى ادخال  كلمة السر الجديدة '),
	confirmPassword: yup
		.string()
		.required('يرجى تأكيد كلمة السر الجديدة ')
		.test('confirm-password-test', 'كلمة السر غير متطابقة', function (value) {
			return value === this.parent.password;
		}),
});

export const forgetPasswordSchema = yup.object({
	email: yup.string().email('ادخل بريد الكتروني صحيح').required('يرجى ادخال البريد الالكتروني '),
});

export const passwordResetSchema = yup.object({
	password: yup.string().required(' يرجى ادخال  كلمة السر الجديدة '),
	confirmPassword: yup
		.string()
		.required('يرجى تأكيد كلمة السر ')
		.test('confirm-password-test', 'كلمة السر غير متطابقة', function (value) {
			return value === this.parent.password;
		}),
});

export const otpVerificationSchema = yup.object({
	code: yup
		.string()
		.min(6, 'رقم التحقق غير صحيح')
		.max(6, 'رقم التحقق غير صحيح')
		.required('يرجى ادخال رقم التحقق '),
});

export const createTenantSchhema = yup.object({
	host: yup
		.string()
		.matches(/^[A-Za-z]*$/, 'يجب ان يتكون الاسم من حروف انجليزية فقط وبدون فواصل')
		.max(30, 'يجب ان لايتجاوز عدد الحروف 30 حرف')
		.required('يرجي ادخال اسم االنطاق الخاص بك '),
	site_name: yup.string().required('يرجي ادخال اسم الموقع'),
	agreement: yup.boolean().isTrue('يجب الموافقة علي شروط الاستخدام').required(),
});

export const updateTenantSchhema = yup.object({
	site_name: yup.string().required('يرجي ادخال اسم الموقع'),
	delivery_price: yup.number().min(0, 'يرجي ادخال سعر صحيح'),
});
