import {APPBAR_DESKTOP, APPBAR_MOBILE} from 'src/constants.js';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import DataList from 'src/components/wrappers/DataList';

export default function NotificationMenu({
	notifications,
	menuShown,
	goToAllNotifications,
	goToNotification,
}) {
	return (
		<>
			{menuShown && (
				<Stack
					position={{xs: 'fixed', md: 'absolute'}}
					borderRadius={{xs: '0 0 12px 12px', md: '12px'}}
					border={'1px solid #e1e4e4'}
					top={{xs: APPBAR_MOBILE, md: '100%'}}
					right={'0'}
					width={{xs: '100vw', md: 450}}
					// height={{xs: '60vh', md: 400}}
					maxHeight={600}
					sx={{
						bgcolor: 'white',
						color: 'black',
						overflow: 'hidden',

						boxShadow: '0 0 2px 0 rgba(145, 158, 171, 0.24), 0 16px 32px -4px rgba(145, 158, 171, 0.24)',
					}}
				>
					<Stack p={2} spacing={2} divider={<Divider flexItem />} sx={{overflowY: 'auto'}}>
						<Typography color="primary" variant="subtitle1">
							الاشعارات
						</Typography>
						<DataList
							noDataMessage="لا يوجد اشعارات"
							data={notifications}
							renderItem={(notification) => {
								return (
									<Stack
										key={notification.id}
										sx={{cursor: 'pointer'}}
										onClick={(e) => goToNotification(e, notification.id, notification.type)}
									>
										<Typography variant={'subtitle2'}>{notification.title}</Typography>
										<Typography variant={'body2'}>{notification.body}</Typography>
									</Stack>
								);
							}}
						/>
					</Stack>
					{/* <Button
						sx={{borderTop: '1px solid #e5e8eb', borderRadius: '0'}}
						onClick={(e) => goToAllNotifications(e)}
					>
						إظهار كل الإشعارات
					</Button> */}
				</Stack>
			)}
		</>
	);
}
