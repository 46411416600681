import {Typography} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {useBranches} from 'src/components/BranchPicker';

export default function ItemBranchName({item}) {
	const {data: branches, isLoading} = useBranches();

	if (!item || !item.branch) return;
	const text = isLoading
		? ''
		: (item.branch?.name ?? branches.find((b) => b.id === item.branch)?.name ?? item.branch);
	return <Typography variant="body2">الفرع: {text}</Typography>;
}
