import {IconButton, Typography} from '@mui/material';
import React from 'react';
import {useSelector} from 'react-redux';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

export default function VisitSite({text = 'عرض المتجر'}) {
	const {host} = useSelector((state) => state.admin.user.OwnedWebsite);

	return (
		<IconButton
			// style={containerStyles}
			onClick={async () => {
				window.open(
					`https://${host}`,
					'_blank', // <- This is what makes it open in a new window.
				);
			}}
		>
			<RemoveRedEyeIcon sx={{opacity: 0.7, mx: 1}} />
			<Typography sx={{fontWeight: 'bold'}} variant="body2">
				{text}
			</Typography>
		</IconButton>
	);
	/**
		return (
			<a
				style={{color: '#02393E', textDecoration: 'none'}}
				target="_blank"
				href={`https://${host}`}
				rel="noreferrer"
			>
				{text}
			</a>
		);
	*/
}
