import {Box, Drawer, Stack} from '@mui/material';
// material
import {experimentalStyled as styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import {DRAWER_WIDTH} from 'src/constants';
import {version} from 'src/settings';
import {MHidden} from '../../components/@material-extend';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/ui/NavSection';
//
import sidebarConfig from './SidebarConfig';
import preval from 'preval.macro';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({theme}) => ({
	[theme.breakpoints.up('lg')]: {
		flexShrink: 0,
		width: DRAWER_WIDTH,
	},
}));
// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
	isOpenSidebar: PropTypes.bool,
	onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({isOpenSidebar, onCloseSidebar}) {
	const admin = useSelector((s) => s.admin.user);
	const {pathname} = useLocation();
	useEffect(() => {
		if (isOpenSidebar) {
			onCloseSidebar();
		}
	}, [pathname]);

	const renderContent = (
		<Scrollbar
			sx={{
				height: '100%',
				'& .simplebar-content': {
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
				},
			}}
		>
			<Box sx={{px: 2.5, py: 3}}>
				<Box
					component={RouterLink}
					to="/"
					sx={{
						display: 'inline-flex',
						textDecoration: 'none',
						width: '100%',
						justifyContent: 'center',
					}}
				>
					<Logo sx={{textAlign: 'center'}} />
				</Box>
			</Box>
			<NavSection navConfig={sidebarConfig(admin)} />
			<Stack sx={{flexGrow: 1, mt: 4, justifyContent: 'center'}} />
			{/* <Stack sx={{p: 2}} alignItems="center"></Stack>

<Stack sx={{flexGrow: 1, mt: 4, justifyContent: 'center'}}>
<VisitSite />
<LogoutButton />
			</Stack> */}
			<Stack
				sx={{
					bottom: 0,
					// bgcolor: '#5C5E65',
					borderTop: '0.5px solid #575961',
					opacity: 0.6,
					width: '100%',
					padding: 1,
					justifyContent: 'center',
				}}
			>
				<a
					style={{color: '#575961', textAlign: 'center', textDecoration: 'none'}}
					target="_blank"
					href="https://vetrinas.ly/terms.html"
					rel="noreferrer"
				>
					شروط و سياسة الاستخدام
				</a>
				<Stack alignItems="center" justifyContent="center">
					{/* {new Date().toISOString} */}
					{preval`module.exports =  new Date().toLocaleString('ar-AE', {
												year: 'numeric',
												month: '2-digit',
												day: '2-digit',
												hour: '2-digit',
												minute: '2-digit',
												hour12: false, // Set to true if you want to use 12-hour format without AM/PM
											})`}
				</Stack>
			</Stack>
		</Scrollbar>
	);

	return (
		<RootStyle>
			{/* for mobile screens */}
			<MHidden width="lgUp">
				<Drawer
					anchor="left"
					open={isOpenSidebar}
					onClose={onCloseSidebar}
					PaperProps={{
						sx: {width: DRAWER_WIDTH},
					}}
				>
					{renderContent}
				</Drawer>
			</MHidden>

			{/* for large screens */}
			<MHidden width="lgDown">
				<Drawer
					anchor="left"
					open
					variant="persistent"
					PaperProps={{
						sx: {
							width: DRAWER_WIDTH,
							bgcolor: 'background.default',
						},
					}}
				>
					{renderContent}
				</Drawer>
			</MHidden>
		</RootStyle>
	);
}
