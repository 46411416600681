const serverKnownErrors = {
	401: 'ليس لديك صلاحية للقيام بهذه العملية',
	403: 'ليس لديك صلاحية للقيام بهذه العملية',
	// 404 ignored because way may customize the message
	405: 'method not allowed',
	408: 'request timeout',
	413: 'حجم البيانات المرسلة اكبر من الحد المسموح به في السيرفر',
	429: 'لقد قمت بعدد كبير من المحاولات، يرجي المحاولة في وقت لاحق',
	// server errors
	500: 'حدث خطا في السيرفر',
	503: 'تعذر الاتصال بالسيرفر',
	504: 'connection timeout',
};

export default serverKnownErrors;
