/* eslint-disable no-unreachable */
import {Button, Card, Checkbox, Container, Divider, Stack, Typography} from '@mui/material';
import {useState} from 'react';
import {useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import CreatableSelect from 'src/components/inputs/CreatableSelect';
import Picker from 'src/components/inputs/Picker';
import MuiLink from 'src/components/ui/StyledLink';
import {useDebounce} from 'src/hooks/useDebounce';
import {LoadingView} from 'src/routes';
import {exchangeOrderItems, getOrder, getProducts} from 'src/utils/AdminApi';
import {createListFromRange} from 'src/utils/array';
import {getCartItemsTotalPrice} from 'src/utils/cartItemUtils';
import {calcPriceAfterDiscount} from 'src/utils/discountUtils';
import filterDeleted from 'src/utils/filterDeleted';
import {handleRequestError, isNullOrEmpty} from 'src/utils/helperMethods';
import {getNumber, sumBy} from 'src/utils/numberUtils';
import {filterObject} from 'src/utils/objectUtils';
import {buildOrderItem, getItemTotalPrice} from 'src/utils/orderItemUtils';
import {getImageSrc} from 'src/utils/productUtils';
import {getSiteCurrency} from 'src/utils/selectors';
import CartItemSelector from './CartItemSelector';
import useOrderItemsSelection from './NewAddOrderPage/useOrderItemsSelection';
import ItemBranchName from 'src/components/ItemBranchName';

const getPriceDiff = (addedProducts, itemsToRemove) => {
	return (
		getCartItemsTotalPrice(addedProducts) -
		sumBy(Object.values(itemsToRemove), (i) =>
			getItemTotalPrice(i.price, i.discount, getNumber(i.selectedQuantity, 1)),
		)
	);
};
export default function ExchangeOrderItems() {
	let {id} = useParams();
	let navigate = useNavigate();
	const [orderItems, setItems] = useState([]);
	//  by default we want to show one input to add product by default
	const [itemsToRemove, setItemsToRemove] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);

	const {
		handleItemQuantityChange,
		checkAddedItemExists,
		handleAddItem,
		handleRemoveItem,
		addedProducts,
		resetSelections,
		handleItemChange,
	} = useOrderItemsSelection();

	const {data: order, isLoading} = useQuery(['order', id], () => getOrder(id), {
		enabled: !!id,
		refetchOnWindowFocus: false,
		// we need local state to update items after changes are saved
		// we need to filter soft deleted items
		onSuccess: (data) => setItems(filterDeleted(data.items)),
	});

	const handleExchange = async () => {
		setIsSubmitting(true);
		try {
			const res = await exchangeOrderItems(
				order.id,
				Object.values(itemsToRemove),
				addedProducts.map(buildOrderItem),
			);
			toast.success('تمت عملية الاستبدال بنجاح');
			navigate(`/orders/${order.uuid}`);
		} catch (err) {
			handleRequestError(err, (status, body) => {
				if (status === 406) {
					return toast.error('لقد تجاوزت الكمية المتاحة');
				}
				toast.error(body?.message ?? 'حدث خطأ غير متوقع');
			});
			setIsSubmitting(false);
		}
	};

	if (isLoading) return <LoadingView />;

	return (
		<Container maxWidth="md">
			<Card sx={{p: 2}}>
				<Stack spacing={2}>
					<Typography variant="h3">تبديل</Typography>

					<Typography variant="subtitle2">تحديد المنتجات المراد استبدالها</Typography>

					<Stack spacing={1}>
						{orderItems.map((item) => {
							const shouldBeRemoved = itemsToRemove[item.id] != null ?? false;
							const unit_price = calcPriceAfterDiscount(item.price, item.discount);
							const total = unit_price * item.quantity;
							return (
								<Card key={item.id} sx={{p: 1}}>
									<Stack
										alignItems={{xs: 'default', sm: 'center'}}
										direction={{xs: 'column', sm: 'row'}}
										justifyContent="space-between"
									>
										{/* metadata */}
										<Stack spacing={2} alignItems="center" direction="row">
											<Checkbox
												color="error"
												checked={shouldBeRemoved}
												onChange={(e) => {
													let checked = e.target.checked;
													if (checked) setItemsToRemove({...itemsToRemove, [item.id]: item});
													else setItemsToRemove(filterObject(itemsToRemove, item.id));
												}}
											/>
											<img
												loading="lazy"
												alt={item.product.name}
												width={50}
												height={50}
												src={getImageSrc(item.product)}
											/>
											<Stack>
												<MuiLink to={`/products/${item.product.id}`}>
													<Typography>
														{item.product.name} {item.variation?.name && `- ${item.variation?.name}`}
													</Typography>
												</MuiLink>

												<Typography> العدد: {item.quantity}</Typography>
												<Stack spacing={3} direction="row">
													<Typography>
														{total}
														{getSiteCurrency()}
													</Typography>
													<ItemBranchName item={item} />
												</Stack>
											</Stack>
										</Stack>
										{/* in case if there's more than 1 item we need to let user select how many piece to change */}
										{item.quantity > 1 && shouldBeRemoved && (
											<Picker
												sx={{minWidth: 100}}
												label="عدد القطع"
												value={getNumber(itemsToRemove[item.id].selectedQuantity, 1)}
												onChange={(e) =>
													setItemsToRemove({
														...itemsToRemove,
														[item.id]: {...item, selectedQuantity: getNumber(e.target.value, 1)},
													})
												}
												options={createListFromRange(1, item.quantity)}
											/>
										)}

										{/* <Typography>{item.variation ? item.variation.quantity : item.product.quantity}</Typography> */}
									</Stack>
								</Card>
							);
						})}
					</Stack>

					{!isNullOrEmpty(itemsToRemove) && (
						<>
							<Divider />

							<CartItemSelector
								title="المنتجات البديلة"
								handleItemQuantityChange={handleItemQuantityChange}
								checkAddedItemExists={checkAddedItemExists}
								handleAddItem={handleAddItem}
								handleRemoveItem={handleRemoveItem}
								addedProducts={addedProducts}
								resetSelections={resetSelections}
								handleItemChange={handleItemChange}
							/>
						</>
					)}
				</Stack>
			</Card>
			{!isNullOrEmpty(itemsToRemove) && !isNullOrEmpty(addedProducts) && (
				<Card sx={{p: 2, mt: 2}}>
					<Typography>القيمة السابقة: {order.total}</Typography>
					<Typography>
						القيمة الجديدة: {order.total + getPriceDiff(addedProducts, itemsToRemove)}
					</Typography>
					<Typography fontWeight="bold">الفرق: {getPriceDiff(addedProducts, itemsToRemove)}</Typography>
					<Button sx={{mt: 3}} disabled={isSubmitting} variant="contained" onClick={handleExchange}>
						تأكيد العملية
					</Button>
				</Card>
			)}
		</Container>
	);
}
