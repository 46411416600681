import {CircularProgress, Select, Stack, Typography} from '@mui/material';
import React, {useState} from 'react';
import {orderStatusOptions} from 'src/utils/orderUtils';
import Picker from 'src/components/inputs/Picker';
import {canAccess} from 'src/utils/permissions';

export default function OrderStatusPicker({
	defaultStatus,
	size = 'small',
	options = orderStatusOptions,
	status,
	onStatusChange,
	sx = {},
	forUpdate = false,
}) {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const show_all = !forUpdate || canAccess();
	const status_list = show_all ? options : getAllowedStatus(options, status);
	return (
		<Picker
			disabled={isSubmitting}
			defaultValue={defaultStatus}
			sx={sx}
			size={size}
			value={status}
			onChange={async (...args) => {
				if (!forUpdate) return onStatusChange(...args);
				setIsSubmitting(true);
				try {
					await onStatusChange(...args);
				} finally {
					setIsSubmitting(false);
				}
			}}
			options={status_list}
			labelKey="label"
			valueKey="key"
			label="حالة الطلب"
			loading={isSubmitting}
		/>
	);
}

const getAllowedStatus = (statusList = [], currentStatus) => {
	const index = statusList.findIndex((status) => status.key === currentStatus);
	return statusList.slice(index);
};
