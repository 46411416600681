import {createContext, useContext, useState} from 'react';
import BasicModal from 'src/components/modals/BasicModal';
const defaultValue = {
	isVisible: false,
	modalProps: {
		title: null,
		children: null,
	},
	open: () => {},
	close: () => {},
};
const ModalContext = createContext(defaultValue);

const ModalProvider = ({children}) => {
	const [value, setValue] = useState(defaultValue);

	return (
		<ModalContext.Provider
			value={{
				value,
				setValue,
				open: (modelProps) =>
					setValue((v) => ({...v, isVisible: true, modalProps: modelProps ?? defaultValue.modalProps})),
				close: () => setValue((v) => ({...v, isVisible: false, modalProps: defaultValue.modalProps})),
			}}
		>
			{children}

			<BasicModal
				title={value.modalProps.title}
				isVisible={value.isVisible}
				setVisibility={(bool) => {
					setValue((v) => ({...v, isVisible: bool}));
				}}
				children={value.modalProps.children}
			/>
		</ModalContext.Provider>
	);
};

const useModal = () => {
	const model = useContext(ModalContext);

	return {
		open: model.open,
		close: model.close,
		renderButton: (buttonText, modelProps) => {
			return (
				<button
					type="button"
					onClick={() => {
						model.open(modelProps);
					}}
				>
					{buttonText}
				</button>
			);
		},
	};
};

export {ModalContext as Modal, ModalProvider, useModal};
