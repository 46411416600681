import {Switch} from '@mui/material';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {updateTenantConfigAction} from 'src/reducers';

export default function ShippingProviderStateSwitch({provider}) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {id, config} = useSelector((s) => s.admin.user.OwnedWebsite) ?? {};
	const isEnabled = config?.shippingProviders?.[provider]?.enabled === true;
	const handleChange = (_, newValue) => {
		/* 
            scenarios
            1. first time to use: 
            shippingProviders will undefined or shippingProviders[provider] will be undefined
            redirect him to shipping provider settings page
            2. else
            shippingProviders[provider] will be set
            just update the enabled state by the new state
        */
		// scenario 1
		if (!config?.shippingProviders?.[provider]) return navigate(`/shipping-methods/${provider}`);
		// scenario 2
		config.shippingProviders[provider].enabled = newValue;
		dispatch(updateTenantConfigAction(id, config));
	};
	return <Switch size="small" checked={isEnabled} onChange={handleChange} />;
}
