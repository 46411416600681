import {Button, Container, Stack, TextField, Typography} from '@mui/material';
import {useInfiniteQuery, useQuery} from 'react-query';
import AccessControlWrapper from 'src/components/AccessControlWrapper';
import Page from 'src/components/Page';
import useVisible from 'src/hooks/useVisible';
import {getPurchases} from 'src/utils/AdminApi';
import PurchaseCreateForm from './PurchaseCreateForm';
import PurchaseListItemView from './PurchaseItem';
import useDataSearch from 'src/hooks/useDataSearch';
import {stringify} from 'qs';
import useUrlFilters from 'src/hooks/useUrlFilters';
import PurchasePageFilter from './PurchasePageFilter';
import {toast} from 'react-toastify';

const limit = 20;

export default function PurchasesPage() {
	const {filters} = useUrlFilters();

	const {data, refetch, fetchNextPage, hasNextPage} = useInfiniteQuery(
		['purchases', filters],
		({pageParam = 0}) => {
			const {search_term, ...otherFilters} = filters;
			const qs = stringify(
				{
					_sort: 'id:desc',
					_start: pageParam * limit,
					_limit: limit,
					_where: {
						_or: [{'items.product.name_contains': search_term}, {description_contains: search_term}],
					},
					...otherFilters,
				},
				{
					encode: false,
				},
			);

			return getPurchases(`?${qs}`);
		},
		{
			refetchOnWindowFocus: false,
			getNextPageParam: (lastPage, allPages) =>
				lastPage.length < limit ? undefined : allPages.length,
		},
	);

	const purchases = Array.isArray(data?.pages) ? data.pages.flat() : [];

	const {isVisible: createFormIsVisible, toggle: toggleCreateform} = useVisible();

	const onCreate = () => {
		refetch();
		toggleCreateform();
		toast.success('تمت الاضافة بنجاح');
	};
	return (
		<Page title="المشتريات">
			<AccessControlWrapper>
				<Container>
					<Stack spacing={2}>
						<Stack direction="row" justifyContent="space-between">
							<Typography variant="h4">المشتريات</Typography>
							<Button onClick={toggleCreateform}>{createFormIsVisible ? 'تراجع' : 'اضافة مشتريات'}</Button>
						</Stack>
						{createFormIsVisible && <PurchaseCreateForm onCreate={onCreate} />}
						<PurchasePageFilter />
						<hr />
						{purchases.map((purchaseItem) => {
							return (
								<PurchaseListItemView onDelete={refetch} key={purchaseItem.id} purchase={purchaseItem} />
							);
						})}
						{hasNextPage && (
							<Button sx={{mt: 1}} fullWidth onClick={() => fetchNextPage()} variant={'text'}>
								عرض المزيد
							</Button>
						)}
					</Stack>
				</Container>
			</AccessControlWrapper>
		</Page>
	);
}
