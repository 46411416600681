import {useState} from 'react';
import {mapToObject} from 'src/utils/array';
import {isBetween} from 'src/utils/numberUtils';
import {filterObject} from 'src/utils/objectUtils';
/**
 *
 * @param {{initialFormValues:object,data:Array,key:string}} config
 * @returns
 */
export default function useBulkActions(config) {
	const {data = [], initialFormValues, key = 'id'} = config ?? {};
	// states
	const [selections, setSelections] = useState({});
	const [bulkInputs, setBulkInputs] = useState(initialFormValues);
	// helper variables
	const numberOfSelectedItems = Object.keys(selections).length;
	const allAreSelected = numberOfSelectedItems === data.length;
	const someAreSelected = isBetween(numberOfSelectedItems, 1, data.length - 1);
	// helper methods
	const reset = () => {
		setSelections({});
		setBulkInputs(initialFormValues);
	};

	const selectAll = () => {
		let _selections = mapToObject(data, (p) => ({[p[key]]: p}));
		setSelections(_selections);
	};

	// useful ui props
	const getHandleCheck = (item) => (e) => {
		if (e.target.checked) setSelections({...selections, [item[key]]: item});
		else setSelections(filterObject(selections, item[key]));
	};

	const getMainCheckboxProps = () => {
		return {
			checked: allAreSelected,
			indeterminate: someAreSelected,
			onChange: (e) => {
				if (e.target.checked === false) {
					setSelections({});
				} else {
					selectAll();
				}
			},
		};
	};

	return {
		selections,
		setSelections,
		bulkInputs,
		setBulkInputs,
		reset,
		getHandleCheck,
		getMainCheckboxProps,
		allAreSelected,
		selectAll,
	};
}
