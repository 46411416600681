import {isNullOrEmpty} from './helperMethods';

// getCountString(1,'صفحة','صفحتان','صفحات') => صفحة واحدة
export const getCountString = (number, single, two, plural, singleFlag) => {
	if (typeof number !== 'number') return '';
	if (number === 0) return '0';
	else if (number === 1) return singleFlag ? singleFlag : number;
	else if (number === 2) return two;
	else if (number < 11) return number + ` ${plural} `;
	else return number + ' ' + single;
};
// export const getBasicCountString = (number,singleWord='',singleFlag=true) => {
//     getCountString(number,singleWord,singleWord.substring(0,singleWord.length-1).concat('تان'),singleWord.substring(0,singleWord.length-1).concat('ات'),singleFlag)
// }
export const getBasicCountString = (number, pluralWord = '', singleFlag = true) => {
	return getCountString(
		number,
		pluralWord.concat('ة'),
		pluralWord.concat('تان'),
		pluralWord,
		singleFlag,
	);
};

export const randomString = (length = 4) => {
	let chars = 'abcdefghijklmnopqrstuvwxyz';
	let string = '';
	for (let ii = 0; ii < length; ii++) {
		string += chars[Math.floor(Math.random() * chars.length)];
	}

	return string;
};

export const randomEmail = () => {
	return randomString(7) + '@faqraou.com';
};

export const isArabicText = (text) => {
	const regex = /[\u0600-\u06FF\u0750-\u077F]/;
	return regex.test(text);
};
export const containSpeicalCharacters = (text) => {
	const regex = /[!؟ـ،./؛١٢٣٤٥٦٧٨٩٠1234567890@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
	return regex.test(text);
};

export const getFormattedPhoneNumber = (phone, withCountryCode = false, countryCode = '+218') => {
	if (isNullOrEmpty(phone)) return '';
	// remove leading zero
	if (withCountryCode) {
		return `${countryCode}${phone.replace(/^0+/, '')}`;
	} else if (phone[0] === '0') return phone;
	else return `0${phone}`;
};

export function uuidv4() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
		(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
	);
}

export const getString = (str, fallback = '') => {
	if (str == null || typeof str === 'object') return fallback;
	// we wrap with `` incase it's number it will be string, so we always get stirng back
	return `${str}`;
};
/* 
	use case
	[shirt, green],- => product-green
	[shirt, null],- => shirt
*/
export const concatValidStrings = (strings, seperator = '') => {
	const validStrings = strings.filter((s) => !(s == null || typeof s === 'object'));
	return validStrings.join(seperator);
};

export const normalizeArabicText = (text) => {
	// Remove diacritics
	const diacritics = /[\u064B-\u0652]/g;
	text = text.replace(diacritics, '');

	// Normalize hamza variations
	const hamza = {
		أ: 'ا',
		إ: 'ا',
		آ: 'ا',
	};
	text = text
		.split('')
		.map((char) => hamza[char] || char)
		.join('');

	return text;
};
