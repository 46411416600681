import PropTypes from 'prop-types';
import {useMemo} from 'react';
// material
import {CssBaseline} from '@mui/material';
import {
	ThemeProvider,
	createTheme,
	StyledEngineProvider,
} from '@mui/material/styles';
//
import shape from './shape';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
// import GlobalStyles from './globalStyles';
import componentsOverride from './overrides';
import shadows, {customShadows} from './shadows';
// ----------------------------------------------------------------------
// we need it for data-grid
import {arEG} from '@mui/material/locale';

ThemeConfig.propTypes = {
	children: PropTypes.node,
};

export default function ThemeConfig({children}) {
	const themeOptions = useMemo(
		() => ({
			palette,
			shape,
			typography,
			breakpoints,
			shadows,
			customShadows,
			direction: 'rtl',
		}),
		[],
	);

	const theme = createTheme(themeOptions, arEG);
	theme.components = componentsOverride(theme);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{/* <GlobalStyles /> */}
				{/* <TextField
            fullWidth
            autoComplete="البريد الالكتروني"
            type="email"
            label="البريد الالكتروني"
   
          /> */}
				{children}
			</ThemeProvider>
		</StyledEngineProvider>
	);
}
