import {Container, Grid, Stack, Typography} from '@mui/material';
import React from 'react';
import IntegrationItem from './IntegrationItem';
import thirdPartyApps from './thirdPartyApps';
import DeliveryProvidersList from '../DeliveryMethods/DeliveryProviders.list';

export default function ListApps() {
	return (
		<Container>
			<Stack spacing={2}>
				<Typography variant="h4">التطبيقات</Typography>
				{/* descrippiton : this section about third party apps to integrate with */}
				{/* Printer co */}
				<Grid container spacing={2}>
					<Grid item xs={6} sm={4} md={3}>
						<IntegrationItem app={thirdPartyApps.fb_pixel} />
					</Grid>
					<Grid item xs={6} sm={4} md={3}>
						<IntegrationItem app={thirdPartyApps.fb_chat} />
					</Grid>
					<Grid item xs={6} sm={4} md={3}>
						<IntegrationItem app={thirdPartyApps.printerco} />
					</Grid>
					<Grid item xs={6} sm={4} md={3}>
						<IntegrationItem app={thirdPartyApps.google_analytics} />
					</Grid>
				</Grid>
				<Typography mt={1} variant="h4">
					الربط مع شركات الشحن
				</Typography>
				<DeliveryProvidersList />
			</Stack>

			{/* facebook pixel */}
			{/* facebook chat */}

			{/* links to delivery method, payment method */}
		</Container>
	);
}
