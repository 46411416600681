import {Badge, Divider, List, ListItemText, Stack, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {styled} from '@mui/system';
import React, {useState} from 'react';
import {featureStatus} from './features.utils';

const StyledBadge = styled(Badge)(() => ({
	'&:hover': {
		cursor: 'pointer',
	},
}));

function FeatureDetails({showForm, setShowForm, data, votesData}) {
	const [showEditForm, setShowEditForm] = useState(false);
	const handleClose = () => {
		setShowForm(false);
	};

	return (
		<Dialog open={showForm} onClose={handleClose}>
			<DialogContent sx={{minWidth: 450, xs: 'none'}}>
				<List sx={{height: '100%'}}>
					<Stack>
						<Stack direction="row" maxWidth>
							<Typography variant="h4" sx={{flexGrow: 1}}>
								{data.title}
							</Typography>
						</Stack>
						<Typography>{data.proposer}</Typography>
					</Stack>
					<Divider />
					<Typography sx={{mt: 1}}>
						الحالة{': '}
						{featureStatus[data.status].label}
					</Typography>
					<Divider />

					<ListItemText sx={{mt: 2}} primary={data.discreption} />
					<Divider />

					<Stack sx={{mt: 2}} direction="row">
						<ListItemText sx={{flexGrow: 0.05}} primary={`${votesData.votesCount} صوت`} />
						<StyledBadge
							onClick={votesData.voteUpHandler}
							disabled={votesData.hasVoted}
							variant="outlined"
						>
							<svg
								width="21"
								height="21"
								viewBox="0 0 21 21"
								fill="#ffffff"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M11.2954 2.51864C10.9854 2.13221 10.3346 2.13221 10.0247 2.51864L3.51646 10.6539C3.42098 10.7737 3.36121 10.9179 3.34402 11.0701C3.32682 11.2223 3.35289 11.3763 3.41924 11.5143C3.48558 11.6524 3.58952 11.7689 3.7191 11.8505C3.84868 11.9322 3.99866 11.9756 4.15182 11.9759H7.40593V17.6706C7.40593 17.8863 7.49164 18.0933 7.64421 18.2458C7.79677 18.3984 8.0037 18.4841 8.21946 18.4841H13.1006C13.3164 18.4841 13.5233 18.3984 13.6759 18.2458C13.8284 18.0933 13.9141 17.8863 13.9141 17.6706V11.9759H17.1683C17.3214 11.9756 17.4714 11.9322 17.601 11.8505C17.7306 11.7689 17.8345 11.6524 17.9008 11.5143C17.9672 11.3763 17.9933 11.2223 17.9761 11.0701C17.9589 10.9179 17.8991 10.7737 17.8036 10.6539L"
									fill={'#02393E'}
									stroke={'#02393E'}
								/>
								<path
									fill={votesData.hasVoted ? '#02393E' : 'white'}
									d="M11.2954 2.51864ZM13.1006 10.3488H12.2871V16.857H9.03298V10.3488H5.84477L10.66 4.32955L15.4753 10.3488H13.1006Z"
								/>
							</svg>
						</StyledBadge>
					</Stack>
				</List>
			</DialogContent>
			<DialogActions>
				<Button fullWidth onClick={handleClose} variant="contained" size="medium">
					إغلاق
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default FeatureDetails;
