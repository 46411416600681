import {Alert, Box, Card, Container, Stack, Typography} from '@mui/material';
import React from 'react';
import {useParams} from 'react-router-dom';
import DynamicForm from 'src/components/DynamicForm/DynamicForm';
import thirdPartyApps from './thirdPartyApps';
import {useDispatch, useSelector} from 'react-redux';
import {updateTenantConfigAction} from 'src/reducers';
import {toast} from 'react-toastify';

export default function ShowApps() {
	const {id: appId} = useParams();
	let app = thirdPartyApps[appId];
	const {name, bgcolor, iconPath, darkIconPath, customSettingsPageLink} = app;
	const imgSrc = darkIconPath ?? iconPath;

	const dispatch = useDispatch();
	const site = useSelector((s) => s.admin.user.OwnedWebsite);
	// old user settings
	const appSavedConfig = site?.config?.[appId];

	// auto fill form values with old settings
	const settingsFormConfig = {
		...app.formConfig,
		inputs: app.formConfig.inputs.map((input) => {
			return {
				...input,
				value: appSavedConfig?.[input.name] ?? '',
			};
		}),
	};

	const onSubmit = (values, a) => {
		// redux work , save in database

		toast.promise(
			dispatch(
				updateTenantConfigAction(site.id, {
					...site.config,
					[appId]: values,
				}),
			),
			{
				error: 'حدث خطا غير متوقع',
				success: 'تم التحديث بنجاح',
				pending: 'يرجي الانتظار',
			},
		);
	};

	return (
		<Container>
			<Card sx={{p: 4, maxWidth: 500, margin: 'auto'}}>
				<Stack py={1} alignItems="center">
					<img src={imgSrc} height={80} />
					<Typography variant="h4">اعدادات الربط </Typography>
				</Stack>
				<Stack>
					<DynamicForm submitButtonText="تأكيد" config={settingsFormConfig} onSubmit={onSubmit} />
				</Stack>

				{false && (
					<Alert sx={{fontSize: 18, mt: 2}} severity="error">
						{'errorMsg'}
					</Alert>
				)}
			</Card>
		</Container>
	);
}

/* 
    every app has: id, icon, name, user settings (apiKey,password....)
    must be saved in database so that we can use them when needed 
    (example using data related to shipping company account to send order )
    
    form inputs are different 

    different way of storing data

*/
