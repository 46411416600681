// Create rtl cache
import createCache from '@emotion/cache';
import {CacheProvider} from '@emotion/react';
import 'react-toastify/dist/ReactToastify.css';
import rtlPlugin from 'stylis-plugin-rtl';

const cacheRtl = createCache({
	key: 'muirtl',
	stylisPlugins: [rtlPlugin],
});

export default function RTL({children}) {
	return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
}
