import {getProducts} from 'src/utils/AdminApi';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import {getApiSearchQueryString} from './productSearch';

const SIZE_OF_SLICED_BARCODE = 0.6; // 60%, trim last 40%
export const getSlicedBarcode = (barcode = '') =>
	barcode.substring(0, Math.round(SIZE_OF_SLICED_BARCODE * barcode.length));

export const onBarcodeDetected = async (barcode = '', handleScanResult) => {
	if (barcode) {
		// becuase some products may have the same numbers but differ just in the last part
		// so we decided to take only the first 60% part of the string not all of it
		const products = await getProducts(`?${getApiSearchQueryString(barcode)}`);
		if (!isNullOrEmpty(products)) {
			handleScanResult(products, barcode);
		} else {
			// we couldn't find any product
			handleScanResult(null, barcode);
		}
	}
};
