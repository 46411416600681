import serverKnownErrors from './serverKnownErrors';

export const updateArray = (array, updates, key) => {
	const newArray = [];
	for (let i = 0; i < array.length; i++) {
		if (array[i][key] === updates[key]) {
			newArray.push(updates);
		} else {
			newArray.push(array[i]);
		}
	}
	return newArray;
};

export const updateById = (array, updates, id) => {
	const newArray = [];
	for (let i = 0; i < array.length; i++) {
		if (array[i].id === id) {
			newArray.push({...array[i], ...updates});
		} else {
			newArray.push(array[i]);
		}
	}
	return newArray;
};

export const updateWhere = (array, updates, fn) => {
	const newArray = [];
	for (let i = 0; i < array.length; i++) {
		if (fn(array[i])) {
			newArray.push({...array[i], ...updates});
		} else {
			newArray.push(array[i]);
		}
	}
	return newArray;
};

export const complexUpdate = (array, updateFn, fn) => {
	const newArray = [];
	for (let i = 0; i < array.length; i++) {
		if (fn(array[i])) {
			newArray.push(updateFn(array[i]));
		} else {
			newArray.push(array[i]);
		}
	}
	return newArray;
};

export function uuidv4() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
		(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
	);
}
// this will get the error object and return string
export const formatStrapiError = (errorResponseBody) => {
	// if error is string this mean error already handled by defult formatter ( ex: no connection )
	if (typeof errorResponseBody === 'string') return errorResponseBody;
	if (typeof errorResponseBody === 'object') {
		const {statusCode, message} = errorResponseBody;

		if (statusCode === 429) {
			return 'لقد قمت بعدد كبير من المحاولات، يرجي المحاولة في وقت لاحق';
		}
		if (statusCode === 413) {
			return 'حجم البيانات كبير';
		}

		if (statusCode === 401 || statusCode === 403) return 'ليس لديك صلاحية للقيام بهذه العملية';

		if (statusCode >= 500) return 'حدث خطا غير متوقع';

		const errors = [];
		if (message) {
			if (Array.isArray(message)) {
				message.forEach((m) => m.messages?.forEach((e) => errors.push(e)));
			} else if (typeof message === 'string') {
				return message;
			} else {
				// object error
				errors.push(message);
			}
		}
		// translate generic errors , TODO - should be done on backend
		for (let i = 0; i < errors.length; i++) {
			if (errors[i].id === 'Auth.form.error.user.not-exist') {
				errors[i].message = 'هذا البريد الالكتروني غير مسجل من قبل';
			} else if (errors[i].id === 'Auth.form.error.email.format') {
				errors[i].message = 'يرجي ادخال بريد الكتروني صحيح';
			} else if (errors[i].id === 'Auth.form.error.code.provide') {
				errors[i].message = 'انتهت صلاحية رابط تغير كلمة المرور';
			} else if (errors[i].id === 'Auth.form.error.email.taken') {
				errors[i].message = 'هذا البريد الالكتروني مستخدم من قبل';
			} else if (errors[i].id === 'Auth.form.error.username.taken') {
				errors[i].message = 'هذا البريد الالكتروني مستخدم من قبل';
			}
		}
		// map all errors in one string
		const errorsString = errors.map((e) => e.message).join('\n');
		return errorsString;
	}
};

// work with strings and arrays
export const isNullOrEmpty = (param) => {
	// null check will work the same way for different types
	if (param == null) return true;

	if (typeof param === 'string' || Array.isArray(param)) {
		return param.length === 0;
	}

	if (typeof param === 'number' || typeof param === 'bigint') return false;

	return Object.keys(param).length === 0;
};

export const length = (param) => {
	if (param == null) return 0;

	if (typeof param === 'string' || Array.isArray(param)) {
		return param.length;
	}

	return Object.keys(param).length;
};

export const mapObjectToArray = (object, fn) => {
	return Object.keys(object).map((key) => {
		if (fn) return fn(key, object[key]);
		else return object[key];
	});
};

export const getCountString = (number, single, two, plural, singleFlag = true) => {
	if (typeof number !== 'number') return '';
	if (number === 0) return '0';
	else if (number === 1) return single + (singleFlag ? ` واحد ` : '');
	else if (number === 2) return two;
	else if (number < 11) return number + ` ${plural} `;
	else return number + ' ' + single;
};

export const getCountStringCustom = (number, single, two, plural, zero = '0') => {
	if (typeof number !== 'number') return '';
	if (number === 0) return zero;
	else if (number === 1) return single;
	else if (number === 2) return two;
	else if (number < 11) return number + ` ${plural} `;
	else return number + ' ' + single;
};

export const getCountString2 = (number, single, plural) => {
	if (typeof number !== 'number') return `0 ${single}`;
	if (number === 0) return `0 ${single}`;
	else if (number === 1) return `1 ${single}`;
	else if (number === 2) return `2 ${single}`;
	else if (number < 11) return `${number} ${plural}`;
	else return `${number} ${single}`;
};

const persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
	arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
export const fixPositiveNumbers = function (str) {
	if (typeof str === 'string') {
		for (var i = 0; i < 10; i++) {
			str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
		}
	}
	if (Number(str) < 0) return '0';
	return str;
};
export const fixNumbers = function (str) {
	if (typeof str === 'string') {
		for (var i = 0; i < 10; i++) {
			str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
		}
	}
	return str;
};

export const getPriceString = (price, currency = 'دينار') => `${price} ${currency}`;

/**
 * Requirements
 * handle common errors
 * @param error AxiosError
 * @param customHandler function take the response body and status to handle the error
 * ( use case: display error below email input when server respond with 'email is used' )
 * @returns {string} represent error message displayed to user
 */
export const handleRequestError = (err, customHandler) => {
	// Request made and server responded with error message
	if (err.response) {
		// response status, data: response body
		const {status, data: body} = err.response;
		// check if it's comman error
		if (serverKnownErrors[status]) return serverKnownErrors[status];
		// use custom handler if passed
		if (customHandler && typeof customHandler === 'function') return customHandler(status, body);

		// we will reach here if not common error or user didn't pass custom handler
		return formatStrapiError(body);
	} else if (err.request) {
		// The request was made but no response was received
		// if (err.code === 'ECONNREFUSED') {
		// }
		// return err.message;
		return 'تعذر الاتصال بالسيرفر';
	} else {
		// Something happened in setting up the request that triggered an Error
		return err.message;
	}
};

// export const handleNotFound = (err,message) => {  }
