// material
import {Box, Button, Card, Container, Stack, Tab, Tabs, TextField, Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import FormikWrapper from 'src/components/wrappers/FormikWrapper';
import LogoutButton from 'src/components/buttons/LogoutButton';
import SubmitButton from 'src/components/buttons/SubmitButton';
import PhoneInput from 'src/components/inputs/PhoneInput';
import {UpdateAdminAccount} from 'src/reducers';
import {requiredEmailValidation, nameValidation, phoneValidation} from 'src/schema/authSchema';
import {handleRequestError} from 'src/utils/helperMethods';
import * as Yup from 'yup';
// components
import Page from '../../components/Page';
import TestPermissionSelector from '../ManageAdminsPage/TestPermissionSelector';
import {accountIs} from 'src/utils/siteUtils';
import {useState} from 'react';
import ChangePassword from './ChangePassword';
import {TabPanel} from 'src/components/TabPanel';
import {requestPermission} from 'src/firebase';

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

const schema = Yup.object().shape({
	name: nameValidation,
	phone: phoneValidation,
	email: requiredEmailValidation,
});

const notification_status = {
	denied: 'غير مسموح',
	granted: 'مسموح',
};
export default function AdminAccount() {
	const [value, setValue] = useState(0);

	const notifications_supported = typeof Notification !== 'undefined';
	const show_notification_button = notifications_supported && Notification.permission === 'default';

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const user = useSelector((s) => s.admin.user);
	const dispatch = useDispatch();
	const handleSubmit = async (values) => {
		try {
			await dispatch(UpdateAdminAccount({...values, username: values.email}));
			toast.success('تم التحديث بنجاح');
		} catch (error) {
			toast.error(handleRequestError(error));
		}
	};

	return (
		<Page title="حسابي">
			<Container maxWidth="md">
				<Card sx={{p: 3}}>
					<Typography variant="h4" gutterBottom>
						حسابي
					</Typography>

					<Box sx={{borderBottom: 1, mb: 3, borderColor: 'divider'}}>
						<Tabs variant="scrollable" value={value} onChange={handleChange}>
							<Tab label="معلومات الحساب" />
							<Tab label="تغير كلمة المرور" />
							<Tab label="اعدادات اضافية" />
						</Tabs>
					</Box>

					<TabPanel index={0} value={value}>
						<FormikWrapper
							hideSubmitButton
							values={{
								name: user.name,
								phone: user.phone,
								email: user.email,
							}}
							schema={schema}
							handleSubmit={handleSubmit}
						>
							{({isSubmitting, getInputProps}) => (
								<Stack spacing={4}>
									<TextField type="text" label="الاسم" fullWidth {...getInputProps('name')} />

									<PhoneInput label="رقم الهاتف" fullWidth {...getInputProps('phone')} />

									<TextField type="email" label="البريد الالكتروني" fullWidth {...getInputProps('email')} />

									<SubmitButton isSubmitting={isSubmitting}>تحديث المعلومات</SubmitButton>
								</Stack>
							)}
						</FormikWrapper>
					</TabPanel>
					<TabPanel index={1} value={value}>
						<ChangePassword />
					</TabPanel>
					<TabPanel index={2} value={value}>
						<Stack spacing={1} direction="row" justifyContent="space-between">
							<Typography variant="subtitle1">الاشعارات</Typography>
							{show_notification_button && (
								<Button variant="outlined" onClick={requestPermission}>
									السماح بإستقبال الاشعارات{' '}
								</Button>
							)}
							{notifications_supported ? (
								!show_notification_button && (
									<Typography>{notification_status[Notification.permission]}</Typography>
								)
							) : (
								<Typography>غير مدعوم</Typography>
							)}
						</Stack>
						{notifications_supported && !show_notification_button && (
							<Typography>
								{Notification.permission === 'denied'
									? 'يمكن تفعيل خاصية استقبال الاشعارات من خلال اعدادات الجهاز/المتصفح'
									: 'يمكن ايقاف تفعيل خاصية استقبال الاشعارات من خلال اعدادات الجهاز/المتصفح'}
							</Typography>
						)}
					</TabPanel>

					<Stack mt={5} direction="row" justifyContent="space-between" alignItems="center">
						{accountIs('demo') && <TestPermissionSelector />}
						<LogoutButton />
					</Stack>
				</Card>
			</Container>
		</Page>
	);
}
