import {Button, Stack, TextField, Typography} from '@mui/material';
import {lazy, Suspense} from 'react';
import {useNavigate} from 'react-router-dom';
import Page from 'src/components/Page';
import {onBarcodeDetected} from 'src/features/barcodeSearch';
import {isDesktop} from 'src/utils/deviceType';

const ProductBarcodeScanner = lazy(() => import('src/pages/User/barcode/ProductBarcodeScanner'));

export default function SearchByBarcodePage() {
	const navigate = useNavigate();
	const handleScanResult = (foundProducts, code) => {
		navigate('/barcode/result', {
			state: {
				products: foundProducts,
				barcode: code,
			},
		});
	};

	return (
		<Page title="البحث">
			<Stack spacing={2} justifyContent="center" alignItems="center">
				<Typography textAlign="center" variant="h4">
					البحث عن طريق كود المنتج
				</Typography>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						onBarcodeDetected(e.target[0].value, handleScanResult);
					}}
				>
					<Stack spacing={2} direction="row">
						<TextField
							inputProps={{
								style: {
									// height: 10,
								},
							}}
							fullWidth
							required
							placeholder="كود المنتج"
							autoFocus
							// onKeyDown={(e) => {
							// 	if (e.key === 'Enter') {
							// 		onBarcodeDetected(e.target.value, handleScanResult);
							// 	}
							// }}
						/>
						<Button variant="contained" type="submit">
							بحث
						</Button>
					</Stack>
				</form>
			</Stack>
			{!isDesktop() && (
				<div style={{marginTop: 40}}>
					<Typography mb={2} textAlign="center" variant="subtitle1">
						او قم بالبحث من خلال الكاميرة
					</Typography>
					<Suspense>
						<ProductBarcodeScanner handleScanResult={handleScanResult} />;
					</Suspense>
				</div>
			)}
		</Page>
	);
}
