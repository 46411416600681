import {useQuery} from 'react-query';
import {getShippingProviders} from 'src/utils/AdminApi';

const useShippingProviders = (options = {}) => {
	return useQuery('getshippingproviders', getShippingProviders, {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		staleTime: 1000 * 60 * 30,
		...options,
	});
};

export default useShippingProviders;
