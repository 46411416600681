import {Box, Button, Divider, RadioGroup, Stack, TextField, Typography} from '@mui/material';
import {useState} from 'react';
import {toast} from 'react-toastify';
import useProducts from 'src/hooks/useProducts';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import {defaultImageSrc} from 'src/utils/productUtils';
import RadioButton from 'src/components/inputs/RadioButton';
import {getImageFormat} from 'src/utils/imageUtils';

/* 
    onConfirm is function will be invoiked with selected product
*/
export default function ProductSelection({onConfirmSelection}) {
	const [selectedId, setSelectedId] = useState('');
	// get products from api
	const {products = []} = useProducts({
		pageSize: -1,
		onFetch: (_products) => setDisplayedProducts(_products),
	});
	// this will be used to display products and filtered with search
	// and we will use original products state when search input cleared
	const [displayedProducts, setDisplayedProducts] = useState([]);

	const onSearch = (e) => {
		const searchTerm = e.target.value?.toLowerCase();
		if (isNullOrEmpty(searchTerm)) return setDisplayedProducts(products);
		setDisplayedProducts(products.filter((p) => p.name.toLowerCase().includes(searchTerm)));
	};

	const onConfirm = () => {
		if (!selectedId) return toast.error('يرجي اختيار المنتج');
		onConfirmSelection(products.find((p) => p.id == selectedId));
	};

	return (
		<Box>
			<Box
				sx={{
					position: 'absolute',
					bgcolor: 'white',
					top: 0,
					left: 0,
					width: '100%',
					zIndex: 3,
				}}
			>
				<TextField fullWidth placeholder="البحث عن منتج" type="text" onChange={onSearch} />
			</Box>
			<RadioGroup
				sx={{
					minHeight: 150,
					mt: '40px',
					pb: '100px',
				}}
				aria-labelledby="payment-gateway"
				name="payyment-gateway-group"
				value={selectedId}
				row
				onChange={(e) => setSelectedId(e.target.value)}
			>
				<Stack sx={{width: '100%'}} spacing={1.5}>
					{displayedProducts.map((product, index) => {
						return (
							<div key={product.id}>
								<Stack sx={{cursor: 'pointer'}} onClick={() => setSelectedId(product.id)}>
									<Stack justifyContent="space-between" direction="row">
										<Stack spacing={1} alignItems="center" direction="row">
											<RadioButton value={product.id} />
											<img loading="lazy" src={getImageFormat(product.main_image)} width={44} height={44} />
											<Typography>{product.name}</Typography>
										</Stack>
									</Stack>
								</Stack>
								{index < displayedProducts.length - 1 && <Divider />}
							</div>
						);
					})}
					{displayedProducts.length === 0 && <Typography>لم يتم العثور علي منتجات</Typography>}
				</Stack>
			</RadioGroup>
			<Box
				sx={{
					boxShadow: `1px 0px 8px gray`,
					position: 'absolute',
					bgcolor: 'white',
					bottom: 0,
					left: 0,
					p: 2,
					width: '100%',
				}}
			>
				<Button sx={{flex: 1, width: '100%'}} size="large" variant="contained" onClick={onConfirm}>
					اختيار
				</Button>
			</Box>
		</Box>
	);
}
