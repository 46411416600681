import DuplicateIcon from '@mui/icons-material/ContentCopy';
import RemoveIcon from '@mui/icons-material/Remove';
import {IconButton, Stack, TextField, Typography} from '@mui/material';
import Picker from 'src/components/inputs/Picker';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import {getItemTotal} from './purchasesUtils';
import {getImageSrc} from 'src/utils/productUtils';
import CreatableSelect from 'src/components/inputs/CreatableSelect';
import {addProduct, getProducts} from 'src/utils/AdminApi';
import {useQuery} from 'react-query';
import {Link} from 'react-router-dom';
import {concatValidStrings} from 'src/utils/stringUtils';
export default function PurchaseItemInputs({
	disabled = false,
	showSelect = false,
	item,
	index,
	onDublicate,
	onChange,
	onRemoveItem,
	handleSearch,
	products,
	refetch,
}) {
	const {total_quantity = 1, cost_per_unit = '', name = '', product = null, variation = -1} = item;
	// 2 places depend on this style to be identical
	const direction = {xs: 'column', md: 'row'};
	return (
		<Stack direction={direction} spacing={2} justifyContent="space-between">
			{/* product selection */}
			{/* if selection change we need to reset selected variation */}
			<Stack flex={1} spacing={1} direction="row">
				{showSelect ? (
					<>
						{item.id == null ? (
							<CreatableSelect
								disabled={disabled}
								handleSearch={handleSearch}
								options={products}
								refetch={refetch}
								selectedValue={product}
								setSelectedValue={(newValue) => {
									onChange(index, {
										product: newValue,
										// reset selected variaiton (so that we don't have product assigned to variation not belong to it)
										variation: null,
									});
								}}
								handelCreateNewOption={async (newValue) => {
									let response = await addProduct({
										name: newValue.inputValue,
										price: 0,
										quantity: 0,
										publish_at: null,
									});
									onChange(index, {
										product: response,
										// reset selected variaiton (so that we don't have product assigned to variation not belong to it)
										variation: null,
									});
									refetch();
								}}
								label="المنتج"
								labelKey="name"
								getCreateLabel={(value) => `اضافة منتج جديد: ${value}`}
								renderMenuItem={(props, option) => {
									return (
										<li {...props}>
											<Stack alignItems="center" spacing={1} direction="row">
												{option.id != -1 && <img width={25} height={25} src={getImageSrc(option)} />}
												<p>{option.name}</p>
											</Stack>
										</li>
									);
								}}
							/>
						) : (
							<Stack alignItems="center" spacing={1} direction="row">
								<img loading="lazy" width={25} height={25} src={getImageSrc(product)} />
								<Link to={`/products/${product.id}`}>
									{concatValidStrings([product.name, variation?.name], ' - ')}
								</Link>
							</Stack>
						)}

						{/* variaiton selection */}
						{/* don't show this selection if there's no variations for the selected product */}
						{!isNullOrEmpty(product) && !isNullOrEmpty(product.variations) && (
							<Picker
								sx={{minWidth: 100}}
								options={[{id: -1, name: 'اختر'}, ...product.variations]}
								name="variation"
								label="الاختيارات"
								labelKey="name"
								valueKey="id"
								value={variation?.id ?? -1}
								onChange={(e) => {
									onChange(index, {
										variation: product.variations.find((v) => v.id == e.target.value),
									});
								}}
							/>
						)}
					</>
				) : (
					<TextField
						fullWidth
						label="اسم"
						placeholder="اسم"
						onChange={(e) => onChange(index, {name: e.target.value})}
						value={name}
						name="name"
						type="text"
						required
					/>
				)}
			</Stack>

			<Stack direction={direction} spacing={1}>
				<TextField
					label="عدد القطع"
					placeholder="عدد القطع"
					onChange={(e) => onChange(index, {total_quantity: e.target.value})}
					value={total_quantity}
					name="total_quantity"
					type="number"
					required
				/>
				<TextField
					label="سعر القطعة"
					placeholder="سعر القطعة"
					onChange={(e) => onChange(index, {cost_per_unit: e.target.value})}
					value={cost_per_unit}
					name="cost_per_unit"
					type="number"
					required
					helperText={`المجموع: ${getItemTotal(item)}`}
				/>
				<Stack spacing={1} direction="row">
					<IconButton size="small" type="button" onClick={() => onDublicate(item)}>
						<DuplicateIcon sx={{fontSize: 16}} />
					</IconButton>
					<IconButton size="small" type="button" onClick={() => onRemoveItem(index)}>
						<RemoveIcon sx={{fontSize: 16}} />
					</IconButton>
				</Stack>
			</Stack>
		</Stack>
	);
}
