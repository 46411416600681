import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet-async';
import {forwardRef} from 'react';
// material
import {Box} from '@mui/material';

// ----------------------------------------------------------------------

// eslint-disable-next-line react/display-name
// const Page = forwardRef(({title = 'فترينا', children, ...other}, ref) => (
// 	<Box ref={ref} {...other}>
// 		<Helmet>
// 			<title>{process.env.NODE_ENV === 'development' ? 'cms-dev' : title}</title>
// 		</Helmet>
// 		{children}
// 	</Box>
// ));

function Page({title = 'فترينا', children, ...other}) {
	return (
		<Box {...other}>
			<Helmet>
				<title>{title}</title>
				{/* <title>{process.env.NODE_ENV === 'development' ? 'cms-dev' : title}</title> */}
			</Helmet>
			{children}
		</Box>
	);
}

Page.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string,
};

export default Page;
