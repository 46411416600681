const {useEffect} = require('react');

export function useDndScrollWorkaround() {
	// issue https://github.com/kutlugsahin/react-smooth-dnd/issues/75
	useEffect(() => {
		const cleanClasses = () => {
			document.body.className = '';
		};
		document.addEventListener('touchend', cleanClasses, false);
		return () => {
			document.removeEventListener('touchend', cleanClasses, false);
		};
	}, []);
}
