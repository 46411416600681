import {Checkbox, FormControlLabel, TextField} from '@mui/material';
import React from 'react';
import PhoneInput from 'src/components/inputs/PhoneInput';
import PasswordField from '../inputs/PasswordField';
import ImageCropPicker from '../inputs/ImageCropPicker.js/ImageCropPicker';
import Picker from '../inputs/Picker';

export default function DynamicFormInput({input, value, setFieldValue, ...inputProps}) {
	const commonProps = {
		label: input.label,
		type: input.type ?? 'text',
		placeholder: input.placeholder ?? input.label,
		value,
		...inputProps,
	};

	if (!input.type || input.type.match(/^(text|email|number)$/))
		return (
			<TextField
				minRows={input.rows}
				multiline={input.multiline}
				onChange={(e) => setFieldValue(input.name, e.target.value)}
				{...commonProps}
				helperText={input.helperText ?? commonProps?.helperText}
			/>
		);
	if (input.type === 'tel') return <PhoneInput {...commonProps} />;
	if (input.type === 'password') return <PasswordField {...commonProps} />;
	if (input.type === 'image-crop')
		return (
			<ImageCropPicker
				ratio={input.ratio}
				label={input.label}
				setFile={(file) => setFieldValue(input.name, file)}
				{...inputProps}
			/>
		);
	if (input.type === 'file')
		return (
			<input
				type="file"
				// {...commonProps}
				onChange={(e) => {
					e.preventDefault();
					setFieldValue(input.name, e.target.files[0]);
				}}
			/>
		);

	if (input.type === 'picker') {
		return (
			<Picker
				key={input.name}
				options={input.options}
				{...commonProps}
				valueKey={input.valueKey}
				labelKey={input.labelKey}
				onChange={(e, option) => setFieldValue(input.name, e.target.value, option)}
			/>
		);
	}
	if (input.type === 'checkbox') {
		return (
			<FormControlLabel
				key={input.name}
				control={
					<Checkbox
						onChange={(e) => setFieldValue(input.name, e.target.checked)}
						{...commonProps}
						defaultChecked={value ?? input.value}
						// name={input.name}
						// checked={input.value}
						// onChange={(e) => formikProps.setFieldValue(input.name, e.target.value)}
					/>
				}
				label={input.label}
			/>
		);
	}
	if (input.type === 'select')
		return (
			<select
				name={input.name}
				onChange={(e) => {
					setFieldValue(input.name, e.target.value);
				}}
			>
				<option>{input.placeholder}</option>
				{input.options.map((option, index) => (
					<option key={index} value={option[input.valueKey] ?? option}>
						{' '}
						{option[input.labelKey] ?? option}
					</option>
				))}
			</select>
		);
}
