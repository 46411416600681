import {remove} from 'lodash';
import {stringify, parse} from 'qs';
import {useLocation, useNavigate} from 'react-router-dom';
import useUrlQuery from 'src/hooks/useUrlQuery';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import {filter} from 'src/utils/objectUtils';
const defaultOptions = {ignoreNullOrEmpty: false};
const useUrlFilters = (options = defaultOptions) => {
	let params = useUrlQuery();
	let filters = {};
	Object.keys(params).forEach((key) => {
		const value = params[key];
		if (options.ignoreNullOrEmpty && (isNullOrEmpty(value) || value === 'none')) return;
		filters[key] = value;
	});
	const navigate = useNavigate();

	const setQueryStringValue = (key, newValue) => {
		if (newValue === undefined) return;

		if (options.ignoreNullOrEmpty && isNullOrEmpty(newValue)) {
			delete filters[key];
			navigate({
				search: stringify(filters),
			});
		} else {
			navigate({
				search: stringify({...filters, [key]: newValue}),
			});
		}
	};
	const setQueryStringValues = (object) => {
		if (object == undefined) return;

		let updates = {};
		Object.keys(object).forEach((key) => {
			const value = object[key];
			if (options.ignoreNullOrEmpty && (isNullOrEmpty(value) || value === 'none')) return;
			updates[key] = value;
		});

		navigate({
			search: stringify({...filters, ...updates}),
		});
	};

	const removeFilter = (key) => {
		const after = remove(filters, key);
		navigate({
			search: stringify(after),
		});
	};
	return {
		filters,
		filtersQueryString: stringify(filters),
		setQueryStringValue,
		removeFilter,
		setQueryStringValues,
	};
};

export default useUrlFilters;
