import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import {TextField} from '@mui/material';

const filter = createFilterOptions();

export default function CreatableSelect({
	allowCreate = true,
	disabled = false,
	options,
	selectedValue,
	setSelectedValue,
	renderMenuItem,
	label,
	labelKey,
	handleSearch,
	handelCreateNewOption,
	disableClear = false,
	// function used to display custom text for new item, by default it will show 'اضافة ${inputValue}'
	getCreateLabel,
}) {
	return (
		<>
			<Autocomplete
				disableClearable={disableClear}
				disabled={disabled}
				fullWidth
				options={options}
				renderInput={(params) => {
					return <TextField disabled={disabled} {...params} label={label} />;
				}}
				getOptionLabel={(option) => {
					if (option.id == -1) return option.inputValue;
					return option[labelKey] ?? '';
				}}
				renderOption={renderMenuItem}
				filterOptions={(options, params) => {
					console.log('🚀 ~ file: CreatableSelect.js:37 ~ options, params:', options, params);
					// console.log(options, params);
					const filtered = filter(options, params);
					if (params.inputValue !== '') {
						handleSearch(params.inputValue);
						if (allowCreate)
							filtered.push({
								id: -1,
								name: getCreateLabel?.(params.inputValue) ?? `اضافة ${params.inputValue}`,
								inputValue: params.inputValue,
							});
					} else {
						handleSearch('');
					}
					return filtered;
				}}
				value={selectedValue}
				onChange={async (event, newValue) => {
					// to make sure that entered value is for creating new item
					if (newValue.id === -1) {
						handelCreateNewOption(newValue);
					} else setSelectedValue(newValue);
				}}
			/>
		</>
	);
}
