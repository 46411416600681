import DynamicForm from 'src/components/DynamicForm/DynamicForm';
import {showModel} from './showModel';

export default function BasicDynamicFormModal({
	// function to close the modal
	proceed,
	inputs,
	options,
}) {
	return (
		<DynamicForm
			onSubmit={(v) => proceed(v)}
			submitButtonText={options?.submitButtonText}
			config={{
				inputs: inputs.map((input) => {
					let name,
						label,
						placeholder,
						value = '';
					let otherAttributes = {};
					if (typeof input === 'string') {
						name = label = placeholder = input;
					} else {
						if (Array.isArray(input)) {
							// [name,value]
							name = label = placeholder = input[0];
							value = input[1] ?? '';
						} else {
							// object
							name = input.name;
							label = input.label ?? name;
							placeholder = input.placeholder ?? label ?? name;
							value = input.value ?? '';
							otherAttributes = input;
						}
					}

					return {
						...otherAttributes,
						name,
						label,
						placeholder,
						type: input?.type ?? 'text',
						value,
					};
				}),
			}}
		/>
	);
}

export function requestUserInputs(title, inputs, options) {
	return showModel({
		title,
		component: <BasicDynamicFormModal inputs={inputs} options={options ?? {}} />,
	});
}
