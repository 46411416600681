import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import React from 'react';
import {toast} from 'react-toastify';
import {addNewFeature} from './features.utils';

export default function CreateOrUpdateFeature({showForm, setShowForm, setFeatures, addFeature}) {
	const status = 'suggested';
	const [proposalData, setProposalData] = React.useState();
	const [isSubmitting, setIsSubmitting] = React.useState(false);

	const addProposalHandler = async () => {
		setIsSubmitting(true);
		try {
			const response = await addNewFeature({
				title: proposalData.title,
				description: proposalData.discreption,
				status,
			});
			toast.success('تم اضافة اقتراحك بنجاح');
			setFeatures((prevFeatures) => [...prevFeatures, response]);
			setShowForm(false);
		} catch {
			toast.success('يرجي المحاولة مجدداً');
		} finally {
			setTimeout(() => {
				setIsSubmitting(false);
			}, 200);
		}
	};

	const handleClose = () => {
		setShowForm(false);
	};

	return (
		<Dialog open={showForm} onClose={handleClose}>
			<DialogTitle>تقديم إقتراح</DialogTitle>
			<DialogContent>
				<TextField
					label="عنوان المقترح"
					sx={{mb: 2, color: '#212B36'}}
					variant="filled"
					fullWidth
					onBlur={(e) => setProposalData({...proposalData, title: e.target.value})}
				/>

				<TextField
					label="وصف المقترح"
					sx={{mb: 1, color: '#212B36'}}
					variant="filled"
					multiline
					minRows={3}
					fullWidth
					onBlur={(e) => setProposalData({...proposalData, discreption: e.target.value})}
				/>

				{/* <Picker
					value={status}
					onChange={(e) => setStatus(e.target.value)}
					options={mapObjectToArray(featureStatus, (k, v) => ({
						label: v.label,
						id: k,
					}))}
					labelKey="label"
					valueKey="id"
					fullWidth
				/> */}
			</DialogContent>
			<DialogActions sx={{display: 'flex', flexDirection: 'column'}}>
				<Button disabled={isSubmitting} variant="contained" fullWidth onClick={addProposalHandler}>
					إنشاء
				</Button>
				<Button fullWidth onClick={handleClose}>
					إغلاق
				</Button>
			</DialogActions>
		</Dialog>
	);
}
