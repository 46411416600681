import {useEffect, useState} from 'react';
import {getCurrencies} from '../utils/AdminApi';

export default function useCurrencies() {
	const [data, setData] = useState([]);

	const fetch = async () => {
		try {
			const response = await getCurrencies();
			setData(response);
		} catch (err) {
			// handle error
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	return [data, setData];
}
