import PlusIcon from '@mui/icons-material/Add';
import {IconButton} from '@mui/material';

export default function PlusButton({onClick, variant = 'contained'}) {
	return (
		<IconButton sx={{bgcolor: '#076458', color: 'white'}} onClick={onClick}>
			<PlusIcon />
		</IconButton>
	);
}
