// material
import {Card, Container, Typography} from '@mui/material';
import {AdminLoginForm} from '../../components/authentication/login';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AdminLogin() {
	return (
		<Container maxWidth="sm">
			<Card sx={{p: 4}}>
				<Typography variant="h4" gutterBottom>
					تسجيل الدخول
				</Typography>

				{<AdminLoginForm />}
			</Card>
		</Container>
	);
}
