// crud

// create = push nothing special ❌
// delete = filter
// retrive = get by id
// update = search for element and change it's value

export const removeByKey = (array, value, key = 'id') => {
	if (!Array.isArray(array)) throw Error('u must pass array to use this function');

	return array.filter((a) => a[key] !== value);
};
// this funciton is like map function but return result as object not array
export const mapToObject = (array, fn, initalValue = {}) => {
	return array.reduce(
		(prev, current) => ({
			...prev,
			...fn(current),
		}),
		initalValue,
	);
};

export const findById = (arr = [], id) => {
	if (!id || !Array.isArray(arr)) return null;

	return arr.find((el) => el.id == id);
};

export const createList = (size, formatter) => {
	const list = [];
	for (let i = 0; i < size; i += 1) {
		if (typeof formatter === 'function') list.push(formatter(i));
		else list.push(i);
	}
	return list;
};

export const createListFromRange = (from, to, step = 1, formatter) => {
	const list = [];
	for (let i = from; i <= to; i += step) {
		if (typeof formatter === 'function') list.push(formatter(i));
		else list.push(i);
	}
	return list;
};
