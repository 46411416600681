import {Button, Card, Checkbox, Divider, Stack, Typography} from '@mui/material';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import {getImageSrc, getProductPrice, getProductQuantity} from 'src/utils/productUtils';

export default function BarcodeResultItem({product}) {
	const [selectedVariations, setSelectedVariations] = useState({});
	const hasVariations = !isNullOrEmpty(product.variations);
	const createOrderButtonDisabled = hasVariations && isNullOrEmpty(selectedVariations);

	const navigate = useNavigate();
	// if product contain variation then user must select variation before he be able to create order

	const handleCreateOrder = () => {
		const state = hasVariations ? {selectedVariations} : {selectedProducts: {[product.id]: product}};
		navigate(`/orders/create`, {state});
	};

	const handlePreviewProduct = () => {
		navigate(`/products/${product.id}`);
	};

	const onVariationChecked = (isChecked, product) => {
		if (isChecked) {
			// add to selection
			setSelectedVariations({
				...selectedVariations,
				[product.variation.id]: product,
			});
		} else {
			// remove from selection
			const newState = {...selectedVariations};
			delete newState[product.variation.id];
			setSelectedVariations(newState);
		}
	};

	return (
		<Card sx={{p: 2}}>
			<Stack spacing={1} alignItems="center" direction="row" justifyContent="space-between">
				<Stack spacing={2} direction="row">
					<img style={{borderRadius: 10}} src={getImageSrc(product)} height="80px" width="80px" />
					<Stack>
						<Typography variant="subtitle1">{product.name}</Typography>
						<Typography>السعر {getProductPrice(product)}</Typography>
						<Typography>الكمية {product.infinite ? '∞' : getProductQuantity(product)}</Typography>
					</Stack>
				</Stack>
				<Button sx={{height: '40px'}} variant="outlined" onClick={handlePreviewProduct}>
					عرض المنتج
				</Button>
			</Stack>
			{/*  */}
			<Divider sx={{my: 2}} />

			{hasVariations && (
				<Stack spacing={1}>
					<Typography variant="h6">يرجي تحديد الاختيارات</Typography>

					<Stack spacing={2} direction="row" flexWrap="wrap">
						{product.variations.map((variation) => {
							const vid = variation.id;
							const isChecked = vid === selectedVariations[vid]?.variation?.id;
							return (
								<Stack alignItems="center" key={vid} direction="row">
									<Checkbox
										// we need to reformat object so that we can easily send to server
										onChange={(e) => onVariationChecked(e.target.checked, {...product, variation})}
										checked={isChecked}
									/>
									<Typography>{variation.name}</Typography>
								</Stack>
							);
						})}
					</Stack>
				</Stack>
			)}

			{/* action buttons */}
			<Button
				fullWidth
				sx={{mt: 4}}
				disabled={createOrderButtonDisabled}
				variant="contained"
				onClick={handleCreateOrder}
			>
				انشاء طلب
			</Button>
		</Card>
	);
}
