import PropTypes from 'prop-types';
// material
import {Grid} from '@mui/material';
import ProductCard from './ProductCard';

// ----------------------------------------------------------------------

ProductGridView.propTypes = {
	products: PropTypes.array.isRequired,
};

export default function ProductGridView({products, ...other}) {
	return (
		<Grid container spacing={3} {...other}>
			{products.map((product) => (
				<Grid key={product.id} item xs={6} sm={4} md={3}>
					<ProductCard product={product} />
				</Grid>
			))}
		</Grid>
	);
}
