export const filter = (object, fn) => {
	let newObject = {};
	for (let k in object) {
		const value = object[k];
		const keep = fn(value, k);
		if (keep) newObject[k] = value;
	}
	return newObject;
};

/**
 * removes all properties from an object except the ones provided in the array
 * @param {object} object the object to be filtered
 * @param {string[]} requiredProperties the attributes to be kept in the object
 */
export const keepOnly = (object = {}, ...requiredProperties) => {
	if (object === null || object === undefined) return object;
	return Object.keys(object)
		.filter((key) => requiredProperties.includes(key))
		.reduce((obj, key) => {
			obj[key] = object[key];

			return obj;
		}, {});
};

export const filterObject = (object, ...filters) => {
	if (object === null || object === undefined) return object;
	return Object.keys(object)
		.filter((key) => !filters.some((filter) => filter == key))
		.reduce((obj, key) => {
			obj[key] = object[key];

			return obj;
		}, {});
};

export const update = (object, keyToUpdate, newValue) => {
	return {...object, [keyToUpdate]: newValue};
};
