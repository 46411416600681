import { updateArray } from "src/utils/helperMethods"

const initalState = { 
    data:[],
}

export const SET_CATEGORIES = "SET_CATEGORIES"
export const ADD_CATEGORY = "ADD_CATEGORY"
export const DELETE_CATEGORY = "DELETE_CATEGORY"
export const UPDATE_CATEGORY = "UPDATE_CATEGORY"
// export const RE_ORDER_CATEGORIES = "RE_ORDER_CATEGORIES"

const categoryReducer = (state=initalState,{payload,type}) => { 
    if(type === SET_CATEGORIES)
        return {data:payload.data}
    if(type === ADD_CATEGORY)
        return {data:[payload.category,...state.data]}
    if(type === DELETE_CATEGORY)
        return {data:state.data.filter(d=>d.id != payload.id)}
    if(type === UPDATE_CATEGORY)
        return {data:updateArray(state.data, payload.category, 'id')}    
    else
        return state
 }
 
 export default categoryReducer
