import QueryString from 'qs';
import {useLocation} from 'react-router';

export default function useUrlQuery() {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	let query = {};
	try {
		query = QueryString.parse(location.search.replace('?', '')) ?? {};
	} catch (error) {
		queryParams.forEach((value, key) => {
			query[key] = value;
		});
	}

	return query;
}
