// react
// material
import {Stack, TextField} from '@mui/material';
import {Form, FormikProvider, useFormik} from 'formik';
import SubmitButton from 'src/components/buttons/SubmitButton';
import ImageCropPicker from 'src/components/inputs/ImageCropPicker.js/ImageCropPicker';
import Picker from 'src/components/inputs/Picker';
import {updateCategory} from 'src/utils/AdminApi';
// form handling
import * as Yup from 'yup';
// components
export default function UpdateCategoryForm({categories = [], category, onSuccess}) {
	const Schema = Yup.object().shape({
		name: Yup.string().required('يجب ادخال اسم الفئة '),
		// image: Yup.array().min(1, 'يجب اضافة صورة').required(),
	});

	const formik = useFormik({
		initialValues: {
			name: category?.name,
			parent: category?.parent?.id,
			image: null,
		},
		validationSchema: Schema,
		onSubmit: (v) => {
			const {name, parent} = v;

			updateCategory(category.id, name, parent, v.image)
				.then((res) => {
					// navigate('/dashboard/categories', { replace: true });
					onSuccess(res);
				})
				.catch((err) => {
					setSubmitting(false);
					alert(err);
				});
		},
	});

	const {
		setSubmitting,
		errors,
		touched,
		values,
		isSubmitting,
		handleSubmit,
		setFieldValue,
		getFieldProps,
	} = formik;

	const getHelperProps = (attr) => ({
		error: Boolean(touched[attr] && errors[attr]),
		helperText: touched[attr] && errors[attr],
	});

	return (
		<FormikProvider value={formik}>
			<Form autoComplete="off" noValidate onSubmit={handleSubmit}>
				<Stack spacing={3}>
					<TextField
						fullWidth
						type="text"
						label="اسم الفئة"
						{...getFieldProps('name')}
						{...getHelperProps('name')}
					/>

					<Picker
						{...getFieldProps('parent')}
						{...getHelperProps('parent')}
						label="التصنيف الرئيسي"
						options={[{id: null, name: 'لا يوجد'}, ...categories]}
						labelKey="name"
						valueKey="id"
						value={values.parent}
						onChange={(e) => setFieldValue('parent', e.target.value)}
					/>

					<ImageCropPicker
						ratio={1}
						placeholder={'الصورة'}
						{...getHelperProps('image')}
						defaultImage={category?.image?.url}
						setFile={(file) => setFieldValue('image', file)}
					/>
					{/* single image vs multiple images */}
				</Stack>

				<SubmitButton isSubmitting={isSubmitting}>تعديل</SubmitButton>
			</Form>
		</FormikProvider>
	);
}
