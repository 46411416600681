import {Button, List, ListItem, ListItemIcon, ListItemText, Typography} from '@mui/material';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {hideModalAction} from 'src/reducers/modal/modalActions';
import BasicModal from './BasicModal';
import InfoIcon from '@mui/icons-material/InfoOutlined';
export default function GlobalModal() {
	const {isVisible, title, body, actionButtonText, redirectTo} = useSelector((s) => s.modal);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleActionButtonClick = () => {
		if (redirectTo != null) {
			navigate(redirectTo);
		} else {
			// dismiss the modal
			dispatch(hideModalAction);
		}
	};

	return (
		<BasicModal title={title} isVisible={isVisible}>
			<ModalBody body={body} />
			<Button sx={{mt: 2}} variant="contained" fullWidth onClick={handleActionButtonClick}>
				{actionButtonText}
			</Button>
		</BasicModal>
	);
}

const ModalBody = ({body}) => {
	const renderListItem = (item, index) => <ModalBodyItem key={index} item={item} />;

	if (!body) return null;
	if (typeof body !== 'object') return <Typography>{body}</Typography>;
	// check if object is array
	if (Array.isArray(body)) return <List>{body.map(renderListItem)}</List>;
	// handle object
	else {
		return <List>{Object.values(body).map(renderListItem)}</List>;
	}
};
const ModalBodyItem = ({item}) => {
	if (typeof item !== 'string') return '';
	return (
		<ListItem>
			<ListItemIcon>
				<InfoIcon color="error" />
			</ListItemIcon>
			<ListItemText>{item}</ListItemText>
		</ListItem>
	);
};
