import {Button, Stack} from '@mui/material';
import useShippingProviders from 'src/pages/DeliveryMethods/useShippingProviders';
import FoxlineSendOrderButton from 'src/plugins/foxline/FoxlineSendOrderButton';
import Row from '../../../components/ui/Row';
import {useNavigate} from 'react-router';
import {requestUserInputs} from 'src/components/modals/BasicDynamicFormModal';
import {string} from 'yup';

const SeeShippingDetailsLink = () => {
	return (
		<a
			style={{
				color: 'gray',
				textDecoration: 'none',
				cursor: 'pointer',
				display: 'block',
				textAlign: 'center',
			}}
			href="#shipping-details"
		>
			عرض معلومات الشحنة
		</a>
	);
};
export default function ShippingProvidersActions({
	onSendShippmentClicked,
	onDeleteShippingClicked,
	manualShippmentLink,
	order,
	setOrder,
}) {
	const type = order?.delivery_info?.type ?? 'delivery';
	const {country, city} = order?.delivery_info ?? {};
	const orderIsLinkedWithShippingCompany = order?.delivery_info?.shippingId != null;
	const {data: shippingProviders = []} = useShippingProviders();
	const nav = useNavigate();
	if (type === 'shipping') {
		return (
			<>
				{/* {JSON.stringify(order.delivery_info)} */}
				<Row label="الدولة" value={country} />
				{order?.delivery_info?.shippingId ? (
					<SeeShippingDetailsLink />
				) : (
					<FoxlineSendOrderButton setOrder={setOrder} order={order} />
				)}
			</>
		);
	}

	return orderIsLinkedWithShippingCompany ? (
		<>
			<Button variant="outlined" size="large" color="error" onClick={onDeleteShippingClicked}>
				حذف الشحنة
			</Button>
			<SeeShippingDetailsLink />
		</>
	) : (
		<Stack spacing={1}>
			{shippingProviders
				.filter((provider) => provider.enabled)
				// for each enabled provider - show button to send order to provider api
				.map((shippingProvider) => {
					const {
						id,
						iconWidth = 30,
						iconHeight = 30,
						name,
						bgcolor,
						iconPath = `/third-party-icons/${id}-icon-dark.png`,
						darkIconPath,
						createForm,
						custom_create_form,
					} = shippingProvider;
					const imgSrc = iconPath ?? darkIconPath;
					return (
						<Button
							endIcon={<img src={imgSrc} width={iconWidth} height={iconHeight} />}
							variant="contained"
							sx={{bgcolor}}
							type="submit"
							onClick={() => {
								if (custom_create_form === true) return nav(`/orders/${order.uuid}/shipping/${id}/create`);
								onSendShippmentClicked(id);
							}}
						>
							{name}
						</Button>
						/* 	<DynamicForm
							showSubmitButton={false}
							config={createForm}
							onSubmit={(values) => onSendShippmentClicked(id, values)}
						>
							{() => {
								return (
									<Button
										endIcon={<img src={imgSrc} width={iconWidth} height={iconHeight} />}
										variant="contained"
										sx={{bgcolor}}
										type="submit"
									>
										{name}
									</Button>
								);
							}}
						</DynamicForm> */
					);
				})}

			<Button
				variant="outlined"
				onClick={async () => {
					const values = await requestUserInputs(
						'ربط يدوي',
						[
							{
								name: 'number',
								label: 'رقم الشحنة',
								validation: string().required('هذا الحقل اجباري'),
							},
							{
								name: 'provider',
								label: 'شركة الشحن',
								type: 'picker',
								options: shippingProviders.filter((provider) => provider.enabled),
								labelKey: 'name',
								valueKey: 'id',
								validation: string().required('هذا الحقل اجباري'),
							},
						],
						{submitButtonText: 'ربط'},
					);
					await manualShippmentLink(values.provider, values.number);
				}}
			>
				ربط يدوي
			</Button>
		</Stack>
	);
}
