import {Grid} from '@mui/material';
import React from 'react';
import Layout from 'src/components/Layout';
import OrderProductsReport from 'src/pages/reports/OrderProductsReport';

export default function ReportsPage() {
	return (
		<Layout htmlTitle={'التقارير'} maxWidth="lg" title={'التقارير'}>
			<OrderProductsReport maxWidth="lg" />
		</Layout>
	);
}
