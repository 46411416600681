// material
import {Card, Container, Typography, Stack} from '@mui/material';
// components
import CreateTenantForm from 'src/components/CreateTenantForm';
// ----------------------------------------------------------------------

export default function SetupPage() {
	return (
		<Stack
			justifyContent="center"
			sx={{minHeight: '100vh', bgcolor: '#0D4A4F', pb: 8}}>
			<Container maxWidth="sm">
				<Card sx={{p: 4}}>
					<Typography variant="h4" gutterBottom>
						معلومات محلك
					</Typography>

					<CreateTenantForm />
					{/* <LogoutButton
						containerStyles={{
							marginTop: 10,
							width: '100%',
							alignSelf: 'center',
						}}
					/> */}
				</Card>
			</Container>
		</Stack>
	);
}
