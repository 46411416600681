import {isNullOrEmpty} from './helperMethods';

export function* cartesian(head, ...tail) {
	const remainder = tail.length > 0 ? cartesian(...tail) : [[]];
	for (let r of remainder) for (let h of head) yield [h, ...r];
}

export const getVariationOptions = (_selectedProperties) => {
	if (isNullOrEmpty(_selectedProperties)) return [];
	// we need to get all possible variaations
	const cartesianPropertyValues = [...cartesian(..._selectedProperties.map((p) => p.values))];
	// format options to make easy to deal with
	const variationOptions = cartesianPropertyValues.map((variant) => ({
		// variant : [{property,id:1,value:s},{property,id:4,value:red}]
		// name would be s-red
		name: variant.map((l) => l.value).join('-'),
		// values would be [1,4]
		values: variant.map((l) => l.id),
		deleted: false,
		// price: null,
		// quantity: null,
	}));

	return variationOptions;
};
export const getVariationOptions2 = (_selectedProperties) => {
	if (isNullOrEmpty(_selectedProperties)) return [];
	// we need to get all possible variaations
	const cartesianPropertyValues = [...cartesian(..._selectedProperties.map((p) => p.values))];
	// format options to make easy to deal with
	const variationOptions = cartesianPropertyValues.map((variant) => ({
		// variant : [{property,id:1,value:s},{property,id:4,value:red}]
		// name would be s-red
		name: variant.map((l) => l.value).join('-'),
		// values would be [1,4]
		values: variant.map((l) => l),
		deleted: false,
		// price: null,
		// quantity: null,
	}));

	return variationOptions;
};

export const getGroupedPropertiesFromVariations = (variations = []) => {
	if (!variations || variations.length === 0) return [];

	// variations example [{values:[{value:'s',property:{name:'size'}}]}]
	// we need to get
	let properties = {};
	variations.forEach((variation) => {
		//   if(properties[])
		// ex  value as s , property : {name:size}
		variation.values.forEach(({property, ...propertyValue}) => {
			// check if already exist
			if (
				properties[property.id] &&
				properties[property.id].values.find((v) => v.id === propertyValue.id)
			) {
				return;
			}

			properties[property.id] = {
				...property,
				// copy values inserted in previous iterations and new value to it , or create new one
				values: Array.isArray(properties[property.id]?.values)
					? [...properties[property.id].values, {...propertyValue, property}]
					: [{...propertyValue, property}],
			};
		});
	});

	return Object.values(properties);
};
