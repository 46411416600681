import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Container} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Page from 'src/components/Page.js';
import UpdatePropForm from '../components/_dashboard/ProductsProperties/UpdatePropForm/UpdatePropForm.js';
import {getProperty} from '../utils/AdminApi.js';

export default function UpdateProperty() {
	let {id} = useParams();
	const navigate = useNavigate();
	const [property, setProperty] = useState();

	const handleGoBack = () => {
		navigate(-1);
	};

	async function fetchProperty() {
		try {
			let prop = await getProperty(id);
			setProperty({...prop});
		} catch (e) {
			console.log(e);
		}
	}

	useEffect(() => {
		fetchProperty();
	}, []);

	return (
		<Page title="Edit Property" sx={{mt: -2.5}}>
			<Container>
				<IconButton onClick={handleGoBack} sx={{mb: -1.8}}>
					<Typography
						sx={{fontSize: '0.65em', display: 'flex', alignItems: 'center', gap: 1.5}}
						variant="caption"
					>
						<ArrowForwardIosIcon sx={{fontSize: '0.75em'}} />
						خيارات المنتجات
					</Typography>
				</IconButton>
				<Typography variant="h3" gutterBottom>
					تعديل الخيار
				</Typography>
				<UpdatePropForm prop={property} setProperty={setProperty} />
			</Container>
		</Page>
	);
}

const dummyProp = {
	id: 23,
	name: 'مقاسات احذية',
	category: null,
	values: [
		{
			id: 43,
			value: '34',
			property: 23,
		},
		{
			id: 44,
			value: '40',
			property: 23,
		},
		{
			id: 45,
			value: '41',
			property: 23,
		},
		{
			id: 46,
			value: '14',
			property: 23,
		},
	],
};
