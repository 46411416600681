import {Button, Card, Container, Stack, Typography} from '@mui/material';
import {stringify} from 'qs';
import {useInfiniteQuery, useQuery} from 'react-query';
import AccessControlWrapper from 'src/components/AccessControlWrapper';
import Page from 'src/components/Page';
import useUrlFilters from 'src/hooks/useUrlFilters';
import {getInvoices, getInvoicesTotal} from 'src/utils/AdminApi';
import InvoiceItem from './InvoiceItem';
import InvoicePageFilter from './InvoicePageFilter';
import InfoTip from 'src/components/ui/InfoTip';
import PaidIcon from '@mui/icons-material/Paid';
import PendingIcon from '@mui/icons-material/AccessTime';
import RefreshIcon from '@mui/icons-material/Refresh';
import {DateTime} from 'luxon';
const limit = 20;

export default function InvoicesPage() {
	const {filters, filtersQueryString} = useUrlFilters({ignoreNullOrEmpty: true});

	const {data, refetch, fetchNextPage, hasNextPage} = useInfiniteQuery(
		['invoices', filters],
		({pageParam = 0}) => {
			let {search_term, paid_at_lte, paid_at_gte, ...otherFilters} = filters;

			paid_at_lte = paid_at_lte ? DateTime.fromISO(paid_at_lte).toUTC().toISO() : undefined;
			paid_at_gte = paid_at_gte ? DateTime.fromISO(paid_at_gte).toUTC().toISO() : undefined;

			const qs = stringify(
				{
					_sort: 'id:desc',
					_start: pageParam * limit,
					_limit: limit,
					paid_at_lte,
					paid_at_gte,
					// _where: {
					// 	_or: [{'items.product.name_contains': search_term}, {description_contains: search_term}],
					// },
					...otherFilters,
				},
				{
					encode: false,
				},
			);

			return getInvoices(`?${qs}`);
		},
		{
			refetchOnWindowFocus: false,
			getNextPageParam: (lastPage, allPages) =>
				lastPage.length < limit ? undefined : allPages.length,
		},
	);

	const {data: invoiceTotalRes} = useQuery(
		['invoice-total', filtersQueryString],
		() => getInvoicesTotal(filtersQueryString),
		{
			refetchOnWindowFocus: false,
		},
	);

	const invoices = Array.isArray(data?.pages) ? data.pages.flat() : [];

	return (
		<Page title="الفواتير">
			<AccessControlWrapper>
				<Container maxWidth="md">
					<Card sx={{p: 3}}>
						<Stack spacing={2}>
							<Stack direction="row" justifyContent="space-between">
								<Typography variant="h4">الفواتير</Typography>
							</Stack>
							<InvoicePageFilter />
							<Stack spacing={2} direction="row">
								<Typography color="grey" variant="h6">
									مجموع الفواتير:
								</Typography>
								<Typography sx={{color: '#4E9B57'}} variant="subtitle1">
									مدفوعة: {invoiceTotalRes?.paid_invoices_total_result ?? 0} <PaidIcon fontSize="5px" />
								</Typography>
								<Typography color="grey" variant="subtitle1">
									قيد الاجراء: {invoiceTotalRes?.unpaid_invoices_total_result ?? 0}{' '}
									<PendingIcon fontSize="5px" />
								</Typography>
								<Typography color="error" variant="subtitle1">
									ارجاع: {invoiceTotalRes?.money_back_total_result ?? 0} <RefreshIcon fontSize="5px" />
								</Typography>
							</Stack>

							{invoices.map((item) => {
								return (
									<InvoiceItem
										key={item.id}
										invoice={item}
										// onUpdate={() => {
										// 	refetch();
										// }}
										allowUpdate={false}
									/>
								);
							})}
							{hasNextPage && (
								<Button sx={{mt: 1}} fullWidth onClick={() => fetchNextPage()} variant={'text'}>
									عرض المزيد
								</Button>
							)}
						</Stack>
					</Card>
				</Container>
			</AccessControlWrapper>
		</Page>
	);
}
