import {useNavigate} from 'react-router-dom';
import {deleteProperties} from '../../../../utils/AdminApi.js';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {toast} from 'react-toastify';
import DeleteAlertDialog from 'src/components/modals/DeleteAlertDialog.js';

const PropertyRow = ({prop, handleDelete}) => {
	const navigate = useNavigate();

	const editProp = () => {
		navigate('/properties/' + prop?.id);
	};

	const deleteProp = async () => {
		try {
			await deleteProperties(prop?.id);
			handleDelete(prop?.id);
		} catch (e) {
			toast.error('جدث خطأ غير متوقع!');
		}
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				paddingInlineStart: 2.5,
			}}
		>
			<Typography variant="body1">{prop?.name}</Typography>
			<Box sx={{gap: 5}}>
				<Button onClick={editProp}>تعديل</Button>
				<DeleteAlertDialog onDelete={deleteProp} />
			</Box>
		</Box>
	);
};
export default PropertyRow;
