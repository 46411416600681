import {Checkbox, FormControlLabel, Stack, Typography} from '@mui/material';
import React from 'react';

export default function PermissionsCheckboxList({
	allowed_actions = [],
	setFieldValue,
	allPermissions = [],
	fieldName,
}) {
	return (
		<>
			<Typography variant="subtitle1">الصلاحيات</Typography>
			<Stack direction="row" flexWrap="wrap">
				{allPermissions.map((thisPermission) => {
					return (
						<FormControlLabel
							key={thisPermission.id}
							control={
								<Checkbox
									onChange={(e) => {
										if (e.target.checked) setFieldValue(fieldName, [...allowed_actions, e.target.value]);
										else {
											setFieldValue(
												fieldName,
												allowed_actions.filter((pid) => pid != e.target.value),
											);
										}
									}}
									name={fieldName}
									value={thisPermission.id}
									checked={!!allowed_actions.find((p) => p == thisPermission.id)}
								/>
							}
							label={thisPermission.label}
						/>
					);
				})}
			</Stack>
		</>
	);
}
