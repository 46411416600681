// react
// material
import {Stack, TextField} from '@mui/material';
import {Form, FormikProvider, useFormik} from 'formik';
import SubmitButton from 'src/components/buttons/SubmitButton';
import ImageCropPicker from 'src/components/inputs/ImageCropPicker.js/ImageCropPicker';
import Picker from 'src/components/inputs/Picker';
import {addCategory} from 'src/utils/AdminApi';
// form handling
import * as Yup from 'yup';
// components

export default function CreateCategoryForm({onSuccess, categories = []}) {
	const Schema = Yup.object().shape({
		name: Yup.string().required('يجب ادخال اسم الفئة '),
		// image: Yup.array().min(1, 'يجب اضافة صورة').required(),
	});

	const formik = useFormik({
		initialValues: {
			name: '',
			image: null,
		},
		validationSchema: Schema,
		onSubmit: (v) => {
			const {name, parent} = v;
			const rowOrder = (categories[0]?.row_order ?? 0) + 1;
			addCategory(name, parent, rowOrder, v.image)
				.then((res) => {
					// navigate('/dashboard/categories', { replace: true });
					onSuccess(res);
				})
				.catch((err) => {
					setSubmitting(false);
					alert(err);
				});
		},
	});

	const {
		setSubmitting,
		setFieldValue,
		errors,
		touched,
		values,
		isSubmitting,
		handleSubmit,
		getFieldProps,
	} = formik;

	const getHelperProps = (attr) => ({
		error: Boolean(touched[attr] && errors[attr]),
		helperText: touched[attr] && errors[attr],
	});

	return (
		<FormikProvider value={formik}>
			<Form autoComplete="off" noValidate onSubmit={handleSubmit}>
				<Stack spacing={3}>
					<TextField
						fullWidth
						type="text"
						label="اسم الفئة"
						{...getFieldProps('name')}
						{...getHelperProps('name')}
					/>

					<Picker
						{...getFieldProps('parent')}
						{...getHelperProps('parent')}
						label="التصنيف الرئيسي"
						options={categories}
						labelKey="name"
						valueKey="id"
						value={values.parent}
						onChange={(e) => setFieldValue('parent', e.target.value)}
					/>

					<ImageCropPicker
						ratio={1}
						placeholder={'الصورة'}
						{...getHelperProps('image')}
						setFile={(file) => setFieldValue('image', file)}
					/>
				</Stack>

				<SubmitButton isSubmitting={isSubmitting}>اضافة</SubmitButton>
			</Form>
		</FormikProvider>
	);
}
