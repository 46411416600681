const {stringify} = require('qs');

export const getApiSearchQueryString = (searchTerm) => {
	return stringify(
		{
			_where: {
				_or: [{name_contains: searchTerm}, {sku_contains: searchTerm}],
			},
		},
		{
			encode: true,
		},
	);
};

export const searchForProducts = (products, searchTerm) => {
	return products.filter(
		(p) => p.name.toLowerCase().includes(searchTerm) || p.sku?.toLowerCase().includes(searchTerm),
	);
};
