import {Card, Container, Link, Stack, Typography} from '@mui/material';
import {useQuery} from 'react-query';
import {Link as RouterLink} from 'react-router-dom';
import AccessControlWrapper from 'src/components/AccessControlWrapper';
import Page from 'src/components/Page';
import {findProductPermission} from 'src/permissionsList';
import {getOutOfStockProducts} from 'src/utils/AdminApi';
import {getImageFormat} from 'src/utils/imageUtils';
import {defaultImageSrc} from 'src/utils/productUtils';

export default function OutOfStockPage() {
	// NOTE: response coming from this endpoint is different than diffuult product endpoint response
	// main_image is string not object and some attributes not exist
	const {data: products = [], isLoading} = useQuery(['products'], () => getOutOfStockProducts(), {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});
	return (
		<Page title="منتجات غير متوفرة">
			<AccessControlWrapper controllerAction={findProductPermission}>
				<Container>
					<Typography variant="h4">منتجات غير متوفرة</Typography>

					<Stack mt={1} spacing={2}>
						{products.map((product) => {
							return (
								<Link
									key={product.id}
									to={`/products/${product.id}`}
									// color="inherit"
									// underline="none"
									component={RouterLink}
								>
									<Card sx={{p: 1}}>
										<Stack spacing={1} alignItems="center" direction="row">
											<img loading="lazy" src={getImageFormat(product.main_image)} width={44} height={44} />
											<Typography>{product.name}</Typography>
										</Stack>
									</Card>
								</Link>
							);
						})}
					</Stack>
				</Container>
			</AccessControlWrapper>
		</Page>
	);
}
