import { Container, Stack, Typography } from '@mui/material';
import BarcodeResultItem from './BarcodeResultItem';
import ProductBarcodeNotFoundActions from './ProductBarcodeNotFoundActions';

// when we found product using barcode then we need to display some action buttons
export default function ProductBarcodeFoundActions({barcode='',products=[]}) {
	return (
		<Container maxWidth="md">
			<Stack sx={{height: '100%'}} spacing={3} px={4}>
				<Typography color="primary" variant="h5">
					تم العثور علي المنتج
				</Typography>


				{products.map(product=>{
					return <BarcodeResultItem key={product.id} product={product}/>
				})}

				<ProductBarcodeNotFoundActions barcode={barcode} />
				
			</Stack>
		</Container>
	);
}
