import React from 'react';
import {dragDropDivProps, preventDefault} from './filesUtils';

export default function DragDropInput({
	disabled = false,
	onChange = preventDefault,
	fileInputRef,
	placeholder = ' قم بسحب وإسقاط ملفات الصور هنا لرفعها',
}) {
	return (
		<div className="drop-container" onDrop={onChange} {...dragDropDivProps}>
			<div className="drop-message">
				{/* <div className="upload-icon"></div> */}
				<p style={{fontSize: 14}}>{placeholder}</p>
				<label className="file-upload-btn">
					<input
						disabled={disabled}
						ref={fileInputRef}
						className="file-input"
						type="file"
						// multiple={allowMultiple}
						onChange={onChange}
					/>
					تصفح الملفات
				</label>
			</div>
		</div>
	);
}
