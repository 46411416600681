import {LoadingButton} from '@mui/lab';
import {Button, Stack, TextField} from '@mui/material';
import {useState} from 'react';
import {toast} from 'react-toastify';
import BranchPicker from 'src/components/BranchPicker';
import {showModel} from 'src/components/modals/showModel';
import {manualUpdateProductQuantity} from 'src/utils/AdminApi';
import {handleRequestError} from 'src/utils/helperMethods';
import {isNumber} from 'src/utils/numberUtils';

export default function UpdateProductQtyModal({
	// function to close the modal
	dismiss,
	onSuccess,
	productId,
	variationId,
}) {
	const [qty, setQty] = useState();
	const [desc, setDesc] = useState('');
	const [branch, setBranch] = useState(null);
	const [isSubmitting, setisSubmitting] = useState(false);
	return (
		<form
			onSubmit={async (e) => {
				e.preventDefault();
				setisSubmitting(true);
				try {
					await manualUpdateProductQuantity({
						productId,
						variationId,
						newQuantity: qty,
						description: desc,
						branch,
					});
					onSuccess();
				} catch (err) {
					toast.error(handleRequestError(err));
				}
				setisSubmitting(false);
			}}
		>
			<Stack spacing={6}>
				<Stack spacing={1}>
					<BranchPicker
						fullWidth
						label={'الفرع'}
						allowNone={false}
						value={branch}
						onChange={(e) => setBranch(e.target.value)}
					/>
					<TextField
						label="الكمية"
						autoComplete="off"
						sx={{flex: '1 1 auto'}}
						type="number"
						value={qty ?? ''}
						onChange={(e) => +e.target.value >= 0 && setQty(e.target.value)}
					/>
					<TextField
						fullWidth
						type="text"
						minRows={2}
						multiline
						label="تفاصيل / سبب التعديل"
						onChange={(e) => setDesc(e.target.value)}
						value={desc}
					/>
				</Stack>
				<LoadingButton
					loading={isSubmitting}
					type="submit"
					disabled={!isNumber(qty)}
					variant="contained"
				>
					تحديث
				</LoadingButton>
			</Stack>
		</form>
	);
}
