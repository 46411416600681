import FilterIcon from '@mui/icons-material/Tune';
import {
	Checkbox,
	FormControlLabel,
	IconButton,
	Stack,
	TextField,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {useEffect, useState} from 'react';
import OrderStatusPicker from 'src/pages/Orders/show/OrderStatusPicker';
import OrderAssignPicker, {useSiteAdminsQuery} from 'src/pages/Orders/show/OrderAssignPicker';
import useDataSearch from 'src/hooks/useDataSearch';
import useUrlFilters from 'src/hooks/useUrlFilters';
import {orderStatusOptions} from 'src/utils/orderUtils';
import Picker from 'src/components/inputs/Picker';
import {accountIs} from 'src/utils/siteUtils';
import {isFeatureAllowed} from 'src/utils/featureUtils';
import {order_selected_date_feature_flag} from 'src/feature_flag_list';
import ProductOrVariationSelect, {
	useProductOrVariationSelect,
} from 'src/components/ProductOrVariationSelect';
import {devLog} from 'src/utils/devUtils';
import {getDateBoundaries} from 'src/utils/date';
import DateRangeInput from 'src/components/DateRangeInput';
/**
 * @param {Object} props - Component props
 * @param {Array<'search' | 'status' | 'product' | 'assignee' | 'creator' | 'payment_method' | 'payment_received' | 'fulfillment_type' | 'created_at' | 'selected_date'>} props.hidden
 */
export default function OrdersFilter({hidden = []}) {
	const [isOpen, setisOpen] = useState(false);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const {filters, setQueryStringValue, setQueryStringValues, removeFilter} = useUrlFilters({
		ignoreNullOrEmpty: true,
	});
	const {data: allAdmins = []} = useSiteAdminsQuery();
	const {onSearch} = useDataSearch((value) => {
		setQueryStringValue('q', value);
	}, filters.q);

	const onStatusChange = (e) => {
		if (e.target.value === 'none') return removeFilter('status');
		setQueryStringValue('status', e.target.value ?? '');
	};
	const onAssigneeChange = (assigneeIds = []) => {
		if (!assigneeIds || assigneeIds.length === 0 || assigneeIds[0] === 'none')
			return removeFilter('assignee');
		setQueryStringValue('assignee', assigneeIds?.[0] ?? '');
	};

	// const productSelectProps = useProductOrVariationSelect({});

	const isVisible = (name) => {
		return !hidden.includes(name);
	};
	return (
		<>
			<Stack rowGap={2} columnGap={2} flexWrap="wrap" my={1} direction={{sm: 'row', xs: 'column'}}>
				{(isVisible('search') || isSmallScreen) && (
					<Stack flex={1} spacing={1} direction="row" justifyContent="space-between">
						{isVisible('search') && (
							<TextField
								label="بحث"
								sx={{minWidth: '200px'}}
								size="medium"
								fullWidth
								placeholder="البحث بإسم الزبون"
								type="text"
								// helperText="يمكن البحث حسب اسم رقم الزبون، رقم الطلب، اسم او باركود المنتج"
								onChange={onSearch}
							/>
						)}
						{isSmallScreen && (
							<IconButton onClick={() => setisOpen(!isOpen)}>
								<FilterIcon />
							</IconButton>
						)}
					</Stack>
				)}
				{(!isSmallScreen || isOpen) && (
					<>
						{isVisible('status') && (
							<OrderStatusPicker
								options={[
									{
										key: 'none',
										label: 'الكل',
									},
									...orderStatusOptions,
								]}
								size="medium"
								onStatusChange={onStatusChange}
								status={filters.status ?? 'none'}
								sx={{minWidth: 200}}
							/>
						)}

						{/* {isVisible('product') && (
							<ProductOrVariationSelect
								{...productSelectProps}
								hideVariations
								multiple
								onChange={(s) => {
									if (!s.product || s.product.length === 0) return removeFilter('products');
									setQueryStringValue(
										'products',
										s.product.map((p) => p.id),
									);
									// setQueryStringValues({
									// 	product: s.product?.id ?? '',
									// 	// variation: productSelectProps.variation?.id ?? '',
									// });
								}}
							/>
						)} */}

						{isVisible('assignee') && allAdmins.length > 0 && (
							<OrderAssignPicker
								sx={{minWidth: 200}}
								label="المسوؤل عن الطلب"
								size="medium"
								noSelectionLabel="الكل"
								onChange={onAssigneeChange}
								admins={allAdmins}
								value={filters.assignee ?? 'none'}
								noSelectionValue="none"
							/>
						)}

						{/* <Picker
							sx={{minWidth: 150}}
							value={filters.guestId_null ?? 'none'}
							onChange={(e) => {
								setQueryStringValue('guestId_null', e.target.value);
							}}
							options={[
								{
									label: 'الكل',
									value: 'none',
								},
								{
									label: 'الموقع',
									value: 'false',
								},
								{
									label: 'يدوي',
									value: 'true',
								},
							]}
							labelKey="label"
							valueKey="value"
							label="مصدر الطلب"
						/> */}
						{isVisible('creator') && allAdmins.length > 0 && (
							<Picker
								sx={{minWidth: 150}}
								value={filters.creator ?? 'none'}
								onChange={(e) => {
									setQueryStringValue('creator', e.target.value);
								}}
								options={[
									{
										label: 'الكل',
										value: 'none',
									},
									...allAdmins.map((admin) => ({
										label: admin.name,
										value: admin.id,
									})),
								]}
								labelKey="label"
								valueKey="value"
								label="مدخل الطلب"
							/>
						)}
						{isVisible('payment_method') && (
							<Picker
								sx={{minWidth: 150}}
								value={filters['payment.gateway'] ?? 'none'}
								onChange={(e) => {
									setQueryStringValue('payment.gateway', e.target.value);
								}}
								options={[
									{
										label: 'الكل',
										value: 'none',
									},
									{
										label: 'كاش',
										value: 'cash',
									},
									{
										label: 'بطاقة مصرفية',
										value: 'pos',
									},
									{
										label: 'الكتروني',
										value: 't_lync',
									},
								]}
								labelKey="label"
								valueKey="value"
								label="طريقة الدفع"
							/>
						)}
						{isVisible('payment_received') && (
							<Picker
								sx={{minWidth: 150}}
								value={filters.payment_received ?? 'none'}
								onChange={(e) => {
									setQueryStringValue('payment_received', e.target.value);
								}}
								options={[
									{
										label: 'الكل',
										value: 'none',
									},
									{
										label: 'تم التسوية',
										value: 1,
									},
									{
										label: 'لم يتم التسوية',
										value: 0,
									},
								]}
								labelKey="label"
								valueKey="value"
								label="التسوية"
							/>
						)}
						{isVisible('fulfillment_type') && (
							<Picker
								sx={{minWidth: 150}}
								value={filters.fulfillment_type ?? 'none'}
								onChange={(e) => {
									if (e.target.value === 'none') {
										return removeFilter('fulfillment_type');
									}
									setQueryStringValue('fulfillment_type', e.target.value);
								}}
								options={[
									{
										label: 'الكل',
										value: 'none',
									},
									{
										label: 'توصيل',
										value: 'delivery',
									},
									{
										label: 'استلام شخصي',
										value: 'pickup',
									},
								]}
								labelKey="label"
								valueKey="value"
								label="طريقة الاستلام"
							/>
						)}

						{isVisible('created_at') && (
							<DateRangeInput
								from={{
									value: filters.fulfillment_date_gte,
									onChange: (value) => setQueryStringValue('fulfillment_date_gte', value),
								}}
								to={{
									value: filters.fulfillment_date_lte,
									onChange: (value) => setQueryStringValue('fulfillment_date_lte', value),
								}}
							/>
						)}

						{isVisible('selected_date') && isFeatureAllowed(order_selected_date_feature_flag) && (
							<DateRangeInput
								from={{
									label: 'موعد التسليم من',
									value: filters.selected_date_gte,
									onChange: (value) => setQueryStringValue('selected_date_gte', value),
								}}
								to={{
									label: 'موعد التسليم الي',
									value: filters.selected_date_lte,
									onChange: (value) => setQueryStringValue('selected_date_lte', value),
								}}
							/>
						)}

						{/* <FormControlLabel
							control={
								<Checkbox
									onChange={(e) => setQueryStringValue('down_payment_gte', e.target.checked ? 1 : 'none')}
									checked={filters.down_payment_gte == 1}
								/>
							}
							label="مع عربون فقط"
						/> */}
					</>
				)}
			</Stack>
			{/* {!isNullOrEmpty(filters) && `${Object.keys(filters).length} filters applied`} */}
		</>
	);
}
