import {Button, Card, Grid, Stack, Typography} from '@mui/material';
import React from 'react';
import {accountIs} from 'src/utils/siteUtils';
import useShippingProviders from './useShippingProviders';
import {useNavigate} from 'react-router-dom';
import ShippingProviderStateSwitch from 'src/components/ShippingProviderStateSwitch';

export default function DeliveryProvidersList() {
	const {data: shippingProviders = []} = useShippingProviders();

	return (
		<Grid container spacing={2}>
			{shippingProviders
				// show with the logo,name for each provider
				.map((shippingProvider) => {
					return <ShippingProviderItem provider={shippingProvider} />;
				})}

			{(accountIs('demo') || accountIs('alassalsweets')) && (
				<ShippingProviderItem
					provider={{
						id: 'foxline',
						name: 'foxline',
						bgcolor: 'black',
						customSettingsPageLink: `/plugins/foxline`,
						hidden: true,
						darkIconPath: './third-party-icons/foxline-icon-dark.png',
					}}
				/>
			)}
		</Grid>
	);
}

function ShippingProviderItem({provider}) {
	const {id, name, bgcolor, iconPath, darkIconPath, customSettingsPageLink} = provider;
	const imageSrc = darkIconPath ?? iconPath;
	const navigate = useNavigate();
	return (
		<Grid item xs={12} sm={6} md={4}>
			<Card
				sx={{
					p: 2,
					minHeight: 240,
				}}
			>
				<ShippingProviderStateSwitch provider={id} />
				<Stack spacing={1} alignItems="center">
					<img src={imageSrc} height={70} />
					<Typography variant="h5">{name}</Typography>
					<Button
						onClick={() => {
							navigate(customSettingsPageLink ? customSettingsPageLink : '/shipping-methods/' + id); // he want to stop this provider
						}}
						variant="outlined"
						color="primary"
					>
						اعدادات
					</Button>
				</Stack>
			</Card>
		</Grid>
	);
}
