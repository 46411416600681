import {Typography} from '@mui/material';
import React from 'react';
import {calcPriceAfterDiscount} from 'src/utils/discountUtils';
import {getSiteCurrency} from 'src/utils/selectors';

export default function Price({price, discount, fontSize = 14, sx = {}}) {
	const price_string = calcPriceAfterDiscount(price, discount) + ' ' + getSiteCurrency();

	const raw = `السعر: ${price_string}`;

	return <Typography sx={{fontSize, ...sx}}>{raw}</Typography>;
}

export function ItemPrice({item}) {
	const price = item.price ?? item.product?.price ?? 0;
	const discount = item.discount ?? item.product?.discount ?? 0;

	return <Price price={price} discount={discount} />;
}
