import {Container, Stack} from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useEffect, useState} from 'react';
import PlusButton from 'src/components/buttons/PlusButton.js';
import AddPropertyModal from 'src/components/_dashboard/properties/AddPropertyModal.js';
import Page from 'src/components/Page.js';
import PropertyRow from '../../components/_dashboard/ProductsProperties/PropertyRow/PropertyRow.js';
import {getProperties} from '../../utils/AdminApi.js';

const scrollBarStyling = {
	scrollbarWidth: 'none',
};

export default function ProductsProperties() {
	const [properties, setProperties] = useState([]);
	const [addModalIsVisible, setAddModalVisibility] = useState(false);

	// Fetching all properties async function then assigning them to properties
	async function fetchProperties() {
		try {
			const allProperties = await getProperties();
			setProperties(allProperties);
			console.dir(allProperties);
		} catch (e) {
			console.log(e);
		}
	}

	const handleDelete = (id) => {
		setProperties(properties.filter((p) => p.id != id));
	};

	// call fetch function when page is loaded
	useEffect(() => {
		fetchProperties();
	}, []);
	return (
		<Page title="Products Properties">
			<Container>
				<Stack sx={{mb: 2}} spacing={2} direction="row" justifyContent="space-between">
					<Typography variant="h3">خيارات المنتجات</Typography>
					<PlusButton onClick={() => setAddModalVisibility(true)} />
				</Stack>
				<Grid container spacing={2}>
					{properties.length !== 0 &&
						properties.map((property, index) => (
							<Grid item xs={12} key={property?.id}>
								<PropertyRow handleDelete={handleDelete} prop={property} />
							</Grid>
						))}
				</Grid>
			</Container>
			<AddPropertyModal
				isVisible={addModalIsVisible}
				setVisibility={setAddModalVisibility}
				onCreate={(newProperty) => {
					setProperties([...properties, newProperty]);
					setAddModalVisibility(false);
				}}
			/>
		</Page>
	);
}
