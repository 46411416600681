import {Container, Typography} from '@mui/material';
import React from 'react';
import DynamicForm from 'src/components/DynamicForm/DynamicForm';

export default function FinancePage() {
	return (
		<Container>
			{/*  */}
			{/* expected revenue from selling all products*/}
			<Typography>Calculate price</Typography>
			{/* 	<DynamicForm
				onSubmit={(v) => {
					const profit = (v.cost * v.margin) / 100;
					const price = v.cost + profit;
					alert(price);
				}}
				config={{
					inputs: [
						{
							name: 'cost',
							label: 'cost',
							placeholder: 'cost',
							type: 'number',
							value: 100,
						},
						{
							name: 'margin',
							label: 'margin',
							placeholder: 'margin',
							type: 'number',
							value: 50,
						},
					],
				}}
			/> */}
			<Typography mt={2}>Calculate price and break even</Typography>
			<DynamicForm
				onSubmit={(v, a) => {
					let cost = v.cost * v.currency;
					const total_costs = cost * v.quantity;
					const profit_per_unit = (cost * v.margin) / 100;
					const total_profit = profit_per_unit * v.quantity;
					const price = cost + profit_per_unit;
					const revenue = price * v.quantity;
					const adsBudget = revenue * (v.ads / 100);
					const sells_to_break_even = Math.ceil(total_costs / price);
					alert(
						`cost:${cost}LYD\nprice:${price}LYD\nrevenue=${revenue}LYD\n\nprofit=${total_profit}LYD\nshould sell:${sells_to_break_even}/${
							v.quantity
						} item\n\nads=${adsBudget}LYD\nprofit-ads=${
							total_profit - adsBudget
						}LYD\nshould sell=${Math.ceil((total_costs + adsBudget) / price)}/${v.quantity} item`,
					);
					a.setSubmitting(false);
				}}
				config={{
					inputs: [
						{
							name: 'currency',
							label: 'exchange rate',
							placeholder: 'currency exchange rate',
							type: 'number',
							value: 1,
							step: 0.5,
						},
						{
							name: 'cost',
							label: 'cost',
							placeholder: 'cost',
							type: 'number',
							value: 100,
						},
						{
							name: 'margin',
							label: 'margin',
							placeholder: 'profit margin',
							type: 'number',
							value: 50,
						},
						{
							name: 'quantity',
							label: 'quantity',
							placeholder: 'quantity',
							type: 'number',
							value: 10,
						},
						{
							name: 'ads',
							label: 'ads percentage',
							placeholder: 'ads',
							type: 'number',
							value: 25,
						},
					],
				}}
			/>
			{/* calculate price */}
		</Container>
	);
}
