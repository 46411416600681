import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Link as MuiLink} from '@mui/material';

export default function StyledLink({
	to,
	color = undefined,
	addHoverEffect = false,
	underline = 'hover',
	children = undefined,
	sx = {},
}) {
	return (
		<MuiLink
			sx={sx}
			className={addHoverEffect && 'hover-effect'}
			to={to}
			color={color}
			underline={underline}
			component={RouterLink}
		>
			{children}
		</MuiLink>
	);
}
