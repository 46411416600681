import React from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Card,
	LinearProgress,
	Stack,
	Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {getStoreProgress} from '../../utils/AdminApi.js';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Link from '@mui/material/Link';
import useFind from '../../hooks/useFind.js';

function StoreProgress() {
	const [{progress = null, tasks = []}, , {isLoading}] = useFind(getStoreProgress);
	if (!progress || progress === 100 || isLoading) return null;
	return (
		<Stack
			sx={{
				width: '100%',
				marginInline: 'auto',
				borderRadius: '25px',
				mb: 5,
			}}
		>
			<Accordion>
				<Card sx={{p: 2}}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Box
							sx={{
								...childrenCommonStyles,
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<Typography
								sx={{
									minWidth: 'fit-content',
									height: '100%',
									mx: 2,
									fontWeight: '700',
									marginInlineStart: 2,
									fontSize: '1.1em',
									flexGrow: 0.03,
								}}
							>
								نسبة إكتمال المتجر
							</Typography>
							<Box sx={{minWidth: 'max-content'}}>
								<Typography variant="body2" color="text.secondary">{`${Math.round(progress)}%`}</Typography>
							</Box>
							<Box sx={{flexGrow: 0.965, mx: 'auto'}}>
								<LinearProgress
									color="success"
									sx={{bgcolor: '#DADADA', marginInlineStart: 2}}
									variant="determinate"
									value={progress ?? 0}
								/>
							</Box>
						</Box>
					</AccordionSummary>
				</Card>

				<AccordionDetails sx={{p: 0}}>
					<Box
						sx={{
							width: '100%',
							height: '1.1em',
							// background:
							// 	'linear-gradient(180deg, rgba(222, 226, 232, 1) 0%, rgba(255, 255, 255, 1) 40%)',
						}}
					/>
					{tasks.map((task) => (
						<Link href={task.link} key={task.taskName}>
							<Box
								key={task.taskName}
								sx={{
									width: '100%',
									py: 2,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									cursor: 'pointer',
									bgcolor: 'white',
								}}
							>
								<Box
									sx={{
										width: '2.3em',
										borderRadius: '50%',
										position: 'relative',
										flexGrow: 0.05,
										px: 2,
									}}
								>
									{task.done ? (
										<CheckCircleIcon sx={{color: '#54D62C', fontSize: '2em'}} />
									) : (
										<RadioButtonUncheckedIcon sx={{fontSize: '2em', color: '#dcdfe3'}} />
									)}
								</Box>
								<Typography sx={{flexGrow: 0.95, fontSize: '1.4em'}}>{task.taskName}</Typography>
								<Box
									sx={{
										flexGrow: 0.03,
										display: 'flex',
										justifyContent: 'flex-end',
										paddingInlineEnd: 2,
									}}
								>
									<ArrowBackIosNewIcon sx={{fontSize: '1.3em'}} />
								</Box>
							</Box>
						</Link>
					))}
				</AccordionDetails>
			</Accordion>
		</Stack>
	);
}

export default StoreProgress;

const childrenCommonStyles = {
	width: '100%',
};
