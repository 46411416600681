import {DynamicFormConfig} from 'src/components/DynamicForm/DynamicForm.types';

type ThirdPartyApp = {
	id: string;
	name: string;
	darkIconPath?: string;
	soon?: boolean;
	formConfig: DynamicFormConfig;
};

const thirdPartyApps: {
	[key: string]: ThirdPartyApp;
} = {
	printerco: {
		id: 'printerco',
		name: 'printer co',
		darkIconPath: '/third-party-icons/printerco.png',
		formConfig: {
			inputs: [
				{
					type: 'text',
					name: 'printer_id',
					value: '',
					label: 'printer id',
				},
				{
					type: 'text',
					name: 'api_key',
					value: '',
					label: 'api key',
				},
				{
					type: 'text',
					name: 'license_key',
					value: '',
					label: 'license key',
				},
				{
					type: 'password',
					name: 'password',
					value: '',
					label: 'password',
				},
			],
		},
	},
	fb_pixel: {
		id: 'fb_pixel',
		name: 'facebook pixel',
		darkIconPath: '/third-party-icons/fb_pixel.png',
		formConfig: {
			inputs: [
				{
					type: 'text',
					name: 'id',
					value: '',
					label: 'facebook pixel id',
				},
			],
		},
	},
	fb_chat: {
		id: 'fb_chat',
		name: 'facebook chat plugin',
		darkIconPath: '/third-party-icons/fb_chat.png',
		formConfig: {
			inputs: [
				{
					type: 'text',
					name: 'id',
					value: '',
					label: 'facebook chat plugin id',
				},
			],
		},
	},
	google_analytics: {
		id: 'google_analytics',
		name: 'google analytics',
		darkIconPath: '/third-party-icons/google_analytics.png',
		soon: true,
		formConfig: {
			inputs: [
				// {
				// 	type: 'text',
				// 	name: 'id',
				// 	value: '',
				// 	label: 'facebook chat plugin id',
				// },
			],
		},
	},
};

export default thirdPartyApps;
