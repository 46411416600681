import {Card, Container, Grid, Stack, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import Page from 'src/components/Page.js';
import {availablePaymentMethods, paymentMethods} from 'src/components/payments/paymentMethods.js';
import {redirectToWhatsUp} from 'src/utils/links.js';
import {accountIs} from 'src/utils/siteUtils.js';
import {updateTenantConfigAction} from '../../../../reducers/index.js';
import PaymentMethodSelectionCard, {PaymentMethodCard} from './PaymentMethodCard.js';

const initialPaymentMethods = {
	// sadad: {
	// 	label: 'سداد',
	// 	value: 'sadad',
	// 	enabled: false,
	// },
	// adfali: {
	// 	label: 'ادفعلي',
	// 	value: 'adfali',
	// 	enabled: false,
	// },
	// bank: {
	// 	label: 'البطاقة المصرفية',
	// 	value: 'bank',
	// 	enabled: false,
	// },
};

const submitBtnSx = {
	width: '100%',
	mt: 2,
	display: 'flex',
	justifyContent: 'flex-end',
	alignItems: 'center',
};
export default function PymentMethodsPage() {
	const dispatch = useDispatch();
	const tenant = useSelector((s) => s.admin.user.OwnedWebsite);
	// show paypal and bunq only for demo account
	const methods =
		accountIs('demo') || accountIs('alassalsweets')
			? availablePaymentMethods
			: availablePaymentMethods.filter((m) => m.value !== 'paypal' && m.value !== 'bunq');
	// cash is enabled by default
	const storePaymentConfiguration = tenant.config?.payment_methods ?? {cash: {enabled: true}};
	// submit function
	const updatePaymentMethodHandler = async (gateway, newValue) => {
		const newPaymentConfiguration = {
			...storePaymentConfiguration,
			[gateway]: {
				...storePaymentConfiguration[gateway],
				enabled: newValue,
			},
		};

		// validation
		if (Object.values(newPaymentConfiguration).every((method) => method.enabled === false))
			return toast.error('يرجي اختيار طريقة دفع اخري لكي تتمكن من ايقاف طريقة الدفع');

		const newConfig = {
			...tenant.config,
			payment_methods: newPaymentConfiguration,
		};
		// the {id} is for the ownedWebsite
		try {
			await dispatch(updateTenantConfigAction(tenant.id, newConfig));
		} catch (err) {
			toast.error('حدث خطأ غير متوقع');
		}
	};

	return (
		<Page title="طرق الدفع">
			<Container sx={{height: '100%', width: '100%'}}>
				<h1>طرق الدفع</h1>
				<Grid mt={2} container spacing={4}>
					{methods.map((method) => (
						<Grid key={method.value} item xs={6} sm={4} md={3}>
							<PaymentMethodSelectionCard
								enabled={storePaymentConfiguration[method.value]?.enabled ?? false}
								method={method}
								toggleState={updatePaymentMethodHandler}
							/>
						</Grid>
					))}
				</Grid>
				{!tenant.config.t_lync && (
					<Stack alignItems="center" mt={4} spacing={1} direction="row">
						<Typography>لتفعيل الدفع الالكتروني يرجي التواصل معنا </Typography>
						<Button size="small" variant="contained" onClick={redirectToWhatsUp}>
							تواصل
						</Button>
					</Stack>
				)}
			</Container>
		</Page>
	);
}
