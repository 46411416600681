import {InputAdornment, TextField} from '@mui/material';
import React from 'react';

export default function PhoneInput({placeholder = '91xxxxxxx', ...props}) {
	const handleKeyDown = (e) => {
		if (/^[0-9]*$/.test(e.key) || e.keyCode === 8 || e.keyCode === 46) {
			return;
		}
		e.preventDefault();
	};
	return (
		<TextField
			fullWidth
			type="tel"
			inputProps={{style: {direction: 'ltr', paddingLeft: 8}, maxLength: 10}}
			placeholder={placeholder}
			InputProps={{
				inputMode: 'numeric',
				endAdornment: <InputAdornment position="start"> 🇱🇾 +218</InputAdornment>,
			}}
			// onKeyDown={handleKeyDown}
			label="رقم الهاتف"
			{...props}
		/>
	);
}
