import {Container, Stack, Typography} from '@mui/material';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import DynamicForm from 'src/components/DynamicForm/DynamicForm';
import {string} from 'yup';
import {
	updateTenantConfigAction,
	updateTenantStoreConfigAction,
} from '../../../../reducers/index.js';

export default function PaypalSettingsPage() {
	const dispatch = useDispatch();
	const tenant = useSelector((s) => s.admin.user.OwnedWebsite);
	const store_config = tenant.store_config ?? {};
	const {client_id = '', rate = 1, currency} = store_config.paypal ?? {};

	const formConfig = {
		inputs: [
			{
				label: 'client id',
				name: 'client_id',
				type: 'text',
				validation: string().required('يرجي ادخال رمز المتجر'),
				value: client_id,
			},
			{
				label: 'العملة',
				name: 'currency',
				options: ['USD', 'EUR', 'GBP'],
				type: 'picker',
				validation: string().required('يرجي ادخال العملة'),
				value: currency,
			},
			{
				label: 'سعر الصرف',
				name: 'rate',
				type: 'number',
				// validation: nameValidation,
				value: rate ?? 1,
			},
		],
	};
	// submit function
	const updatePaymentMethodHandler = async (values) => {
		const newConfig = {
			...store_config,
			paypal: values,
		};
		try {
			await dispatch(updateTenantStoreConfigAction(tenant.id, newConfig));
			toast.success('تم حفظ التغيرات بنجاح');
		} catch (err) {
			toast.error('حدث خطأ غير متوقع');
		}
	};

	return (
		<Container sx={{height: '100%', width: '100%'}}>
			<Typography sx={{textAlign: 'center', color: 'gray', pb: 4}} variant="subtitle2">
				اعدادات الدفع الالكتروني
			</Typography>
			<Stack>
				<DynamicForm
					submitButtonText="تحديث"
					config={formConfig}
					onSubmit={updatePaymentMethodHandler}
				/>
			</Stack>
		</Container>
	);
}
