import {useState} from 'react';

export default function useVisible(defaultState = false) {
	const [isVisible, setIsVisible] = useState(defaultState);

	function show() {
		setIsVisible(true);
	}
	function hide() {
		setIsVisible(false);
	}
	function toggle() {
		setIsVisible(!isVisible);
	}
	return {
		isVisible,
		show,
		hide,
		toggle,
		setIsVisible,
	};
}
