import {Button, Container, Stack, Typography} from '@mui/material';
import React from 'react';
import {useSelector} from 'react-redux';
import Logo from 'src/components/Logo';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import {redirectToWhatsUp} from 'src/utils/links';

export default function ExpiredSite() {
	const {subscription = 'free'} = useSelector((state) => state.admin.user.OwnedWebsite);
	const isFreeTrial = isNullOrEmpty(subscription) || subscription === 'free';

	// subscription expired
	const subscriptionExpiryTextByType = isFreeTrial
		? 'انتهت فترة الاشتراك التجريبي الخاص بك'
		: 'انتهت فترة الاشتراك الخاص بك';
	const expirationText = `${subscriptionExpiryTextByType} `;

	const callToActionText = isFreeTrial ? 'اشترك الان' : 'تجديد الاشتراك';

	const onClick = () => {
		redirectToWhatsUp();
		// window.location = window.location.origin + '/payment';
		// window.location = 'https://vetrinas.ly/subscribe.html';
	};

	return (
		<div
			style={{
				// margin: 0,
				height: '100vh',
				width: '100vw',
				backgroundSize: 'cover',
				backgroundImage: `url("/static/backgrounds/gradientwavebackground.png")`,
				backgroundRepeat: 'no-repeat',
			}}
		>
			<Container>
				<Stack height="80vh" pt={4} alignItems="center" justifyContent="center">
					<Logo />
					<Stack mt={4} spacing={2} alignItems="center">
						<Typography variant="h5"> {expirationText}</Typography>
						<Typography variant="body1">للاستمرار في استخدام منصة فترينا يرجي تجديد الاشتراك</Typography>
						<Button onClick={onClick} size="large" variant="contained">
							{callToActionText}
						</Button>
					</Stack>
				</Stack>
			</Container>
		</div>
	);
}
