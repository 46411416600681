import {Form, Formik} from 'formik';
import {filter, filterObject} from 'src/utils/objectUtils';

// TODO proptypes
export default function FormikWrapper({
	values,
	handleSubmit,
	children,
	hideSubmitButton = true,
	schema,
}) {
	const showSubmitButton = !hideSubmitButton;
	// merge between getFieldProps and getHelperProps
	const getInputProps =
		({getFieldProps, ...formikProps}) =>
		(inputName) => {
			// for material ui extra props to handle the error text and visiblity
			const helperProps = {
				onWheel: (e) => e.target.blur(),
				error: Boolean(formikProps.touched[inputName] && formikProps.errors[inputName]),
				helperText: formikProps.touched[inputName] && formikProps.errors[inputName],
			};

			const r = {...helperProps, ...getFieldProps(inputName)};

			return filter(r, (v) => v != null);
		};
	//   we need function take input name and return object
	// currently i'am return fixed object

	const renderInputs = (formikProps) => {
		// const inputProps = getInputProps(formikProps);
		return children({
			...formikProps,
			getInputProps: getInputProps(formikProps),
		});
	};

	const renderChild = (formikProps) => {
		return (
			<Form autoComplete="off" onSubmit={formikProps.handleSubmit}>
				{renderInputs(formikProps)}
				{showSubmitButton &&
					(formikProps.isSubmitting ? (
						'loading'
					) : (
						<input style={{marginTop: 12}} type="submit" value="Submit" />
					))}
			</Form>
		);
	};

	return (
		<Formik
			enableReinitialize
			onSubmit={handleSubmit}
			initialValues={values}
			validationSchema={schema}
			// eslint-disable-next-line react/no-children-prop
			children={renderChild}
		/>
	);
}
