import {Card, Divider, Stack, TextField, Typography} from '@mui/material';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {updateTenantAction} from '../../../reducers/index.js';
import {updateTenantSchhema} from '../../../schema/authSchema.js';
import FormikWrapper from '../../wrappers/FormikWrapper.js';
import ImageCropPicker from '../../inputs/ImageCropPicker.js/ImageCropPicker.js';
import SubmitButton from '../../buttons/SubmitButton.js';
import FileUploadField from 'src/components/inputs/ImageCropPicker.js/FileUploadField.js';

const defaultStyles = {
	colors: {
		primary: '#161C24',
		background: '#FDFCF9',
		text: '#161C24',
	},
};

const getPrimaryColor = (styles) => {
	return styles?.colors?.primary ?? defaultStyles.colors.primary;
};

const BasicInfoForm = () => {
	const user = useSelector((s) => s.admin.user);
	const site = user.OwnedWebsite;

	const dispatch = useDispatch();
	const handleSubmit = async (values) => {
		const {site_name, color, description, logo} = values;

		toast.promise(
			dispatch(
				updateTenantAction(
					site.id,
					site_name,
					{
						...defaultStyles,
						colors: {...defaultStyles.colors, primary: color},
					},
					{
						...site?.config,
						meta: {description},
					},
					logo,
				),
			),
			{
				error: 'حدث خطا غير متوقع',
				success: 'تم التحديث بنجاح',
				pending: 'يرجي الانتظار',
			},
		);
	};

	return (
		<Card sx={{maxWidth: 600, p: 4}}>
			<Stack>
				{/*// ====================================================================================*/}
				{/*// step Header*/}
				{/* <Stack
					sx={{maxWidth: 600, mb: 5}}
					direction="row"
					alignItems="center"
					justifyContent="space-between"
				>
					<Typography variant="h4" gutterBottom>
						اعدادات الموقع
					</Typography>
				</Stack> */}

				{/*// ============================================================================================*/}
				{/*// Formik Wrapper for both Forms*/}
				<FormikWrapper
					hideSubmitButton
					values={{
						site_name: site.site_name ?? '',
						color: getPrimaryColor(site.style),
						description: site.config?.meta?.description,
						pixelId: site.config?.fb_pixel?.id,
						fbChatId: site.config?.fb_chat?.id,
						logo: site.logo?.id,
					}}
					schema={updateTenantSchhema}
					handleSubmit={handleSubmit}
				>
					{({isSubmitting, getInputProps, setFieldValue, values}) => (
						<Stack spacing={3}>
							<TextField label="ااسم الموقع" placeholder="الاسم" {...getInputProps('site_name')} />
							<TextField
								multiline
								minRows={2}
								label="وصف الموقع"
								placeholder="وصف الموقع"
								{...getInputProps('description')}
							/>
							<FileUploadField
								{...getInputProps('logo')}
								onChange={(newValue) => {
									setFieldValue('logo', newValue);
								}}
								defaultImage={site.logo?.url}
								ratio={1}
								label="شعار المحل"
							/>
							<Stack direction="row" justifyContent="space-between">
								<p>لون الموقع الاساسي</p>
								<input
									type="color"
									onChange={(e) => setFieldValue('color', e.target.value)}
									label="لون الموقع الاساسي"
									name="color"
									value={values.color}
								/>
							</Stack>
							<SubmitButton isSubmitting={isSubmitting}>حفظ التغيرات</SubmitButton>
						</Stack>
					)}
				</FormikWrapper>
			</Stack>
		</Card>
	);
};

export default BasicInfoForm;
