import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {Box, Button, Divider, IconButton, Stack, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import Page from 'src/components/Page.js';
import {getDateTimeString} from '../../utils/date.js';

function NotificationPage(props) {
	let {id} = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const [notification, setNotification] = useState(null);
	const userNotifications = useSelector((state) => state.admin.user.notifications);

	const fetchNotification = () => {
		let selectedNotificationIndex = Object.values(userNotifications);
		setNotification(selectedNotificationIndex.find((b) => b.id == id));
	};

	useEffect(() => {
		fetchNotification(id);
	}, [id, location]);

	function followLinkHandler() {
		navigate(notification.link);
	}

	function goBackHandler() {
		navigate('/notifications');
	}

	return (
		<Page>
			{notification && (
				<Stack sx={{px: '0.5rem', pt: '1rem'}} gap={2}>
					<Box sx={titleContStyles}>
						<Box sx={styles.titleArrow}>
							<Box sx={styles.backArrow}>
								<IconButton onClick={goBackHandler}>
									<ArrowForwardTwoToneIcon />
								</IconButton>
							</Box>
							<Typography variant="h1">{notification.title}</Typography>
						</Box>

						<Divider {...allProps.dividers.captions} />

						<Box sx={styles.captionsCont}>
							<Box sx={styles.seenCont}>
								<Typography variant="caption" sx={{width: 'max-content'}}>
									{!notification.seen && 'not '}seen
								</Typography>
								<DoneAllIcon color={notification.seen ? 'info' : 'action'} />
							</Box>
							<Typography sx={{width: 'max-content'}} variant="caption">
								{getDateTimeString(notification.created_at)}
							</Typography>
						</Box>
					</Box>
					<Divider orentation="horizontal" />

					<Typography {...allProps.mainBody.typo}>{notification.body}</Typography>

					{notification.link && (
						<Box sx={styles.goToLink}>
							<Button {...allProps.goToLink} onClick={followLinkHandler}>
								الذهاب للصفحة
							</Button>
						</Box>
					)}
				</Stack>
			)}
		</Page>
	);
}

export default NotificationPage;
const titleContStyles = {
	display: 'flex',
	flexDirection: {xs: 'column', sm: 'row'},
	justifyContent: 'space-between',
	alignItems: 'center',
	gap: 1,
};

const mobileBackArrowContStyles = {
	display: {xs: 'initial', md: 'none'},
};

const styles = {
	titleArrow: {
		...titleContStyles,
		flexGrow: 0.8,
		flexDirection: 'row',
		alignItems: 'center',
		width: {xs: '100%', sm: 'auto'},
		justifyContent: {
			xs: 'flex-start',
			sm: 'space-between',
		},
	},
	backArrow: mobileBackArrowContStyles,
	mainBody: {textAlign: 'justify', textJustify: 'inter-word', px: 1.5},
	goToLink: {...titleContStyles, justifyContent: 'center'},
	captionsCont: {
		...titleContStyles,
		gap: 2,
		flexDirection: 'row',
		flexGrow: 0.1,
		width: 'max-content',
	},
	seenCont: {...titleContStyles, gap: 0.1, flexDirection: 'row'},
};

const allProps = {
	mainBody: {
		typo: {
			fontSize: 'large',
			variant: 'body1',
			sx: styles.mainBody,
		},
	},
	dividers: {
		captions: {
			orentation: 'horizontal',
			sx: {display: {xs: 'initial', sm: 'none'}},
		},
	},

	goToLink: {
		size: 'large',
		color: 'secondary',
	},
};
