import {useState, useEffect} from 'react';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
// material
import {Button, Card, Container, Stack, Typography} from '@mui/material';
import Page from 'src/components/Page';
import DynamicList from 'src/components/DynamicList';
import equalId from 'src/utils/equalId';
import useUrlQuery from 'src/hooks/useUrlQuery';
import {DateTime} from 'luxon';

export default function ProductInventoryChanges({}) {
	const {id} = useParams();
	const navigate = useNavigate();
	const {variationId} = useUrlQuery();

	// const [state, setState] = useState();
	// useEffect(() => {}, []);

	return (
		<Page title="فترينا">
			<Container maxWidth="md">
				<Card sx={{p: 3}}>
					<Typography variant="h4">تغيرات المخزون للمنتج</Typography>
					<DynamicList
						api_route={`tenant/products/${id}/inventory-changes`}
						cols={[
							// {key: 'id', label: 'id'},
							// {key: 'variation', label: 'variation'},
							{
								key: 'type',
								label: '#',
								render: (v, item) => {
									console.log('the item', item);
									return (
										<Link
											style={{color: '#1B925F'}}
											to={
												v === 'order'
													? `/orders/${item.parent}`
													: item.type === 'purchase'
														? `/purchases/${item.parent}`
														: item.type === 'manual'
															? `/product-quantity-updates/${item.parent}`
															: '#'
											}
										>
											{types[v]}
										</Link>
									);
								},
							},
							{key: 'quantity', label: 'الكمية'},
							{key: 'price', label: 'السعر'},
							{key: 'total', label: 'المجموع'},
							{
								key: 'created_at',
								label: 'التاريخ',
								getValue: (v) => DateTime.fromISO(v).toFormat('yyyy-MM-dd - HH:mm'),
							},
						]}
						transformData={(data) =>
							data.filter((d) => {
								if (variationId) {
									return equalId(d.variation, variationId);
								}
								return d;
							})
						}
					/>
				</Card>
			</Container>
		</Page>
	);
}

const types = {
	order: 'طلب',
	purchase: 'مشتريات',
	manual: 'جرد',
	transfer: 'نقل',
};
