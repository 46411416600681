import React, {useRef, useState} from 'react';
import {Button, Stack, TextField} from '@mui/material';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import printJS from 'print-js';
import {useReactToPrint} from 'react-to-print';
import {updateProduct} from 'src/utils/AdminApi';
import {toast} from 'react-toastify';

const scale = 5; // better quality
export default function ProductBarcodeGenerator({pid, sku = '', onChange}) {
	const [oldSku, setOldSku] = useState(sku);
	let [barcode, setBarcode] = useState(sku ?? '');
	const barcodeImageUrl = `https://bwipjs-api.metafloor.com/?bcid=code128&text=${barcode}&includetext&scale=${scale}`;
	const imgRef = useRef(null);

	const autoFillBarcodeWithProductId = () => {
		onChange(`${pid}`);
	};
	const generateBarcodeImage = () => {
		setBarcode(sku);
	};

	const updateBarcode = async () => {
		try {
			await updateProduct(pid, {
				sku,
			});
			setOldSku(sku);
			toast.success('تم التحديث بنجاح');
		} catch (err) {
			toast.error('حدث خطأ غير متوقع');
		}
	};
	// const handlePrint = () => {
	// 	printJS({
	// 		printable: `<img src="${barcodeImageUrl}" />`,
	// 		type: 'raw-html',
	// 		header: 'header',
	// 	});
	// };
	const handlePrint = useReactToPrint({
		content: () => imgRef.current,
		pageStyle: `
	
		@page  {
	 padding: 0;
	 size: 28mm 30mm;
	 margin: 0;
	 
 }

	 
      html, body {
        margin: 0;      /* Ensure no margin in body */
        padding: 0;     /* Ensure no padding in body */
         width: 100%;
         height: 95%;    

      }
		


    }
  		`,
	});

	return (
		<Stack spacing={2}>
			<Stack spacing={1} direction="row" justifyContent="space-between">
				<TextField
					fullWidth
					type="text"
					label="كود المنتج"
					name="sku"
					value={sku ? sku : ''}
					onChange={(e) => onChange(e.target.value)}
				/>
				{isNullOrEmpty(sku) && (
					<Button sx={{minWidth: 120}} variant="outlined" onClick={autoFillBarcodeWithProductId}>
						انشاء باركود
					</Button>
				)}
				{!isNullOrEmpty(sku) && (isNullOrEmpty(barcode) || sku != barcode) && (
					<Button sx={{minWidth: 120}} variant="outlined" onClick={generateBarcodeImage}>
						عرض الباركود
					</Button>
				)}
			</Stack>

			{!isNullOrEmpty(barcode) && barcode === sku && (
				<>
					{/* <img
						alt="product barcode"
						style={{height: 150, width: 'auto', objectFit: 'contain'}}
						src={barcodeImageUrl}
					/> */}

					<div style={{height: '100px', width: '100px'}} className="">
						<img
							ref={imgRef}
							style={{
								// border: 'solid 1px red',
								// background: 'blue',
								height: '100%',
								width: '100%',
							}}
							src={`https://bwipjs-api.metafloor.com/?bcid=code128&text=${barcode}&includetext&scale=5`}
						/>
					</div>
					<Button variant="outlined" type="button" onClick={handlePrint}>
						طباعة
					</Button>
				</>
			)}
			{oldSku != sku && (
				<Button variant="outlined" type="button" onClick={updateBarcode}>
					حفظ الباركود
				</Button>
			)}
		</Stack>
	);
}
