export const dhlProductTable = [
	{ProductCode: 'K', Token: 'TDK', Name: 'Express 9.00'},
	{ProductCode: 'T', Token: 'TDT', Name: 'Express 12.00'},
	{ProductCode: 'Y', Token: 'TDY', Name: 'Express 12.00'},
	{ProductCode: 'E', Token: 'TDE', Name: 'Express 9.00'},
	{ProductCode: 'P', Token: 'WPX', Name: 'Express Wordwilde'},
	{ProductCode: 'U', Token: 'ECX', Name: 'Express Wordwilde (EU)'},
	{ProductCode: 'D', Token: 'DOX', Name: 'Wordwilde'},
	{ProductCode: 'N', Token: 'DOM', Name: 'Domestic Express'},
	{ProductCode: 'H', Token: 'ESI', Name: 'Economy select'},
	{ProductCode: 'W', Token: 'ESU', Name: 'Economy select (EU)'},
];

export const dhlShippingTerms = [
	{code: 'EXW', description: 'ExWorks'},
	{code: 'FCA', description: 'Free Carrier'},
	{code: 'CPT', description: 'Carriage Paid To'},
	{code: 'CIP', description: 'Carriage and Insurance Paid To'},
	{code: 'DPU', description: 'Delivered at Place Unloaded'},
	{code: 'DAP', description: 'Delivered at Place'},
	{code: 'DDP', description: 'Delivered Duty Paid'},
	{code: 'FAS', description: 'Free Alongside Ship'},
	{code: 'FOB', description: 'Free on Board'},
	{code: 'CFR', description: 'Cost and Freight'},
	{code: 'CIF', description: 'Cost, Insurance and Freight'},
	{code: 'DAF', description: 'Delivered at Frontier'},
	{code: 'DAT', description: 'Delivered at Terminal'},
	{code: 'DDU', description: 'Delivered Duty Unpaid'},
	{code: 'DEQ', description: 'Delivery ex Quay'},
	{code: 'DES', description: 'Delivered ex Ship'},
];

export const dhlPackageCodes = [
	{code: '3BX', description: 'Third Box'},
	{code: '2BC', description: 'Second Box Carton'},
	{code: '2BP', description: 'Second Box Pallet'},
	{code: 'CE1', description: 'Customs Entry 1'},
	{code: '7BX', description: 'Seventh Box'},
	{code: '6BX', description: 'Sixth Box'},
	{code: '4BX', description: 'Fourth Box'},
	{code: '2BX', description: 'Second Box'},
	{code: '1CE', description: 'First Customs Entry'},
	{code: 'WB1', description: 'Waybill 1'},
	{code: 'WB3', description: 'Waybill 3'},
	{code: 'XPD', description: 'Express Delivery'},
	{code: '8BX', description: 'Eighth Box'},
	{code: '5BX', description: 'Fifth Box'},
	{code: 'WB6', description: 'Waybill 6'},
	{code: 'TBL', description: 'Table Load'},
	{code: 'TBS', description: 'Table Shipment'},
	{code: 'WB2', description: 'Waybill 2'},
];

export const dhlTypeCode = [
	'direct_consumer',
	'business',
	'government',
	'other',
	'private',
	'reseller',
];

export const dhlUnitDescriptions = [
	{code: 'BOX', description: 'Boxes'},
	{code: '2GM', description: 'Centimeters'},
	{code: '2M3', description: 'Cubic Centimeters'},
	{code: '3M3', description: 'Cubic Feet'},
	{code: 'M3', description: 'Cubic Meters'},
	{code: 'DPR', description: 'Dozen Pairs'},
	{code: 'DOZ', description: 'Dozen'},
	{code: '2NO', description: 'Each'},
	{code: 'PCS', description: 'Pieces'},
	{code: 'GM', description: 'Grams'},
	{code: 'GRS', description: 'Gross'},
	{code: 'KG', description: 'Kilograms'},
	{code: 'L', description: 'Liters'},
	{code: 'M', description: 'Meters'},
	{code: '3GM', description: 'Milligrams'},
	{code: '3L', description: 'Milliliters'},
	{code: 'X', description: 'No Unit Required'},
	{code: 'NO', description: 'Number'},
	{code: '2KG', description: 'Ounces'},
	{code: 'PRS', description: 'Pairs'},
	{code: '2L', description: 'Gallons'},
	{code: '3KG', description: 'Pounds'},
	{code: 'CM2', description: 'Square Centimeters'},
	{code: '2M2', description: 'Square Feet'},
	{code: '3M2', description: 'Square Inches'},
	{code: 'M2', description: 'Square Meters'},
	{code: '4M2', description: 'Square Yards'},
	{code: '3M', description: 'Yards'},
	{code: 'CM', description: 'Centimeters'},
	{code: 'CONE', description: 'Cone'},
	{code: 'CT', description: 'Carat'},
	{code: 'EA', description: 'Each'},
	{code: 'LBS', description: 'Pounds'},
	{code: 'RILL', description: 'Rill'},
	{code: 'ROLL', description: 'Roll'},
	{code: 'SET', description: 'Set'},
	{code: 'TU', description: 'Time Unit'},
	{code: 'YDS', description: 'Yard'},
];
