import FilterIcon from '@mui/icons-material/Tune';
import {
	Chip,
	IconButton,
	Stack,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {useState} from 'react';
import BranchPicker from 'src/components/BranchPicker';
import Picker from 'src/components/inputs/Picker';

import useDataSearch from 'src/hooks/useDataSearch';
import useUrlFilters from 'src/hooks/useUrlFilters';
import useUser from 'src/hooks/useUser';
export default function InvoicePageFilter() {
	const [isOpen, setisOpen] = useState(false);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const {filters, setQueryStringValue, removeFilter} = useUrlFilters({ignoreNullOrEmpty: true});
	const {user} = useUser();
	const branch = user.branch;

	const {filter: search_value, onSearch} = useDataSearch((value) => {
		setQueryStringValue('number_contains', value);
	}, filters.number_contains);

	return (
		<Stack>
			<Stack my={1} spacing={2} direction={{sm: 'row', xs: 'column'}} flexWrap={'wrap'}>
				<Stack flex={1} spacing={1} direction="row" justifyContent="space-between">
					<TextField
						label="بحث"
						sx={{minWidth: '50%'}}
						size="medium"
						fullWidth
						placeholder="البحث رقم الفاتورة"
						type="text"
						value={search_value ?? ''}
						onChange={onSearch}
					/>
					<Picker
						sx={{minWidth: 100}}
						value={filters['type'] ?? 'none'}
						onChange={(e) => {
							setQueryStringValue('type', e.target.value);
						}}
						options={[
							{
								label: 'الكل',
								value: 'none',
							},
							{
								label: 'طلب',
								value: 'order',
							},
							{
								label: 'عربون',
								value: 'down_payment',
							},
							{
								label: 'استبدال',
								value: 'exchange',
							},
							{
								label: 'استرجاع',
								value: 'refund',
							},
						]}
						labelKey="label"
						valueKey="value"
						label="النوع"
					/>

					{isSmallScreen && (
						<IconButton onClick={() => setisOpen(!isOpen)}>
							<FilterIcon />
						</IconButton>
					)}
				</Stack>
				{(!isSmallScreen || isOpen) && (
					<>
						<Stack spacing={1} direction={{sm: 'row', xs: 'column'}}>
							{!branch && (
								<BranchPicker
									minWidth={120}
									variant="outlined"
									value={filters.branch ?? ''}
									onChange={(e) => setQueryStringValue('branch', e.target.value)}
								/>
							)}
							<Stack spacing={1} direction="row">
								<TextField
									InputLabelProps={{shrink: true}}
									// pattern="\d{4}-\d{2}-\d{2}"
									// type="date"
									type="datetime-local"
									fullWidth
									label="تاريخ الدفع: من"
									placeholder="من"
									onChange={(e) => setQueryStringValue('paid_at_gte', e.target.value ?? '')}
									value={filters.paid_at_gte ?? ''}
									required
								/>
								<TextField
									InputLabelProps={{shrink: true}}
									// pattern="\d{4}-\d{2}-\d{2}"
									// type="date"
									type="datetime-local"
									fullWidth
									label="الي"
									placeholder="الي"
									// '2023-02-09T23:59:59.000Z'
									onChange={(e) => setQueryStringValue('paid_at_lte', e.target.value ?? '')}
									value={filters.paid_at_lte ?? ''}
									required
								/>
							</Stack>
						</Stack>
					</>
				)}
			</Stack>
			{/* list of filters */}
			<Stack direction="row" spacing={1}>
				{Object.keys(filters)
					.filter((f) => f === 'none')
					.map((filter) => (
						<Chip label={filters[filter]} onDelete={() => setQueryStringValue(filter, '')} />
					))}
			</Stack>

			{/* {!isNullOrEmpty(filters) && `${Object.keys(filters).length} filters applied`} */}
		</Stack>
	);
}
