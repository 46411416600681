import * as yup from 'yup';
import {
	emailValidation,
	phoneValidation,
} from 'src/schema/commanValidationSchema';

export const updateAdditionalIfnoSchema = yup.object({
	storeEmail: emailValidation,
	storePhone: phoneValidation,
});
