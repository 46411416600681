import React from 'react';
import useUrlQuery from 'src/hooks/useUrlQuery';
import OrdersFilter from 'src/pages/Orders/list/OrdersFilter';
import qs from 'qs';
import {useQuery} from 'react-query';
import {request} from 'src/utils/AdminApi';
import TableWrapper from 'src/components/wrappers/TableWrapper';
import {Card, Typography} from '@mui/material';
import Layout from 'src/components/Layout';
import {LoadingView} from 'src/routes';
export default function OrderProductsReport({maxWidth = 'md'}) {
	const query = useUrlQuery();
	const route = `/reports/orders-products?${qs.stringify(query)}`;
	const {data, isLoading, refetch, error} = useQuery(route, () => request(route));

	return (
		<Layout title={'تقرير الطلبات'} titleVariant={'h6'} maxWidth={maxWidth} minHeight="60vh">
			<OrdersFilter hidden={['search', 'creator', 'payment_method', 'payment_received']} />

			{isLoading && <LoadingView minHeight="40vh" />}
			{error && <Typography color="error">{error.message ?? 'حدث خطا غير متوقع'}</Typography>}
			{data?.products?.length > 0 && (
				<TableWrapper
					headerColumns={[
						{key: 'name', label: 'الصنف'},
						{key: 'ordered_quantity', label: 'العدد'},
					]}
					align="start"
					data={data?.products ?? []}
				/>
			)}
			{data?.products?.length === 0 && (
				<Typography textAlign="center" my={8}>
					لا يوجد بيانات
				</Typography>
			)}
		</Layout>
	);
}
