// material
import {Button, CircularProgress, Stack, TextField, Typography} from '@mui/material';
import {Form, FormikProvider, useFormik} from 'formik';
import {useState} from 'react';
// redux
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import PasswordField from 'src/components/inputs/PasswordField';
import {handleRequestError} from 'src/utils/helperMethods';
import {redirectToWhatsUp} from 'src/utils/links';
import * as Yup from 'yup';
import {Login as LoginAction} from 'src/reducers/adminAuth/adminAuthActions';

// ----------------------------------------------------------------------

export default function AdminLoginForm() {
	const dispatch = useDispatch();

	const navigate = useNavigate();
	const [error, setError] = useState('');

	const LoginSchema = Yup.object().shape({
		email: Yup.string().email('ادخل بريد الكتروني صحيح').required('يحب ادخال البريد الالكتروني'),
		password: Yup.string().required('ادخل كلمة المرور'),
	});

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
			remember: true,
		},
		validationSchema: LoginSchema,
		onSubmit: async (v) => {
			setError('');
			try {
				await dispatch(LoginAction(v.email, v.password));
				navigate('/admin', {replace: true});
			} catch (err) {
				setError(
					handleRequestError(err, (status) => status === 400 && 'بريد الكتروني او كلمة مرور غير صحيحة'),
				);
			}
		},
	});

	const {errors, touched, isSubmitting, handleSubmit, getFieldProps} = formik;

	return (
		<FormikProvider value={formik}>
			<Form autoComplete="off" noValidate onSubmit={handleSubmit}>
				<Stack spacing={3}>
					<TextField
						fullWidth
						autoComplete="البريد الالكتروني"
						type="email"
						label="البريد الالكتروني"
						{...getFieldProps('email')}
						error={Boolean(touched.email && errors.email)}
						helperText={touched.email && errors.email}
					/>

					<PasswordField
						{...getFieldProps('password')}
						label="كلمة المرور"
						fullWidth
						error={Boolean(touched.password && errors.password)}
						helperText={touched.password && errors.password}
					/>
				</Stack>

				{/*
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
  <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="تذكرني"
          />

          <Link component={RouterLink} variant="subtitle2" to="#">
            Forgot password?
          </Link>
        </Stack> */}

				{isSubmitting ? (
					<CircularProgress color="secondary" sx={{mt: 4}} />
				) : (
					<Button
						sx={{mt: 4}}
						fullWidth
						size="large"
						type="submit"
						variant="contained"
						color="secondary"
						// onClick={()=>setSubmitting(true)}
						// loading={isSubmitting}
					>
						تسجيل الدخول
					</Button>
				)}

				<Button sx={{mt: 2}} fullWidth onClick={redirectToWhatsUp} size="large" variant="outlined">
					انشاء حساب
				</Button>

				{error && (
					<Typography color="red" pt={2}>
						{error}
					</Typography>
				)}
			</Form>
		</FormikProvider>
	);
}
