import {Button, Container, Stack, Switch, Typography} from '@mui/material';
import {useQuery, useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import NavButton from 'src/components/NavButton';
import Page from 'src/components/Page';
import PlusButton from 'src/components/buttons/PlusButton';
import DeleteAlertDialog from 'src/components/modals/DeleteAlertDialog';
import TableWrapper from 'src/components/wrappers/TableWrapper';
import {
	deleteDeliveryMethod,
	getDeliveryMethods,
	request,
	updateDeliveryMethods,
	updateDeliveryMethods2,
} from 'src/utils/AdminApi';
import {handleRequestError} from 'src/utils/helperMethods';
import DeliveryProvidersList from './DeliveryProviders.list';
import {accountIsDemo} from 'src/utils/customSite';

export default function DeliveryMethods() {
	// fetch data from delivery methods
	const {data = [], refetch} = useQuery('delivery_methods', () => getDeliveryMethods(), {
		refetchOnWindowFocus: false,
		// refetchOnMount: false,
	});
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const handleDelete = async (id) => {
		try {
			await deleteDeliveryMethod(id);
			queryClient.refetchQueries('delivery_methods');
			toast.success('تم الحذف بنجاح');
		} catch (error) {
			toast.error(handleRequestError(error));
		}
	};
	return (
		<Page title="الشحن">
			<Container>
				<Stack spacing={1} direction="row" justifyContent="space-between">
					<Typography variant="h4">طرق الاستلام والتوصيل</Typography>
					<PlusButton onClick={() => navigate('/delivery-methods/create')} />
				</Stack>
				{/* custom delivery methods */}
				<TableWrapper
					headerColumns={['الاسم', 'النوع', 'تفعيل']}
					dataRows={data.map((ele) => [
						ele.name,
						ele.type === 'flatRate' ? 'توصيل' : 'استلام شخصي',
						<Switch
							size="small"
							checked={ele.enabled}
							onChange={async (_, v) => {
								await updateDeliveryMethods2(ele.id, {
									enabled: v,
								});
								refetch();
							}}
						/>,
						<NavButton key={ele.id} text="تعديل" to={`/delivery-methods/${ele.id}`} />,
						<DeleteAlertDialog key={ele.id} onDelete={() => handleDelete(ele.id)} />,
					])}
				/>
				{/* 3rd party delivery methods */}
				<Typography sx={{my: 2}} variant="h4">
					الربط مع شركات الشحن
				</Typography>

				<DeliveryProvidersList />

				{accountIsDemo() && (
					<Button
						onClick={async () => {
							const res = await request('/deliveryOptions');
							console.log('res', res);
						}}
					>
						test delivery options ( see console )
					</Button>
				)}
			</Container>
		</Page>
	);
}
