import {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
// material
import {Button, Card, Container, Divider, Stack, Typography} from '@mui/material';
import Page from 'src/components/Page';
import {useQuery} from 'react-query';
import {request} from 'src/utils/AdminApi';
import Row from 'src/components/ui/Row';

export default function CustomerDetails({}) {
	const {id} = useParams();
	const {data, isLoading} = useQuery(['customer', id], () => request(`/customers/${id}`));
	const navigate = useNavigate();

	const [state, setState] = useState();
	useEffect(() => {}, []);

	if (isLoading) return '';
	return (
		<Page title="فترينا">
			<Container maxWidth="md">
				<Card sx={{p: 3}}>
					<Stack spacing={2}>
						<Stack direction="row" justifyContent="space-between">
							<Typography variant="h4">حساب العميل</Typography>
						</Stack>
						<Row label="الاسم" value={data.name} />
						<Row label="البريد الالكتروني" value={data.email} />
						<Typography variant="h6">العنوان</Typography>
						<Row label="المدينة" value={data.city} />
						<Row label="المنطقة" value={data.district} />
						<Row label="العنوان" value={data.address} />
						<Divider />
						<Row label="حالة الحساب" value={!!data.blocked ? 'محظور' : 'فعال'} />
					</Stack>
				</Card>
			</Container>
		</Page>
	);
}
