const {useState, useEffect} = require('react');
const {default: useFind} = require('src/hooks/useFind');
const {getProperties} = require('src/utils/AdminApi');
const {getVariationOptions} = require('src/utils/variationUtils');

export const useProductPropertyToVariationHook = () => {
	const [allSiteProperties, setProperties] = useFind(getProperties);

	/**
	 * [{values:['s','m']},{values:['1','2','3']}]
	 */
	const [selectedProperties, setSelectedProperties] = useState([]);
	// theese will be used to add new options value to existing options (properties)
	const [pvModalIsVisible, setPvModalVisibility] = useState(null);
	const [propertyToAddTo, setPropertyToAddTo] = useState(null);
	/**
	 * [s1,s2,s3,m1,m2,m3]
	 */
	// const variationsOptions = cartesian();
	/**
	 * [s1,m2,m3]
	 */
	const [variations, setVariations] = useState([]);

	useEffect(() => {
		// this logic if variations is not empty
		// we need to restore delete state for old variants after create new options
		const newVariations = getVariationOptions(selectedProperties);
		newVariations.forEach((vars) => {
			const {deleted = false, price, quantity} = variations.find((v) => v.name === vars.name) ?? {};
			vars.deleted = deleted ?? false;
			vars.price = vars.price ?? price;
			vars.quantity = vars.quantity ?? quantity;
		});
		setVariations(newVariations);
	}, [selectedProperties]);

	const onNewPropertyValueAdded = (newPropertyValue) => {
		const propertyToUpdateIndex = allSiteProperties.findIndex((p) => p.id === propertyToAddTo);
		const _allSiteProperties = [...allSiteProperties];
		_allSiteProperties[propertyToUpdateIndex].values.push(newPropertyValue);
		setProperties(_allSiteProperties);
		// reset
		setPvModalVisibility(false);
		setPropertyToAddTo(null);
	};

	return {
		variations,
		setVariations,
		setProperties,
		allSiteProperties,
		selectedProperties,
		setSelectedProperties,
		pvModalIsVisible,
		setPvModalVisibility,
		propertyToAddTo,
		setPropertyToAddTo,
		onNewPropertyValueAdded,
	};
};
