import {
	Paper,
	TableRow,
	TableHead,
	TableContainer,
	TableCell,
	Table,
	Typography,
	TableBody,
} from '@mui/material';

// rows are array of array
export default function TableWrapper({
	align = 'center',
	dataRows,
	data,
	headerColumns = [],
	spans = [],
	colWidth = [],
}) {
	const getColumnKey = (col) => {
		return col?.key || col;
	};
	const getColumnLabel = (col) => {
		return col?.label || col;
	};

	if (!dataRows && data) {
		dataRows = data.map((row) =>
			headerColumns.map((column, index) => {
				const key = getColumnKey(column);
				const value = row[key];
				if (typeof column.getValue === 'function') {
					return column.getValue(value, row);
				} else {
					return value;
				}
			}),
		);
	}
	return (
		// <div>
		<div className="show-scrollbar">
			<Paper>
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								{headerColumns.map((column, index) => (
									<TableCell
										style={{minWidth: colWidth[index]}}
										key={index}
										colSpan={spans[index]}
										align={align}
									>
										{getColumnLabel(column)}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{dataRows.map((row, rowIndex) => (
								<TableRow key={rowIndex}>
									{row.map((column, index) => (
										<TableCell
											key={index}
											style={{minWidth: colWidth[index]}}
											colSpan={spans[index]}
											align={align}
										>
											{headerColumns[index]?.render ? (
												headerColumns[index].render(column, data[rowIndex])
											) : (
												<Typography>{column}</Typography>
											)}
										</TableCell>
									))}
								</TableRow>
								// <StyledTableCell align="center">{col1}</StyledTableCell>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</div>
	);
}
